import React from "react";
import LOGBOOK_LABEL from "./../utils/constants/logbookLabel";
import { OwcTypography } from "@one/react";
import styles from "./WelcomeScreen.module.scss";

const WelcomeScreen = ({ text = "You must login to view Log Book" }) => {
  return (
    <div
      className={styles.welcomeScreenWrapper}
      data-testid="main-page-not-authenticated-with-no-error-by-backend"
    >
      <OwcTypography style={{ color: "inherit" }} variant="title2">
        {LOGBOOK_LABEL.app_name}
      </OwcTypography>
      <OwcTypography style={{ color: "inherit" }} variant="title2">
        Welcome
      </OwcTypography>
      <div className={styles.wrapTitle5}>
        <OwcTypography style={{ color: "inherit" }} variant="title5">
          {text}
        </OwcTypography>
      </div>
    </div>
  );
};

export default WelcomeScreen;
