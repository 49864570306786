import styled from "styled-components";
import { useFormikContext } from "formik";
import { ExpandRow, TwoColumnsBox, Title } from "./FormsStyled";
import { useContext, useState, useEffect } from "react";
import { capitalize, find } from "lodash";
import { ItemDialogFormContext } from "./item-context/context";
import ItemFormSuggester from "./suggester/ItemFormSuggester";
import ItemFormSuggestionPicker from "./suggester/ItemFormSuggestionPicker";
import { useSelector } from "react-redux";
import {
  replaceEmptyWithHyphen,
  validateSelection,
  valueOrEmpty
} from "../../../utils/helpers/text";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import COMMON_LOGS_DATA_MODEL from "./../../../utils/constants/commonLogsDataModel";
import ACTION_LOGS_DATA_MODEL from "../../../utils/constants/actionLogsDataModel";

import { loadInstrumentSuggestion } from "./redux/actions";
import { connect } from "react-redux";
import { generateID } from "@digitallab/grid-common-components";
import {
  Module,
  formStates,
  equipmentStatus,
  formTypes
} from "../../../constants";
import { OwcChip, OwcInput, OwcTypography } from "@one/react";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";

const InstrumentInfoRow = styled(ExpandRow)`
  font-size: 14px;
  line-height: 20px;
  margin-right: 7rem;
  display: grid;
  ${(props) => props.error && `color: #CC0033;`}
`;

const EquipmentDetailsDiv = styled.div`
  display: flex;
`;

const EquipmentIdOrSerialNoMessage = styled.span`
  font-size: 12px;
  line-height: 23px;
  font-weight: 400;
  margin-top: -20px;
`;

const TypeStyles = styled.span`
  font-weight: 500;
`;

const ChipBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  flex-wrap: wrap;
  > * {
    margin: 0 4px;
  }

  > :last-child {
    margin: 0;
  }
`;

const InstrumentInfoAndChips = styled(ExpandRow)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;

  ${ChipBox} {
    flex: 0 1 auto;
  }
`;

const InstrumentIdentyficationBox = ({
  clearSuggestionFn,
  selectSuggestionFn,
  showChipBox,
  loadInstrumentSuggestion,
  equipmentDetail,
  type
}) => {
  const formik = useFormikContext();
  const instrumentSuggestion = useSelector(
    (state) => state.runLogsForm.instrumentSuggestion
  );
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const systemStatuss = useSelector((store) => store.runLogsForm.systemStatuss);
  const { isInventoryId, setEquipmentActionObject } = useContext(
    ItemDialogFormContext
  );
  const { formState } = useContext(CoversheetMainPageContext);
  const [focusedOn, setFocusedOn] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [equipmentSuggDetail, setEquipmentSuggDetail] = useState(null);
  const user = useSelector((state) => state.user);
  const handleFocusFactory = (on) => (event) => {
    setFocusedOn(on);
    setAnchorEl(event.target);
  };
  const handleBlur = () => {
    setFocusedOn(null);
    setAnchorEl(null);
  };
  useEffect(() => {
    if (isInventoryId && equipmentDetail) {
      selectSuggestionFn(equipmentDetail, {
        loadInstrumentSuggestion,
        formik,
        user
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let gxpReady;
    let systemStatus;
    if (instrumentSuggestion) {
      gxpReady = instrumentSuggestion?.qualificationStatus;
      systemStatus = instrumentSuggestion?.equipSystemStatus;
      formik.setFieldValue(
        "inventoryId",
        instrumentSuggestion?.inventoryId,
        true
      );
      setEquipmentSuggDetail(() => instrumentSuggestion);
      setEquipmentActionObject(instrumentSuggestion);
    } else if (equipmentDetail) {
      gxpReady = equipmentDetail?.qualificationStatus;
      systemStatus = equipmentDetail?.equipSystemStatus;
      setEquipmentSuggDetail(() => equipmentDetail);
      setEquipmentActionObject(instrumentSuggestion);
    } else {
      setEquipmentSuggDetail(() => null);
      setEquipmentActionObject(null);
    }
    if (type === formTypes?.RUN_LOG) {
      formik.setFieldValue(
        "gxpReady",
        find(gxpReadys, {
          key: gxpReady
        }) || {
          key: "-",
          value: "-"
        }
      );

      formik.setFieldValue(
        "systemStatus",
        find(systemStatuss, {
          value: systemStatus
        }) || {
          key: "-",
          value: "-"
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instrumentSuggestion, equipmentDetail]);
  return (
    <TwoColumnsBox>
      <Title data-testid="instrument-identification-title">
        {LOGBOOK_LABEL.eqipment_identification_heading}
      </Title>
      {formState === formStates?.NEW_FORM && (
        <>
          <OwcInput
            style={{ width: "100%" }}
            onBlur={(event) => {
              handleBlur(event);
              formik.handleBlur(event);
            }}
            onFocus={handleFocusFactory("equipmentId")}
            data-testid="instrument-identyfication-equipmentId-input"
            color="primary"
            variant="filled"
            id={generateID.textBoxID(
              Module.POPUP_FIELDS.equipmentId,
              "textbox"
            )}
            name="equipmentId"
            label={COMMON_LOGS_DATA_MODEL.equipmentId.value}
            value={valueOrEmpty(formik.values.equipmentId, false, "")}
            onInputChange={(event) => {
              formik.handleChange(event);
            }}
            onInputKeyDown={(e) => {
              formik.setFieldValue("inventoryId", "", true);
              if (instrumentSuggestion) {
                clearSuggestionFn({
                  loadSuggestion: loadInstrumentSuggestion,
                  formik,
                  user,
                  equipmentDetail
                });
              }
            }}
          />
          <OwcInput
            style={{ width: "100%" }}
            onBlur={(event) => {
              handleBlur(event);
              formik.handleBlur(event);
            }}
            onFocus={handleFocusFactory("serialNumber")}
            data-testid="instrument-identyfication-serial-number-input"
            color="primary"
            variant="filled"
            id={generateID.textBoxID(
              Module.POPUP_FIELDS.serialNumber,
              "textbox"
            )}
            name="serialNumber"
            label={COMMON_LOGS_DATA_MODEL.serialNumber.value}
            value={valueOrEmpty(formik.values.serialNumber, false, "")}
            onInputChange={(event) => {
              formik.handleChange(event);
            }}
            onInputKeyDown={(e) => {
              formik.setFieldValue("inventoryId", "", true);
              if (instrumentSuggestion) {
                clearSuggestionFn({
                  loadSuggestion: loadInstrumentSuggestion,
                  formik,
                  user,
                  equipmentDetail
                });
              }
            }}
          />
          <EquipmentIdOrSerialNoMessage>
            {LOGBOOK_LABEL.equipmentId_serialNo_message}
          </EquipmentIdOrSerialNoMessage>
        </>
      )}
      {equipmentSuggDetail && (
        <InstrumentInfoAndChips>
          <EquipmentDetailsDiv>
            {formState !== formStates?.NEW_FORM && (
              <>
                <InstrumentInfoRow data-testid="instrument-identyfication-equipment-model-label">
                  {COMMON_LOGS_DATA_MODEL.equipmentId.value}:{" "}
                  <TypeStyles data-testid="instrument-identyfication-equipment-model-value">
                    {valueOrEmpty(equipmentSuggDetail?.equipmentId, false, "-")}
                  </TypeStyles>
                </InstrumentInfoRow>
                <InstrumentInfoRow data-testid="instrument-identyfication-equipment-model-label">
                  {COMMON_LOGS_DATA_MODEL.serialNumber.value}:{" "}
                  <TypeStyles data-testid="instrument-identyfication-equipment-model-value">
                    {valueOrEmpty(
                      equipmentSuggDetail?.serialNumber,
                      false,
                      "-"
                    )}
                  </TypeStyles>
                </InstrumentInfoRow>
              </>
            )}
            <InstrumentInfoRow data-testid="instrument-identyfication-equipment-model-label">
              {COMMON_LOGS_DATA_MODEL.equipmentModel.value}:{" "}
              <TypeStyles data-testid="instrument-identyfication-equipment-model-value">
                {valueOrEmpty(equipmentSuggDetail?.equipmentModel, false, "-")}
              </TypeStyles>
            </InstrumentInfoRow>
            <InstrumentInfoRow data-testid="instrument-identyfication-equipment-nickName-label">
              {COMMON_LOGS_DATA_MODEL.equipmentNickName.value}:{" "}
              <TypeStyles data-testid="instrument-identyfication-equipment-nickName-value">
                {valueOrEmpty(
                  equipmentSuggDetail?.equipmentNickName,
                  false,
                  "-"
                )}
              </TypeStyles>
            </InstrumentInfoRow>
            {showChipBox && (
              <>
                <ChipBox data-testid="run-data-chip-box">
                  <div data-testid="run-data-chip-box-col-label-for-gxp">
                    {ACTION_LOGS_DATA_MODEL.gxpReady.value}:
                  </div>
                  <OwcChip
                    outlined
                    data-testid="run-data-chip-box-col-value-for-gxp"
                  >
                    <OwcTypography variant="badge">
                      {equipmentSuggDetail?.qualificationStatus
                        ? replaceEmptyWithHyphen(
                            validateSelection(gxpReadys, {
                              key: equipmentSuggDetail?.qualificationStatus
                            })
                          )
                        : "-"}
                    </OwcTypography>
                  </OwcChip>
                </ChipBox>
                <ChipBox>
                  <div data-testid="run-data-chip-box-col-label-for-system-status">
                    {ACTION_LOGS_DATA_MODEL.systemStatus.value}:
                  </div>
                  <OwcChip
                    outlined
                    data-testid="run-data-chip-box-col-value-for-system-status"
                  >
                    <OwcTypography variant="badge">
                      {equipmentSuggDetail?.equipSystemStatus
                        ? equipmentSuggDetail?.equipSystemStatus
                        : "-"}
                    </OwcTypography>
                  </OwcChip>
                </ChipBox>

                <ChipBox>
                  <div data-testid="run-data-chip-box-col-label-for-system-status">
                    {ACTION_LOGS_DATA_MODEL.status.value}:
                  </div>
                  <OwcChip
                    outlined
                    data-testid="run-data-chip-box-col-value-for-system-status"
                  >
                    {equipmentSuggDetail?.status ===
                      equipmentStatus?.active?.key && (
                      <OwcTypography
                        variant="badge"
                        style={{
                          color:
                            "var(--one-color-interaction-default-positive-1)"
                        }}
                      >
                        {capitalize(equipmentSuggDetail?.status)}
                      </OwcTypography>
                    )}
                    {equipmentSuggDetail?.status ===
                      equipmentStatus?.pending?.key && (
                      <OwcTypography
                        variant="badge"
                        style={{
                          color:
                            "var(--one-color-interaction-default-warning-1)"
                        }}
                      >
                        {capitalize(equipmentSuggDetail?.status)}
                      </OwcTypography>
                    )}
                  </OwcChip>
                </ChipBox>
              </>
            )}
          </EquipmentDetailsDiv>
        </InstrumentInfoAndChips>
      )}
      {(formik.touched.equipmentId || formik.touched.serialNumber) &&
        !equipmentSuggDetail && (
          <InstrumentInfoRow
            data-testid="instrument-identification-error"
            error
          >
            {LOGBOOK_LABEL.equipmentId_serialNo_error_msg}
            {capitalize(user?.site || "")}
            {LOGBOOK_LABEL.equipmentId_serialNo_error_msg_1}
            {capitalize(user?.site || "")}
            {LOGBOOK_LABEL.equipmentId_serialNo_error_msg_2}
          </InstrumentInfoRow>
        )}

      <ItemFormSuggester
        clearSuggestionFn={clearSuggestionFn}
        focusedOn={focusedOn}
        selectSuggestionFn={selectSuggestionFn}
        equipmentDetail={equipmentDetail}
      />
      {!isInventoryId && (
        <ItemFormSuggestionPicker
          anchorEl={anchorEl}
          selectSuggestionFn={selectSuggestionFn}
          PopoverStyle={{ maxHeight: "50%" }}
        />
      )}
    </TwoColumnsBox>
  );
};

export default connect(null, { loadInstrumentSuggestion })(
  InstrumentIdentyficationBox
);
