import { useEffect, useState } from "react";
import { withApollo } from "react-apollo";
import { get, sortBy } from "underscore";
import { HistoryItemContext } from "./context";
import HistoryItemDialog from "./HistoryItemDialog";

const HistoryItemWrapper = ({
  client,
  children,
  queryName,
  importFn,
  CellsInfoArray,
  title,
  dataTestId,
  addButtonLabel,
  disableStatus = false
}) => {
  const [open, setOpen] = useState(false);
  const [historyItem, setHistoryItem] = useState(null);
  const [item, setItem] = useState(null);
  const handleOpenHistoryContent = (theItem) => {
    setOpen(true);
    setItem(theItem);
  };
  const handleCloseHistoryContent = () => {
    setOpen(false);
    setItem(null);
  };

  useEffect(() => {
    const fetchHistory = async () => {
      if (item) {
        const data = await importFn({ ...item }, { client });
        setHistoryItem(
          sortBy(get(data, [queryName, "items"]) ?? [], "createdAt").reverse()
        );
      }
    };

    fetchHistory();
  }, [item, client, importFn, queryName]);

  return (
    <HistoryItemContext.Provider
      value={{
        open,
        handleOpenHistoryContent,
        handleCloseHistoryContent,
        historyItem,
        item
      }}
    >
      {children}
      <HistoryItemDialog
        selectedItem={item}
        open={open}
        handleClose={handleCloseHistoryContent}
        CellsInfoArray={CellsInfoArray}
        title={title}
        dataTestId={dataTestId}
        addButtonLabel={addButtonLabel}
        disableStatus={disableStatus}
      />
    </HistoryItemContext.Provider>
  );
};

export default withApollo(HistoryItemWrapper);
