import { OwcCard, OwcIcon, OwcTooltip, OwcTypography } from "@one/react";

import { generateID } from "@digitallab/grid-common-components";
import {
  LinkContainerDivInnerLeft,
  LinkContainerDivInnerRight,
  LinkContainerDivView,
  LinkContainerStyled
} from "./log-book-item-form-dialog/FormsStyled";

const ActionLogLinkDetailView = ({ externalDocuments }) => {
  return externalDocuments !== null && externalDocuments.length > 0 ? (
    <div style={{ margin: "10px 0" }}>
      <OwcCard
        flat
        style={{
          padding: "0px",
          width: "625px",
          border: "1px solid var(--one-color-interaction-default-neutral-2)"
        }}
      >
        <div
          slot="header"
          style={{
            display: "flex",
            background: "var(--one-color-background-neutral-2)",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-3px",
            padding: "10px"
          }}
        >
          <OwcTypography
            variant="button"
            id={generateID.UUID(
              "AddLinkHeadingBanner",
              `addLinkHeadingBanner`,
              "type_title"
            )}
          >
            <strong>Link list</strong>
          </OwcTypography>
        </div>

        <LinkContainerStyled
          data-testid="link-block"
          id={generateID.UUID("LinkBlock", `link_block`, "div")}
          style={{ maxHeight: "150px", height: "auto" }}
        >
          {externalDocuments?.map((item, index) => {
            return (
              <LinkContainerDivView>
                <LinkContainerDivInnerLeft>
                  <OwcIcon type="outlined" name="link"></OwcIcon>
                  <OwcTypography
                    variant="subtitle2"
                    id={generateID.UUID(
                      "LinkBlockName",
                      `link_block_name`,
                      `div-${index}`
                    )}
                    style={{
                      marginLeft: "5px",
                      color: "var(--one-color-interaction-default-brand-1)",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      wordWrap: "break-word",
                      width: "415px"
                    }}
                  >
                    {item.name}
                  </OwcTypography>
                  <OwcTooltip
                    anchor={generateID.UUID(
                      "LinkBlockName",
                      `link_block_name`,
                      `div-${index}`
                    )}
                  >
                    {item.name}
                  </OwcTooltip>
                </LinkContainerDivInnerLeft>
                <LinkContainerDivInnerRight>
                  <div
                    style={{ cursor: "pointer", marginRight: "5px" }}
                    onClick={() => {
                      window.open(`${item.link}`);
                    }}
                  >
                    <OwcIcon
                      name="launch"
                      type="filled"
                      variant="primary"
                      id={generateID.UUID(
                        "LinkBlockEdit",
                        `link_block`,
                        `icon-${index}`
                      )}
                    ></OwcIcon>
                    <OwcTooltip
                      anchor={generateID.UUID(
                        "LinkBlockEdit",
                        `link_block`,
                        `icon-${index}`
                      )}
                    >
                      {"Link"}
                    </OwcTooltip>
                  </div>
                </LinkContainerDivInnerRight>
              </LinkContainerDivView>
            );
          })}
        </LinkContainerStyled>
      </OwcCard>
    </div>
  ) : (
    <></>
  );
};

export default ActionLogLinkDetailView;
