import React from "react";
import styled from "styled-components";
import { get } from "underscore";

export const TextFieldStyles = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
`;

export const LabelForFieldStyles = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
`;
export const Details = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsInfoInstruments = ({ item, dataSource, key }) => {
  let value;
  if (item.component) {
    const Component = item.component;
    value = <Component values={dataSource} />;
  } else {
    value = get(dataSource, item.field) ? get(dataSource, item.field) : "-";
  }

  return (
    <Details data-testid="cover-sheet-expanded-content-details-row" key={key}>
      <LabelForFieldStyles
        data-testid={`cover-sheet-expanded-content-details-row-label-for${item.label}`}
      >
        {item.label}
      </LabelForFieldStyles>
      <TextFieldStyles
        data-testid={`cover-sheet-expanded-content-details-row-value-for${item.label}`}
      >
        {value && value === "null" ? "-" : value}
      </TextFieldStyles>
    </Details>
  );
};
