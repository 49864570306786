import {
  createPDF,
  createHTMLPDF,
  createSnapShotPDF,
  createAllLogPDF,
  createRunLogPDF,
  createActionLogPDF
} from "./pdf-helpers";
import { format } from "date-fns";
import RocheLogo from "../../../images/roche.png";

const fileExtension = ".pdf";
export const raportTitleDefault = "Log Book Report";

export const FILTER_DICTIONARY_MAP = {
  equipmentModel: "Model",
  dateFrom: "From date",
  dateTo: "To date",
  instrument: "Equipment ID/serial nr",
  signee: "Signee"
};
const returnFormattedValue = (theObject, theKey) => {
  const value = theObject?.[theKey];
  if (theKey === "dateTo" || theKey === "dateFrom") {
    const date = new Date(value);
    if (date instanceof Date && !isNaN(date)) {
      return format(date, "dd-MMM-yyyy");
    } else {
      return value;
    }
  } else {
    return value;
  }
};

export const getFilterDescription = (
  filterObj = {},
  filterObj1 = {},
  dictionaryMap = FILTER_DICTIONARY_MAP
) => {
  if (!filterObj || !dictionaryMap) {
    return "-";
  }
  if (filterObj.instrument) {
    delete filterObj.equipmentModel;
  }
  if (filterObj?.signee && filterObj?.signee?.length === 0) {
    filterObj.signee = null;
  }
  if (filterObj1?.logReport === "changeLog") {
    const description = Object.keys(filterObj)
      ?.reduce(
        (acc, key) =>
          filterObj?.[key]
            ? `${acc}${FILTER_DICTIONARY_MAP[key]}${
                key === "dateFrom"
                  ? "\xa0".repeat(7)
                  : key === "dateTo"
                  ? "\xa0".repeat(11)
                  : "\xa0".repeat(12)
              }${returnFormattedValue(filterObj, key)}, `
            : acc,
        ""
      )
      ?.slice(0, -2);
    let formattedString = "-";
    if (description) {
      formattedString = description.split(", ").join("\n\n");
    }
    return formattedString;
  } else {
    const description = Object.keys(filterObj)
      ?.reduce(
        (acc, key) =>
          filterObj?.[key]
            ? `${acc}${FILTER_DICTIONARY_MAP[key]} = ${returnFormattedValue(
                filterObj,
                key
              )}, `
            : acc,
        ""
      )
      ?.slice(0, -2);
    return description ? description : "-";
  }
};

export const exportToPDF = async ({
  paginationContext: { sortDirection },
  customFilterContext: {
    signee,
    equipmentModel,
    instrument,
    dateFrom,
    dateTo,
    signeeChangeLog
  },
  searchTextContext: { logListData },
  storeUser,
  client,
  fetchingFn,
  fileNamePrefix,
  raportTitle = raportTitleDefault,
  mapFn,
  appliedSearchText,
  getMetaDescription = getFilterDescription,
  inventoryId,
  logType,
  allLogHierarchyData,
  logBookEquipment,
  storeGxpReadys
}) => {
  /*const { items } = await fetchingFn({
    client,
    signee,
    equipmentModel,
    instrument,
    dateFrom,
    dateTo,
    sortDirection,
    limit: 200,
    nextToken: null,
    drillData: true,
    storeUser,
    inventoryId,
    logType
  });*/
  const fileName = `${fileNamePrefix}-${format(new Date(), "dd-MMM-yyyy")}`;
  const tablePDF = mapFn(
    logListData,
    allLogHierarchyData,
    logBookEquipment,
    inventoryId,
    storeGxpReadys
  );
  if (fileNamePrefix === "change-logs-report") {
    const doc = await createHTMLPDF(tablePDF?.records, {
      createdBy: storeUser?.name || storeUser?.email,
      title: raportTitle,
      filters: getMetaDescription(
        {
          equipmentModel,
          instrument: appliedSearchText,
          dateFrom,
          dateTo,
          signee: signeeChangeLog
        },
        { logReport: "changeLog" }
      ),
      tableHeaders: tablePDF?.headers,
      image: {
        src: RocheLogo
      },
      file: {
        fileName: fileName + fileExtension
      }
    });
    return doc;
  } else if (fileNamePrefix === "all-logs-report") {
    const doc = await createAllLogPDF(tablePDF?.records, {
      createdBy: storeUser?.name || storeUser?.email,
      title: raportTitle,
      filters: getMetaDescription({
        equipmentModel,
        instrument: appliedSearchText,
        dateFrom,
        dateTo,
        signee
      }),
      tableHeaders: tablePDF?.headers,
      image: {
        src: RocheLogo
      },
      // hierarchy: tablePDF?.hierarchy,
      entryType: tablePDF?.entryType,
      hierarchyHeaders: tablePDF?.hierarchyHeaders,
      hierarchyRecords: tablePDF?.hierarchyRecords,
      attributes: tablePDF?.attributes,
      file: {
        fileName: fileName + fileExtension
      }
    });
    if (tablePDF?.entryType) {
      doc.save(fileName + fileExtension);
      // window.open(doc.output("bloburl"), "_blank");
    }
    return doc;
  } else if (fileNamePrefix === "run-logs-report" && inventoryId) {
    const doc = await createRunLogPDF(tablePDF?.records, {
      createdBy: storeUser?.name || storeUser?.email,
      title: raportTitle,
      filters: getMetaDescription({
        equipmentModel,
        instrument: appliedSearchText,
        dateFrom,
        dateTo,
        signee
      }),
      tableHeaders: tablePDF?.headers,
      image: {
        src: RocheLogo
      },
      // hierarchy: tablePDF?.hierarchy,
      entryType: tablePDF?.entryType,
      hierarchyHeaders: tablePDF?.hierarchyHeaders,
      hierarchyRecords: tablePDF?.hierarchyRecords,
      attributes: tablePDF?.attributes,
      file: {
        fileName: fileName + fileExtension
      }
    });
    if (tablePDF?.entryType) {
      doc.save(fileName + fileExtension);
      // window.open(doc.output("bloburl"), "_blank");
    }
    return doc;
  } else if (fileNamePrefix === "action-logs-report" && inventoryId) {
    const doc = await createActionLogPDF(tablePDF?.records, {
      createdBy: storeUser?.name || storeUser?.email,
      title: raportTitle,
      filters: getMetaDescription({
        equipmentModel,
        instrument: appliedSearchText,
        dateFrom,
        dateTo,
        signee
      }),
      tableHeaders: tablePDF?.headers,
      image: {
        src: RocheLogo
      },
      // hierarchy: tablePDF?.hierarchy,
      entryType: tablePDF?.entryType,
      hierarchyHeaders: tablePDF?.hierarchyHeaders,
      hierarchyRecords: tablePDF?.hierarchyRecords,
      attributes: tablePDF?.attributes,
      file: {
        fileName: fileName + fileExtension
      }
    });
    if (tablePDF?.entryType) {
      doc.save(fileName + fileExtension);
      // window.open(doc.output("bloburl"), "_blank");
    }
    return doc;
  } else {
    const doc = await createPDF(tablePDF?.records, {
      createdBy: storeUser?.name || storeUser?.email,
      title: raportTitle,
      filters: getMetaDescription({
        equipmentModel,
        instrument: appliedSearchText,
        dateFrom,
        dateTo,
        signee
      }),
      tableHeaders: tablePDF?.headers,
      image: {
        src: RocheLogo
      }
    });
    doc.save(fileName + fileExtension);
    return doc;
  }
};

export const exportToSnapshotPDF = async ({
  storeUser,
  fileNamePrefix,
  shortIdentifier = "-",
  date,
  time,
  records
}) => {
  const fileName = `${fileNamePrefix}-${format(new Date(), "dd-MMM-yyyy")}`;
  const headerText =
    date && time
      ? `Snapshot - ${shortIdentifier} valid on ${date} ${time}`
      : `Snapshot - ${shortIdentifier}`;
  const tablePDF = {
    headers: [
      {
        header: headerText,
        value: 120
      },
      {
        header: "",
        value: "auto"
      }
    ],
    records
  };

  const doc = await createSnapShotPDF(tablePDF?.records, {
    createdBy: storeUser?.name || storeUser?.email,
    title: `Cover Sheet - ${shortIdentifier}`,
    filters: date && time ? `Date = ${date}, Time = ${time}` : "-",
    tableHeaders: tablePDF?.headers,
    image: {
      src: RocheLogo
    }
  });
  doc.save(fileName + fileExtension);
  return doc;
};
