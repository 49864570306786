import React from "react";
import { OwcEditableDropdown, OwcListItem } from "@one/react";
import { generateID } from "@digitallab/grid-common-components";

const CommonOwcEditableDropDown = ({
  labelValue,
  style,
  list,
  selected = null,
  label,
  propValue,
  defaultLabel,
  onChange,
  helperText,
  onClose,
  onOpen,
  required = false,
  disabled = false,
  sopHelperText = false
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          padding: sopHelperText ? "8px 8px 0px 0px" : ""
        }}
      >
        <OwcEditableDropdown
          style={style}
          label={labelValue ? label : selected !== null ? defaultLabel : label}
          placeholder={labelValue ? "" : label}
          value={selected}
          onSelectChange={(e) => {
            onChange(e.detail);
          }}
          required={required}
          onPanelOpen={onOpen}
          disabled={disabled}
          onPanelClose={onClose}
          requiredText={helperText}
        >
          {list?.map((item, index) => {
            const option = item?.[propValue] || item;
            return (
              <>
                <OwcListItem
                  value={option}
                  key={option}
                  data-testid={`${label}-${option}`}
                  id={generateID.UUID(
                    label,
                    `EditableDropDown_${label}-${option}`,
                    "item"
                  )}
                >
                  {option}
                </OwcListItem>
              </>
            );
          })}
        </OwcEditableDropdown>
      </div>
    </div>
  );
};

export default CommonOwcEditableDropDown;
