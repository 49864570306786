import { useFormikContext } from "formik";
import styled from "styled-components";
import {
  changeDateFormat,
  commonPropsForInputsWithoutValue
} from "../../helpers";
import {
  SOFTWARE_CHANGE,
  equipmentStatus,
  formStates,
  formTypes
} from "../../../../constants";
import {
  ExpandRow,
  Mainbox,
  Title,
  TwoCell,
  EmptyLinkContainerStyled,
  EmptyLinkContainer,
  LinkContainerDiv,
  LinkContainerDivInnerLeft,
  LinkContainerDivInnerRight,
  LinkContainerStyled
} from "../../log-book-item-form-dialog/FormsStyled";
import { useSelector } from "react-redux";
import RunSelectInput from "../../run-logs/run-logs-form/run-data-inputs/RunSelectInput";
import { useContext, useEffect, useState } from "react";
import LOGBOOK_LABEL from "../../../../utils/constants/logbookLabel";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";
import {
  OwcCard,
  OwcCheckbox,
  OwcDatepicker,
  OwcIcon,
  OwcInput,
  OwcTextarea,
  OwcTooltip,
  OwcTypography
} from "@one/react";
import RunTimeInput from "../../run-logs/run-logs-form/run-data-inputs/RunTimeInput";
import CommonOwcEditableDropDown from "../../../../components/shared/CommonOwcEditableDropDown";
import { find } from "lodash";
import { CoversheetMainPageContext } from "../../../cover-sheet/coversheet-main-page-context/context";
import IconEmptyBox from "../../../../icons/IconEmptyBox";
import { ItemDialogFormContext } from "../../log-book-item-form-dialog/item-context/context";

const Container = styled.div`
  & > div > div > .MuiInputBase-root {
    padding-top: 12px;
  }
`;

const UpdateVersionShouldBePublishedStyled = styled.div`
  background-color: #fafafa;
  padding: 3px 12px;
  border-radius: 4px;
`;
const ActionDataInputsBox = () => {
  const formik = useFormikContext();
  const classes = {};
  const [actionTime, setActionTime] = useState(null);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const actionLog = useSelector((store) => store.runLogsForm.actionLog);
  const systemStatuss = useSelector((store) => store.runLogsForm.systemStatuss);
  const { formState } = useContext(CoversheetMainPageContext);
  const {
    setActionLogLinkName,
    setActionLogLinkUrl,
    setActionLogLinkCurrentIndex,
    setActionLogLinkCurrentFlag,
    setAddLinkFlag,
    equipmentActionObject
  } = useContext(ItemDialogFormContext);
  const [selectedActionType, setSelectedActionType] = useState(null);
  const dateValue = (value) => {
    if (
      (value.detail instanceof Date && !isNaN(value.detail)) ||
      value.detail === null
    ) {
      formik.setFieldValue("actionDate", value.detail, true);
    }
  };
  const openLinkPopup = (str) => {
    setActionLogLinkCurrentFlag(str);
  };

  useEffect(() => {
    if (formik?.values?.action) {
      setSelectedActionType(() => {
        if (formik?.values?.action?.key) {
          const obj = find(actionLog, { value: formik?.values?.action?.value });
          if (!obj) {
            formik.setFieldError("action", "Enter action type");
          }
          if (
            obj &&
            obj?.value === SOFTWARE_CHANGE &&
            formState !== formStates?.EDITABLE_FORM &&
            equipmentActionObject?.status === equipmentStatus?.pending?.key
          ) {
            formik.setFieldValue(
              "updatedSoftwareVersion.newValue",
              equipmentActionObject?.softwareVersion !== "null"
                ? equipmentActionObject?.softwareVersion
                : "",
              true
            );
            formik.setFieldValue(
              "updatedSoftwareVersion.shouldBePublished",
              false,
              true
            );
          }

          return obj ? obj.value : null;
        }

        return null;
      });
    } else {
      setSelectedActionType(() => null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.action]);

  useEffect(() => {
    if (formState !== formStates?.EDITABLE_FORM) {
      formik.setFieldValue(
        "gxpReady",
        find(gxpReadys, {
          key: equipmentActionObject?.qualificationStatus
        }) || {
          key: "-",
          value: "-"
        }
      );

      formik.setFieldValue(
        "systemStatus",
        find(systemStatuss, {
          value: equipmentActionObject?.equipSystemStatus
        }) || null
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentActionObject]);
  useEffect(() => {
    if (formState !== formStates?.EDITABLE_FORM) {
      let time = formik?.values?.actionTime
        ? new Date(
            changeDateFormat(new Date()) + " " + formik?.values?.actionTime
          )
        : null;

      setActionTime(time);
    } else {
      const tempActionTime = formik?.values?.actionTime
        ? new Date(
            changeDateFormat(new Date()) + " " + formik?.values?.actionTime
          )
        : null;
      setActionTime(tempActionTime);
      formik.setFieldValue(
        "updatedSoftwareVersion.shouldBePublished",
        false,
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.actionTime]);
  const onSelectedActionType = (actionType) => {
    if (formik?.values?.action?.key !== actionType?.key) {
      formik.setValues({
        ...formik.values,
        action: actionType
      });
      setSelectedActionType(() => actionType?.value ?? null);
    }
  };
  return (
    <>
      <Mainbox>
        <Title data-testid="action-data-title">
          {LOGBOOK_LABEL.action_data}
        </Title>
        <OwcDatepicker
          style={{ width: "100%" }}
          autoClose
          inputVariant="filled"
          onBlur={formik.handleBlur}
          format="dd-MMM-yyyy"
          data-testid="action-data-inputs-action-date-input"
          id={generateID.UUID(Module.POPUP_FIELDS.actionDate, 1, "datePicker")}
          maxDate={new Date().getTime()}
          name="actionDate"
          variant="filled"
          label={`${ACTION_LOGS_DATA_MODEL.actionDate.value} *`}
          value={
            typeof formik.values.actionDate === "string"
              ? new Date(formik.values.actionDate)
              : formik.values.actionDate
          }
          onValueChange={(e) => dateValue(e)}
          invalidDateMessage={"Invalid date format"}
          required={true}
          requiredDateMessage={formik.errors.actionDate}
        >
          {formik.errors.actionDate && (
            <span slot="error-text">{formik.errors.actionDate}</span>
          )}
        </OwcDatepicker>

        <RunTimeInput
          property={ACTION_LOGS_DATA_MODEL.actionTime.key}
          label={ACTION_LOGS_DATA_MODEL.actionTime.value}
          testid="action-start-time"
          timeObj={actionTime}
          setTimeObj={setActionTime}
          required={true}
          maxTime={new Date().getTime()}
          logType={formTypes?.ACTION_LOG}
        />

        <Container>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={ACTION_LOGS_DATA_MODEL.actionType.value + ` *`}
            defaultLabel={ACTION_LOGS_DATA_MODEL.actionType.value + ` *`}
            propValue="value"
            list={actionLog}
            id={generateID.UUID(Module.POPUP_FIELDS.actionType, 1, "dropDown")}
            selected={selectedActionType}
            onChange={(selectedValue) => {
              const selVal = actionLog?.find(
                (value, index) => index === selectedValue
              );
              onSelectedActionType(selVal ?? null);
            }}
            required={true}
            helperText={
              formik.errors[ACTION_LOGS_DATA_MODEL.actionType.key]?.key
            }
          />
        </Container>

        <OwcInput
          style={{ width: "100%" }}
          onBlur={formik.handleBlur}
          data-testid="action-data-inputs-operator-user-name-input"
          color="primary"
          variant="filled"
          id={generateID.textBoxID(
            Module.POPUP_FIELDS.actionOperatorUserName,
            "textbox"
          )}
          name="operatorUserName"
          label={COMMON_LOGS_DATA_MODEL.operatorUserName.value}
          value={formik.values.operatorUserName}
          onInputChange={formik.handleChange}
          {...commonPropsForInputsWithoutValue(
            classes,
            formik,
            "operatorUserName",
            "operator-user-name",
            "action-data-inputs"
          )}
        />
        <OwcInput
          onBlur={formik.handleBlur}
          style={{ width: "100%" }}
          data-testid="action-data-inputs-ecrNumber-input"
          variant="filled"
          fullWidth
          id={generateID.textBoxID(Module.POPUP_FIELDS.ecrNumber, "textbox")}
          name="ecrNumber"
          label={ACTION_LOGS_DATA_MODEL.ecrNumber.value}
          value={formik.values.ecrNumber}
          onInputChange={formik.handleChange}
          {...commonPropsForInputsWithoutValue(
            classes,
            formik,
            "ecrNumber",
            "ecrNumber",
            "action-data-inputs"
          )}
        />
        <div>
          <RunSelectInput
            objectAsValue
            testidPrefix="action-data"
            options={gxpReadys}
            property="gxpReady"
            Selectlabel={ACTION_LOGS_DATA_MODEL.gxpReady.value}
            testid="gxpReady"
            propValue="key"
            propLabel="value"
            required
            fullWidth
            disabled={
              formState !== formStates?.EDITABLE_FORM &&
              equipmentActionObject?.status === equipmentStatus?.pending?.key
                ? true
                : false
            }
          />
          {formState !== formStates?.EDITABLE_FORM && (
            <OwcTypography variant="body1" style={{ marginLeft: "10px" }}>
              {equipmentActionObject?.status === equipmentStatus?.pending?.key
                ? "Will not be propagated to repository"
                : "Propagate to repository"}
            </OwcTypography>
          )}
        </div>
        <div>
          <RunSelectInput
            objectAsValue
            testidPrefix="action-data"
            options={systemStatuss}
            property="systemStatus"
            Selectlabel={ACTION_LOGS_DATA_MODEL.systemStatus.value}
            testid="systemStatus"
            propValue="key"
            propLabel="value"
            required
            fullWidth
            disabled={
              formState !== formStates?.EDITABLE_FORM &&
              equipmentActionObject?.status === equipmentStatus?.pending?.key
                ? true
                : false
            }
          />
          {formState !== formStates?.EDITABLE_FORM && (
            <OwcTypography variant="body1" style={{ marginLeft: "10px" }}>
              {equipmentActionObject?.status === equipmentStatus?.pending?.key
                ? "Will not be propagated to repository"
                : "Propagate to repository"}
            </OwcTypography>
          )}
        </div>
        {formik.values.action &&
          formik.values.action?.value?.toLowerCase() ===
            SOFTWARE_CHANGE.toLowerCase() && (
            <>
              {}
              <TwoCell>
                <OwcInput
                  onBlur={formik.handleBlur}
                  data-testid="action-data-inputs-new-software-version-input"
                  variant="filled"
                  id="updatedSoftwareVersion.newValue"
                  name="updatedSoftwareVersion.newValue"
                  label="New software version"
                  value={formik.values.updatedSoftwareVersion.newValue}
                  onInputChange={formik.handleChange}
                  onInputKeyDown={formik.handleChange}
                  style={{ width: "100%" }}
                  disabled={
                    formState !== formStates?.EDITABLE_FORM &&
                    equipmentActionObject?.status ===
                      equipmentStatus?.pending?.key
                      ? true
                      : false
                  }
                />
              </TwoCell>
              <TwoCell>
                <UpdateVersionShouldBePublishedStyled>
                  <OwcCheckbox
                    data-testid="action-data-inputs-new-software-version-checkbox"
                    checked={
                      formik.values.updatedSoftwareVersion.shouldBePublished
                    }
                    // name="updatedSoftwareVersion.shouldBePublished"
                    onInputChange={(e) =>
                      formik.setFieldValue(
                        "updatedSoftwareVersion.shouldBePublished",
                        e.detail
                      )
                    }
                    id="updatedSoftwareVersion.shouldBePublished"
                    disabled={
                      equipmentActionObject?.status ===
                        equipmentStatus?.pending?.key ||
                      formState === formStates?.EDITABLE_FORM
                        ? true
                        : false
                    }
                  >
                    {LOGBOOK_LABEL.publish_to_ER}
                  </OwcCheckbox>
                </UpdateVersionShouldBePublishedStyled>
              </TwoCell>
            </>
          )}
        <ExpandRow>
          <OwcTextarea
            cols={20}
            data-testid="action-data-inputs-description-input"
            id={generateID.textBoxID(
              Module.POPUP_FIELDS.actionDescription,
              "textbox"
            )}
            label={COMMON_LOGS_DATA_MODEL.description.value + ` *`}
            onInputChange={(e) => {
              formik.setFieldValue("description", e.target.value, true);
            }}
            onInputKeyDown={(e) => {
              formik.setFieldValue("description", e.target.value, true);
            }}
            onInputReset={() => formik.setFieldValue("description", "", true)}
            value={formik.values.description}
            resizable
            reset
            rows={4}
            maxLength={200}
            minLength={0}
          />
        </ExpandRow>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <OwcTypography
            variant="subtitle1"
            id={generateID.UUID(
              "AddLinkHeading",
              `addLinkHeading`,
              "type_title"
            )}
            style={{ padding: "10px 0" }}
          >
            <strong>External document and file links</strong>
          </OwcTypography>

          <OwcCard flat style={{ padding: "0px", width: "200%" }}>
            <div
              slot="header"
              style={{
                display: "flex",
                background: "var(--one-color-background-neutral-2)",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "-3px",
                padding: "10px"
              }}
            >
              <OwcTypography
                variant="button"
                id={generateID.UUID(
                  "AddLinkHeadingBanner",
                  `addLinkHeadingBanner`,
                  "type_title"
                )}
              >
                <strong>Link list</strong>
              </OwcTypography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer"
                }}
              >
                <OwcIcon
                  type="legacy"
                  variant="primary"
                  name="circle_add"
                  onClick={() => {
                    openLinkPopup("Add");
                  }}
                ></OwcIcon>
                <OwcTypography
                  variant="subtitle2"
                  style={{
                    marginLeft: "5px",
                    color: "var(--one-color-interaction-default-brand-1)"
                  }}
                  onClick={() => {
                    openLinkPopup("Add");
                  }}
                >
                  <strong> Add link</strong>
                </OwcTypography>
              </div>
            </div>

            {!formik?.values?.externalDocument ||
            formik?.values?.externalDocument?.length <= 0 ? (
              <EmptyLinkContainerStyled
                data-testid="not-found-apps"
                id={generateID.UUID(
                  "EmptyApplication",
                  `application_empty`,
                  "div"
                )}
              >
                <EmptyLinkContainer
                  onClick={() => {
                    openLinkPopup("Add");
                  }}
                >
                  <OwcIcon className="emptyIconMyLab">
                    <IconEmptyBox />
                  </OwcIcon>
                  <OwcTypography
                    variant="title6"
                    id={generateID.UUID(
                      "PleaseAddLink",
                      `addLink`,
                      "type_title"
                    )}
                  >
                    <strong> Please add link</strong>
                  </OwcTypography>
                  <OwcTypography
                    variant="body2"
                    id={generateID.UUID(
                      "NoLinkAvailable",
                      `NoLinkAvailable`,
                      "type_Description"
                    )}
                  >
                    No links available
                  </OwcTypography>
                </EmptyLinkContainer>
              </EmptyLinkContainerStyled>
            ) : (
              <LinkContainerStyled
                data-testid="link-block"
                id={generateID.UUID("LinkBlock", `link_block`, "div")}
              >
                {formik?.values?.externalDocument?.map((item, index) => {
                  return (
                    <LinkContainerDiv>
                      <LinkContainerDivInnerLeft>
                        <OwcIcon type="outlined" name="link"></OwcIcon>
                        <OwcTypography
                          variant="subtitle2"
                          style={{
                            marginLeft: "5px",
                            color:
                              "var(--one-color-interaction-default-brand-1)"
                          }}
                        >
                          {item.name}
                        </OwcTypography>
                      </LinkContainerDivInnerLeft>
                      <LinkContainerDivInnerRight>
                        <div
                          style={{ cursor: "pointer", marginRight: "5px" }}
                          onClick={() => {
                            setActionLogLinkName(
                              formik?.values?.externalDocument[index]?.name
                            );
                            setActionLogLinkUrl(
                              formik?.values?.externalDocument[index]?.link
                            );
                            setActionLogLinkCurrentIndex(index);
                            setAddLinkFlag(false);
                            openLinkPopup("Edit");
                          }}
                        >
                          <OwcIcon
                            name="write"
                            type="filled"
                            id={generateID.UUID(
                              "LinkBlockEdit",
                              `link_block`,
                              `icon-${index}`
                            )}
                          ></OwcIcon>
                          <OwcTooltip
                            anchor={generateID.UUID(
                              "LinkBlockEdit",
                              `link_block`,
                              `icon-${index}`
                            )}
                          >
                            {"Edit"}
                          </OwcTooltip>
                        </div>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            openLinkPopup("Delete");
                            setAddLinkFlag(true);
                            setActionLogLinkCurrentIndex(index);
                          }}
                        >
                          <OwcIcon
                            type="outlined"
                            name="delete"
                            id={generateID.UUID(
                              "LinkBlockDelete",
                              `link_block`,
                              `icon-${index}`
                            )}
                          ></OwcIcon>
                          <OwcTooltip
                            anchor={generateID.UUID(
                              "LinkBlockDelete",
                              `link_block`,
                              `icon-${index}`
                            )}
                          >
                            {"Delete"}
                          </OwcTooltip>
                        </div>
                      </LinkContainerDivInnerRight>
                    </LinkContainerDiv>
                  );
                })}
              </LinkContainerStyled>
            )}
          </OwcCard>
        </div>
      </Mainbox>
    </>
  );
};

export default ActionDataInputsBox;
