import CustomSelect from "../../../../../components/shared/CustomSelect";
import { useFormikContext } from "formik";
import { ChipsWrapper } from "../../../log-book-item-form-dialog/FormsStyled";
import { findOrFallbackByObject } from "../../../helpers/find-or-fallback-by-object";
import { OwcChip } from "@one/react";

const RunSelectInput = ({
  options,
  property,
  Selectlabel,
  testid,
  propValue,
  propLabel,
  testidPrefix,
  SelectProps = {},
  customMethodInHandleChange,
  multiple = false,
  required = false,
  fullWidth = multiple,
  objectAsValue = false,
  disabled = false
}) => {
  const formik = useFormikContext();
  const renderValueMultiple = (selected) => (
    <ChipsWrapper>
      {selected.map((selectedItem) => (
        <OwcChip
          data-testid={`${testidPrefix}-inputs-${testid}-input-chip-for-${selectedItem}`}
          key={selectedItem}
          variant="outlined"
          onMouseDown={(event) => {
            event.stopPropagation();
          }}
          onRemove={() => {
            formik.handleChange({
              target: {
                name: property,
                value: formik.values[property].filter(
                  (value) => value !== selectedItem
                )
              }
            });
          }}
          removable
          round
          label={selectedItem}
        >
          {selectedItem}
        </OwcChip>
      ))}
    </ChipsWrapper>
  );

  const renderValueObject = () =>
    findOrFallbackByObject(options, formik.values[property], {
      propValue,
      propLabel
    });

  let selectValue;
  let renderValue;
  if (multiple) {
    selectValue = formik.values[property];
    renderValue = renderValueMultiple;
  } else if (objectAsValue) {
    selectValue = formik.values[property]?.[propValue] ?? "";
    renderValue = renderValueObject;
  } else {
    selectValue = formik.values[property] ?? "";
    renderValue = undefined;
  }

  const handleChange = (event) => {
    customMethodInHandleChange?.(event.target.value);
    if (!objectAsValue) {
      return formik.handleChange(event);
    }

    const obj = options.find((item) => item.key === event.target.value);

    return formik.handleChange({
      ...event,
      target: {
        ...event.target,
        value: obj
          ? {
              key: obj.key,
              value: obj.value
            }
          : null
      }
    });
  };

  return (
    <CustomSelect
      onBlur={formik.handleBlur}
      variant="filled"
      style={{ margin: 0 }}
      options={options}
      required={required}
      name={property}
      onChange={handleChange}
      selectLabel={Selectlabel}
      propValue={propValue}
      propLabel={propLabel}
      testid={`${testidPrefix}-inputs-${testid}-input`}
      error={formik.touched[property] && Boolean(formik.errors[property])}
      helperText={
        formik.errors[property]?.key
          ? formik.errors[property]?.key
          : formik.errors[property]
      }
      multiple={multiple}
      fullWidth={fullWidth}
      value={selectValue}
      SelectProps={SelectProps}
      renderValue={renderValue}
      disabled={disabled}
    />
  );
};

export default RunSelectInput;
