import { IconButton, Tooltip } from "@mui/material";
import { OwcIcon, OwcIconButton } from "@one/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ListAction = ({ item, triggerSetCollapse }) => {
  const { inventoryId } = useParams();
  const [open, setOpen] = useState(item.isExpanded);
  const [isSelected, setIsSelected] = useState(false);

  const handleOptionClick = (inventoryId) => {
    const launchURL = `/cover-sheet/${inventoryId}`;
    window.open(launchURL);
  };

  useEffect(() => {
    if (inventoryId === item?.inventoryId || inventoryId === item?.id)
      setIsSelected(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryId]);

  return (
    <div style={{ display: "flex" }}>
      {!isSelected && (
        <Tooltip title="more details" arrow>
          <IconButton
            aria-label="expand row"
            onClick={() => {
              setOpen(!open);
              triggerSetCollapse(!open);
            }}
            data-testid={`cluster-log-expanded-more-icon-${item.id}`}
          >
            {open ? (
              <OwcIcon name="chevron_up" type="legacy" />
            ) : (
              <OwcIcon name="chevron_down" type="legacy" />
            )}
          </IconButton>
        </Tooltip>
      )}

      {isSelected ? (
        <OwcIcon
          aria-label="expand row"
          data-testid="open-logbook-button-noaction"
          style={{ marginLeft: 35 }}
        >
          -
        </OwcIcon>
      ) : (
        <Tooltip title="Open log book" arrow>
          <OwcIconButton
            aria-label="expand row"
            onClick={() => {
              handleOptionClick(item?.inventoryId || item?.id);
            }}
            data-testid={`open-logbook-button-${item?.id}`}
            flat
            icon="overview"
            type="legacy"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default ListAction;
