import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../constants";
import { IconButton } from "@mui/material";
import { OwcTypography } from "@one/react";

const ClusterLogBookSubComponentActions = ({
  dataTestId,
  item,
  setSubComponentOpen,
  setSelectedSubEquipment,
  onLoadCurrentRowParam
}) => {
  let count = 0;
  if (item.length !== 0) {
    count =
      item?.subEquipment && item?.subEquipment.length > 0
        ? item?.subEquipment.length
        : 0;
  }
  return (
    <div>
      <IconButton
        data-testid={`cluster-log-book-action`}
        onClick={() => {
          onLoadCurrentRowParam(item);
          setSubComponentOpen(true);
          setSelectedSubEquipment(item?.subEquipment);
        }}
        id={
          dataTestId === "run-log"
            ? generateID.buttonID(
                Module.BUTTON.run_log_popup.run_sub_component,
                "button"
              )
            : generateID.buttonID(
                Module.BUTTON.action_log_popup.log_sub_component,
                "button"
              )
        }
      >
        <OwcTypography
          variant="subtitle1"
          style={{ color: "var(--one-color-interaction-default-brand-1)" }}
        >
          {count < 10 ? `0${count}` : count}
        </OwcTypography>
      </IconButton>
    </div>
  );
};
export default ClusterLogBookSubComponentActions;
