import React, { useContext } from "react";
import _ from "underscore";
import { useSelector } from "react-redux";
import { CustomFilterContext } from "../custom-filter/context";
import { initialState } from "../custom-filter/CustomFilterWrapper";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { SearchTextContext } from "./suggester/search-context/context";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { generateID } from "@digitallab/grid-common-components";
import { Module, RESETALL_TOOLTIP } from "../../../constants";
import { OwcButton, OwcTooltip } from "@one/react";

export const ClearAll = ({ className }) => {
  const { dispatchAction, ...restFilter } = useContext(CustomFilterContext);
  const { dispatchAction: paginationDispatch } = useContext(PaginationContext);
  const {
    onSearchTextChange,
    searchText,
    onAppliedSearchTextChange,
    appliedSearchText
  } = useContext(SearchTextContext);
  const storeUser = useSelector((store) => store.user);
  if (
    _.isEqual(
      {
        ...initialState,
        site: storeUser?.site,
        inputsState: { ...initialState?.inputsState, site: storeUser?.site }
      },
      restFilter
    ) &&
    !searchText &&
    !appliedSearchText
  ) {
    return null;
  }
  return (
    <>
      <OwcButton
        data-testid="filters-clear-all-button"
        onClick={() => {
          onSearchTextChange("");
          onAppliedSearchTextChange("");
          paginationDispatch({ type: "clearAll" });
          dispatchAction({
            type: "clearAll"
          });
        }}
        id={generateID.buttonID(Module.BUTTON.clear_all, "button")}
        variant="secondary"
      >
        {LOGBOOK_LABEL.BUTTON.clear_all}
      </OwcButton>
      <div>
        <OwcTooltip
          anchor={generateID.buttonID(Module.BUTTON.clear_all, "button")}
          placement="top"
        >
          {RESETALL_TOOLTIP}
        </OwcTooltip>
      </div>
    </>
  );
};

export default ClearAll;
