import { capitalize, find, isBoolean } from "lodash";
import styled from "styled-components";
import { get } from "underscore";
import { DetailsCellStyled } from "./DetailsCellStyled";
import { DetailsCellsWrapperStyled } from "./DetailsCellsWrapperStyled";
import {
  rearangeDisplayDate,
  validateSelection
} from "./../../../utils/helpers/text/index";
import {
  SHOW_SUB_CLUSTER_KEY,
  AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE,
  emptyClusterInformation,
  GXPemptyInstrumentsOptions
} from "../../../constants";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import COMMON_LOGS_DATA_MODEL from "../../../utils/constants/commonLogsDataModel";
import { linkedInstanceList } from "../../../constants";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { OwcTooltip } from "@one/react";

const ChangesCellContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000000;
`;

const DetailCellContainer = styled.div`
  padding: 10px;
  width: 100%;
  background-color: var(--one-color-gray-50);
`;

const ClusterCellOld = styled.div`
  text-decoration: line-through;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  color: var(--one-color-cobas-accent-orange-800);
`;

const ClusterCellNew = styled.div`
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  color: var(--one-color-accent-dark-cyan-600);
`;

const ChangesCellOld = styled.span`
  width: 100%;
  margin-right: 10px;
  text-decoration: line-through;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--one-color-cobas-accent-orange-800);
`;

const ChangesCellNew = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--one-color-accent-dark-cyan-600);
`;

const NoValueCell = styled.span`
  width: 100%;
  margin-left: 2px;
`;

const SubEqCellStyled = styled.div`
  color: var(--one-color-gray-800);
  width: 100%;
  background-color: var(--one-color-gray-50);
  border-radius: 4px;
  padding: 20px 16px;
  box-sizing: border-box;
  margin-left: 50px;
`;

const WrapperStyled = styled.div`
  width: 100%;
  background-color: #fafafa;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
`;

const SingleDetailsCell = ({ cellValue }) => {
  if (isBoolean(cellValue?.oldvalue)) {
    cellValue.oldvalue = String(cellValue?.oldvalue);
  }
  if (isBoolean(cellValue?.newvalue)) {
    cellValue.newvalue = String(cellValue?.newvalue);
  }
  return (
    <>
      <ChangesCellContainer>
        {cellValue?.oldvalue && cellValue?.oldvalue !== "null" ? (
          <>
            <ChangesCellOld id={cellValue.oldvalue}>
              {cellValue.oldvalue}
            </ChangesCellOld>
            <OwcTooltip anchor={cellValue.oldvalue}>
              {cellValue.oldvalue}
            </OwcTooltip>
          </>
        ) : (
          <NoValueCell>-</NoValueCell>
        )}
        {cellValue?.newvalue && cellValue?.newvalue !== "null" ? (
          <>
            <ChangesCellNew id={cellValue.newvalue}>
              {cellValue.newvalue}
            </ChangesCellNew>
            <OwcTooltip anchor={cellValue.newvalue}>
              {cellValue.newvalue}
            </OwcTooltip>
          </>
        ) : (
          <NoValueCell>-</NoValueCell>
        )}
      </ChangesCellContainer>
    </>
  );
};

const MultiLineDetailsCell = ({ cellValue }) => {
  return (
    <>
      {Array.isArray(cellValue)
        ? cellValue.map((value) => {
            return <SingleDetailsCell cellValue={value} />;
          })
        : ""}
    </>
  );
};

const DetailsCell = ({
  label,
  cellValue,
  isAuditTrial = false,
  Wrapper,
  ...props
}) => {
  return (
    <>
      {cellValue ? (
        <Wrapper {...props}>
          {label === LOGBOOK_LABEL.run_detail_heading ||
          label === LOGBOOK_LABEL.action_detail_heading ||
          label === COMMON_LOGS_DATA_MODEL.subEquipment.value ? (
            <div data-testid="details-cell-label">
              <strong>{label}</strong>
            </div>
          ) : (
            <div data-testid="details-cell-label">{label}</div>
          )}
          <div>
            {isAuditTrial ? (
              props?.multiLine ? (
                <>
                  <MultiLineDetailsCell
                    cellValue={cellValue === "null" ? "" : cellValue}
                  />
                </>
              ) : (
                <>
                  <SingleDetailsCell
                    cellValue={cellValue === "null" ? "" : cellValue}
                  />
                </>
              )
            ) : label !== LOGBOOK_LABEL.run_detail_heading &&
              label !== LOGBOOK_LABEL.action_detail_heading ? (
              label === COMMON_LOGS_DATA_MODEL.subEquipment.value ? (
                cellValue
              ) : (
                <strong>{cellValue === "null" ? "-" : cellValue}</strong>
              )
            ) : (
              ""
            )}
          </div>
        </Wrapper>
      ) : (
        ""
      )}
    </>
  );
};

const SubClusterCell = ({
  items,
  relevantPositionState,
  prefixString = null
}) => {
  let newArrItems = [];
  items.forEach((item) => {
    if (item?.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Deleted) {
      newArrItems = [item, ...newArrItems];
    } else {
      newArrItems = [...newArrItems, item];
    }
  });
  return (
    <div
      style={{ maxHeight: "200px", overflow: "auto" }}
      id="ClusterDecommission-ClusterDetailsCells-FrameWithBackground"
    >
      {newArrItems.map((item, index) => {
        let deleteDivCollector = "";
        let addDivCollector = "";
        let newUpdateDiv = "";
        let oldUpdateDiv = "";

        if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Deleted) {
          if (relevantPositionState) {
            deleteDivCollector += `Position ${
              item?.positionInCluster !== null &&
              item?.positionInCluster !== "undefined" &&
              item?.positionInCluster !== "null"
                ? item?.positionInCluster
                : " -"
            }: `;
          }
          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            deleteDivCollector += `${clusterItem?.label}:${
              item?.oldvalue?.[clusterItem?.key] !== null &&
              item?.oldvalue?.[clusterItem?.key] !== "undefined" &&
              item?.oldvalue?.[clusterItem?.key] !== "null"
                ? item?.oldvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        } else if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Created) {
          if (relevantPositionState && item?.positionInCluster) {
            addDivCollector += `Position ${
              item?.positionInCluster !== null &&
              item?.positionInCluster !== "undefined" &&
              item?.positionInCluster !== "null"
                ? item?.positionInCluster
                : " -"
            }: `;
          }
          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            addDivCollector += `${clusterItem?.label}:${
              item?.newvalue?.[clusterItem?.key] !== null &&
              item?.newvalue?.[clusterItem?.key] !== "undefined" &&
              item?.newvalue?.[clusterItem?.key] !== "null"
                ? item?.newvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        } else if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Updated) {
          if (relevantPositionState) {
            newUpdateDiv += `Position ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }: Sub-component- ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }`;
            oldUpdateDiv += `Position ${
              item?.oldvalue?.positionInCluster !== null &&
              item?.oldvalue?.positionInCluster !== "undefined" &&
              item?.oldvalue?.positionInCluster !== "null"
                ? item?.oldvalue?.positionInCluster
                : " "
            }: Sub-component- ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }`;
          }

          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            newUpdateDiv += `${clusterItem?.label}:${
              item?.newvalue?.[clusterItem?.key] !== null &&
              item?.newvalue?.[clusterItem?.key] !== "undefined" &&
              item?.newvalue?.[clusterItem?.key] !== "null"
                ? item?.newvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;

            oldUpdateDiv += `${clusterItem?.label}:${
              item?.oldvalue?.[clusterItem?.key] !== null &&
              item?.oldvalue?.[clusterItem?.key] !== "undefined" &&
              item?.oldvalue?.[clusterItem?.key] !== "null"
                ? item?.oldvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        }
        return (
          <>
            <ClusterCellOld id={deleteDivCollector}>
              {prefixString && deleteDivCollector && `${prefixString} : `}
              {deleteDivCollector}
            </ClusterCellOld>
            <OwcTooltip anchor={deleteDivCollector}>
              {deleteDivCollector}
            </OwcTooltip>

            <ClusterCellNew id={addDivCollector}>
              {prefixString && addDivCollector && `${prefixString} : `}
              {addDivCollector}
            </ClusterCellNew>
            <OwcTooltip anchor={addDivCollector}>{addDivCollector}</OwcTooltip>

            <ClusterCellOld id={oldUpdateDiv}>
              {prefixString && oldUpdateDiv && `${prefixString} : `}
              {oldUpdateDiv}
            </ClusterCellOld>
            <OwcTooltip anchor={oldUpdateDiv}>{oldUpdateDiv}</OwcTooltip>

            <ClusterCellNew id={newUpdateDiv}>
              {prefixString && newUpdateDiv && `${prefixString} : `}
              {newUpdateDiv}
            </ClusterCellNew>
            <OwcTooltip anchor={newUpdateDiv}>{newUpdateDiv}</OwcTooltip>
          </>
        );
      })}
    </div>
  );
};

const DetailsCells = ({
  isAuditTrial = false,
  notHistory = false,
  item,
  infoMeta,
  Wrapper = DetailsCellsWrapperStyled,
  CellWrapper = DetailsCellStyled,
  relevantPositionState,
  auditAction
}) => {
  let changedKeys = Object.keys(item);
  let changeFieldConfig = infoMeta.fields;
  let displayCond = true;

  if (auditAction === "CLUSTER_UPDATE") {
    displayCond = false;
    let allFieldKeys = Object.keys(emptyClusterInformation);
    let arr = [];
    for (let key of changedKeys) {
      arr.push(allFieldKeys.includes(key));
    }
    if (arr.includes(true)) {
      displayCond = true;
    }
  }

  const getChangedValuesArray = (changedItemConfig, changes) => {
    if (
      changedItemConfig?.field === "dateOfNextMaintanance" ||
      changedItemConfig?.field === "dateOfLastMaintanance" ||
      changedItemConfig?.field === "dateOfNextPeriodicReview"
    ) {
      return {
        oldvalue: changes?.oldvalue
          ? rearangeDisplayDate(changes?.oldvalue)
          : null,
        newvalue: changes?.newvalue
          ? rearangeDisplayDate(changes?.newvalue)
          : null
      };
    }
    if (
      changedItemConfig?.field === "room" ||
      changedItemConfig?.field === "floor" ||
      changedItemConfig?.field === "buildingLocation" ||
      changedItemConfig?.field === "module"
    ) {
      if (changes?.value) {
        return changes?.value || null;
      } else {
        if (
          changes?.oldvalue?.value === changes?.newvalue?.value ||
          (!changes?.oldvalue?.value && !changes?.newvalue?.value)
        ) {
          return null;
        }
        return {
          oldvalue: changes?.oldvalue?.value,
          newvalue: changes?.newvalue?.value
        };
      }
    }
    if (changedItemConfig?.field === "linkedInstance") {
      const oldvalue = find(linkedInstanceList, {
        linkedInstance: changes?.oldvalue
      });
      const newvalue = find(linkedInstanceList, {
        linkedInstance: changes?.newvalue
      });
      return {
        oldvalue: oldvalue?.linkedInstanceDisplay,
        newvalue: newvalue?.linkedInstanceDisplay
      };
    }
    if (changedItemConfig?.field === "sop") {
      const sopChanges = getModifiedChanges(changes, "value");
      return sopChanges;
    }
    if (changedItemConfig?.field === "qualificationDocuments") {
      if (changes?.value) {
        const docChanges = getModifiedChanges(
          changes?.value,
          "name",
          "documentId"
        );
        return docChanges;
      }
    }
    if (changedItemConfig?.field === "qualificationStatus") {
      const oldValue = validateSelection(
        GXPemptyInstrumentsOptions.qualificationStatus,
        { key: changes?.oldvalue }
      );

      const newvalue = validateSelection(
        GXPemptyInstrumentsOptions.qualificationStatus,
        { key: changes?.newvalue }
      );
      return {
        oldvalue: oldValue,
        newvalue: newvalue
      };
    }
    if (changedItemConfig?.field === "installedTests") {
      const testChanges = getModifiedChanges(changes, "version", "name");
      return testChanges;
    }
    if (changedItemConfig?.field === DATA_MODEL_TABLE.status.key) {
      return {
        oldvalue: changes?.oldvalue ? capitalize(changes?.oldvalue) : "-",
        newvalue: changes?.newvalue ? capitalize(changes?.newvalue) : "-"
      };
    }

    if (
      changedItemConfig?.field === "inventoryNumber" ||
      changedItemConfig?.field === "maintenanceIntervalInDays"
    ) {
      return {
        oldvalue: changes?.oldvalue?.toString(),
        newvalue: changes?.newvalue?.toString()
      };
    }
    return changes;
  };
  return !isAuditTrial ? (
    <DetailCellContainer style={{ paddingLeft: notHistory ? 60 : 0 }}>
      <Wrapper
        data-testid="details-cells-wrapper"
        style={{ display: "flex", flexWrap: "wrap" }}
      >
        {infoMeta?.fields?.map((field) => {
          let body = get(item, field.field) || "-";
          body = typeof body === "object" ? body?.value || "-" : body;
          if (Array.isArray(body) && body.length === 0) {
            body = "-";
          }
          if (field.component) {
            const Component = field.component;

            body = <Component item={item} />;
          }

          return (
            <DetailsCell
              Wrapper={CellWrapper}
              label={field.label}
              {...(field.props ?? {})}
              cellValue={body}
            ></DetailsCell>
          );
        })}
      </Wrapper>
    </DetailCellContainer>
  ) : (
    <WrapperStyled>
      {displayCond && (
        <Wrapper
          data-testid="audit-trail-details-cells-wrapper"
          style={{ marginLeft: "50px" }}
        >
          {changedKeys.map((fieldId) => {
            const changedItemConfig = find(changeFieldConfig, {
              field: fieldId
            });
            const changedItemValues = getChangedValuesArray(
              changedItemConfig,
              item[fieldId]
            );
            if (fieldId === DATA_MODEL_TABLE.tags.key) {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gridColumn: "1 / -1"
                  }}
                >
                  <div
                    data-testid="details-cell-label"
                    id={DATA_MODEL_TABLE.tags.key}
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px"
                    }}
                  >
                    {DATA_MODEL_TABLE.tags.value}
                  </div>
                  <div>
                    <TagLogComponent item={item[fieldId]} />
                  </div>
                </div>
              );
            }
            return (
              <>
                {changedItemConfig ? (
                  <DetailsCell
                    isAuditTrial={isAuditTrial}
                    Wrapper={CellWrapper}
                    label={changedItemConfig?.label}
                    {...(changedItemConfig?.props ?? {})}
                    cellValue={changedItemValues}
                  ></DetailsCell>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Wrapper>
      )}
      <SubEqCellStyled>
        {item.hasOwnProperty("subEquipment") &&
          item?.subEquipment.length > 0 && (
            <SubClusterCell
              items={item?.subEquipment}
              relevantPositionState={relevantPositionState}
            />
          )}
        {item.hasOwnProperty("leadingClusterSoftware") &&
          Object.keys(item?.leadingClusterSoftware).length > 0 && (
            <SubClusterCell
              items={[item?.leadingClusterSoftware]}
              relevantPositionState={relevantPositionState}
              prefixString={"Leading cluster software"}
            />
          )}
      </SubEqCellStyled>
    </WrapperStyled>
  );
};

export default DetailsCells;

const concatChanges = (changes, primaryKey, secondaryKey) => {
  if (changes) {
    const primaryValue = changes[primaryKey] ? `${changes[primaryKey]}` : "";
    const secondaryValue = changes[secondaryKey]
      ? `, ${changes[secondaryKey]}`
      : "";
    return `${primaryValue}${secondaryValue}`;
  } else {
    return "";
  }
};

const getModifiedChanges = (changes, primaryKey, secondaryKey) => {
  return changes.map((item) => {
    const itemWithPrimaryKey = item[primaryKey];
    if (itemWithPrimaryKey) {
      if (primaryKey === "version") {
        return {
          oldvalue: `${itemWithPrimaryKey[secondaryKey]}, ${itemWithPrimaryKey?.oldvalue}`,
          newvalue: `${itemWithPrimaryKey[secondaryKey]}, ${itemWithPrimaryKey?.newvalue}`
        };
      } else {
        return {
          oldvalue: `${itemWithPrimaryKey?.oldvalue}, ${itemWithPrimaryKey[secondaryKey]}`,
          newvalue: `${itemWithPrimaryKey?.newvalue}, ${itemWithPrimaryKey[secondaryKey]}`
        };
      }
    }
    if (primaryKey === "version") {
      return {
        oldvalue: concatChanges(item?.oldvalue, secondaryKey, primaryKey),
        newvalue: concatChanges(item?.newvalue, secondaryKey, primaryKey)
      };
    } else {
      return {
        oldvalue: concatChanges(item?.oldvalue, primaryKey, secondaryKey),
        newvalue: concatChanges(item?.newvalue, primaryKey, secondaryKey)
      };
    }
  });
};

const TagLogComponent = ({ item = [] }) => {
  const html = item.map((x) =>
    x?.type === "Created" ? (
      <span>{x?.newvalue}</span>
    ) : (
      x?.oldvalue &&
      x?.oldvalue !== "null" && (
        <>
          <span style={{ textDecoration: "line-through" }} id={x?.oldvalue}>
            {x?.oldvalue}
          </span>
        </>
      )
    )
  );
  const tagsData = html.map((x, index) => (index === 0 ? x : <>,{x}</>));
  return (
    <ChangesCellContainer>
      <ChangesCellNew>{tagsData}</ChangesCellNew>
    </ChangesCellContainer>
  );
};
