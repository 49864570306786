import React, { useContext } from "react";
import styled from "styled-components";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import ACTION_LOGS_DATA_MODEL from "../../../utils/constants/actionLogsDataModel";
import {
  entryType,
  equipmentStatus,
  formStates,
  formTypes
} from "../../../constants";
import { LogBookSelectContext } from "./logBooksSelectAll/context";
import ClusterAgGridTableAddFlowFirstStep from "../../../components/shared/ClusterAgGridTableAddFlowFirstStep";
import { uniq } from "underscore";
import { useEffect } from "react";
import { useState } from "react";
import { ClusterActions } from "../../cover-sheet/cluster-cover-sheet/ClusterDescriptionDetailView";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { runLogsClearSuggestion } from "../run-logs/run-logs-form/run-logs-clear-suggestion";
import { runLogsSelectSuggestion } from "../run-logs/run-logs-form/run-logs-select-suggestion";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";
import { capitalize, find } from "lodash";
import { replaceEmptyWithHyphen } from "../../../utils/helpers/text";
import { OwcChip, OwcTypography } from "@one/react";

const LogBookTableStyled = styled.div`
  & .row {
    background-color: #fafafa;
  }
`;

export const subClusterMapping = (obj, selected = null) => {
  let data = [obj];
  let tempData = [...data];
  let tempRowList = [];
  let parentArray = [];
  tempData[0]?.forEach((item, index) => {
    let subParentArray = uniq([...parentArray, item?.inventoryId]);
    const subClusterParent = uniq([...subParentArray]);
    if (selected) {
      if (find(selected, { inventoryId: item?.inventoryId })) {
        tempRowList.push({ ...item, clusterParent: subClusterParent });
      }
    } else {
      tempRowList.push({ ...item, clusterParent: subClusterParent });
    }
    if (item?.subEquipment) {
      item.subEquipment.forEach((element, index1) => {
        const childClusterParent = uniq([
          ...subParentArray,
          element?.inventoryId
        ]);

        if (selected) {
          if (find(selected, { inventoryId: element?.inventoryId })) {
            tempRowList.push({ ...element, clusterParent: childClusterParent });
          }
        } else {
          tempRowList.push({ ...element, clusterParent: childClusterParent });
        }
      });
    }
  });

  return tempRowList;
};

const LogBookClusterTable = ({ dataTestId, equipmentDetail }) => {
  const { onSetSelection, selected } = useContext(LogBookSelectContext);
  const [clusterSubEquipmentDetails, setClusterSubEquipmentDetails] = useState(
    []
  );

  const { formState, logType } = useContext(CoversheetMainPageContext);
  const formik = useFormikContext();
  const user = useSelector((store) => store.user);

  useEffect(() => {
    if (logType === formTypes?.RUN_LOG) {
      if (formState !== formStates?.EDITABLE_FORM) {
        runLogsClearSuggestion({ formik, user, equipmentDetail });
      } else {
        runLogsSelectSuggestion(equipmentDetail, { formik, user });
      }
    }
    // eslint-disable-next-line
  }, [formState]);

  useEffect(() => {
    const clusterDetails = () => {
      const coverSheetDetail = subClusterMapping(equipmentDetail?.subEquipment);
      setClusterSubEquipmentDetails(() => coverSheetDetail);
    };

    if (equipmentDetail?.subEquipment) {
      clusterDetails();
    }
  }, [equipmentDetail]);
  const EquipmentMetaDisplay = {
    fields: {
      clusterIdentifier: {
        headProps: { style: { width: 100 } },
        cellProps: { style: { width: 100 } },
        text: "",
        sortable: false,
        component: ({
          item,
          triggerClusterSetCollapse,
          client,
          clusterEquipmentInfoAction
        }) => {
          switch (item.entryType) {
            case entryType?.cluster:
              return (
                <ClusterActions
                  client={client}
                  item={item}
                  triggerClusterSetCollapse={triggerClusterSetCollapse}
                  clusterEquipmentInfoAction={clusterEquipmentInfoAction}
                  viewExpander={true}
                />
              );
            default:
              return "";
          }
        }
      },
      equipmentModel: {
        text: DATA_MODEL_TABLE.equipmentModel.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        }
      },
      variant: {
        text: DATA_MODEL_TABLE.variant.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      equipmentId: {
        text: DATA_MODEL_TABLE.equipmentId.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      serialNumber: {
        text: DATA_MODEL_TABLE.serialNumber.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      equipmentNickName: {
        text: DATA_MODEL_TABLE.equipmentNickName.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      status: {
        text: DATA_MODEL_TABLE.status.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        },
        cellRenderer: (params) => {
          return (
            <>
              <StatusClusterComponent params={params} />
            </>
          );
        }
      },
      qualificationStatus: {
        text: ACTION_LOGS_DATA_MODEL.gxpReady.value,
        sortable: true,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        },
        cellRenderer: (params) => {
          return (
            <>{replaceEmptyWithHyphen(params?.data?.qualificationStatus)}</>
          );
        }
      },
      equipSystemStatus: {
        text: DATA_MODEL_TABLE.systemStatus.value,
        sortable: true,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        },
        cellRenderer: (params) => {
          return <>{replaceEmptyWithHyphen(params?.data?.equipSystemStatus)}</>;
        }
      }
    },
    rowKeyId: "id"
  };

  return (
    <LogBookTableStyled data-testid={dataTestId}>
      <ClusterAgGridTableAddFlowFirstStep
        data={equipmentDetail ? clusterSubEquipmentDetails : []}
        meta={EquipmentMetaDisplay}
        onSetSelection={onSetSelection}
        selected={selected}
        isEditMode={formState === formStates?.EDITABLE_FORM}
      />
    </LogBookTableStyled>
  );
};

export const StatusClusterComponent = ({ params }) => {
  return (
    <>
      <OwcChip
        outlined
        data-testid="run-data-chip-box-col-value-for-system-status"
      >
        {params?.data?.status === equipmentStatus?.active?.key && (
          <OwcTypography
            variant="badge"
            style={{
              color: "var(--one-color-interaction-default-positive-1)"
            }}
          >
            {capitalize(params?.data?.status)}
          </OwcTypography>
        )}
        {params?.data?.status === equipmentStatus?.pending?.key && (
          <OwcTypography
            variant="badge"
            style={{
              color: "var(--one-color-interaction-default-warning-1)"
            }}
          >
            {capitalize(params?.data?.status)}
          </OwcTypography>
        )}
      </OwcChip>
    </>
  );
};

export default LogBookClusterTable;
