import { Tooltip } from "@mui/material";
import styled from "styled-components";
import {
  SHOW_SUBEQUIPMENT_KEY,
  AUDIT_CLUSTER_ACTION
} from "../../../constants";
import { lineGenerator } from "./ClusterDetailsCellsServices";
import { OwcTooltip } from "@one/react";

const Frame = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: #000000;
  box-sizing: border-box;
`;

const MessageText = styled.div`
  display: flex;
  padding-bottom: 15px;
`;
const BoldText = styled.span`
  font-weight: 500;
`;
const CustomTooltipInnerDivStyled = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  white-space: nowrap;
  max-width: 100%;
  width: 850px;
  padding: 3px;
`;
const FrameWithBackground = styled(Frame)`
  background-color: var(--one-color-gray-50);
  border: 1px solid var(--one-color-gray-300);
  color: var(--one-color-accent-dark-cyan-600);
  border-radius: 4px;
  padding: 20px 16px;
  overflow: auto;
`;

const ClusterDetailsCells = ({
  items,
  type,
  relevantPositionState = false,
  messageText = null,
  isEquipment
}) => {
  if (items && items?.length > 0) {
    items?.sort((a, b) => a?.positionInCluster - b?.positionInCluster);
  }
  return (
    <FrameWithBackground
      style={
        type === AUDIT_CLUSTER_ACTION?.cluster
          ? { maxHeight: "200px" }
          : { maxHeight: "100px" }
      }
      id="ClusterDecommission-ClusterDetailsCells-FrameWithBackground"
    >
      {messageText && (
        <>
          <MessageText id="ClusterDecommission-ClusterDetailsCells-MessageText">
            {messageText}
          </MessageText>
        </>
      )}
      {type === AUDIT_CLUSTER_ACTION?.cluster &&
        items.map((item, index) => {
          const divCollecter = lineGenerator(item);
          return (
            <>
              <CustomTooltipInnerDivStyled id="ClusterDecommission-ClusterDetailsCells-CustomTooltipInnerDivStyled">
                Sub component- {relevantPositionState && index + 1}
                <span id={divCollecter}>{divCollecter}</span>
              </CustomTooltipInnerDivStyled>
              <OwcTooltip
                id="ClusterDecommission-ClusterDetailsCells-CustomTooltip"
                anchor={divCollecter}
              >
                {divCollecter}
              </OwcTooltip>
            </>
          );
        })}
      {type === AUDIT_CLUSTER_ACTION?.subEquipmentAdd ||
      type === AUDIT_CLUSTER_ACTION?.subEquipmentRemove ? (
        <SubClusterCell item={items} type={type} isEquipment={isEquipment} />
      ) : (
        ""
      )}
    </FrameWithBackground>
  );
};

export default ClusterDetailsCells;

const SubClusterCell = ({ item, type, isEquipment }) => {
  let divCollecter = "";
  SHOW_SUBEQUIPMENT_KEY.forEach((clusterItem, indexItem) => {
    divCollecter += `${clusterItem?.label}: ${
      item[clusterItem?.key] !== null &&
      item[clusterItem?.key] !== undefined &&
      item[clusterItem?.key] !== "null"
        ? item[clusterItem?.key]
        : " -"
    }${indexItem < SHOW_SUBEQUIPMENT_KEY.length - 1 ? ", " : ""}`;
  });
  const typeString = !isEquipment ? "Cluster" : "Equipment";

  return (
    <div id="ClusterDecommission-SubClusterCell-OuterDiv">
      {type === AUDIT_CLUSTER_ACTION?.subEquipmentAdd && (
        <Tooltip
          title={`${typeString} was added to cluster: ${divCollecter}`}
          id="ClusterDecommission-SubClusterCell-CustomTooltip"
        >
          <CustomTooltipInnerDivStyled id="ClusterDecommission-SubClusterCell-CustomTooltipInnerDivStyled">
            <BoldText id="ClusterDecommission-SubClusterCell-BoldText">
              {typeString} was added to cluster: &nbsp;
            </BoldText>
            {divCollecter}
          </CustomTooltipInnerDivStyled>
        </Tooltip>
      )}
      {type === AUDIT_CLUSTER_ACTION?.subEquipmentRemove && (
        <Tooltip
          title={`${typeString} was removed from cluster: ${divCollecter}`}
          id="ClusterDecommission-SubClusterCell-CustomTooltip-0"
        >
          <CustomTooltipInnerDivStyled id="ClusterDecommission-SubClusterCell-CustomTooltipInnerDivStyled-0">
            <BoldText id="ClusterDecommission-SubClusterCell-BoldText-0">
              {typeString} was removed from cluster: &nbsp;
            </BoldText>
            {divCollecter}
          </CustomTooltipInnerDivStyled>
        </Tooltip>
      )}
    </div>
  );
};
