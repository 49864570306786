import { combineReducers } from "redux";
import equipmentModelsReducer from "../features/log-book/redux/reducer";
import RunLogsFormReducer from "../features/log-book/log-book-item-form-dialog/redux/reducer";
import userReducer from "../features/user/redux/reducer";

const rootReducer = combineReducers({
  user: userReducer,
  equipmentModels: equipmentModelsReducer,
  runLogsForm: RunLogsFormReducer
});

export default rootReducer;
