import DLabGrid from "../../../components/DLabGrid/DLabGrid";
import React, { useRef, useMemo, useContext, useState, useEffect } from "react";
import { SearchTextContext } from "../log-book-item-fillter/suggester/search-context/context";
import {
  ChangeLogMetaDisplay,
  logViews,
  removeField
} from "../../../constants";
import {
  changeDateFormat,
  valueOrEmpty
} from "../../../utils/helpers/text/index";
import ChangeLogDetailCellRenderer from "./ChangeLogDetailCellRenderer";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import { CustomFilterContext } from "../custom-filter/context";
import { CircularProgress } from "@mui/material";
import { withApollo } from "react-apollo";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { loadUserInfo } from "../../user/redux/actions";
import { UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS } from "../../../gql/logBooksapi";
import { isEqual } from "date-fns";

const ChangeLogGridTable = ({ type, userDetails, client, loadUserInfo }) => {
  const { searchText, onLastLogListData } = useContext(SearchTextContext);
  const {
    nextToken,
    data,
    fetching,
    dispatchAction: paginationDispatch
  } = useContext(PaginationContext);
  const { inventoryId } = useParams();
  const [gridApi, setGridApi] = useState(null);
  const {
    inputsState: { dateFrom, dateTo, signee, signeeChangeLog },
    dispatchAction
  } = useContext(CustomFilterContext);
  const gridRef = useRef();
  const detailRowAutoHeight = true;
  const rowStyle = { background: "var(--one-color-cobas-accent-gray-100)" };
  const [tempRowData, setTempRowData] = useState([]);
  const dateFormatFormatter = (params) => {
    return changeDateFormat(params?.data?.createdAt, "DD-MMM-YYYY");
  };
  const signeeIdGetter = (params) => {
    let signee =
      params?.data?.modifiedByUserId !== ""
        ? params?.data?.modifiedByUserId
        : params?.data?.modifiedByEmail;
    return signee;
  };

  const ChangeSectionGetter = (params) => {
    let count = [];
    let removeCount = 0;
    if (params?.data?.changes) {
      let lengthChanges = JSON.parse(params?.data?.changes);
      // eslint-disable-next-line array-callback-return
      removeField.map((item) => {
        if (item in lengthChanges) return removeCount++;
      });
      count = Object.keys(lengthChanges).length - removeCount;
    }
    return count < 10 ? `0${count}` : count;
  };

  const filterParams = {
    filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
    inRangeFloatingFilterDateFormat: "DD-MMM-YYYY",
    suppressAndOrCondition: true,
    defaultJoinOperator: "OR",
    buttons: ["reset", "apply"],
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      let cellDate = new Date(cellValue);
      if (isEqual(cellDate, filterLocalDateAtMidnight)) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    }
  };

  const overrideCellRender = (key, defaultObj) => {
    switch (key) {
      case "modifiedByUserId":
        defaultObj = {
          ...defaultObj,
          valueGetter: signeeIdGetter,
          width: 500,
          maxWidth: 500,
          floatingFilter: false
        };
        break;
      case "editComment":
        defaultObj = {
          ...defaultObj,
          floatingFilter: false
        };
        break;
      case "createdAt":
        defaultObj = {
          ...defaultObj,
          filter: "agDateColumnFilter",
          filterParams,
          valueFormatter: dateFormatFormatter,
          filterValueGetter: dateFormatFormatter
        };
        break;
      case "changes":
        defaultObj = {
          ...defaultObj,
          valueGetter: ChangeSectionGetter,
          filterValueGetter: ChangeSectionGetter
        };
        break;
      case "detailExpander":
        defaultObj = {
          ...defaultObj,
          cellRenderer: "agGroupCellRenderer",
          width: 40,
          maxWidth: 40,
          pinned: "left"
        };

        break;

      default:
        defaultObj = {
          ...defaultObj,
          valueGetter: (params) =>
            valueOrEmpty(params?.data[key] ?? "-", false, "-"),
          filterValueGetter: (params) =>
            valueOrEmpty(params?.data[key] ?? "-", false)
        };
        break;
    }
    return defaultObj;
  };
  const dLabColumnDef = useMemo(() => {
    let defaultObj;
    let colDef = [];

    for (const key in ChangeLogMetaDisplay.fields) {
      if (key !== "actionButtons") {
        defaultObj = {
          field: key,
          headerName: ChangeLogMetaDisplay.fields[key].text,
          filter: true,
          floatingFilter: true
        };
        defaultObj = overrideCellRender(key, defaultObj);

        colDef.push(defaultObj);
      }
    }

    return colDef;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ChangeLogMetaDisplay.fields]);

  const onGridReady = (params) => {
    setGridApi(params);
    if (
      userDetails?.lastDisplayColumns &&
      typeof userDetails?.lastDisplayColumns === "string"
    ) {
      var lastDisplayColumns = JSON.parse(userDetails?.lastDisplayColumns);
      var logView = inventoryId
        ? logViews?.CENTRIC_VIEW
        : logViews?.NORMAL_VIEW;
      var columnState =
        lastDisplayColumns?.[type]?.[logView]?.displayDefinition;
      if (columnState) {
        params.columnApi.applyColumnState({
          state: columnState?.currentState,
          applyOrder: true
        });
      }
    }
  };

  const onColumnMoved = (params) => {
    var currentState = params.columnApi.getColumnState();
    let savecolumnObj = {
      displayName: "",
      displayDefinition: {
        currentState
      }
    };
    if (
      userDetails?.lastDisplayColumns &&
      typeof userDetails?.lastDisplayColumns === "string"
    ) {
      var lastDisplayColumns = JSON.parse(userDetails?.lastDisplayColumns);
      var logView = inventoryId
        ? logViews?.CENTRIC_VIEW
        : logViews?.NORMAL_VIEW;
      var logColumns = { [type]: { [logView]: savecolumnObj } };
      lastDisplayColumns = { ...lastDisplayColumns, ...logColumns };

      const updateData = async (client) => {
        await client.mutate({
          mutation: UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS,
          variables: {
            id: userDetails?.id,
            email: userDetails?.email,
            lastDisplayColumns: JSON.stringify(lastDisplayColumns)
          },
          fetchPolicy: "no-cache"
        });
      };
      updateData(client);
      loadUserInfo({
        ...userDetails,
        lastDisplayColumns: JSON.stringify(lastDisplayColumns)
      });
    }
  };

  let attr = {
    columnDefs: dLabColumnDef,
    animateRows: true,
    rowExport: false,
    defaultToolPanel: "filters",
    suppressContextMenu: true,
    hiddenByDefault: true,
    rowData: tempRowData,
    onGridReady,
    onColumnMoved
  };
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      suppressMenu: true,
      sortable: true,
      minWidth: 200
    };
  }, []);

  useEffect(() => {
    if (gridApi) {
      gridRef.current.api.setQuickFilter(searchText);
      let rowData = [];
      gridRef?.current?.api?.forEachNodeAfterFilterAndSort((node) =>
        rowData.push(node.data)
      );
      onLastLogListData(rowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  useEffect(() => {
    setTempRowData([]);
  }, [dateFrom, dateTo, signee, signeeChangeLog]);
  useEffect(() => {
    let tempArray = [];
    if (tempRowData.length > 0) {
      tempArray = [...tempArray, ...tempRowData];
    }
    if (data.length > 0) {
      tempArray = [...tempArray, ...data];
    }
    setTempRowData(tempArray);

    setTimeout(() => {
      if (gridRef && gridRef?.current?.api) {
        let rowData = [];
        gridRef?.current?.api?.forEachNodeAfterFilterAndSort((node) =>
          rowData.push(node.data)
        );
        onLastLogListData(rowData);
      }
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const scrollEvent = (event) => {
    const container = document.querySelector(".grid-container");
    if (
      Math.ceil(container.clientHeight + container.scrollTop) >=
        container.scrollHeight &&
      nextToken
    ) {
      paginationDispatch({ type: "nextData" });
    }
  };

  const detailCellRenderer = useMemo(() => {
    return ChangeLogDetailCellRenderer;
  }, []);

  return fetching && tempRowData.length === 0 ? (
    <div
      style={{
        minHeight: "200px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress size={30} />
    </div>
  ) : (
    <div
      className="grid-container"
      onScroll={scrollEvent}
      style={{
        height: "300px",
        overflow: "scroll"
      }}
    >
      <DLabGrid
        {...attr}
        pagination={false}
        suppressPaginationPanel={false}
        defaultColDef={defaultColDef}
        masterDetail={true}
        detailCellRenderer={detailCellRenderer}
        detailRowAutoHeight={detailRowAutoHeight}
        gridRef={gridRef}
        rowStyle={rowStyle}
        domLayout="autoHeight"
        onFilterChanged={(params) => {
          let rowData = [];
          gridRef?.current?.api?.forEachNodeAfterFilterAndSort((node) =>
            rowData.push(node.data)
          );
          onLastLogListData(rowData);
          const filters = params.api.getFilterModel();
          if (Object.keys(filters).length > 0) {
            dispatchAction({
              type: "setGridFilter",
              payload: true
            });
          } else {
            dispatchAction({
              type: "setGridFilter",
              payload: false
            });
          }
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userDetails: state?.user
});

export default compose(
  connect(mapStateToProps, { loadUserInfo }),
  withApollo
)(ChangeLogGridTable);
