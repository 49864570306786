import DLabGrid from "../DLabGrid/DLabGrid";
import React, { useRef, useState, useMemo } from "react";
import { useEffect } from "react";
import { valueOrEmpty } from "../../utils/helpers/text";

const ClusterGridTableAddFlow = ({ meta, data = [] }) => {
  const gridRef = useRef();
  const [dataList, setDataList] = useState([]);

  const overrideCellRender = (key, defaultObj, metaData) => {
    switch (key) {
      case "gxpReady":
        defaultObj = {
          ...defaultObj,
          cellRenderer: metaData?.cellRenderer
        };
        break;
      case "systemStatus":
        defaultObj = {
          ...defaultObj,
          cellRenderer: metaData?.cellRenderer
        };
        break;
      case "status":
        defaultObj = {
          ...defaultObj,
          cellRenderer: metaData?.cellRenderer
        };
        break;
      default:
        defaultObj = {
          ...defaultObj,
          valueFormatter: (params) =>
            valueOrEmpty(params?.data[key] ?? "-", false, "-")
        };
        break;
    }
    return defaultObj;
  };

  const dLabColumnDef = useMemo(() => {
    let defaultObj;
    let colDef = [];

    for (const key in meta.fields) {
      if (key !== "actions" && key !== "clusterIdentifier") {
        defaultObj = {
          field: key,
          headerName: meta.fields[key].text,
          autoHeight: true,
          minWidth: 215
        };

        defaultObj = overrideCellRender(key, defaultObj, meta.fields[key]);

        colDef.push(defaultObj);
      }
    }
    return colDef;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.fields]);
  useEffect(() => {
    setDataList(() => data);
    // eslint-disable-next-line
  }, [data]);

  let attr = {
    columnDefs: dLabColumnDef,
    rowData: dataList,
    treeData: true,
    animateRows: true,
    suppressContextMenu: true,
    hiddenByDefault: true,
    suppressRowClickSelection: true
  };

  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      resizable: true,
      suppressMenu: true,
      sortable: false,
      minWidth: 200
    };
  }, []);

  const autoGroupColumnDef = useMemo(() => {
    return {
      headerName: "",
      maxWidth: 50,
      cellRendererParams: {
        innerRenderer: () => {
          return "";
        },
        suppressCount: true
      },
      pinned: "left"
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataPath = useMemo(() => {
    return (data) => {
      return data.clusterParent;
    };
  }, []);

  return (
    <DLabGrid
      {...attr}
      pagination={false}
      suppressPaginationPanel={false}
      defaultColDef={defaultColDef}
      gridRef={gridRef}
      autoGroupColumnDef={autoGroupColumnDef}
      getDataPath={getDataPath}
      groupDefaultExpanded={-1}
    />
  );
};

export default ClusterGridTableAddFlow;
