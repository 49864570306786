import styled from "styled-components";
import InstrumentInformationsWrapper from "../instrument-informations-dialog/instrument-informations-context/InstrumentInformationsWrapper";
import HistoryItemWrapper from "../log-book-history-item/HistoryItemWrapper";
import ItemCoversheetWrapper from "../log-book-item-cover-sheet-dialog/coversheet-context/ItemCoversheetWrapper";
import ItemWrapper from "../log-book-item-form-dialog/item-context/ItemWrapper";
import ItemFormDialog from "../log-book-item-form-dialog/ItemFormDialog";
import LogBookList from "../log-book-table/LogBookList";
import ClusterItemFormDialog from "../log-book-item-form-dialog/ClusterItemFormDialog";
import { entryType as entType, formTypes } from "../../../constants";
import { emptyLogSheetCluster } from "../log-sheet/log-sheet-form/empty-log-sheet";
import { logSheetClusterValidationSchemaFactory } from "../log-sheet/log-sheet-form/log-sheet-validation-schema";
import { CreateLogSheetChangeCluster } from "../log-sheet/log-sheet-form/CreateLogSheetChange";
import { emptyRunLogCluster } from "../run-logs/run-logs-form/empty-run-log";
import { CreateRunLogChangeCluster } from "../run-logs/run-logs-form/CreateRunLogChange";
import RunLogClusterTableMeta, {
  runLogRegularSizes,
  runLogTabletSizes
} from "../run-logs/runLogs-table/RunLogClusterTableMeta";
import LogSheetClusterTableMeta, {
  logSheetRegularSizes,
  logSheetTabletSizes
} from "../log-sheet/log-sheet-table/LogSheetClusterTableMeta";
import ClusterItemSubComponentDialog from "../log-book-item-form-dialog/ClusterItemSubComponentDialog";
import {
  actionHeadingDetailCell,
  runHeadingDetailCell,
  SubComponent
} from "../common-for-log-sheet-and-run-logs/HistoryCommonCellsInfo";
import { useContext, useState } from "react";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";
import { runLogClusterValidationSchemaFactory } from "../run-logs/run-logs-form/run-log-validation-schema";
import AllLogClusterTableMeta from "../run-logs/runLogs-table/AllLogClusterTableMeta";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ItemPage = ({
  addButtonLabel,
  queryName,
  importFn,
  CellsInfoArray,
  title,
  dataTestId,
  type,
  fetchingFn,
  orderBy,
  meta,
  emptyItem,
  cleanItemFn,
  createItemChangeFn,
  validationSchemaFactory,
  selectSuggestionFn,
  clearSuggestionFn,
  showChipBox,
  addNewDialogButtonLabel,
  children,
  equipmentDetail
}) => {
  const [clusterLogStatus, setClusterLogStatus] = useState(false);
  const { isClusterForm, allLogType } = useContext(CoversheetMainPageContext);
  const disableStatus =
    equipmentDetail?.entryType === entType?.clusterSubequipment;

  if (equipmentDetail?.entryType === entType?.cluster) {
    delete RunLogClusterTableMeta.fields.equipmentModel;
    delete RunLogClusterTableMeta.fields.equipmentNickName;
    delete runLogTabletSizes.equipmentModel;
    delete runLogTabletSizes.equipmentNickName;
    delete runLogRegularSizes.equipmentModel;
    delete runLogRegularSizes.equipmentNickName;

    delete LogSheetClusterTableMeta.fields.equipmentModel;
    delete LogSheetClusterTableMeta.fields.equipmentNickName;
    delete logSheetTabletSizes.equipmentModel;
    delete logSheetTabletSizes.equipmentNickName;
    delete logSheetRegularSizes.equipmentModel;
    delete logSheetRegularSizes.equipmentNickName;

    if (!CellsInfoArray.includes(SubComponent)) {
      /* Adding Subcomponent for audit trail for Cluster log*/
      if (type === formTypes?.RUN_LOG) {
        CellsInfoArray.unshift(runHeadingDetailCell);
      } else {
        CellsInfoArray.unshift(actionHeadingDetailCell);
      }
      CellsInfoArray.unshift(SubComponent);
    }
  } else if (equipmentDetail?.entryType === "CLUSTER-SUBEQUIPMENT") {
    delete RunLogClusterTableMeta.fields.subEquipment;
    delete runLogRegularSizes.subEquipment;
    delete runLogTabletSizes.subEquipment;
    delete LogSheetClusterTableMeta.fields.subEquipment;
    delete logSheetTabletSizes.subEquipment;
    delete logSheetRegularSizes.subEquipment;
  }
  return (
    <ItemCoversheetWrapper>
      <ItemWrapper
        entryDataType={equipmentDetail?.entryType}
        setClusterLogStatus={setClusterLogStatus}
      >
        <InstrumentInformationsWrapper addButtonLabel={addButtonLabel}>
          <HistoryItemWrapper
            queryName={queryName}
            importFn={importFn}
            CellsInfoArray={CellsInfoArray}
            title={title}
            dataTestId={dataTestId}
            addButtonLabel={addButtonLabel}
            disableStatus={disableStatus}
          >
            {equipmentDetail?.entryType === entType.cluster ||
            equipmentDetail?.entryType === entType.clusterSubequipment ? (
              <LogBookList
                dataTestId={`${dataTestId}-table`}
                orderBy={orderBy}
                meta={
                  type === formTypes?.RUN_LOG
                    ? RunLogClusterTableMeta
                    : type === formTypes?.ACTION_LOG
                    ? LogSheetClusterTableMeta
                    : AllLogClusterTableMeta
                }
                fetchingFn={fetchingFn}
                disableStatus={disableStatus}
                clusterLogStatus={clusterLogStatus}
                setClusterLogStatus={setClusterLogStatus}
                type={type}
              />
            ) : (
              <LogBookList
                dataTestId={`${dataTestId}-table`}
                orderBy={orderBy}
                meta={
                  type === formTypes?.ALL_LOG ? AllLogClusterTableMeta : meta
                }
                fetchingFn={fetchingFn}
                disableStatus={disableStatus}
                type={type}
              />
            )}
          </HistoryItemWrapper>
        </InstrumentInformationsWrapper>

        {!isClusterForm && (
          <ItemFormDialog
            addNewDialogButtonLabel={addNewDialogButtonLabel}
            emptyItem={emptyItem}
            cleanItemFn={cleanItemFn}
            createItemChangeFn={createItemChangeFn}
            validationSchemaFactory={validationSchemaFactory}
            dataTestId={dataTestId}
            selectSuggestionFn={selectSuggestionFn}
            clearSuggestionFn={clearSuggestionFn}
            type={type}
            showChipBox={showChipBox}
            equipmentDetail={equipmentDetail}
          >
            {children}
          </ItemFormDialog>
        )}
        {isClusterForm && (
          <ClusterItemFormDialog
            addNewDialogButtonLabel={addNewDialogButtonLabel}
            emptyItem={
              type === formTypes?.RUN_LOG
                ? emptyRunLogCluster
                : type === formTypes?.ALL_LOG
                ? allLogType === formTypes?.RUN_LOG
                  ? emptyRunLogCluster
                  : emptyLogSheetCluster
                : emptyLogSheetCluster
            }
            cleanItemFn={cleanItemFn}
            createItemChangeFn={
              type === formTypes?.RUN_LOG
                ? CreateRunLogChangeCluster
                : type === formTypes?.ALL_LOG
                ? allLogType === formTypes?.RUN_LOG
                  ? CreateRunLogChangeCluster
                  : CreateLogSheetChangeCluster
                : CreateLogSheetChangeCluster
            }
            validationSchemaFactory={
              type === formTypes?.RUN_LOG
                ? runLogClusterValidationSchemaFactory
                : type === formTypes?.ALL_LOG
                ? allLogType === formTypes?.RUN_LOG
                  ? runLogClusterValidationSchemaFactory
                  : logSheetClusterValidationSchemaFactory
                : logSheetClusterValidationSchemaFactory
            }
            dataTestId={dataTestId}
            selectSuggestionFn={selectSuggestionFn}
            clearSuggestionFn={clearSuggestionFn}
            type={type === formTypes?.ALL_LOG ? allLogType : type}
            showChipBox={showChipBox}
            equipmentDetail={equipmentDetail}
            setClusterLogStatus={setClusterLogStatus}
          />
        )}
        <ClusterItemSubComponentDialog equipmentDetail={equipmentDetail} />
      </ItemWrapper>
    </ItemCoversheetWrapper>
  );
};

export default ItemPage;
