import { OwcIconButton } from "@one/react";
import moment from "moment";

const commonPropsForInputs =
  ({ fieldKeyFn, valueFn, errorFn, helperTextFn }) =>
  (
    classes,
    formik,
    key,
    dataTestIdKey,
    dataTestIdForFormKey,
    disabled = false
  ) => ({
    InputProps: `${valueFn(formik, key) ?? ""}`.length > 0 && {
      endAdornment: !disabled && (
        // <RocheInputAdornment position="end">
        <div slot="suffix">
          <OwcIconButton
            data-testid={`${dataTestIdForFormKey}-fields-clear-button-${dataTestIdKey}-input`}
            edge="end"
            onClick={() => formik.setFieldValue(fieldKeyFn(key), "", true)}
            icon="circle_clear"
            type="filled"
            flat
          />
        </div>
      )
    },
    error: errorFn(formik, key),
    helperText: helperTextFn(formik, key),
    FormHelperTextProps: {
      "data-testid": `${dataTestIdForFormKey}-fields-helper-text-${dataTestIdKey}-input`
    }
  });

export const commonPropsForInputsWithoutValue = commonPropsForInputs({
  fieldKeyFn: (key) => key,
  valueFn: (formik, key) => formik.values?.[key],
  errorFn: (formik, key) => formik.touched[key] && Boolean(formik.errors[key]),
  helperTextFn: (formik, key) => formik.touched[key] && formik.errors[key]
});

export const changeDateFormat = (inputDate) => {
  try {
    if (typeof inputDate === "string") {
      const dateObj = new Date(inputDate).toUTCString();
      const splitDateFormat = dateObj?.split(" ");
      return (
        splitDateFormat[1] + "-" + splitDateFormat[2] + "-" + splitDateFormat[3]
      );
    } else {
      const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const convertedDate = inputDate.toLocaleString("en-GB", {
        timeZone: localTimeZone
      });

      const splitDate = convertedDate?.split(",");
      if (splitDate.count === 0) {
        throw new Error("No valid date found for conversion");
      }

      const splitDateFormat = splitDate[0]?.split("/");
      if (splitDate.count === 0) {
        throw new Error("No valid date found for conversion");
      }

      var day = splitDateFormat[0];
      var month = splitDateFormat[1];
      var year = splitDateFormat[2];

      return year + "-" + month + "-" + day;
    }
  } catch (error) {
    console.log(error);
  }
};

const isoFormatToCustomFormat = (isoString) => {
  const dateObj = new Date(isoString).toUTCString();
  const splitDateFormat = dateObj?.split(" ");
  const currentDate =
    splitDateFormat[1] + "-" + splitDateFormat[2] + "-" + splitDateFormat[3];

  let timeVal = isoString.split("T");
  let time = timeVal[1].split(":");
  let hours = parseInt(time[0]);
  let mid = " am";
  if (hours === 12) {
    mid = " pm";
  }
  if (hours > 12) {
    hours = hours % 12;
    mid = " pm";
    if (hours === 0) {
      mid = " am";
    }
  }
  if (hours === 0) {
    hours = 12;
  }

  const timeString = hours + ":" + time[1] + mid;
  const timeIn24HrFormatString = parseInt(time[0]) + ":" + time[1];
  return {
    dateString: currentDate,
    timeString: timeString,
    dateAndTimeString: `${currentDate}, ${timeString}`,
    dateAndTimeIn24HrFormatString: `${currentDate}, ${timeIn24HrFormatString}`
  };
};

const stringToDate = (inputString) => {
  return new Date(inputString);
};

const dateToisoString = (inputDate) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const convertedDate = inputDate.toLocaleString("en-GB", {
    timeZone: localTimeZone
  });

  let isoVal = inputDate.toTimeString();
  isoVal = isoVal.split(" ");
  const splitDate = convertedDate?.split(",");
  if (splitDate.count === 0) {
    throw new Error("No valid date found for conversion");
  }

  const splitDateFormat = splitDate[0]?.split("/");
  if (splitDate.count === 0) {
    throw new Error("No valid date found for conversion");
  }

  let day = splitDateFormat[0];
  let month = splitDateFormat[1];
  let year = splitDateFormat[2];

  const isoString = year + "-" + month + "-" + day + "T" + isoVal[0] + ".000Z";
  return isoString;
};

export const utcStringToLocaleString = (inputString, timeFormat) => {
  const dateObj = stringToDate(inputString);
  const isoString = dateToisoString(dateObj);
  const { dateAndTimeString } = isoFormatToCustomFormat(isoString);
  const { dateAndTimeIn24HrFormatString } = isoFormatToCustomFormat(isoString);
  if (timeFormat === 24) return dateAndTimeIn24HrFormatString;
  return dateAndTimeString;
};

export const convertDateToUTCZone = (inputDate) => {
  const dateObj = new Date(inputDate).toUTCString();
  const newDateObj = new Date(dateObj).toISOString();
  return newDateObj;
};

export const changeTimeFormat = (inputTime) => {
  const d = new Date(inputTime);
  const convertedTime = moment(d.toLocaleTimeString(), "hh:mm A").format(
    "HH:mm:ss"
  );
  return convertedTime;
};
export const convertMilliSecondTime = (date = null, time = null) => {
  if (date === null || time === null) {
    return null;
  }
  const finalDateTime = new Date(changeDateFormat(date) + " " + time);
  const timeinMS = finalDateTime.getTime() / 1000;
  return timeinMS;
};
