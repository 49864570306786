import { OwcIcon, OwcList, OwcTooltip } from "@one/react";
import { OwcListItem } from "@one/react/dist";
import { useHistory } from "react-router";
import styles from "./AppDrawer.module.scss";
import { HOME_SUB_MENU } from "../constants";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { random } from "lodash";
const DrawerSideBar = ({ item }) => {
  const [sideMenu, setSideMenu] = useState(HOME_SUB_MENU);
  const history = useHistory();
  const handleRedirect = (item) => {
    if (!item.isDisabled && item.url !== null) {
      const updatedSideMenu = sideMenu.map((items) => {
        items.isActive = items.url === item.url;
        return items;
      });
      setSideMenu(updatedSideMenu);
      history.replace(item.url);
    }
  };
  useEffect(() => {
    const pathName = window.location.pathname;
    const updatedSideMenu = sideMenu.map((item) => {
      item.isActive = item.url === pathName;
      return item;
    });
    setSideMenu(updatedSideMenu);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <OwcList data-testid="sidebar-list">
      {sideMenu.map((item) => {
        const rand = random(100000000000);
        return (
          <>
            <OwcTooltip anchor={item.id + rand}>{item.text}</OwcTooltip>
            <OwcListItem
              selected={item.isActive}
              onClick={() => handleRedirect(item)}
              noBorder
              id={item.id + rand}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                {typeof item.icon === "string" ? (
                  <OwcIcon
                    name={item.icon}
                    type="legacy"
                    className={styles.appdrawerIconColor}
                  />
                ) : (
                  <div className={styles.appdrawerIconStyle}>{item.icon}</div>
                )}
                <span style={{ marginLeft: 15 }}>{item.text}</span>
              </div>
            </OwcListItem>
          </>
        );
      })}
    </OwcList>
  );
};
export default connect(null, {})(DrawerSideBar);
