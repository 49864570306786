import React from "react";
import LogBookTable from "./LogBookTable";
import UpdateData from "./UpdateData";

const LogBookList = ({
  dataTestId,
  orderBy,
  meta,
  fetchingFn,
  disableStatus,
  clusterLogStatus = false,
  type
}) => {
  return (
    <>
      <LogBookTable
        dataTestId={dataTestId}
        orderBy={orderBy}
        meta={meta}
        disableStatus={disableStatus}
        type={type}
      />
      <UpdateData fetchingFn={fetchingFn} clusterLogStatus={clusterLogStatus} />
    </>
  );
};

export default LogBookList;
