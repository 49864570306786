import moment from "moment";
import { valueOrEmpty } from "../../../../utils/helpers/text";
import { changeLogs, changeTable } from "../../../../utils/helpers/changeIndex";
import ReactDOMServer from "react-dom/server";

export const RUN_SHEET_TABLE_HEADERS = [
  { header: "Date", value: "auto" },
  { header: "Edited by", value: "auto" },
  { header: "Reason", value: "auto" },
  { header: "Change logs", value: "auto" }
];

export const runSheetItemsToPDF = (
  items,
  allLogHierarchyData,
  logBookEquipment,
  inventoryId = null,
  storeGxpReadys
) => {
  const tableRecords = items.map((item) => {
    const activityDate = `${moment(item?.modifiedDate).format("DD-MMM-yyyy")}`;
    const changeLogItem = changeLogs(item);
    let arrayCheck = Array.isArray(changeLogItem);
    return [
      activityDate,
      valueOrEmpty(item?.modifiedByUserId || item?.modifiedByEmail, false, "-"),
      valueOrEmpty(item?.editReason, false, "-"),
      arrayCheck
        ? changeLogItem
        : ReactDOMServer.renderToStaticMarkup(changeLogItem)
    ];
  });

  const changeLogItem = changeTable(tableRecords, logBookEquipment);
  const records = ReactDOMServer.renderToStaticMarkup(changeLogItem);

  return {
    headers: RUN_SHEET_TABLE_HEADERS,
    records
  };
};
