import React from "react";

import { ChipsWrapper } from "../../features/log-book/log-book-item-form-dialog/FormsStyled";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { OwcChip } from "@one/react";

const filter = createFilterOptions();

const onChangeItemToValue = (
  newValue,
  { objectAsValue, propValue, propLabel }
) => {
  if (typeof newValue === "string") {
    return objectAsValue
      ? {
          [propValue]: newValue.replace(/\s/g, "_") + new Date().toISOString(),
          [propLabel]: newValue
        }
      : newValue;
  } else if (newValue && newValue.inputValue) {
    // Create a new value from the user input
    return objectAsValue
      ? {
          [propValue]:
            newValue.inputValue.replace(/\s/g, "_") + new Date().toISOString(),
          [propLabel]: newValue.inputValue
        }
      : newValue.inputValue;
  } else {
    return objectAsValue ? newValue : newValue?.[propValue];
  }
};

const getPropertyOrWhole = (item, { objectAsValue, propValue }) =>
  objectAsValue ? item?.[propValue] : item;

const CustomAutocompleteWithOwnProp = ({
  value,
  id,
  dataTestid,
  optionsList,
  setValue,
  label,
  propValue,
  propLabel,
  objectAsValue,
  fullWidth,
  multiple,
  error,
  helperText,
  ...props
}) => {
  const renderTags = (selectedObjects, getTagProps) => (
    <ChipsWrapper>
      {selectedObjects?.map((selectedObject, index) => {
        const chipLabel = objectAsValue
          ? selectedObject?.inputValue ?? selectedObject?.[propLabel]
          : selectedObject;

        return (
          <OwcChip
            data-testid={`${dataTestid}-chip-for-${chipLabel}`}
            key={selectedObject}
            variant="outlined"
            onMouseDown={(event) => {
              event.stopPropagation();
            }}
            {...getTagProps({ index })}
            removable
            round
            onRemove={(e) => {
              let chip = value.filter((x, i) => i !== index);
              setValue(chip);
            }}
          >
            {chipLabel}
          </OwcChip>
        );
      })}
    </ChipsWrapper>
  );
  return (
    <>
      <Autocomplete
        fullWidth={fullWidth}
        value={value}
        onChange={(event, newValue) => {
          if (multiple) {
            setValue(
              newValue
                .map((item) =>
                  onChangeItemToValue(item, {
                    objectAsValue,
                    propValue,
                    propLabel
                  })
                )
                .reduce((acc, item) => {
                  if (
                    !acc.some(
                      (v) =>
                        getPropertyOrWhole(v, { propValue, objectAsValue }) ===
                        getPropertyOrWhole(item, { propValue, objectAsValue })
                    )
                  ) {
                    acc.push(item);
                  }

                  return acc;
                }, [])
            );
          } else {
            setValue(
              onChangeItemToValue(newValue, {
                objectAsValue,
                propValue,
                propLabel
              })
            );
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          // Suggest the creation of a new value
          if (params.inputValue !== "") {
            filtered.push({
              inputValue: params.inputValue,
              [propLabel]: `Add "${params.inputValue}"`
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={id}
        data-testid={dataTestid}
        options={optionsList}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option[propLabel];
        }}
        // renderOption={(option) => (
        //   <li data-testid={`${dataTestid}-${option[propValue]}`}>
        //     {option[propLabel]}
        //   </li>
        // )}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{}}
            label={label}
            variant="filled"
            helperText={helperText}
            error={error}
            data-testid={`${dataTestid}-text-field`}
            size="small"
          />
        )}
        multiple={multiple}
        renderTags={renderTags}
        openOnFocus={true}
        {...props}
      />
    </>
  );
};

export default CustomAutocompleteWithOwnProp;
