import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getAllMasterFilterData } from "./LoadEquipmentService";
import EquipmentMainPage from "./EquipmentMainPage";
import { withApollo } from "react-apollo";
import { useSelector } from "react-redux";
import {
  DEFAULT_COLUMNS,
  DEFAULT_FILTER,
  DEFAULT_PAGE_SIZE
} from "../../constants";
import { CircularProgress } from "@mui/material";

const LoadEquipmentInfo = (client) => {
  const [masterDataFilters, setMasterDataFilters] = useState({});
  const [myLabFilter, setMyLabFilter] = useState(null);
  const [lastFavFilter, setLastFavFilter] = useState(DEFAULT_FILTER);
  const [userFilters, setUserFilters] = useState([]);
  const [userDisplayColumn, setUserDisplayColumn] = useState([]);
  const [lastDisplayColumns, setLastDisplayColumns] = useState(DEFAULT_COLUMNS);
  const [lastPageSize, setLastPageSize] = useState(DEFAULT_PAGE_SIZE);

  const storeUser = useSelector((store) => store.user);
  useEffect(() => {
    loadFilterMasterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadFilterMasterData = async () => {
    const {
      masterFiltersList,
      myLabFilterMaster,
      userDetail,
      userDisplayColumnMaster
    } = await getAllMasterFilterData({
      client: client,
      site: storeUser?.site,
      userID: storeUser?.id
    });

    setMyLabFilter(() => myLabFilterMaster);
    setMasterDataFilters(() => masterFiltersList);
    setUserFilters(() => userDetail?.userFilters);
    setLastFavFilter(() => userDetail?.lastFavFilter);
    setUserDisplayColumn(() => userDisplayColumnMaster);
    setLastDisplayColumns(() => userDetail?.lastDisplayColumns);
  };

  if (Object.keys(masterDataFilters)?.length === 0) {
    return (
      <>
        <div
          className="loading"
          style={{
            height: 200,
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <CircularProgress size={30} />
        </div>
      </>
    );
  }

  return (
    <>
      {Object.keys(masterDataFilters)?.length > 0 && (
        <>
          <EquipmentMainPage
            masterDataFilters={masterDataFilters}
            myLabFilter={myLabFilter}
            lastFavFilter={lastFavFilter}
            setLastFavFilter={setLastFavFilter}
            userFilters={userFilters}
            setUserFilters={setUserFilters}
            userDisplayColumn={userDisplayColumn}
            setUserDisplayColumn={setUserDisplayColumn}
            lastDisplayColumns={lastDisplayColumns}
            setLastDisplayColumns={setLastDisplayColumns}
            lastPageSize={lastPageSize}
            setLastPageSize={setLastPageSize}
          />
        </>
      )}
    </>
  );
};
export default withApollo(LoadEquipmentInfo);
