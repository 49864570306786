const IconEmptyBox = (props) => (
  <svg
    width="85"
    height="77"
    viewBox="0 0 85 77"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M42.5024 39.5957L14.5024 58.1974L42.5024 76.9142L70.4973 58.1974L42.5024 39.5957Z"
      fill="#0B41CD"
    />
    <path
      d="M14.5024 58.3124L42.5024 39.5956V9.68164L14.5024 28.393V58.3124Z"
      fill="#B2D1F0"
    />
    <path
      d="M70.4973 58.3124L42.5024 39.5956V9.68164L70.4973 28.2833V58.3124Z"
      fill="#0B41CD"
    />
    <path
      d="M43.0146 47.1977L69.9852 29.2646V57.8961L43.0146 75.9223V47.1977Z"
      fill="#B2D1F0"
    />
    <path
      d="M15.0146 57.8953V29.7461L41.9903 47.142V75.9215L15.0146 57.8953Z"
      fill="#B2D1F0"
    />
    <path
      d="M42.4973 46.8904V46.8301L14.5024 28.7765V58.1972L42.4973 76.914L70.4973 58.1972C70.4973 58.1972 70.4973 35.6658 70.4973 28.2832L42.4973 46.8904ZM41.9903 75.2807L15.5319 57.5888V30.7167L41.9903 47.7947V75.2807ZM69.473 57.5888L43.1631 75.2807V47.844L69.473 30.2508V57.5888Z"
      fill="#0B41CD"
    />
    <path
      d="M0.504883 18.8018L28.4998 0.0849609L43.4396 10.323L15.4549 29.0453L0.504883 18.8018Z"
      fill="#F7F9FC"
    />
    <path
      d="M41.5957 10.2963L56.4997 0.392578L84.4997 18.9943L69.5394 28.9199L41.5957 10.2963Z"
      fill="#F7F9FC"
    />
    <path
      d="M0.5 37.6991L15.4347 27.7734L43.4398 46.3696L28.5 56.2898L0.5 37.6991Z"
      fill="#F7F9FC"
    />
    <path
      d="M41.5962 46.3808L69.5911 27.6641L84.5002 37.7596L56.5002 56.4764L41.5962 46.3808Z"
      fill="#F7F9FC"
    />
    <path
      d="M28.4949 1.3839L41.9494 10.6135L42.4923 10.9862L43.0403 10.619L56.5 1.67986L82.5538 18.9881L69.9597 27.3463L68.5717 28.267L69.9546 29.2042L82.5794 37.7542L56.5103 55.1775L43.0506 46.0685L42.5077 45.7013L41.9648 46.063L28.5 54.9967L2.4411 37.6994L15.0403 29.3248L16.4181 28.4095L15.0506 27.4723L2.42061 18.8127L28.4949 1.3839ZM28.5 0.0849609L0.505122 18.8018L14.5026 28.3931L0.5 37.6994L28.5 56.2901L42.5026 46.9948L56.5 56.4765L84.5 37.7652L70.5026 28.2835L84.5 18.9936L56.5 0.391884L42.5026 9.69273L28.5 0.0849609Z"
      fill="#0B41CD"
    />
  </svg>
);
export default IconEmptyBox;
