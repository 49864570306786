import { useRef, useState } from "react";
import ExportToPDFButton from "../log-book-export-pdf/ExportToPDFButton";
import { OwcIconButton } from "@one/react";
import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper
} from "@mui/material";

const ChangeButtonActionBox = ({
  dataTestId,
  fetchingFn,
  fileNamePrefix,
  raportTitle,
  mapSecondFn
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {/* <ButtonGroup
        variant="outlined"
        ref={anchorRef}
        data-testid={`export-${dataTestId}-menu-group`}
        aria-label="split export button"
      >
        <Button
          data-testid={`export-${dataTestId}-menu-label`}
          variant="outlined"
          style={{
            background: "var(--one-color-gray-50)",
            padding: "5px 10px",
            border: "thin solid var(--one-color-background-brand-1)",
            borderRadius: "4px 0 0 4px",
            fontFamily: "var(--one-text-font-family-default)",
            fontSize: "var(--one-typography-desktop-button-font-size)",
            fontWeight: "var(--one-typography-desktop-button-font-weight)",
            textTransform: "inherit",
            color: "var(--one-color-interaction-default-brand-1)"
          }}
        >
          {LOGBOOK_LABEL.BUTTON.export_file}
        </Button>
        <Button
          size="small"
          aria-label="select export method"
          aria-haspopup="menu"
          onClick={handleToggle}
          data-testid={`export-${dataTestId}-menu-trigger`}
          style={{
            padding: 0,
            background: "var(--one-color-gray-50)",
            border: "thin solid var(--one-color-background-brand-1)",
            borderRadius: "0 4px 4px 0"
          }}
          variant="outlined"
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup> */}
      <div>
        <OwcIconButton
          flat
          ref={anchorRef}
          type="outlined"
          icon="more_vertical"
          onClick={handleToggle}
          //   id={params?.data?.id[0]}
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 2
          }}
          placement="bottom-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    id={`export-${dataTestId}-menu`}
                    data-testid={`export-${dataTestId}-menu`}
                  >
                    <ExportToPDFButton
                      raportTitle={raportTitle}
                      fetchingFn={fetchingFn}
                      dataTestId={dataTestId}
                      fileNamePrefix={fileNamePrefix}
                      mapFn={mapSecondFn}
                    />
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </>
  );
};

export default ChangeButtonActionBox;
