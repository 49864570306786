import { omit } from "underscore";
import { v4 } from "uuid";
import { DEFAULT_SITE, formStates } from "../../../../constants";
import { changeTimeFormat } from "../../helpers";

export const makeCleanLogSheet = ({ item, isNew, user, formState }) =>
  isNew
    ? {
        id: v4(),
        logSheetEntryId: v4(),
        actionDate: new Date(),
        actionTime: changeTimeFormat(new Date()),
        approverId: user?.user ?? "",
        approverUserName: user.name ?? "",
        site: user.site ?? DEFAULT_SITE,
        inventoryId:
          formState === formStates?.NEW_FORM ? "" : item?.inventoryId,
        equipmentId:
          formState === formStates?.NEW_FORM ? "" : item?.equipmentId,
        serialNumber:
          formState === formStates?.NEW_FORM ? "" : item?.serialNumber
      }
    : {
        ...omit(item, "__typename"),
        approverId: user?.user ?? "",
        approverUserName: user.name ?? "",
        site: user.site ?? DEFAULT_SITE,
        instrumentDetails: {
          ...omit(item?.instrumentDetails, "__typename")
        },
        id: v4(),
        logSheetEntryId: item?.id
      };
