import styled from "styled-components";
import { fetchRunLogs } from "../run-logs/fetch-run-logs";
import {
  runLogsItemsToPDF,
  runLogsItemsToSheet
} from "../run-logs/run-logs-export/run-logs-items-to-sheet";
import { CreateRunLogChange } from "../run-logs/run-logs-form/CreateRunLogChange";
import { emptyRunLog } from "../run-logs/run-logs-form/empty-run-log";
import { makeCleanRunLog } from "../run-logs/run-logs-form/make-clean-run-log";
import { runLogValidationSchemaFactory } from "../run-logs/run-logs-form/run-log-validation-schema";
import { runLogsClearSuggestion } from "../run-logs/run-logs-form/run-logs-clear-suggestion";
import { runLogsSelectSuggestion } from "../run-logs/run-logs-form/run-logs-select-suggestion";
import RunDataInputsBox from "../run-logs/run-logs-form/RunDataInputsBox";
import { CellsInfoArray } from "../run-logs/run-logs-history/HistoryRunLogCellsInfoArray";
import { ImportHistoryOfRunLogs } from "../run-logs/run-logs-history/ImportHistoryOfRunLogs";
import {
  RunLogTableMeta,
  RunLogTableEquipmentCentricMeta
} from "../run-logs/runLogs-table/RunLogTableMeta";
import ItemPage from "./ItemPage";
import LOGBOOK_LABEL from "./../../../utils/constants/logbookLabel";
import { formTypes } from "../../../constants";
import { useParams } from "react-router-dom";

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const Header = styled.span`
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
`;

const RunLogsPage = ({ equipmentDetail }) => {
  const { inventoryId } = useParams();
  return (
    <ItemPage
      raportTitle="Runlog Report"
      addButtonLabel={LOGBOOK_LABEL.BUTTON.add_new_run}
      queryName="runLogChangeByRunLogEntryId"
      importFn={ImportHistoryOfRunLogs}
      CellsInfoArray={CellsInfoArray}
      title={LOGBOOK_LABEL.run_logs_history_heading}
      dataTestId="run-log"
      type={formTypes?.RUN_LOG}
      fetchingFn={fetchRunLogs}
      mapFn={runLogsItemsToSheet}
      mapSecondFn={runLogsItemsToPDF}
      fileNamePrefix="run-logs-report"
      pageTitle={LOGBOOK_LABEL.PAGE_TITLE.run_logs}
      orderBy="runStartDate"
      meta={inventoryId ? RunLogTableEquipmentCentricMeta : RunLogTableMeta}
      emptyItem={emptyRunLog}
      cleanItemFn={makeCleanRunLog}
      createItemChangeFn={CreateRunLogChange}
      validationSchemaFactory={runLogValidationSchemaFactory}
      selectSuggestionFn={runLogsSelectSuggestion}
      clearSuggestionFn={runLogsClearSuggestion}
      showChipBox={true}
      equipmentDetail={equipmentDetail}
    >
      <RunDataInputsBox equipmentDetail={equipmentDetail} />
    </ItemPage>
  );
};

export default RunLogsPage;
