import { useContext } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { NormalFont } from "../../../components/shared/NormalFont";
import { HistoryItemContext } from "./context";
import HistoryItemSingleFrame from "./HistoryItemSingleFrame";
import { utcStringToLocaleString } from "../../log-book/helpers";
import { OwcIconButton } from "@one/react";
import { entryType, formStates, formTypes } from "../../../constants";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";
import { LogBookSelectContext } from "../log-book-table/logBooksSelectAll/context";
import { getSubEquipments } from "../../../utils/helpers/fetching";
import {
  getEquipDetailLogList,
  getRunLogSubEquipments
} from "../run-logs/run-logs-form/CreateRunLogChange";
import { getActionLogSubEquipments } from "../log-sheet/log-sheet-form/CreateLogSheetChange";
import { CloudSearchConfig } from "../../../components/shared/CloudSearchConfig";
import { getEquipDetailInfo } from "../LoadLogBooksInfo";
import { withApollo } from "react-apollo";
import { find, unionBy } from "lodash";

const HistoryItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:first-child) {
    margin-top: 16px;
  }
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  color: #333333;
`;

const HistoryItemContent = ({
  client,
  selectedItem,
  CellsInfoArray,
  dataTestId,
  disableStatus = false
}) => {
  const { handleOpenWorkFlowModel, loadLogBookEquipment, logType, allLogType } =
    useContext(CoversheetMainPageContext);
  const { onSetSelection } = useContext(LogBookSelectContext);
  const { historyItem, handleCloseHistoryContent } =
    useContext(HistoryItemContext);
  const user = useSelector((store) => store.user);
  const canEdit = useSelector((store) => store.user.canEdit);
  const systemStatus = useSelector((store) => store.runLogsForm.systemStatuss);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);

  const loadSelectedSubEquipment = async (equipmentData) => {
    if (logType === formTypes?.RUN_LOG) {
      return await getRunLogSubEquipments({
        client,
        id: equipmentData?.id
      });
    } else if (logType === formTypes?.ACTION_LOG) {
      return await getActionLogSubEquipments({
        client,
        id: equipmentData?.id
      });
    } else if (
      logType === formTypes?.ALL_LOG &&
      allLogType === formTypes?.RUN_LOG
    ) {
      return await getRunLogSubEquipments({
        client,
        id: equipmentData?.id
      });
    } else if (
      logType === formTypes?.ALL_LOG &&
      allLogType === formTypes?.ACTION_LOG
    ) {
      return await getActionLogSubEquipments({
        client,
        id: equipmentData?.id
      });
    }
  };

  const handlePromises = async ({
    promises,
    selectedEquipment,
    equipmentData,
    subEquipmentLogDetails = null
  }) => {
    const updatedEquipmentData = await getSubEquipments({
      promises,
      selectedEquipment,
      equipmentData,
      logType,
      gxpReadys,
      systemStatus,
      isEditMode: true,
      subEquipmentLogDetails
    });
    if (updatedEquipmentData) {
      loadLogBookEquipment(updatedEquipmentData?.equipmentData);
      onSetSelection(updatedEquipmentData?.selectedSubEquip);
      handleOpenWorkFlowModel({
        isCluster: equipmentData?.entryType === entryType?.cluster,
        form: formStates?.EDITABLE_FORM
      });
    }
  };

  return (
    <div data-testid={`history-${dataTestId}-content`}>
      {historyItem?.map((theItem, index) => {
        return (
          <>
            <HistoryItemHeader
              data-testid={`history-${dataTestId}-content-header-${index}`}
            >
              <div>
                {utcStringToLocaleString(theItem.createdAt)}
                {theItem.editReason && (
                  <NormalFont>
                    {" "}
                    (Edited by{" "}
                    {!theItem.approverUserName ? "-" : theItem.approverUserName}
                    )
                  </NormalFont>
                )}
              </div>
              {index === 0 && (
                <OwcIconButton
                  data-testid={`history-${dataTestId}-content-edit-first`}
                  onClick={() => {
                    handleCloseHistoryContent();
                    let equipmentData = { ...selectedItem };
                    equipmentData.qualificationStatus = equipmentData
                      ?.equipmentDetails?.qualificationStatus
                      ? equipmentData?.equipmentDetails?.qualificationStatus
                      : null;
                    equipmentData.equipSystemStatus = equipmentData
                      ?.equipmentDetails?.systemStatus
                      ? equipmentData?.equipmentDetails?.systemStatus
                      : "-";
                    equipmentData.entryType =
                      equipmentData?.equipmentDetails.entryType;
                    equipmentData.equipmentModel =
                      equipmentData?.equipmentDetails?.equipmentModel;
                    equipmentData.equipmentNickName =
                      equipmentData.equipmentDetails.equipmentNickName;
                    //TODO: check and remove the code if it is not required
                    equipmentData.addInfo = {
                      currentGxPStatusFromLogs:
                        equipmentData?.equipmentDetails?.addInfo
                          ?.currentGxPStatusFromLogs || null,
                      currentSystemStatusFromLogs:
                        equipmentData?.equipmentDetails?.addInfo
                          ?.currentSystemStatusFromLogs || null
                    };
                    if (
                      logType === formTypes?.RUN_LOG ||
                      logType === formTypes?.ACTION_LOG
                    ) {
                      equipmentData.selectedLogType = logType;
                    } else {
                      equipmentData.selectedLogType =
                        equipmentData?.logType === "Action"
                          ? formTypes?.ACTION_LOG
                          : formTypes?.RUN_LOG;
                    }
                    equipmentData.subEquipment = [];
                    let promises = [];
                    let clusterSubEquipmentPromises = [];
                    let cloudSeachPromiesClusterSub = [];
                    const defaultObj =
                      equipmentData?.selectedLogType === formTypes?.RUN_LOG
                        ? {
                            key: "-",
                            value: "-"
                          }
                        : null;

                    if (
                      equipmentData?.selectedLogType === formTypes?.ACTION_LOG
                    ) {
                      equipmentData.gxpReady =
                        find(gxpReadys, {
                          key: equipmentData?.gxpReady?.key
                        }) || defaultObj;
                      equipmentData.systemStatus =
                        find(systemStatus, {
                          value: equipmentData?.systemStatus?.value
                        }) || defaultObj;

                      if (equipmentData?.entryType === entryType?.cluster) {
                        equipmentData.gxpReadyCluster =
                          find(gxpReadys, {
                            key: equipmentData?.gxpReadyCluster?.key
                          }) || defaultObj;
                        equipmentData.systemStatusCluster =
                          find(systemStatus, {
                            value: equipmentData?.systemStatusCluster?.value
                          }) || defaultObj;
                      }
                    }

                    if (equipmentData?.entryType === entryType?.cluster) {
                      Promise.all([
                        loadSelectedSubEquipment(equipmentData)
                      ]).then(async (response) => {
                        let updatedResponseData = response
                          ? response[0] || []
                          : null;
                        updatedResponseData = updatedResponseData?.map(
                          (data) => {
                            const updatedData = {
                              ...data,
                              gxpReady:
                                find(gxpReadys, {
                                  key: data?.gxpReady?.key
                                }) || defaultObj,
                              systemStatus:
                                find(systemStatus, {
                                  value: data?.systemStatus?.value
                                }) || defaultObj
                            };
                            return updatedData;
                          }
                        );
                        const clusterIds = [];
                        const result = await CloudSearchConfig({
                          sortBy: "position_in_cluster",
                          searchValue: `cluster_id:${equipmentData?.inventoryId} AND site_name:${user.site}`,
                          start: 0,
                          size: 1000,
                          sort: "asc"
                        });
                        result?.data?.forEach((subEquip) => {
                          if (subEquip.entry_type[0] === entryType?.cluster) {
                            clusterIds.push(subEquip.id[0]);
                          }
                          promises?.push(
                            getEquipDetailInfo(subEquip.id[0], client)
                          );
                        });

                        let subEquipLogPromises = [];
                        let subEquipmentLogDetails = [];
                        if (
                          updatedResponseData &&
                          updatedResponseData?.length > 0
                        ) {
                          let uniqRespose = unionBy(
                            updatedResponseData,
                            "inventoryId"
                          );
                          uniqRespose.forEach((item) => {
                            const logTypeKey =
                              equipmentData?.selectedLogType ===
                              formTypes?.RUN_LOG
                                ? "runLogEntryId"
                                : "logSheetEntryId";
                            subEquipLogPromises.push(
                              getEquipDetailLogList(
                                item[logTypeKey],
                                client,
                                equipmentData?.selectedLogType
                              )
                            );
                          });

                          Promise.all(subEquipLogPromises).then(
                            (logDetails) => {
                              subEquipmentLogDetails = logDetails?.map(
                                (log) => {
                                  const logObj = {
                                    ...log,
                                    inventoryId:
                                      log?.equipmentDetails?.inventoryId,
                                    gxpReady:
                                      find(gxpReadys, {
                                        key: log?.gxpReady?.key
                                      }) || defaultObj,
                                    systemStatus:
                                      find(systemStatus, {
                                        value: log?.systemStatus?.value
                                      }) || defaultObj
                                  };
                                  return logObj;
                                }
                              );
                              if (clusterIds?.length > 0) {
                                clusterIds.forEach((clusterId) => {
                                  const clusterSubEquipment = CloudSearchConfig(
                                    {
                                      sortBy: "position_in_cluster",
                                      searchValue: `cluster_id:${clusterId} AND site_name:${user.site}`,
                                      start: 0,
                                      size: 1000,
                                      sort: "asc"
                                    }
                                  );
                                  cloudSeachPromiesClusterSub.push(
                                    clusterSubEquipment
                                  );
                                });

                                const allCloudSearchPromised = Promise.all(
                                  cloudSeachPromiesClusterSub
                                );
                                allCloudSearchPromised?.then(
                                  (clusterSubEquipmentRespose) => {
                                    clusterSubEquipmentRespose?.forEach(
                                      (subEquip) => {
                                        subEquip?.data?.forEach((equip) => {
                                          clusterSubEquipmentPromises?.push(
                                            getEquipDetailInfo(
                                              equip.id[0],
                                              client
                                            )
                                          );
                                        });
                                      }
                                    );
                                    handlePromises({
                                      promises: [
                                        ...promises,
                                        ...clusterSubEquipmentPromises
                                      ],
                                      selectedEquipment: [updatedResponseData],
                                      equipmentData,
                                      subEquipmentLogDetails
                                    });
                                  }
                                );
                              } else {
                                handlePromises({
                                  promises: [...promises],
                                  selectedEquipment: [updatedResponseData],
                                  equipmentData,
                                  subEquipmentLogDetails
                                });
                              }
                            }
                          );
                        }
                      });
                    } else {
                      loadLogBookEquipment(equipmentData);
                      handleOpenWorkFlowModel({
                        isCluster:
                          selectedItem?.data?.equipmentDetails?.entryType ===
                          entryType?.cluster,
                        form: formStates?.EDITABLE_FORM
                      });
                    }
                    // handleOpen(item);
                  }}
                  disabled={
                    selectedItem?.equipmentDetails?.clusterId || !canEdit
                  }
                  style={{ padding: 5 }}
                  flat
                  icon="edit"
                  type="legacy"
                />
              )}
            </HistoryItemHeader>
            {theItem.editReason && (
              <>
                <div style={{ marginBottom: 3, color: "#333333" }}>
                  Reason:
                  <span style={{ marginLeft: 3 }}>
                    {!theItem.editReason ? "-" : theItem.editReason}
                  </span>
                </div>
                <div style={{ marginBottom: 3, color: "#333333" }}>
                  <div>
                    Comment:
                    <span style={{ marginLeft: 3 }}>
                      {!theItem?.editComment ? "-" : theItem?.editComment}
                    </span>
                  </div>
                </div>
              </>
            )}
            <HistoryItemSingleFrame
              history={theItem}
              CellsInfoArray={CellsInfoArray}
            />
          </>
        );
      })}
    </div>
  );
};

export default withApollo(HistoryItemContent);
