import * as R from "ramda";
import { at } from "lodash/fp";
import { omit } from "underscore";
import { format, parse } from "date-fns";
import { DAYS, MONTHS, RUN_STATUS_FIELD, entryType } from "../../../constants";
import { uniqBy, orderBy, trim, find } from "lodash";
export const emailToName = (email) =>
  typeof email === "string" ? email.split("@")[0]?.replace(/\./g, " ") : "";

export const getText = (text) => R.compose(R.toLower, R.trim)(text);

export const valueOrEmpty = (
  value,
  itsADate = false,
  defaultValue = "",
  dateFormat = "dd-MMM-yyyy"
) => {
  if (value && itsADate) {
    return format(new Date(value), dateFormat);
  } else if (value && value !== "null" && !itsADate) {
    return value;
  } else {
    return defaultValue;
  }
};

export const stringOrEmpty = (
  value,
  { propLabel = "value", defaultValue = "", joinString = ", " }
) => {
  if (Array.isArray(value) && value.length > 0) {
    if (typeof value[0] === "object")
      return value.map((item) => item[propLabel]).join(joinString);
    else return value.join(joinString);
  }
  return defaultValue;
};

export const filterByQuery = (
  data,
  query,
  propFilter = ["name"],
  propSort = ["name"],
  reverse = false
) => {
  if (!query) {
    R.sort(R.descend(R.path(propSort)))(data);
  }
  const _query = getText(query);
  const valuesAt = at(propFilter);
  const result = R.compose(
    R.sort(R.descend(R.path(propSort))),
    R.filter(
      R.compose(R.contains(_query), getText, R.compose(R.join(""), valuesAt))
    )
  )(data);

  return reverse ? result.reverse() : result;
};

export const omitOrReturn = (obj) => {
  if (obj && typeof obj === "object" && Object.keys(obj).length > 0) {
    return omit(obj, "__typename");
  }
  return obj;
};

export const omitOrReturnArray = (obj) => {
  if (obj && Array.isArray(obj))
    return obj.map((item) => omit(item, "__typename"));

  return obj;
};

export const getLocationText = (itemWithLocationDetails) => {
  let { buildingLocation, floorAndRoomLocation } =
    itemWithLocationDetails ?? {};
  if (!buildingLocation && !floorAndRoomLocation) {
    return "";
  }
  if (typeof buildingLocation === "object") {
    buildingLocation = buildingLocation.value;
  }
  if (typeof floorAndRoomLocation === "object") {
    floorAndRoomLocation = floorAndRoomLocation.value;
  }
  if (!buildingLocation && !floorAndRoomLocation) {
    return "";
  }
  return `${buildingLocation ?? ""};${floorAndRoomLocation ?? ""}`;
};

export const getLocationDetails = (itemWithLocationDetails) => {
  const { location } = itemWithLocationDetails ?? {};

  return location && location !== "null" ? location : "-";
};
export const parseTime = (
  time,
  { timeFormat = "h:mm aaa", parseFormat = "HH:mm:ss" } = {}
) => {
  if (!time) {
    return "";
  }

  try {
    const parsedDate = parse(time, parseFormat, new Date());
    return format(parsedDate, timeFormat);
  } catch (e) {
    return time;
  }
};

export const parseTimeLocal = (time) => {
  if (!time) {
    return "";
  }
  try {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let runStartDateNow = new Date();
    runStartDateNow.setHours(0, 0, 0, 0);
    const runStartTimesplitTime = time.split(":");
    const runStartTimeseconds =
      +runStartTimesplitTime[0] * 60 * 60 +
      +runStartTimesplitTime[1] * 60 +
      +runStartTimesplitTime[2];
    runStartDateNow.setSeconds(runStartTimeseconds);
    const parsedDateLocal = runStartDateNow.toLocaleString("en-GB", {
      timeZone: localTimeZone
    });
    const runStartTimesplitTimeNew = parsedDateLocal.split(",");
    const formattedVal = runStartTimesplitTimeNew[0].split("/");
    runStartDateNow = new Date(
      formattedVal[2],
      formattedVal[1],
      formattedVal[0]
    );
    runStartDateNow.setHours(0, 0, 0, 0);
    const runStartTimesplitTimeNewVal = runStartTimesplitTimeNew[1].split(":");
    const runStartTimesecondsnew =
      +runStartTimesplitTimeNewVal[0] * 60 * 60 +
      +runStartTimesplitTimeNewVal[1] * 60 +
      +runStartTimesplitTimeNewVal[2];
    runStartDateNow.setSeconds(runStartTimesecondsnew);
    let hours = runStartDateNow.getHours();
    let minutes = runStartDateNow.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours <= 9 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  } catch (e) {
    return time;
  }
};
export const changeDateFormat = (inputDate, format = null) => {
  try {
    if (typeof inputDate === "string") {
      if (format === null) {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        const FormattedDateObj = new Date(currentDate);
        return FormattedDateObj;
      }
      if (format === "dd-MMM-yyyy" || format === "DD-MMM-YYYY") {
        const dateObj = new Date(inputDate).toUTCString();
        const splitDateFormat = dateObj?.split(" ");
        const currentDate =
          splitDateFormat[1] +
          "-" +
          splitDateFormat[2] +
          "-" +
          splitDateFormat[3];
        return currentDate;
      }
      if (format === "mm/dd/yyyy h:mm:ss") {
        const dateObjString = new Date(inputDate).toUTCString();
        let dateArray = inputDate.split("T");
        dateArray = dateArray[0].split("-");
        const splitDateFormat = dateObjString?.split(" ");
        const currentDate =
          parseInt(dateArray[1]) +
          "/" +
          dateArray[2] +
          "/" +
          splitDateFormat[3];
        const timeVal = inputDate.split("T");
        const time = timeVal[1].split(":");
        let hours = parseInt(time[0]);
        const seconds = time[2].slice(0, 2);
        let mid = " AM";
        if (hours === 12) {
          mid = " PM";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " PM";
          if (hours === 0) {
            mid = " AM";
          }
        }
        if (hours < 10) {
          hours = "0" + hours;
        }
        return currentDate + ", " + hours + ":" + time[1] + ":" + seconds + mid;
      }
      if (format === "h:mm") {
        let timeVal = inputDate.split("T");
        let time = timeVal[1].split(":");
        let hours = parseInt(time[0]);

        let mid = " am";
        if (hours === 12) {
          mid = " pm";
        }
        if (hours > 12) {
          hours = hours % 12;
          mid = " pm";
          if (hours === 0) {
            mid = " am";
          }
        }
        return hours + ":" + time[1] + mid;
      }
      if (format === "ISOToDate") {
        const timeVal = inputDate.split("T");
        const date = timeVal[0].split("-");
        const month = parseInt(date[1]) - 1;
        const time = timeVal[1].split(":");
        const hours = parseInt(time[0]);
        const dateObj = new Date(date[2] + "-" + MONTHS[month] + "-" + date[0]);
        dateObj.setTime(dateObj.getTime() + hours * 60 * 60 * 1000);
        return dateObj;
      }
    } else {
      if (format === "EEEE, dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const weekDay = inputDate.getDay();
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return (
          DAYS[weekDay] +
          ", " +
          day +
          "-" +
          MONTHS[monthVal] +
          "-" +
          year +
          " ."
        );
      }
      if (format === "DD-MMM-YYYY" || format === "dd-MMM-yyyy") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthVal = inputDate.getMonth();
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let year = splitDateFormat[2];

        return day + "-" + MONTHS[monthVal] + "-" + year;
      }
      if (format === "yyyy-MM-dd") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        var month = splitDateFormat[1];
        let year = splitDateFormat[2];

        return year + "-" + month + "-" + day;
      }
      if (format === "ISO") {
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const convertedDate = inputDate.toLocaleString("en-GB", {
          timeZone: localTimeZone
        });

        let isoVal = inputDate.toTimeString();
        isoVal = isoVal.split(" ");
        const splitDate = convertedDate?.split(",");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        const splitDateFormat = splitDate[0]?.split("/");
        if (splitDate.count === 0) {
          throw new Error("No valid date found for conversion");
        }

        let day = splitDateFormat[0];
        let month = splitDateFormat[1];
        let year = splitDateFormat[2];

        const isoString =
          year + "-" + month + "-" + day + "T" + isoVal[0] + ".000Z";
        return isoString;
      }
    }
  } catch (error) {
    console.log(error);
  }
};
export const getTimeZoneOffset = (dateObj = null) => {
  if (dateObj === null) {
    return null;
  }
  if (!dateObj instanceof Date) {
    dateObj = new Date();
  }
  var t = dateObj.toString().match(/[-+]\d{4}/)[0];
  return t.substring(0, 3) + ":" + t.substring(3);
};
const envs = ["dev", "test", "staging", "stage", "hotfix"];

export const getEnv = () => {
  if (envs?.includes(process.env?.REACT_APP_ENV)) {
    if (
      process.env?.REACT_APP_ENV === "staging" ||
      process.env?.REACT_APP_ENV === "stage"
    ) {
      return "stg";
    } else {
      return process.env?.REACT_APP_ENV;
    }
  } else {
    return null;
  }
};

export const getEnvLebel = (currentEnv) => {
  switch (currentEnv) {
    case "hotfix":
      return "Test Hotfix Environment";
    case "test":
      return "Test Environment";
    case "stg":
      return "Staging Environment";
    case "dev":
      return "Development Environment";
    default:
      return "";
  }
};

export const checkEnvReturnValue = (currentEnv, returnVal) => {
  if (
    currentEnv === "hotfix" ||
    currentEnv === "test" ||
    currentEnv === "stg" ||
    currentEnv === "dev"
  ) {
    return returnVal[0];
  } else {
    return returnVal[1];
  }
};

export const getHashCommitValue = (url) => {
  const fragments = url.split("/");
  return fragments[fragments.length - 1].toUpperCase();
};

export const scrollAvailability = () => {
  const currLocation = window.location.href.split("/");
  const scrollAvailability =
    currLocation[currLocation.length - 1] === "info" ? "auto" : "hidden";
  return scrollAvailability;
};

export const replaceEmptyWithHyphen = (val) => {
  return val === "" || val === "null" || val === null || val === undefined
    ? "-"
    : val;
};

export function rearangeDisplayDate(inputDate) {
  // expects Y-m-d
  var splitDate = inputDate.split("-");
  if (splitDate.count === 0) {
    return "-";
  }
  var year = splitDate[0];
  var month = splitDate[1];
  var day = splitDate[2];

  return day + "-" + month + "-" + year;
}
export const sortedList = (items, fieldName = [], sortDirection = ["asc"]) => {
  return orderBy(items, [...fieldName], [...sortDirection]);
};

export const uniqList = (items, fieldName) => {
  return uniqBy(items, fieldName);
};

export const getSortedUniqueList = (list = [], columnName) => {
  return uniqList(list.map((x) => trim(x[columnName]))).sort();
};

export const getUniqValuesWithCaseInsensitive = (values) => {
  let uniqueValues = [];
  let repeatedValuesCaseInSensitive = [];
  values?.forEach((value) => {
    if (!repeatedValuesCaseInSensitive?.includes(value?.toLowerCase())) {
      uniqueValues.push(value);
      repeatedValuesCaseInSensitive.push(value?.toLowerCase());
    }
  });
  return uniqueValues;
};

export const convertToSnakeCase = (value) => {
  return value
    .replace(/([A-Z])/g, "_$1")
    .trim()
    ?.toLowerCase();
};

export const convertToCameCase = (str) => {
  if (!str?.includes("_")) {
    return str;
  }
  let arr = str.split("_");

  return arr
    .map((value, index) => {
      return index === 0
        ? value
        : value.charAt(0).toUpperCase() + value.slice(1);
    })
    ?.join("");
};
export const convertStrToObjColumns = (savedColumn) => {
  let displayColumn =
    typeof savedColumn === "string" ? JSON.parse(savedColumn) : savedColumn;
  displayColumn = {
    ...displayColumn,
    displayDefinition:
      typeof displayColumn?.displayDefinition === "string"
        ? JSON.parse(displayColumn?.displayDefinition)
        : displayColumn?.displayDefinition
  };

  return displayColumn;
};
export const subEquipmentSorting = (value) => {
  return value?.sort((a, b) =>
    a.positionInCluster > b.positionInCluster ? 1 : -1
  );
};

export const subEquipmentsortedList = (sorted, unSorted) => {
  let subEquipmentList = unSorted.sort((a, b) => {
    const indexA = sorted.findIndex((type) => a.inventoryId === type.id);
    const indexB = sorted.findIndex((type) => b.inventoryId === type.id);
    return indexA - indexB;
  });
  return subEquipmentList;
};

export const stringComparator = (
  valueA,
  valueB,
  nodeA,
  nodeB,
  isDescending
) => {
  if (valueA === valueB) return 0;
  return valueA > valueB ? 1 : -1;
};

export const overrideListForAgGrid = (list) => {
  const tempList = list.map((item) => {
    if (item?.mediaType && item?.mediaType !== undefined) {
      const mediaTypeArray = item.mediaType.map((x) => x.value);
      let mediaTypeString = mediaTypeArray.join(", ");
      item.mediaType =
        mediaTypeString === null || !mediaTypeString ? "-" : mediaTypeString;
    }
    if (item?.assay && item?.assay !== undefined) {
      const assayArray = item?.assay;
      let assayString = assayArray.join(", ");
      item.assay = assayString === null || !assayString ? "-" : assayString;
    }
    if (item?.systemStatus && item?.systemStatus !== undefined) {
      item.systemStatus = valueOrEmpty(
        entryType.cluster === item?.equipmentDetails?.entryType
          ? item.systemStatusCluster
          : item.systemStatus,
        false,
        "-"
      );
    }
    if (item?.runStatus && item?.runStatus !== undefined) {
      item.runStatus = RUN_STATUS_FIELD[item.runStatus];
    }

    return item;
  });

  return tempList;
};

export const validURL = (str) => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%\\/_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  if (!pattern.test(str)) {
    return false;
  } else {
    return true;
  }
};

export const validateSelection = (baseObj, findingData) => {
  const valData = find(baseObj, findingData);
  if (valData) return valData?.value || null;
};
