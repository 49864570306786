import * as types from "./actionTypes";
import initialState from "./initialState";

export default function userReducer(
  state = initialState,
  { type, payload } = {}
) {
  switch (type) {
    case types.LOAD_USER_INFO:
      return { ...state, ...payload };
    default:
      return state;
  }
}
