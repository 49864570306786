import React, { useState, useContext } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ItemDialogFormContext } from "./context";
import { CLUSTER_STEPS, entryType } from "../../../../constants";
import { CoversheetMainPageContext } from "../../../cover-sheet/coversheet-main-page-context/context";
const ItemWrapper = ({
  children,
  entryDataType = "STANDALONE-EQUIPMENT",
  setClusterLogStatus = () => {
    return false;
  },
  setOpenDialog = false,
  openDialog
}) => {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  const [subClusterFlag, setSubClusterFlag] = useState(true);
  const [isInventoryId, setIsInventoryId] = useState(false);
  const { inventoryId } = useParams();
  const [subComponentOpen, setSubComponentOpen] = useState(false);
  const [selectedSubEquipment, setSelectedSubEquipment] = useState(null);
  const [activeStep, setActiveStep] = useState(CLUSTER_STEPS[0]);
  const [isStandaloneInvId, setIsStandaloneInvId] = useState("");
  const [rowParam, setRowParam] = useState(null);
  const [equipmentActionObject, setEquipmentActionObject] = useState(null);
  const [subClusterPendingFlag, setSubClusterPendingFlag] = useState(null);

  /*For action log link*/
  const [actionLogLinkName, setActionLogLinkName] = useState(null);
  const [actionLogLinkUrl, setActionLogLinkUrl] = useState(null);
  const [actionLogLinkCurrentIndex, setActionLogLinkCurrentIndex] =
    useState(null);
  const [actionLogLinkCurrentFlag, setActionLogLinkCurrentFlag] =
    useState(null);
  const [addLinkFlag, setAddLinkFlag] = useState(false);
  /*For action log link*/

  const [propagationSync, setPropagationSync] = useState(false);
  const { setRefreshCoversheet, handleOpenChange } = useContext(
    CoversheetMainPageContext
  );
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  useEffect(() => {
    if (typeof inventoryId !== "undefined" && inventoryId !== null) {
      setIsInventoryId(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpen = (theItem) => {
    setClusterLogStatus(false);
    setOpen(true);
    setItem(theItem);
  };

  const handleListOpen = (theItem) => {
    if (entryDataType === entryType.cluster) {
      const equipmentData = { ...theItem };
      equipmentData.entryType = equipmentData.equipmentDetails.entryType;
      setItem(equipmentData);
    } else {
      setItem(theItem);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSubComponentOpen(false);
    setItem(null);
    setActiveStep(CLUSTER_STEPS[0]);
    setClusterLogStatus(false);
    if (openDialog) {
      setOpenDialog(false);
    }
    if (isInventoryId && setRefreshCoversheet !== undefined) {
      setRefreshCoversheet(false);
    }
  };

  useEffect(() => {
    handleOpen(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleOpenChange]);

  const onLoadCurrentRowParam = (item) => {
    setRowParam(() => item);
  };

  return (
    <ItemDialogFormContext.Provider
      value={{
        open,
        handleOpen,
        handleListOpen,
        handleClose,
        item,
        isInventoryId,
        subComponentOpen,
        setSubComponentOpen,
        activeStep,
        setActiveStep,
        setIsInventoryId,
        isStandaloneInvId,
        setIsStandaloneInvId,
        subClusterFlag,
        setSubClusterFlag,
        selectedSubEquipment,
        setSelectedSubEquipment,
        rowParam,
        onLoadCurrentRowParam,
        isSubmitDisabled,
        setIsSubmitDisabled,
        actionLogLinkName,
        setActionLogLinkName,
        actionLogLinkUrl,
        setActionLogLinkUrl,
        actionLogLinkCurrentIndex,
        setActionLogLinkCurrentIndex,
        actionLogLinkCurrentFlag,
        setActionLogLinkCurrentFlag,
        addLinkFlag,
        setAddLinkFlag,
        propagationSync,
        setPropagationSync,
        equipmentActionObject,
        setEquipmentActionObject,
        subClusterPendingFlag,
        setSubClusterPendingFlag
      }}
    >
      {children}
    </ItemDialogFormContext.Provider>
  );
};

export default ItemWrapper;
