import { useFormikContext } from "formik";
import React, { useContext } from "react";
import { connect, useSelector } from "react-redux";
import { loadInstrumentSuggestion } from "../redux/actions";
import ItemFormSuggestionPickerBase from "./ItemFormSuggestionPickerBase";
import { CoversheetMainPageContext } from "../../../cover-sheet/coversheet-main-page-context/context";
import { formStates } from "../../../../constants";

const ItemFormSuggestionPicker = ({
  loadInstrumentSuggestion,
  anchorEl,
  selectSuggestionFn,
  PopoverStyle = {}
}) => {
  const formik = useFormikContext();
  const instrumentSuggestions = useSelector(
    (state) => state.runLogsForm.instrumentSuggestions
  );
  const { formState } = useContext(CoversheetMainPageContext);

  return (
    <ItemFormSuggestionPickerBase
      loadInstrumentSuggestion={loadInstrumentSuggestion}
      anchorEl={anchorEl}
      selectSuggestionFn={selectSuggestionFn}
      instrumentSuggestions={instrumentSuggestions}
      formik={formik}
      shouldShow={formState !== formStates?.EDITABLE_FORM}
      PopoverStyle={PopoverStyle}
    />
  );
};

export default connect(null, { loadInstrumentSuggestion })(
  ItemFormSuggestionPicker
);
