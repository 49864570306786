import React, { useState } from "react";
import { CoversheetMainPageContext } from "./context";
import { formStates, formTypes } from "../../../constants";

const CoversheetMainPageWrapper = ({ children }) => {
  const [refreshCoversheet, setRefreshCoversheet] = useState(false);
  const [handleOpenChange, setHandleOpenChange] = useState(false);
  const [tabValue, setTabValue] = useState("Add Run log");
  const [openWorkFlowModel, setOpenWorkFlowModel] = useState(false);
  const [logBookEquipment, setLogBookEquipment] = useState(null);
  const [isClusterForm, setIsClusterForm] = useState(false);
  const [formState, setFormState] = useState(formStates?.NEW_FORM);
  const [logType, setLogType] = useState(formTypes?.RUN_LOG);
  const [refreshAfterEditOrNew, setRefreshAfterEditOrNew] = useState(false);
  const [allLogType, setAllLogType] = useState(formTypes?.RUN_LOG);
  const [allLogHierarchyData, setAllLogHierarchyData] = useState(null);

  const handleOpenWorkFlowModel = ({
    isCluster = false,
    form = formStates?.NEW_FORM
  }) => {
    setFormState(() => form);
    setIsClusterForm(isCluster);
    setOpenWorkFlowModel(true);
  };

  const handleCloseWorkFlowModel = () => {
    setIsClusterForm(false);
    setOpenWorkFlowModel(false);
  };

  const loadLogBookEquipment = (logBookEquip) => {
    setLogBookEquipment(() => logBookEquip);
  };

  const loadFormType = (type) => {
    setLogType(() => type);
  };

  const loadAllFormType = (type) => {
    setAllLogType(() => type);
  };

  const loadAllLogHierarchyData = (type) => {
    setAllLogHierarchyData(() => type);
  };

  const loadFormState = (formState) => {
    setFormState(() => formState);
  };

  const refreshTable = () => {
    setRefreshAfterEditOrNew((prevState) => !prevState);
  };

  return (
    <CoversheetMainPageContext.Provider
      value={{
        refreshCoversheet,
        setRefreshCoversheet,
        handleOpenChange,
        setHandleOpenChange,
        tabValue,
        setTabValue,
        openWorkFlowModel,
        setOpenWorkFlowModel,
        handleOpenWorkFlowModel,
        handleCloseWorkFlowModel,
        loadLogBookEquipment,
        logBookEquipment,
        isClusterForm,
        formState,
        logType,
        loadFormType,
        loadFormState,
        refreshTable,
        refreshAfterEditOrNew,
        allLogType,
        loadAllFormType,
        allLogHierarchyData,
        loadAllLogHierarchyData
      }}
    >
      {children}
    </CoversheetMainPageContext.Provider>
  );
};

export default CoversheetMainPageWrapper;
