import { Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { withApollo } from "react-apollo";

import LoadItemForm from "./LoadItemForm";
import { ItemDialogFormContext } from "./item-context/context";
import ItemForm from "./ItemForm";
import ItemFormDialogBody from "./ItemFormDialogBody";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";
import styled from "styled-components";
import { OwcWorkflowOverlay } from "@one/react";
import { formStates, formTypes } from "../../../constants";
import { useSelector } from "react-redux";
import { find } from "lodash";

export const AddContainer = styled.div`
  width: 100%;
  min-height: 420px;
  background: var(--one-color-cobas-white);
  border: thin solid thin;
  box-sizing: border-box;
  padding: 16px 28px;
`;
export const AddContainerBody = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 20px 16px;
  background: var(--one-color-cobas-white);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  margin-bottom: 20px;
`;
const ItemFormDialog = ({
  cleanItemFn,
  children,
  createItemChangeFn,
  validationSchemaFactory,
  emptyItem,
  dataTestId,
  selectSuggestionFn,
  clearSuggestionFn,
  type,
  showChipBox,
  addNewDialogButtonLabel,
  equipmentDetail
}) => {
  const { isInventoryId, handleClose } = useContext(ItemDialogFormContext);
  const {
    openWorkFlowModel,
    handleCloseWorkFlowModel,
    loadLogBookEquipment,
    formState,
    logType,
    loadFormState,
    allLogType
  } = useContext(CoversheetMainPageContext);
  const [cleanItem, setCleanItem] = useState({});
  const user = useSelector((state) => state.user);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const systemStatuses = useSelector(
    (store) => store.runLogsForm.systemStatuss
  );
  useEffect(() => {
    let updatedEquipmentDetail = equipmentDetail;
    const defaultObj = {
      key: "-",
      value: "-"
    };
    if (type === formTypes?.RUN_LOG) {
      // TODO: QS and SS
      updatedEquipmentDetail = {
        ...equipmentDetail,
        gxpReady:
          find(gxpReadys, {
            key: equipmentDetail?.qualificationStatus
          }) || defaultObj,
        systemStatus:
          find(systemStatuses, {
            value: equipmentDetail?.equipSystemStatus
          }) || defaultObj
      };
    }

    setCleanItem(() => {
      return cleanItemFn({
        item: updatedEquipmentDetail,
        isNew: formState !== formStates?.EDITABLE_FORM,
        user,
        formState
      });
    });

    // eslint-disable-next-line
  }, [logType, formState]);

  const visibleChangeHandler = (event) => {
    /* Cancel popup implementation */
    if (event?.target?.["data-testid"] !== "confirm-dialog") {
      handleClose();
      if (!isInventoryId) {
        loadLogBookEquipment(null);
      }
      loadFormState(null);
      handleCloseWorkFlowModel();
    }
  };

  return (
    <LoadItemForm>
      <Formik
        validationSchema={validationSchemaFactory}
        initialValues={{ ...emptyItem, ...cleanItem }}
        enableReinitialize={true}
        onSubmit={async () => {}}
      >
        {openWorkFlowModel && (
          <>
            <OwcWorkflowOverlay
              className="owcoverlayZIndex"
              visible={openWorkFlowModel}
              onVisibleChange={visibleChangeHandler}
              disableBackdropClick
            >
              <ItemFormDialogBody
                dataTestId={dataTestId}
                type={logType === formTypes?.ALL_LOG ? allLogType : type}
                addNewDialogButtonLabel={addNewDialogButtonLabel}
                createItemChangeFn={createItemChangeFn}
                equipmentDetail={equipmentDetail}
              >
                <ItemForm
                  selectSuggestionFn={selectSuggestionFn}
                  clearSuggestionFn={clearSuggestionFn}
                  dataTestId={dataTestId}
                  showChipBox={showChipBox}
                  equipmentDetail={equipmentDetail}
                  type={type}
                >
                  {children}
                </ItemForm>
              </ItemFormDialogBody>
            </OwcWorkflowOverlay>
          </>
        )}
      </Formik>
    </LoadItemForm>
  );
};

export default withApollo(ItemFormDialog);
