import React from "react";
import { emptyLogSheet } from "../log-sheet/log-sheet-form/empty-log-sheet";
import { fetchLogSheets } from "../log-sheet/fetch-log-sheets";
import { HistoryLogSheetCellsInfoArray } from "../log-sheet/log-sheet-history/HistoryLogSheetCellsInfoArray";
import { ImportHistoryOfLogSheet } from "../log-sheet/log-sheet-history/ImportHistoryOfLogSheet";
import { CreateLogSheetChange } from "../log-sheet/log-sheet-form/CreateLogSheetChange";
import {
  LogSheetTableMeta,
  LogSheetTableEquipmentCentricMeta
} from "../log-sheet/log-sheet-table/LogSheetTableMeta";
import { logSheetValidationSchemaFactory } from "../log-sheet/log-sheet-form/log-sheet-validation-schema";
import { makeCleanLogSheet } from "../log-sheet/log-sheet-form/make-clean-log-sheet";
import {
  logSheetItemsToSheet,
  runSheetItemsToPDF
} from "../log-sheet/log-sheet-export/log-sheet-items-to-sheet";
import { logSheetSelectSuggestion } from "../log-sheet/log-sheet-form/log-sheet-select-suggestion";
import { logSheetClearSuggestion } from "../log-sheet/log-sheet-form/log-sheet-clear-suggestion";
import ItemPage from "./ItemPage";
import ActionDataInputsBox from "../log-sheet/log-sheet-form/ActionDataInputsBox";
import LOGBOOK_LABEL from "./../../../utils/constants/logbookLabel";
import { formTypes } from "../../../constants";
import { useParams } from "react-router-dom";

const LogSheetPage = ({ equipmentDetail }) => {
  const { inventoryId } = useParams();
  return (
    <ItemPage
      addButtonLabel={LOGBOOK_LABEL.BUTTON.add_new_log}
      queryName="logSheetChangeByLogSheetEntryId"
      importFn={ImportHistoryOfLogSheet}
      CellsInfoArray={HistoryLogSheetCellsInfoArray}
      title={LOGBOOK_LABEL.action_logs_history_heading}
      dataTestId="log-sheet"
      type={formTypes?.ACTION_LOG}
      fetchingFn={fetchLogSheets}
      mapFn={logSheetItemsToSheet}
      mapSecondFn={runSheetItemsToPDF}
      fileNamePrefix="action-logs-report"
      pageTitle={LOGBOOK_LABEL.PAGE_TITLE.log_books}
      orderBy="actionDate"
      meta={inventoryId ? LogSheetTableEquipmentCentricMeta : LogSheetTableMeta}
      emptyItem={emptyLogSheet}
      cleanItemFn={makeCleanLogSheet}
      createItemChangeFn={CreateLogSheetChange}
      validationSchemaFactory={logSheetValidationSchemaFactory}
      selectSuggestionFn={logSheetSelectSuggestion}
      clearSuggestionFn={logSheetClearSuggestion}
      showChipBox={true}
      addNewDialogButtonLabel={"Add Action log"}
      equipmentDetail={equipmentDetail}
    >
      <ActionDataInputsBox />
    </ItemPage>
  );
};

export default LogSheetPage;
