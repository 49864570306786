import { useFormikContext } from "formik";
import CustomAutocompleteWithOwnProp from "../../../../../components/shared/CustomAutocompleteWithOwnProp";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../../constants";
const RunSelectWithOwnProp = ({
  options,
  objectAsValue,
  property,
  Selectable,
  testidPrefix,
  testid,
  propValue,
  propLabel,
  fullWidth,
  error,
  helperText,
  multiple
}) => {
  const formik = useFormikContext();
  return (
    <CustomAutocompleteWithOwnProp
      id={generateID.UUID(Module.POPUP_FIELDS[property], 1, "autocomplete")}
      dataTestid={`${testidPrefix}-inputs-${testid}-input`}
      optionsList={options}
      propValue={propValue}
      propLabel={propLabel}
      objectAsValue={objectAsValue}
      label={Selectable}
      value={formik.values[property]}
      setValue={(newValue) => {
        formik.setFieldValue(property, newValue, true);
      }}
      name={property}
      fullWidth={fullWidth}
      error={formik.touched[property] && Boolean(formik.errors[property])}
      helperText={formik.errors[property]}
      multiple={multiple}
      onBlur={formik.handleBlur}
    />
  );
};

export default RunSelectWithOwnProp;
