import { OwcModalDialog } from "@one/react";
import React from "react";

const CustomSmallDialog = ({
  title,
  actions,
  children,
  open,
  handleClose,
  dataTestId = "small"
}) => {
  return (
    <OwcModalDialog
      data-testid={`${dataTestId}-dialog`}
      size="sm"
      visible={open}
      className="owcmodalZIndex"
      onVisibleChange={(event) => {
        if (!event?.detail) {
          handleClose();
        }
      }}
    >
      <div slot="header" data-testid={`${dataTestId}-dialog-title`}>
        {title}
      </div>
      <div data-testid={`${dataTestId}-dialog-content`}>{children}</div>
      <div slot="actions" data-testid={`${dataTestId}-dialog-actions`}>
        {actions}
      </div>
    </OwcModalDialog>
  );
};

export default CustomSmallDialog;
