import styled from "styled-components";
import LogBookClusterTable from "../log-book-table/LogBookClusterTable";
import ActionDataInputsBoxCluster from "../log-sheet/log-sheet-form/ActionDataInputsBoxCluster";
import ActionDataInputsBoxClusterImpact from "../log-sheet/log-sheet-form/ActionDataInputsBoxClusterImpact";
import RunDataInputsBox from "../run-logs/run-logs-form/RunDataInputsBox";
import { formTypes } from "../../../constants";

const FormStyles = styled.div`
  display: flex;
  flex-direction: column;
`;

const ClusterItemForm = ({ dataTestId, type, activeStep, equipmentDetail }) => {
  const getFormContentByStepActionLog = () => {
    switch (activeStep.id) {
      case 0:
        return (
          <LogBookClusterTable
            dataTestId="cluster-log-sheet"
            equipmentDetail={equipmentDetail}
          />
        );
      case 1:
        return <ActionDataInputsBoxCluster equipmentDetail={equipmentDetail} />;
      case 2:
        return (
          <ActionDataInputsBoxClusterImpact equipmentDetail={equipmentDetail} />
        );
      default:
        return <ActionDataInputsBoxCluster equipmentDetail={equipmentDetail} />;
    }
  };

  const getFormContentByStepRunLog = () => {
    switch (activeStep.id) {
      case 0:
        return (
          <LogBookClusterTable
            dataTestId="log-sheet"
            equipmentDetail={equipmentDetail}
          />
        );
      case 1:
        return <RunDataInputsBox equipmentDetail={equipmentDetail} />;
      default:
        return <RunDataInputsBox equipmentDetail={equipmentDetail} />;
    }
  };

  return (
    <FormStyles data-testid={`${dataTestId}-form`}>
      {type === formTypes?.RUN_LOG
        ? getFormContentByStepRunLog()
        : getFormContentByStepActionLog()}
    </FormStyles>
  );
};

export default ClusterItemForm;
