import React, { useContext, useRef } from "react";
import { OwcMenu, OwcListItem } from "@one/react";
import {
  MyLabMenuArray,
  entryType,
  equipmentStatus,
  formStates,
  formTypes
} from "../../constants";
import { useSelector } from "react-redux";
import { CoversheetMainPageContext } from "../cover-sheet/coversheet-main-page-context/context";

const MyEquipmentMenu = ({ anchorId, setShowMenu, equipmentDetail }) => {
  const menuElement = useRef(null);
  const canEdit = useSelector((store) => store.user.canEdit);
  const { handleOpenWorkFlowModel, loadFormType } = useContext(
    CoversheetMainPageContext
  );
  const redirectWithoutCookie = (inventoryId, option) => {
    let launchURL = "";
    if (option === "viewLogbook") {
      launchURL = `/cover-sheet/${inventoryId}`;
    }
    window.open(launchURL);
  };
  const handleClick = (option) => {
    setShowMenu(false);
    switch (option) {
      case "viewLogbook":
        redirectWithoutCookie(anchorId, option);
        break;
      case "addRunLogs":
        handleOpenWorkFlowModel({
          isCluster: equipmentDetail?.entryType === entryType?.cluster,
          form: formStates?.PARTIAL_NEW_FORM
        });
        loadFormType(formTypes?.RUN_LOG);
        break;
      case "addActionlogs":
        handleOpenWorkFlowModel({
          isCluster: equipmentDetail?.entryType === entryType?.cluster,
          form: formStates?.PARTIAL_NEW_FORM
        });
        loadFormType(formTypes?.ACTION_LOG);
        break;
      default:
    }
  };

  return (
    <div>
      <OwcMenu
        ref={menuElement}
        visible={true}
        onClickOutside={() => setShowMenu(false)}
        anchor={anchorId}
        disable-portal={true}
        strategy="fixed"
      >
        {MyLabMenuArray.map((item) => {
          return (
            <OwcListItem
              onClick={() => {
                handleClick(item.key);
              }}
              id={item.key}
              disabled={
                (item.key === "viewLogbook"
                  ? false
                  : !canEdit || equipmentDetail?.clusterId) ||
                (equipmentDetail?.status === equipmentStatus?.deleted?.key &&
                  item.key !== "viewLogbook")
              }
            >
              {item.value}
            </OwcListItem>
          );
        })}
      </OwcMenu>
    </div>
  );
};

export default MyEquipmentMenu;
