import { DEFAULT_SITE } from "../../../../constants";

export const runLogsSelectSuggestion = (
  item,
  { loadInstrumentSuggestion = null, formik, user }
) => {
  if (loadInstrumentSuggestion) {
    loadInstrumentSuggestion(item);
  }
  formik.setValues({
    ...formik.values,
    site: formik.values?.site ?? user.site ?? DEFAULT_SITE,
    approverId: user?.user ?? "",
    approverUserName: user.name ?? "",
    systemStatus: item.currentSystemStatusFromLogs,
    equipmentId: item.equipmentId,
    serialNumber: item.serialNumber,
    materialNumber: item.materialNumber ?? "",
    equipmentModel: item?.equipmentModel,
    equipmentNickName:
      item?.equipmentNickName ??
      item?.equipmentDetails?.equipmentNickName ??
      "",
    inventoryId: item?.inventoryId ?? item?.id ?? "",
    mediaType: item?.mediaType || [],
    instrumentDetails: {
      ...formik?.values?.instrumentDetails,
      configurationBaseline: item.configurationBaseline,
      instrumentGxPStatus: item?.qualificationStatus || "",
      equipmentNickName: item?.equipmentNickName,
      responsiblePerson: item.responsiblePerson,
      manufacturer: item.manufacturer,
      softwareVersion: item.softwareVersion,
      location: item.location,
      responsibleProxy: item.responsibleProxy
    }
  });
};
