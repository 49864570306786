import gql from "graphql-tag";

export const CREATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE = gql`
  mutation createDigitalLabLogbookUserProfile(
    $email: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
  ) {
    createDigitalLabLogbookUserProfile(
      input: {
        email: $email
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
      }
    ) {
      id
      email
      lastFilter
      status
      site
      familyName
      givenName
      name
      user
      phone
    }
  }
`;

export const UPDATE_DIGITAL_LAB_LOGBOOK_USER_PROFILE_PERSONAL_FIELDS = gql`
  mutation updateDigitalLabLogbookUserProfile(
    $id: ID!
    $email: String!
    $site: String
    $familyName: String
    $givenName: String
    $name: String
    $phone: String
    $lastFilter: String
    $lastDisplayColumns: String
    $lastPageSize: String
  ) {
    updateDigitalLabLogbookUserProfile(
      input: {
        id: $id
        email: $email
        site: $site
        familyName: $familyName
        givenName: $givenName
        name: $name
        phone: $phone
        lastFilter: $lastFilter
        lastDisplayColumns: $lastDisplayColumns
        lastPageSize: $lastPageSize
      }
    ) {
      id
      email
      lastFilter
      status
      site
      familyName
      givenName
      name
      user
      phone
    }
  }
`;

export const CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG_CHANGE = gql`
  mutation createDigitalLabLogbookRunLogChange(
    $approverId: String!
    $approverUserName: String!
    $equipmentId: String
    $id: ID
    $materialNumber: String
    $equipmentModel: String!
    $inventoryId: String!
    $operatorId: String
    $operatorUserName: String
    $runLogEntryId: ID!
    $runStartDate: AWSDate!
    $runEndDate: AWSDate!
    $serialNumber: String
    $site: String!
    $instrumentDetails: InstrumentDetailsInput
    $assay: [String]
    $defectId: String
    $description: String!
    $mediaType: [ValueAttributeInput]
    $numberOfRuns: Int
    $runEndTime: AWSTime
    $runStartTime: AWSTime
    $runStatus: RunLogRunStatus!
    $samplesProcessed: Int
    $systemStatus: ValueAttributeInput!
    $tipsUsed: ValueAttributeInput
    $runIdentification: String
    $eLNid: String
    $eLNGUID: String
    $modelVersion: String
    $logStatus: LogStatus
    $editReason: String
    $editComment: String
    $gxpReady: ValueAttributeInput!
  ) {
    createDigitalLabLogbookRunLogChange(
      input: {
        approverId: $approverId
        approverUserName: $approverUserName
        equipmentId: $equipmentId
        id: $id
        materialNumber: $materialNumber
        equipmentModel: $equipmentModel
        inventoryId: $inventoryId
        operatorId: $operatorId
        operatorUserName: $operatorUserName
        runLogEntryId: $runLogEntryId
        runStartDate: $runStartDate
        runEndDate: $runEndDate
        serialNumber: $serialNumber
        site: $site
        instrumentDetails: $instrumentDetails
        assay: $assay
        defectId: $defectId
        description: $description
        mediaType: $mediaType
        numberOfRuns: $numberOfRuns
        runEndTime: $runEndTime
        runStartTime: $runStartTime
        runStatus: $runStatus
        samplesProcessed: $samplesProcessed
        systemStatus: $systemStatus
        tipsUsed: $tipsUsed
        runIdentification: $runIdentification
        eLNid: $eLNid
        eLNGUID: $eLNGUID
        modelVersion: $modelVersion
        logStatus: $logStatus
        editReason: $editReason
        editComment: $editComment
        gxpReady: $gxpReady
      }
    ) {
      approverId
      approverUserName
      assay
      defectId
      description
      editReason
      editComment
      equipmentId
      id
      materialNumber
      mediaType {
        key
        value
      }
      modelVersion
      numberOfRuns
      eLNid
      eLNGUID
      gxpReady {
        key
        value
      }
      operatorId
      operatorUserName
      runEndDate
      runEndTime
      runIdentification
      runLogEntryId
      runStartDate
      runStartTime
      runStatus
      samplesProcessed
      serialNumber
      site
      systemStatus {
        key
        value
      }
      tipsUsed {
        key
        value
      }
      instrumentDetails {
        configurationBaseline
        equipmentNickName
        location
        instrumentGxPStatus
        responsiblePerson
        responsibleProxy
        softwareVersion
        manufacturer
      }
    }
  }
`;

export const CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET_CHANGE = gql`
  mutation createDigitalLabLogbookLogSheetChange(
    $action: ValueAttributeInput!
    $gxpReady: ValueAttributeInput!
    $approverId: String!
    $approverUserName: String!
    $equipmentId: String
    $inventoryId: String!
    $id: ID
    $equipmentModel: String!
    $materialNumber: String
    $ecrNumber: String
    $operatorId: String
    $operatorUserName: String
    $logSheetEntryId: ID!
    $actionDate: AWSDate!
    $actionTime: AWSTime!
    $serialNumber: String
    $site: String!
    $instrumentDetails: InstrumentDetailsInput
    $description: String!
    $logStatus: LogStatus
    $systemStatus: ValueAttributeInput!
    $modelVersion: String!
    $updatedSoftwareVersion: UpdatedSoftwareVersionInput
    $editReason: String
    $editComment: String
    $externalDocument: [ExternalDocumentInput]
  ) {
    createDigitalLabLogbookLogSheetChange(
      input: {
        action: $action
        gxpReady: $gxpReady
        approverId: $approverId
        approverUserName: $approverUserName
        equipmentId: $equipmentId
        inventoryId: $inventoryId
        id: $id
        equipmentModel: $equipmentModel
        materialNumber: $materialNumber
        ecrNumber: $ecrNumber
        operatorId: $operatorId
        operatorUserName: $operatorUserName
        logSheetEntryId: $logSheetEntryId
        actionDate: $actionDate
        actionTime: $actionTime
        serialNumber: $serialNumber
        site: $site
        instrumentDetails: $instrumentDetails
        description: $description
        logStatus: $logStatus
        systemStatus: $systemStatus
        modelVersion: $modelVersion
        updatedSoftwareVersion: $updatedSoftwareVersion
        editReason: $editReason
        editComment: $editComment
        externalDocument: $externalDocument
      }
    ) {
      action {
        key
        value
      }
      actionDate
      actionTime
      approverId
      approverUserName
      description
      editReason
      editComment
      ecrNumber
      equipmentId
      externalDocument {
        link
        name
      }
      gxpReady {
        key
        value
      }
      id
      instrumentDetails {
        configurationBaseline
        equipmentNickName
        location
        instrumentGxPStatus
        responsiblePerson
        responsibleProxy
        softwareVersion
        manufacturer
      }
      equipmentModel
      logSheetEntryId
      logStatus
      materialNumber
      modelVersion
      operatorId
      operatorUserName
      serialNumber
      site
      systemStatus {
        key
        value
      }
      updatedSoftwareVersion {
        newValue
        shouldBePublished
      }
    }
  }
`;

export const CREATE_DIGITAL_LAB_LOGBOOK_LOG_SHEET_CLUSTER_CHANGE = gql`
  mutation createDigitalLabLogbookClusterLogSheetChange(
    $input: [DigitalLabLogbookClusterLogSheetChangeInput!]
  ) {
    createDigitalLabLogbookClusterLogSheetChange(input: $input) {
      action {
        key
        value
      }
    }
  }
`;

export const CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG_CLUSTER_CHANGE = gql`
  mutation createDigitalLabLogbookClusterRunLogChange(
    $input: [DigitalLabLogbookClusterRunLogChangeInput!]
  ) {
    createDigitalLabLogbookClusterRunLogChange(input: $input) {
      runLogEntryId
    }
  }
`;
export const CREATE_USER_FILTER = gql`
  mutation createUserFilter(
    $userID: ID!
    $filterName: String!
    $filterDefinition: String
  ) {
    createUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterDefinition: $filterDefinition
      }
    ) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;

export const UPDATE_USER_FILTER = gql`
  mutation updateUserFilter(
    $userID: ID!
    $filterName: String!
    $filterDefinition: String
  ) {
    updateUserFilter(
      input: {
        userID: $userID
        filterName: $filterName
        filterDefinition: $filterDefinition
      }
    ) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;
export const CREATE_USER_DISPLAY_COLUMN = gql`
  mutation createUserDisplay(
    $userID: ID!
    $displayName: String!
    $displayDefinition: String
  ) {
    createUserDisplay(
      input: {
        userID: $userID
        displayName: $displayName
        displayDefinition: $displayDefinition
      }
    ) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;

export const UPDATE_USER_DISPLAY_COLUMN = gql`
  mutation updateUserDisplay(
    $userID: ID!
    $displayName: String!
    $displayDefinition: String
  ) {
    updateUserDisplay(
      input: {
        userID: $userID
        displayName: $displayName
        displayDefinition: $displayDefinition
      }
    ) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;
export const DELETE_USER_DISPLAY_COLUMN = gql`
  mutation deleteUserDisplay($userID: ID!, $displayName: String!) {
    deleteUserDisplay(input: { userID: $userID, displayName: $displayName }) {
      displayName
      id
      displayDefinition
      userID
    }
  }
`;
export const DELETE_USER_FILTER = gql`
  mutation deleteUserFilter($userID: ID!, $filterName: String!) {
    deleteUserFilter(input: { userID: $userID, filterName: $filterName }) {
      filterName
      id
      filterDefinition
      userID
    }
  }
`;
