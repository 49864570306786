import React, { useContext } from "react";
import { Header } from "@digitallab/grid-common-components";
import { getEnv, getEnvLebel } from "../../utils/helpers/text";
import { BackendInfoContext } from "../backend-info-context/context";

const currentEnv = getEnv();
const leftLable = getEnvLebel(currentEnv);

const HeaderSection = () => {
  const { rightLabel } = useContext(BackendInfoContext);
  return (
    <Header
      environment={currentEnv}
      leftLabel={leftLable}
      rightLabel={rightLabel}
    />
  );
};
export default HeaderSection;
