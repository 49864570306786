import moment from "moment";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import {
  parseTime,
  parseTimeLocal,
  replaceEmptyWithHyphen,
  validateSelection,
  valueOrEmpty
} from "../../../../utils/helpers/text";
import { entryType } from "../../../../constants";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import {
  allLogAttributesTable,
  allLogHierarchyTable
} from "../../../../utils/helpers/changeIndex";
import ReactDOMServer from "react-dom/server";

export const logSheetItemsToSheet = (items) =>
  items.map((item) => ({
    "Equipment ID": valueOrEmpty(item?.equipmentId),
    "Action date and time": `${valueOrEmpty(
      item.actionDate,
      true
    )} (${valueOrEmpty(parseTime(item.actionTime))})`,
    "Action type": item.action?.value,
    "ECR number": item.ecrNumber,
    "Serial number": valueOrEmpty(item?.serialNumber),
    "Material number": valueOrEmpty(item?.materialNumber),
    Model: valueOrEmpty(item?.equipmentModel),
    Description: valueOrEmpty(item.description),
    Signee: valueOrEmpty(item.approverUserName),
    Nickname: valueOrEmpty(item?.instrumentDetails?.equipmentNickName),
    Operator: valueOrEmpty(item.operatorUserName),
    "Qualification status":
      item?.equipmentDetails?.entryType === entryType.cluster
        ? valueOrEmpty(item.gxpReadyCluster?.value)
        : valueOrEmpty(item.gxpReady?.value),
    "System status":
      item?.equipmentDetails?.entryType === entryType.cluster
        ? valueOrEmpty(item.systemStatusCluster?.value)
        : valueOrEmpty(item.systemStatus?.value),
    Manufacturer: valueOrEmpty(item?.instrumentDetails?.manufacturer),
    Site: valueOrEmpty(item.site),
    Location: valueOrEmpty(item?.instrumentDetails?.location),
    "Software version": valueOrEmpty(item?.instrumentDetails?.softwareVersion),
    Configuration: valueOrEmpty(item?.instrumentDetails?.configurationBaseline),
    "Responsible proxy": valueOrEmpty(
      item?.instrumentDetails?.responsibleProxy
    ),
    "Responsible person": valueOrEmpty(
      item?.instrumentDetails?.responsiblePerson
    )
  }));

export const ACTION_SHEET_TABLE_HEADERS = [
  { header: ACTION_LOGS_DATA_MODEL.actionDate.value, value: 22 },
  { header: ACTION_LOGS_DATA_MODEL.actionType.value, value: 25 },
  { header: COMMON_LOGS_DATA_MODEL.description.value, value: 35 },
  { header: "Model", value: 25 },
  { header: "Variant", value: 20 },
  { header: "Equipment ID", value: 24 },
  { header: COMMON_LOGS_DATA_MODEL.serialNumber.value, value: 23 },
  { header: ACTION_LOGS_DATA_MODEL.gxpReady.value, value: 25 },
  { header: ACTION_LOGS_DATA_MODEL.systemStatus.value, value: 24 },
  { header: ACTION_LOGS_DATA_MODEL.ecrNumber.value, value: 23 },
  { header: COMMON_LOGS_DATA_MODEL.approverUserName.value, value: 30 }
];

export const ACTION_LOGS_CENTRIC_TABLE_HEADERS = [
  { header: ACTION_LOGS_DATA_MODEL.actionDate.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.actionType.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.description.value, value: 40 },
  { header: ACTION_LOGS_DATA_MODEL.gxpReady.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.systemStatus.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.ecrNumber.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.approverUserName.value, value: "auto" }
];

export const ACTION_HIERARCHY_TABLE_HEADERS = [
  { header: "", value: 1 },
  { header: COMMON_LOGS_DATA_MODEL.equipmentModel.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.location.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.manufacturer.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.equipmentId.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.serialNumber.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.gxpReady.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.systemStatus.value, value: "auto" },
  { header: DATA_MODEL_TABLE.gxpRelevant.value + "?", value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.softwareVersion.value, value: "auto" }
];

const parseTimeVariousInputFormats = (time) => {
  const theTime = parseTimeLocal(time);
  if (theTime !== time) {
    return theTime;
  }
};

export const runSheetItemsToPDF = (
  items,
  allLogHierarchyData,
  logBookEquipment,
  inventoryId,
  storeGxpReadys
) => {
  let records = null;
  records = items.map((item) => {
    const activityDate = `${moment(item?.actionDate).format("DD-MMM-yyyy")}`;
    const activityTime = parseTimeVariousInputFormats(item?.actionTime);
    if (inventoryId) {
      return [
        activityDate + " " + activityTime,
        valueOrEmpty(item?.action?.value, false, "-"),
        valueOrEmpty(item?.description, false, "-"),
        valueOrEmpty(
          item?.equipmentDetails?.entryType === entryType.cluster
            ? item.gxpReadyCluster?.value
            : item.gxpReady?.value,
          false,
          "-"
        ),
        valueOrEmpty(
          item?.equipmentDetails?.entryType === entryType.cluster
            ? item.systemStatusCluster?.value
            : item.systemStatus?.value,
          false,
          "-"
        ),
        valueOrEmpty(item?.ecrNumber, false, "-"),
        valueOrEmpty(item?.approverUserName, false, "-")
      ];
    } else {
      return [
        activityDate + activityTime,
        valueOrEmpty(item?.action?.value, false, "-"),
        valueOrEmpty(item?.description, false, "-"),
        valueOrEmpty(item?.equipmentModel, false, "-"),
        valueOrEmpty(item?.equipmentDetails?.variant, false, "-"),
        valueOrEmpty(item?.equipmentId, false, "-"),
        valueOrEmpty(item?.serialNumber, false, "-"),
        valueOrEmpty(
          item?.equipmentDetails?.entryType === entryType.cluster
            ? item.gxpReadyCluster?.value
            : item.gxpReady?.value,
          false,
          "-"
        ),
        valueOrEmpty(
          item?.equipmentDetails?.entryType === entryType.cluster
            ? item.systemStatusCluster?.value
            : item.systemStatus?.value,
          false,
          "-"
        ),
        valueOrEmpty(item?.ecrNumber, false, "-"),
        valueOrEmpty(item?.approverUserName, false, "-")
      ];
    }
  });
  let hierarchyRecords = null;
  if (
    logBookEquipment?.entryType === entryType.cluster ||
    logBookEquipment?.entryType === entryType?.clusterSubequipment
  ) {
    const allLogItem = allLogHierarchyTable(allLogHierarchyData);
    hierarchyRecords = allLogItem.map((item) => {
      return [
        replaceEmptyWithHyphen(item?.clusterParent?.length),
        replaceEmptyWithHyphen(item?.equipmentModel),
        replaceEmptyWithHyphen(item?.location),
        replaceEmptyWithHyphen(item?.manufacturer),
        replaceEmptyWithHyphen(item?.equipmentId),
        replaceEmptyWithHyphen(item?.serialNumber),
        replaceEmptyWithHyphen(
          validateSelection(storeGxpReadys, {
            key: item?.qualificationStatus
          })
        ),
        replaceEmptyWithHyphen(item?.systemStatus),
        item?.gxpRelevant === "NOTAVAILABLE"
          ? "-"
          : replaceEmptyWithHyphen(item?.gxpRelevant),
        replaceEmptyWithHyphen(item?.softwareVersion)
      ];
    });
  }

  const allLogAttributesItem = allLogAttributesTable(logBookEquipment);
  const attributes = ReactDOMServer.renderToStaticMarkup(allLogAttributesItem);
  const equipmentEntryType =
    logBookEquipment?.entryType === entryType.cluster ||
    logBookEquipment?.entryType === entryType?.clusterSubequipment;

  return {
    headers: inventoryId
      ? ACTION_LOGS_CENTRIC_TABLE_HEADERS
      : ACTION_SHEET_TABLE_HEADERS,
    // hierarchy: hierarchy,
    entryType: equipmentEntryType,
    hierarchyHeaders: ACTION_HIERARCHY_TABLE_HEADERS,
    hierarchyRecords: hierarchyRecords,
    attributes: attributes,
    records: records
  };
};
