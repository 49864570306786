import { entryType } from "../../../../constants";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import {
  allLogAttributesTable,
  allLogHierarchyTable
} from "../../../../utils/helpers/changeIndex";
import {
  replaceEmptyWithHyphen,
  validateSelection,
  valueOrEmpty
} from "../../../../utils/helpers/text";
import ReactDOMServer from "react-dom/server";

export const allLogsItemsToSheet = (items) =>
  items.map((item) => ({
    Date: valueOrEmpty(item?.allLogDate, true),
    Type: valueOrEmpty(item?.logType),
    "Type specific details (Action type/Run status)": valueOrEmpty(
      item?.typeSpecificDetails
    ),
    "Qualification status":
      item?.equipmentDetails?.entryType === entryType.cluster
        ? valueOrEmpty(item.gxpReadyCluster?.value)
        : valueOrEmpty(item.gxpReady?.value),
    "System status":
      item?.equipmentDetails?.entryType === entryType.cluster
        ? valueOrEmpty(item.systemStatusCluster?.value)
        : valueOrEmpty(item.systemStatus?.value),
    "Intended use GxP relevant?":
      item?.gxpRelevant === "NOTAVAILABLE"
        ? ""
        : valueOrEmpty(item?.gxpRelevant),
    Description: valueOrEmpty(item.description),
    Signee: valueOrEmpty(item.approverUserName),
    "ECR number": item.ecrNumber,
    "eLN ID": valueOrEmpty(item.eLNid),
    "Run ID": valueOrEmpty(item.runIdentification)
  }));

export const ALL_LOGS_TABLE_HEADERS = [
  { header: "Date", value: "auto" },
  { header: "Type", value: "auto" },
  { header: "Type specific details (Action type/Run status)", value: 45 },
  { header: ACTION_LOGS_DATA_MODEL.gxpReady.value, value: 47 },
  { header: ACTION_LOGS_DATA_MODEL.systemStatus.value, value: 45 },
  { header: COMMON_LOGS_DATA_MODEL.description.value, value: 40 },
  { header: COMMON_LOGS_DATA_MODEL.approverUserName.value, value: 30 }
];

export const HIERARCHY_TABLE_HEADERS = [
  { header: "", value: 1 },
  { header: COMMON_LOGS_DATA_MODEL.equipmentModel.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.location.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.manufacturer.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.equipmentId.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.serialNumber.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.gxpReady.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.systemStatus.value, value: "auto" },
  { header: DATA_MODEL_TABLE.gxpRelevant.value + "?", value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.softwareVersion.value, value: "auto" }
];

export const allLogsItemsToPDF = (
  items,
  allLogHierarchyData,
  logBookEquipment,
  inventoryId = null,
  storeGxpReadys
) => {
  const records = items.map((item) => {
    return [
      valueOrEmpty(item?.allLogDate, true, "-"),
      valueOrEmpty(item?.logType, false, "-"),
      valueOrEmpty(item?.typeSpecificDetails, false, "-"),
      item?.equipmentDetails?.entryType === entryType.cluster
        ? valueOrEmpty(item.gxpReadyCluster?.value)
        : valueOrEmpty(item.gxpReady?.value),
      item?.equipmentDetails?.entryType === entryType.cluster
        ? valueOrEmpty(item.systemStatusCluster?.value)
        : valueOrEmpty(item.systemStatus?.value),
      valueOrEmpty(item?.description, false, "-"),
      valueOrEmpty(item?.approverUserName, false, "-")
    ];
  });

  let hierarchyRecords = null;
  if (
    logBookEquipment?.entryType === entryType.cluster ||
    logBookEquipment?.entryType === entryType.clusterSubequipment
  ) {
    const allLogItem = allLogHierarchyTable(allLogHierarchyData);
    hierarchyRecords = allLogItem.map((item) => {
      return [
        replaceEmptyWithHyphen(item?.clusterParent?.length),
        replaceEmptyWithHyphen(item?.equipmentModel),
        replaceEmptyWithHyphen(item?.location),
        replaceEmptyWithHyphen(item?.manufacturer),
        replaceEmptyWithHyphen(item?.equipmentId),
        replaceEmptyWithHyphen(item?.serialNumber),
        replaceEmptyWithHyphen(
          validateSelection(storeGxpReadys, {
            key: item?.qualificationStatus
          })
        ),
        replaceEmptyWithHyphen(item?.systemStatus),
        item?.gxpRelevant === "NOTAVAILABLE"
          ? "-"
          : replaceEmptyWithHyphen(item?.gxpRelevant),
        replaceEmptyWithHyphen(item?.softwareVersion)
      ];
    });
  }

  const allLogAttributesItem = allLogAttributesTable(logBookEquipment);
  const attributes = ReactDOMServer.renderToStaticMarkup(allLogAttributesItem);
  const equipmentEntryType =
    logBookEquipment?.entryType === entryType.cluster ||
    logBookEquipment?.entryType === entryType?.clusterSubequipment;

  return {
    headers: ALL_LOGS_TABLE_HEADERS,
    // hierarchy: hierarchy,
    entryType: equipmentEntryType,
    hierarchyHeaders: HIERARCHY_TABLE_HEADERS,
    hierarchyRecords: hierarchyRecords,
    attributes: attributes,
    records
  };
};
