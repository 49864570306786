import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import {
  CustomDetailsCellStyled,
  Flex,
  RunLogWrapper
} from "../../features/log-book/common-for-log-sheet-and-run-logs/table-common-meta-info";
import DetailsCells from "./details-cells/DetailsCells";
import RunLogTableMeta, {
  RunLogTableEquipmentCentricMeta
} from "../../features/log-book/run-logs/runLogs-table/RunLogTableMeta";
import { CoversheetMainPageContext } from "../../features/cover-sheet/coversheet-main-page-context/context";
import LogSheetTableMeta, {
  LogSheetTableEquipmentCentricMeta
} from "../../features/log-book/log-sheet/log-sheet-table/LogSheetTableMeta";
import AllLogClusterTableMeta from "../../features/log-book/run-logs/runLogs-table/AllLogClusterTableMeta";

const DetailCellRenderer = (params) => {
  const { tabValue } = useContext(CoversheetMainPageContext);
  const { inventoryId } = useParams();

  let metaField =
    tabValue === "Add Run log"
      ? RunLogTableMeta?.expandedComponentProps?.infoMeta
      : tabValue === "Add Action log"
      ? LogSheetTableMeta?.expandedComponentProps?.infoMeta
      : [];

  let centricMetaField =
    tabValue === "Add Run log"
      ? RunLogTableEquipmentCentricMeta?.expandedComponentProps?.infoMeta
      : tabValue === "Add Action log"
      ? LogSheetTableEquipmentCentricMeta?.expandedComponentProps?.infoMeta
      : tabValue === "All logs"
      ? params?.data?.logType === "Run"
        ? AllLogClusterTableMeta?.expandedComponentProps?.infoMeta
        : AllLogClusterTableMeta?.expandedComponentProps?.actionInfoMeta
      : [];

  return (
    <Flex>
      <DetailsCells
        notHistory={true}
        Wrapper={RunLogWrapper}
        item={params.data}
        infoMeta={inventoryId ? centricMetaField : metaField}
        CellWrapper={CustomDetailsCellStyled}
      />
    </Flex>
  );
};

export default DetailCellRenderer;
