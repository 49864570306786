import { useFormikContext } from "formik";
import { OwcDatepicker } from "@one/react";
const RunDateInput = ({
  property,
  otherDateProperty,
  label,
  testid,
  required = false
}) => {
  const formik = useFormikContext();
  const dateValue = (value) => {
    formik.setFieldTouched(otherDateProperty, true);
    if (
      (value.detail instanceof Date && !isNaN(value.detail)) ||
      value.detail === null
    ) {
      formik.setFieldValue(property, value.detail, true);
    }
  };
  return (
    <>
      <OwcDatepicker
        autoClose
        hasClearIcon={true}
        format="dd-MMM-yyyy"
        label={label}
        placeholder={"DD-MM-YYYY"}
        value={
          typeof formik.values[property] === "string"
            ? new Date(formik.values[property])
            : formik.values[property]
        }
        variant="filled"
        onValueChange={(e) => dateValue(e)}
        style={{ width: "100%" }}
        invalidDateMessage={"Invalid date format"}
        required={required}
        requiredDateMessage={formik.errors[property]}
      >
        {formik.errors[property] && (
          <span slot="error-text">{formik.errors[property]}</span>
        )}
      </OwcDatepicker>
    </>
  );
};

export default RunDateInput;
