import { changeDateFormat } from "../../../utils/helpers/text";
import { getAllData } from "../../../utils/helpers/fetching";
import { REPO_CONFIG } from "../../../constants";

const getQueryAndFilter = ({
  signee,
  signeeChangeLog,
  sortDirection,
  queryDesc,
  queryAsc,
  reason,
  signeeList
}) => {
  let filter;

  //new query ----
  const tempSignefilter = { or: [] };
  if (
    signeeChangeLog &&
    signeeChangeLog?.length !== 0 &&
    signeeChangeLog?.length !== signeeList?.length
  ) {
    signeeChangeLog = signeeChangeLog.toString();
    signeeChangeLog.split(",").forEach((element) => {
      tempSignefilter.or.push({
        modifiedByUserId: {
          eq: element
        }
      });
    });
  }

  const tempReasonfilter = { or: [] };
  if (reason && reason?.length !== 0) {
    reason.split(",").forEach((element) => {
      tempReasonfilter.or.push({
        editReason: {
          eq: element
        }
      });
    });
  }

  const finalTeamQuery = { and: [] };
  if (tempSignefilter.or.length > 0) finalTeamQuery.and.push(tempSignefilter);
  if (tempReasonfilter.or.length > 0) finalTeamQuery.and.push(tempReasonfilter);
  if (finalTeamQuery.and.length > 0) {
    filter = finalTeamQuery;
  }

  if (!filter) {
    filter = undefined;
  }
  let query = sortDirection === "DESC" ? queryDesc : queryAsc;
  return { query, filter };
};

const extractVariables = ({
  instrument,
  dateFrom,
  dateTo,
  storeUser,
  limit,
  nextToken,
  filter,
  inventoryId
}) => {
  if (dateFrom !== null) {
    dateFrom = new Date(dateFrom);
    dateFrom.setHours(0, 0, 0, 0);
  }
  if (dateTo !== null) {
    dateTo = new Date(dateTo);
    dateTo.setHours(23, 59, 59, 0);
  }
  return {
    dateRange:
      dateFrom === null && dateTo === null
        ? undefined
        : [changeDateFormat(dateFrom, "ISO"), changeDateFormat(dateTo, "ISO")],
    filter,
    inventoryId: inventoryId ?? undefined
  };
};

export const getUpdatedItems = (items) => {
  const updatedWithEquipIds = items.map((item) => {
    if (item.equipmentId === "null") {
      item.equipmentId = "";
    }

    return item;
  });
  return [...updatedWithEquipIds];
};

export const fetchCommon =
  ({
    queryAsc,
    queryDesc,
    queryWithInstrumentAsc,
    queryWithInstrumentDesc,
    queryName
  }) =>
  async ({
    client,
    sortDirection,
    equipmentModel,
    signee,
    signeeChangeLog,
    reason,
    instrument,
    dateFrom,
    dateTo,
    storeUser,
    limit,
    nextToken,
    drillData = false,
    inventoryId,
    signeeList
  }) => {
    const { query, filter } = getQueryAndFilter({
      equipmentModel,
      signee,
      signeeChangeLog,
      reason,
      sortDirection,
      queryDesc,
      queryAsc,
      instrument,
      dateFrom,
      dateTo,
      queryWithInstrumentDesc,
      queryWithInstrumentAsc,
      inventoryId,
      signeeList
    });

    const auditTrialInfo = await getAllData({
      client,
      query,
      dataPath: ["data", queryName],
      variables: extractVariables({
        instrument,
        dateFrom,
        dateTo,
        storeUser,
        limit,
        nextToken,
        filter,
        inventoryId
      }),
      drillData: true,
      awsAppSyncConfig: REPO_CONFIG
    });

    const items = auditTrialInfo?.items || [];
    const updatedItems = getUpdatedItems(items);

    return {
      items: updatedItems
    };
  };
