import { v4 } from "uuid";
import {
  CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG_CHANGE,
  CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG_CLUSTER_CHANGE
} from "../../../../gql/logBooksapi/mutations";
import { LogStatus } from "../../enums";
import { Notify } from "../../../notifications/Notify";
import {
  omitOrReturn,
  omitOrReturnArray,
  parseTime
} from "../../../../utils/helpers/text";
import {
  RUN_LOG_BY_CLUSTER_ENTRY_REF_ID,
  ON_CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG,
  ON_UPDATE_DIGITAL_LAB_LOGBOOK_RUN_LOG,
  GET_LOGBOOK_RUNLOG,
  GET_LOGBOOK_ACTIONLOG
} from "../../../../gql/logBooksapi";
import {
  getAllData,
  waitUntilSubscribeConditionTimes
} from "../../../../utils/helpers/fetching";
import { changeDateFormat } from "../../helpers";
import omitDeep from "omit-deep-lodash";
import { entryType, formTypes } from "../../../../constants";
import { subClusterMapping } from "../../log-book-table/LogBookClusterTable";
import { find } from "lodash";

export const checkSubscribeCondition = (variables, dataProperty) => {
  return (data) => data[dataProperty].id === variables.runLogEntryId;
};

export const CreateRunLogChange = async (
  runLog,
  { client, isNew, equipmentDetail }
) => {
  const runStartDate =
    runLog.runStartDate instanceof Date
      ? changeDateFormat(runLog.runStartDate)
      : runLog.runStartDate;
  const runEndDate =
    runLog.runEndDate instanceof Date
      ? changeDateFormat(runLog.runEndDate)
      : runLog.runEndDate;
  delete runLog?.gxpReady?.site;
  delete runLog?.systemStatus?.site;
  delete equipmentDetail?.gxpReady?.site;
  delete equipmentDetail?.systemStatus?.site;
  const defaultObj = {
    key: "-",
    value: "-"
  };
  const variables = {
    ...runLog,
    id: v4(),
    runLogEntryId: isNew ? v4() : equipmentDetail.id,
    inventoryId: runLog?.inventoryId || null,
    numberOfRuns:
      runLog.numberOfRuns || runLog.numberOfRuns === 0
        ? runLog.numberOfRuns
        : null,
    samplesProcessed:
      runLog.samplesProcessed || runLog.samplesProcessed === 0
        ? runLog.samplesProcessed
        : null,
    equipmentModel: runLog?.equipmentModel || equipmentDetail?.equipmentModel,
    materialNumber: runLog?.materialNumber || equipmentDetail?.materialNumber,
    modelVersion: "3",
    runStatus: runLog?.runStatus || null,
    description: runLog?.description || null,
    runStartDate: runStartDate || null,
    runEndDate: runEndDate || null,
    logStatus: LogStatus.ACTIVE,
    mediaType: omitOrReturnArray(runLog.mediaType),
    tipsUsed: omitOrReturn(runLog.tipsUsed),
    runStartTime:
      parseTime(runLog.runStartTime, {
        timeFormat: "HH:mm:ss",
        parseFormat: "hh:mm a"
      }) || null,
    runEndTime:
      parseTime(runLog.runEndTime, {
        timeFormat: "HH:mm:ss",
        parseFormat: "hh:mm a"
      }) || null,
    gxpReady: runLog?.gxpReady
      ? omitOrReturn(runLog?.gxpReady) || defaultObj
      : omitOrReturn(equipmentDetail?.gxpReady) || defaultObj,
    systemStatus: runLog?.systemStatus
      ? omitOrReturn(runLog?.systemStatus) || defaultObj
      : omitOrReturn(equipmentDetail?.systemStatus) || defaultObj
  };
  try {
    const response = await client.mutate({
      mutation: CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG_CHANGE,
      fetchPolicy: "no-cache",
      variables
    });

    Notify({
      appName: "",
      icon: "circle_confirm",
      type: "success",
      text: isNew
        ? "Successfully saved run log!"
        : "Successfully saved run log change!"
    });

    if (isNew) {
      await waitUntilSubscribeConditionTimes(
        client,
        ON_CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG,
        checkSubscribeCondition(variables, "onCreateDigitalLabLogbookRunLog"),
        3
      );
    } else {
      await waitUntilSubscribeConditionTimes(
        client,
        ON_UPDATE_DIGITAL_LAB_LOGBOOK_RUN_LOG,
        checkSubscribeCondition(variables, "onUpdateDigitalLabLogbookRunLog"),
        3
      );
    }

    return response;
  } catch (error) {
    Notify({
      appName: "",
      icon: "no",
      type: "warning",
      text: "Failed to save run log!"
    });
    return { error };
  }
};

export const CreateRunLogChangeCluster = async (
  runLog,
  { client, isNew, selectedEquipments, equipmentDetail }
) => {
  try {
    const runStartDate =
      runLog.runStartDate instanceof Date
        ? changeDateFormat(runLog.runStartDate)
        : runLog.runStartDate;
    const runEndDate =
      runLog.runEndDate instanceof Date
        ? changeDateFormat(runLog.runEndDate)
        : runLog.runEndDate;
    const flatSubEquipments = subClusterMapping(equipmentDetail?.subEquipment);

    let updatedSelectedEquipments = [];
    selectedEquipments.forEach((item) => {
      if (item?.clusterId) {
        let parentObj = find(flatSubEquipments, {
          inventoryId: item?.clusterId
        });
        if (
          parentObj &&
          !find(selectedEquipments, { inventoryId: parentObj?.inventoryId })
        ) {
          updatedSelectedEquipments.push(parentObj);
        }
      }
    });

    const inputArray = generateInputVariable({
      selectedEquipments: [...selectedEquipments, ...updatedSelectedEquipments],
      runLog,
      isNew,
      runStartDate,
      runEndDate,
      equipmentDetail
    });

    const response = await client.mutate({
      mutation: CREATE_DIGITAL_LAB_LOGBOOK_RUN_LOG_CLUSTER_CHANGE,
      fetchPolicy: "no-cache",
      variables: { input: omitDeep([...inputArray], "subEquipmentDetails") }
    });

    if (response?.data?.createDigitalLabLogbookClusterRunLogChange) {
      Notify({
        appName: "",
        icon: "circle_confirm",
        type: "success",
        text: isNew
          ? "Successfully saved run log!"
          : "Successfully saved run log change!"
      });
      return response;
    }
  } catch (error) {
    Notify({
      appName: "",
      icon: "no",
      type: "warning",
      text: "Failed to save run log!"
    });
    return { error };
  }
};

export const getSubEquipmentStructure = (selectedEquipments) => {
  let subEquipments = [];
  selectedEquipments?.forEach((subEquipment) => {
    let obj = {
      inventoryId: subEquipment?.inventoryId,
      shortIdentifier: subEquipment?.shortIdentifier,
      manufacturer: subEquipment?.manufacturer,
      equipmentModel: subEquipment?.equipmentModel,
      equipmentNickName: subEquipment?.equipmentNickName,
      serialNumber: subEquipment?.serialNumber,
      equipmentId: subEquipment?.equipmentId,
      location: subEquipment?.location
    };
    subEquipments.push(obj);
  });
  return subEquipments;
};

const getRunLogDetailsStructure = ({
  runLog,
  isCluster = false,
  equipmentDetail,
  runEndDate,
  runStartDate,
  isNew = false
}) => {
  let instrumentDetails = {
    instrumentGxPStatus: equipmentDetail?.instrumentGxPStatus || null,
    equipmentNickName: equipmentDetail?.equipmentNickName || null,
    responsiblePerson: equipmentDetail?.responsiblePerson || null,
    manufacturer: equipmentDetail?.manufacturer || null,
    softwareVersion: equipmentDetail?.softwareVersion || null,
    location: equipmentDetail?.location || null,
    responsibleProxy: equipmentDetail?.responsibleProxy || null
  };

  if (isCluster) {
    instrumentDetails = {
      ...instrumentDetails,
      configurationBaseline: equipmentDetail?.configurationBaseline || null
    };
  }
  if (isCluster) {
    delete runLog?.gxpReady?.site;
    delete runLog?.systemStatus?.site;
  } else {
    delete equipmentDetail?.gxpReady?.site;
    delete equipmentDetail?.systemStatus?.site;
  }
  const defaultObj = {
    key: "-",
    value: "-"
  };

  let runLogObj = {
    approverId: runLog?.approverId,
    approverUserName: runLog?.approverUserName,
    assay: runLog?.assay,
    defectId: runLog?.defectId,
    description: runLog?.description || null,
    equipmentId: isCluster
      ? runLog?.equipmentId
      : equipmentDetail?.equipmentId || null,
    id: v4(),
    equipmentModel: isCluster
      ? runLog?.equipmentModel
      : equipmentDetail?.equipmentModel || null,
    editReason: runLog?.editReason || "",
    editComment: runLog?.editComment || "",
    gxpReady: isCluster
      ? omitOrReturn(runLog?.gxpReady) || defaultObj
      : omitOrReturn(equipmentDetail?.gxpReady) || defaultObj,
    gxpReadyCluster: isCluster
      ? omitOrReturn(runLog?.gxpReadyCluster) || defaultObj
      : omitOrReturn(equipmentDetail?.gxpReady) || defaultObj,
    inventoryId: isCluster
      ? runLog?.inventoryId
      : equipmentDetail?.inventoryId || null,
    materialNumber: isCluster
      ? runLog?.materialNumber
      : equipmentDetail?.materialNumber || "",
    mediaType: omitOrReturnArray(runLog.mediaType),
    modelVersion: "3",
    numberOfRuns: runLog?.numberOfRuns,
    operatorId: runLog?.operatorId,
    operatorUserName: runLog?.operatorUserName,
    runEndDate: runEndDate || null,
    runEndTime:
      parseTime(runLog.runEndTime, {
        timeFormat: "HH:mm:ss",
        parseFormat: "hh:mm a"
      }) || null,
    runIdentification: runLog?.runIdentification || null,
    runLogEntryId: isNew
      ? v4()
      : isCluster
      ? equipmentDetail?.id
      : equipmentDetail?.runLogEntryId || v4(),
    runStartDate: runStartDate || null,
    runStartTime:
      parseTime(runLog.runStartTime, {
        timeFormat: "HH:mm:ss",
        parseFormat: "hh:mm a"
      }) || null,
    runStatus: runLog?.runStatus || null,
    samplesProcessed: runLog?.samplesProcessed || null,
    serialNumber: isCluster
      ? runLog?.serialNumber
      : equipmentDetail?.serialNumber || "",
    site: runLog?.site || null,
    systemStatus: isCluster
      ? omitOrReturn(runLog?.systemStatus) || defaultObj
      : omitOrReturn(equipmentDetail?.systemStatus) || defaultObj,
    systemStatusCluster: isCluster
      ? omitOrReturn(runLog?.systemStatusCluster) || defaultObj
      : omitOrReturn(equipmentDetail?.systemStatus) || defaultObj,
    tipsUsed: omitOrReturn(runLog.tipsUsed),
    eLNid: runLog?.eLNid || null,
    instrumentDetails,
    logStatus: LogStatus.ACTIVE
  };

  if (equipmentDetail?.entryType !== entryType?.cluster) {
    delete runLogObj.gxpReadyCluster;
    delete runLogObj.systemStatusCluster;
  }

  return runLogObj;
};

const generateInputVariable = ({
  selectedEquipments,
  runLog,
  isNew,
  existingItem,
  runStartDate,
  runEndDate,
  equipmentDetail
}) => {
  let variables = [];
  let clusterObj = getRunLogDetailsStructure({
    equipmentDetail,
    runLog,
    isNew,
    existingItem,
    runStartDate,
    runEndDate,
    isCluster: true
  });

  clusterObj = {
    ...clusterObj,
    subEquipment: getSubEquipmentStructure(selectedEquipments)
  };
  variables.push(clusterObj);
  selectedEquipments.forEach((subEquipment) => {
    let subEquipmentObj = {
      ...getRunLogDetailsStructure({
        equipmentDetail: subEquipment,
        runLog,
        isNew,
        runStartDate,
        runEndDate
      })
    };

    delete subEquipmentObj.id;
    delete subEquipmentObj.logStatus;
    // delete subEquipmentObj.systemStatus;

    subEquipmentObj = {
      ...subEquipmentObj,
      clusterEntryRefId: clusterObj?.runLogEntryId
    };
    variables.push(subEquipmentObj);
  });

  return variables;
};

export const getRunLogSubEquipments = async ({ client, id }) => {
  const subEquipmentData = await getAllData({
    client,
    query: RUN_LOG_BY_CLUSTER_ENTRY_REF_ID,
    variables: {
      limit: 1000,
      clusterEntryRefId: id
    },
    dataPath: ["data", "runLogByClusterEntryRefId"],
    drillData: true
  });
  return subEquipmentData?.items;
};

export const getEquipDetailLogList = async (id, client, logType) => {
  if (id !== undefined) {
    try {
      const result = await client.query({
        query:
          logType === formTypes?.RUN_LOG
            ? GET_LOGBOOK_RUNLOG
            : GET_LOGBOOK_ACTIONLOG,
        fetchPolicy: "no-cache",
        variables: {
          id: id
        }
      });
      let dataPath =
        logType === formTypes?.RUN_LOG
          ? "getDigitalLabLogbookRunLog"
          : "getDigitalLabLogbookLogSheet";

      if (result?.data && result?.data[dataPath]) {
        return result?.data[dataPath];
      } else {
        return null;
      }
    } catch (err) {}
  }
};
