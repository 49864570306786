import { AuditItemContentCellsInfoArray } from "../../features/log-book/audit-trial/AuditItemCellsConfig";
import styled from "styled-components";
import "./changeTable.css";
import parse from "html-react-parser";
import {
  AUDIT_ACTIONS,
  AUDIT_CLUSTER_ACTION,
  AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE,
  emptyClusterInformation,
  SHOW_SUBEQUIPMENT_KEY,
  entryType,
  linkedInstanceList,
  SHOW_SUB_CLUSTER_KEY,
  GXPemptyInstrumentsOptions
} from "../../constants";
import { find, isBoolean } from "lodash";
import {
  rearangeDisplayDate,
  replaceEmptyWithHyphen,
  validateSelection
} from "./text";
import DATA_MODEL_TABLE from "../constants/dataModelTable";
import { recursiveCluster } from "../../components/shared/ClusterAgGridTable";
import { lineGenerator } from "../../features/log-book/log-book-table/ClusterDetailsCellsServices";

const ClusterCellOld = styled.div`
  min-width: 465px;
  width: 465px;
  text-decoration: line-through;
  word-break: break-word;
`;

const ClusterCellNew = styled.div`
  min-width: 465px;
  width: 465px;
  word-break: break-word;
`;

export const Frame = styled.div`
  color: #000000;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 20px;
`;

const MessageText = styled.div`
  display: flex;
  padding-bottom: 15px;
`;
const BoldText = styled.span`
  font-weight: 500;
`;
const CustomTooltipInnerDivStyled = styled.div``;

export const changeTable = (records, logBookEquipment) => {
  var objs = records.map((x) => ({
    date: x[0],
    edited: x[1],
    reason: x[2],
    logs: x[3]
  }));
  const val = logBookEquipment;
  return (
    <div data-testid="change-table">
      <div style={{ height: "0.4px", background: "black" }}></div>
      <div
        style={{ paddingTop: "7px", paddingBottom: "5px", fontSize: "12px" }}
      >
        Attributes
      </div>
      <div style={{ display: "flex", paddingBottom: "20px" }}>
        <div style={{ flex: "50%", paddingRight: "40px" }}>
          <table id="alllogAttributes">
            <tr>
              <td>Model:</td>
              <td>{replaceEmptyWithHyphen(val?.equipmentModel)}</td>
            </tr>
            <tr>
              <td>Variant:</td>
              <td>{replaceEmptyWithHyphen(val?.variant)}</td>
            </tr>
          </table>
        </div>
        <div style={{ flex: "50%", paddingLeft: "40px" }}>
          {val?.entryType === entryType.cluster ||
          val?.entryType === entryType.clusterSubequipment ? (
            <table id="alllogAttributes">
              <tr>
                <td>Nickname:</td>
                <td>{replaceEmptyWithHyphen(val?.equipmentNickName)}</td>
              </tr>
              <tr>
                <td>Location:</td>
                <td>{replaceEmptyWithHyphen(val?.location)}</td>
              </tr>
            </table>
          ) : (
            <table id="alllogAttributes">
              <tr>
                <td>Equipment ID:</td>
                <td>{replaceEmptyWithHyphen(val?.equipmentId)}</td>
              </tr>
              <tr>
                <td>Serial number:</td>
                <td>{replaceEmptyWithHyphen(val?.serialNumber)}</td>
              </tr>
            </table>
          )}
        </div>
      </div>
      <div
        style={{
          height: "0.4px",
          background: "black"
        }}
      ></div>
      <div
        style={{ paddingTop: "8px", paddingBottom: "6px", fontSize: "12px" }}
      >
        Change log list
      </div>
      <table id="changelog" style={{ width: "100%" }}>
        <tr>
          <th style={{ width: "11%" }}>Date</th>
          <th style={{ width: "13%" }}>Edited by</th>
          <th style={{ width: "14%" }}>Reason</th>
          <th style={{ width: "20%" }}>Changed attribute</th>
          <th style={{ width: "20%", textAlign: "right" }}>New value</th>
          <th style={{ width: "20%", textAlign: "right" }}>Old value</th>
        </tr>
        {objs.map((val, key) => {
          let arrayCheck = Array.isArray(val?.logs);
          return (
            <tr key={key}>
              <td
                rowspan={
                  arrayCheck && val?.logs?.length > 0
                    ? val?.logs?.length + 1
                    : ""
                }
              >
                {val?.date}
              </td>
              <td
                rowspan={
                  arrayCheck && val?.logs?.length > 0
                    ? val?.logs?.length + 1
                    : ""
                }
              >
                {val?.edited}
              </td>
              <td
                rowspan={
                  arrayCheck && val?.logs?.length > 0
                    ? val?.logs?.length + 1
                    : ""
                }
              >
                {val?.reason}
              </td>

              {arrayCheck &&
                val?.logs.map((x) => {
                  return (
                    <tr>
                      <td>{x[0]}</td>
                      <td style={{ textAlign: "right" }}>{x[1]}</td>
                      <td
                        style={{
                          textAlign: "right",
                          textDecoration: "line-through",
                          textDecorationThickness: "0.5px"
                        }}
                      >
                        {x[2]}
                      </td>
                    </tr>
                  );
                })}
              {arrayCheck && val?.logs?.length === 0 && (
                <>
                  <td>-</td>
                  <td style={{ textAlign: "right" }}>-</td>
                  <td style={{ textAlign: "right" }}>-</td>
                </>
              )}
              {!arrayCheck && (
                <>
                  <td colspan="3">{parse(val?.logs)}</td>
                </>
              )}
            </tr>
          );
        })}
      </table>
    </div>
  );
};

const ClusterDetailsCells = ({
  items,
  type,
  relevantPositionState = false,
  messageText = null
}) => {
  if (items && items?.length > 0) {
    items?.sort((a, b) => a?.positionInCluster - b?.positionInCluster);
  }
  return (
    <>
      {messageText && (
        <>
          <MessageText id="ClusterDecommission-ClusterDetailsCells-MessageText">
            {messageText}
          </MessageText>
        </>
      )}
      {type === AUDIT_CLUSTER_ACTION?.cluster &&
        items.map((item, index) => {
          const divCollecter = lineGenerator(item);
          return (
            <>
              <CustomTooltipInnerDivStyled id="ClusterDecommission-ClusterDetailsCells-CustomTooltipInnerDivStyled">
                Sub component- {relevantPositionState && index + 1}
                <span id={divCollecter}>{divCollecter}</span>
              </CustomTooltipInnerDivStyled>
            </>
          );
        })}
      {type === AUDIT_CLUSTER_ACTION?.subEquipmentAdd ||
      type === AUDIT_CLUSTER_ACTION?.subEquipmentRemove ? (
        <SubClusterCell1 item={items} type={type} />
      ) : (
        ""
      )}
    </>
  );
};

const SubClusterCell1 = ({ item, type }) => {
  let divCollecter = "";
  SHOW_SUBEQUIPMENT_KEY.forEach((clusterItem, indexItem) => {
    divCollecter += `${clusterItem?.label}: ${
      item[clusterItem?.key] !== null &&
      item[clusterItem?.key] !== undefined &&
      item[clusterItem?.key] !== "null"
        ? item[clusterItem?.key]
        : " -"
    }${indexItem < SHOW_SUBEQUIPMENT_KEY.length - 1 ? ", " : ""}`;
  });

  return (
    <div id="ClusterDecommission-SubClusterCell-OuterDiv">
      {type === AUDIT_CLUSTER_ACTION?.subEquipmentAdd && (
        <CustomTooltipInnerDivStyled id="ClusterDecommission-SubClusterCell-CustomTooltipInnerDivStyled">
          <BoldText id="ClusterDecommission-SubClusterCell-BoldText">
            Equipment was added to cluster: &nbsp;
          </BoldText>
          {divCollecter}
        </CustomTooltipInnerDivStyled>
      )}
      {type === AUDIT_CLUSTER_ACTION?.subEquipmentRemove && (
        <CustomTooltipInnerDivStyled id="ClusterDecommission-SubClusterCell-CustomTooltipInnerDivStyled-0">
          <BoldText id="ClusterDecommission-SubClusterCell-BoldText-0">
            Equipment was removed from cluster: &nbsp;
          </BoldText>
          {divCollecter}
        </CustomTooltipInnerDivStyled>
      )}
    </div>
  );
};

const SingleDetailCell = (DetailsCellValue) => {
  if (isBoolean(DetailsCellValue?.oldvalue)) {
    DetailsCellValue.oldvalue = String(DetailsCellValue?.oldvalue);
  }
  if (isBoolean(DetailsCellValue?.newvalue)) {
    DetailsCellValue.newvalue = String(DetailsCellValue?.newvalue);
  }

  let newValue =
    DetailsCellValue?.newvalue && DetailsCellValue?.newvalue !== "null"
      ? DetailsCellValue.newvalue
      : "-";
  let oldValue =
    DetailsCellValue?.oldvalue && DetailsCellValue?.oldvalue !== "null"
      ? DetailsCellValue.oldvalue
      : "-";
  return {
    newValue,
    oldValue
  };
};

const DetailsCell = (isAuditTrial, label, { ...props }, cellValue) => {
  let changeLogs = [];
  changeLogs.push(`${label}:`);
  let DetailsCellValue = cellValue === "null" ? "" : cellValue;
  if (props?.multiLine) {
    Array.isArray(DetailsCellValue)
      ? DetailsCellValue.map((value) => {
          const { newValue, oldValue } = SingleDetailCell(value);
          changeLogs.push(newValue, oldValue);
          return value;
        })
      : changeLogs.push("", "");
  } else {
    const { newValue, oldValue } = SingleDetailCell(DetailsCellValue);
    changeLogs.push(newValue, oldValue);
  }
  return changeLogs;
};

const SubClusterCell = ({
  items,
  relevantPositionState,
  prefixString = null
}) => {
  let newArrItems = [];
  items.forEach((item) => {
    if (item?.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Deleted) {
      newArrItems = [item, ...newArrItems];
    } else {
      newArrItems = [...newArrItems, item];
    }
  });
  return (
    <div
      style={{ maxHeight: "200px", overflow: "auto" }}
      id="ClusterDecommission-ClusterDetailsCells-FrameWithBackground"
    >
      {newArrItems.map((item, index) => {
        let deleteDivCollector = "";
        let addDivCollector = "";
        let newUpdateDiv = "";
        let oldUpdateDiv = "";

        if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Deleted) {
          if (relevantPositionState) {
            deleteDivCollector += `Position ${
              item?.positionInCluster !== null &&
              item?.positionInCluster !== "undefined" &&
              item?.positionInCluster !== "null"
                ? item?.positionInCluster
                : " -"
            }: `;
          }
          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            deleteDivCollector += `${clusterItem?.label}:${
              item?.oldvalue?.[clusterItem?.key] !== null &&
              item?.oldvalue?.[clusterItem?.key] !== "undefined" &&
              item?.oldvalue?.[clusterItem?.key] !== "null"
                ? item?.oldvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        } else if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Created) {
          if (relevantPositionState && item?.positionInCluster) {
            addDivCollector += `Position ${
              item?.positionInCluster !== null &&
              item?.positionInCluster !== "undefined" &&
              item?.positionInCluster !== "null"
                ? item?.positionInCluster
                : " -"
            }: `;
          }
          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            addDivCollector += `${clusterItem?.label}:${
              item?.newvalue?.[clusterItem?.key] !== null &&
              item?.newvalue?.[clusterItem?.key] !== "undefined" &&
              item?.newvalue?.[clusterItem?.key] !== "null"
                ? item?.newvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        } else if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Updated) {
          if (relevantPositionState) {
            newUpdateDiv += `Position ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }: Sub-component- ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }`;
            oldUpdateDiv += `Position ${
              item?.oldvalue?.positionInCluster !== null &&
              item?.oldvalue?.positionInCluster !== "undefined" &&
              item?.oldvalue?.positionInCluster !== "null"
                ? item?.oldvalue?.positionInCluster
                : " "
            }: Sub-component- ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }`;
          }

          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            newUpdateDiv += `${clusterItem?.label}:${
              item?.newvalue?.[clusterItem?.key] !== null &&
              item?.newvalue?.[clusterItem?.key] !== "undefined" &&
              item?.newvalue?.[clusterItem?.key] !== "null"
                ? item?.newvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;

            oldUpdateDiv += `${clusterItem?.label}:${
              item?.oldvalue?.[clusterItem?.key] !== null &&
              item?.oldvalue?.[clusterItem?.key] !== "undefined" &&
              item?.oldvalue?.[clusterItem?.key] !== "null"
                ? item?.oldvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        }
        return (
          <>
            <ClusterCellOld id={deleteDivCollector}>
              {prefixString && deleteDivCollector && `${prefixString} : `}
              {deleteDivCollector}
            </ClusterCellOld>
            <ClusterCellNew id={addDivCollector}>
              {prefixString && addDivCollector && `${prefixString} : `}
              {addDivCollector}
            </ClusterCellNew>
            <ClusterCellOld id={oldUpdateDiv}>
              {prefixString && oldUpdateDiv && `${prefixString} : `}
              {oldUpdateDiv}
            </ClusterCellOld>
            <ClusterCellNew id={newUpdateDiv}>
              {prefixString && newUpdateDiv && `${prefixString} : `}
              {newUpdateDiv}
            </ClusterCellNew>
          </>
        );
      })}
    </div>
  );
};

const DetailsCells = (
  isAuditTrial,
  item,
  infoMeta,
  relevantPositionState,
  auditAction
) => {
  let changedKeys = Object.keys(item);
  let changeFieldConfig = infoMeta.fields;
  let displayCond = true;
  if (auditAction === "CLUSTER_UPDATE") {
    displayCond = false;
    let allFieldKeys = Object.keys(emptyClusterInformation);
    let arr = [];
    for (let key of changedKeys) {
      arr.push(allFieldKeys.includes(key));
    }
    if (arr.includes(true)) {
      displayCond = true;
    }
  }

  const getChangedValuesArray = (changedItemConfig, changes) => {
    if (
      changedItemConfig?.field === "dateOfNextMaintanance" ||
      changedItemConfig?.field === "dateOfLastMaintanance" ||
      changedItemConfig?.field === "dateOfNextPeriodicReview"
    ) {
      return {
        oldvalue: changes?.oldvalue
          ? rearangeDisplayDate(changes?.oldvalue)
          : null,
        newvalue: changes?.newvalue
          ? rearangeDisplayDate(changes?.newvalue)
          : null
      };
    }
    if (
      changedItemConfig?.field === "room" ||
      changedItemConfig?.field === "floor" ||
      changedItemConfig?.field === "buildingLocation" ||
      changedItemConfig?.field === "module"
    ) {
      if (changes?.value) {
        return changes?.value;
      } else {
        return {
          oldvalue: changes?.oldvalue?.value,
          newvalue: changes?.newvalue?.value
        };
      }
    }
    if (changedItemConfig?.field === "linkedInstance") {
      const oldvalue = find(linkedInstanceList, {
        linkedInstance: changes?.oldvalue
      });
      const newvalue = find(linkedInstanceList, {
        linkedInstance: changes?.newvalue
      });
      return {
        oldvalue: oldvalue?.linkedInstanceDisplay,
        newvalue: newvalue?.linkedInstanceDisplay
      };
    }
    if (changedItemConfig?.field === "sop") {
      const sopChanges = getModifiedChanges(changes, "value");
      return sopChanges;
    }
    if (changedItemConfig?.field === "qualificationDocuments") {
      if (changes?.value) {
        const docChanges = getModifiedChanges(
          changes?.value,
          "name",
          "documentId"
        );
        return docChanges;
      }
    }
    if (changedItemConfig?.field === "qualificationStatus") {
      const oldValue = validateSelection(
        GXPemptyInstrumentsOptions.qualificationStatus,
        { key: changes?.oldvalue }
      );

      const newvalue = validateSelection(
        GXPemptyInstrumentsOptions.qualificationStatus,
        { key: changes?.newvalue }
      );
      return {
        oldvalue: oldValue,
        newvalue: newvalue
      };
    }
    if (changedItemConfig?.field === "installedTests") {
      const testChanges = getModifiedChanges(changes, "version", "name");
      return testChanges;
    }

    if (
      changedItemConfig?.field === "inventoryNumber" ||
      changedItemConfig?.field === "maintenanceIntervalInDays"
    ) {
      return {
        oldvalue: changes?.oldvalue?.toString(),
        newvalue: changes?.newvalue?.toString()
      };
    }

    return changes;
  };
  let changeAttributes = [];
  if (displayCond) {
    changedKeys.map((fieldId) => {
      const changedItemConfig = find(changeFieldConfig, {
        field: fieldId
      });
      const changedItemValues = getChangedValuesArray(
        changedItemConfig,
        item[fieldId]
      );
      let logDetails;
      if (fieldId === DATA_MODEL_TABLE.tags.key) {
        let changeLogs = [];
        changeLogs.push(`${DATA_MODEL_TABLE.tags.value}:`);
        const { newValue, oldValue } = TagLogComponent(item[fieldId]);
        changeLogs.push(newValue);
        changeLogs.push(oldValue);
        changeAttributes.push(changeLogs);
        return changeLogs;
      }
      if (changedItemConfig) {
        logDetails = DetailsCell(
          isAuditTrial,
          changedItemConfig?.label,
          { ...(changedItemConfig?.props ?? {}) },
          changedItemValues
        );
        changeAttributes.push(logDetails);
      }
      return logDetails;
    });
    return changeAttributes;
  }
  return (
    <>
      {item.hasOwnProperty("subEquipment") && item?.subEquipment.length > 0 && (
        <SubClusterCell
          items={item?.subEquipment}
          relevantPositionState={relevantPositionState}
        />
      )}
      {item.hasOwnProperty("leadingClusterSoftware") &&
        Object.keys(item?.leadingClusterSoftware).length > 0 && (
          <SubClusterCell
            items={[item?.leadingClusterSoftware]}
            relevantPositionState={relevantPositionState}
            prefixString={"Leading cluster software"}
          />
        )}
    </>
  );
};

const concatChanges = (changes, primaryKey, secondaryKey) => {
  if (changes) {
    const primaryValue = changes[primaryKey] ? `${changes[primaryKey]}` : "";
    const secondaryValue = changes[secondaryKey]
      ? `, ${changes[secondaryKey]}`
      : "";
    return `${primaryValue}${secondaryValue}`;
  } else {
    return "";
  }
};

const getModifiedChanges = (changes, primaryKey, secondaryKey) => {
  return changes.map((item) => {
    const itemWithPrimaryKey = item[primaryKey];
    if (itemWithPrimaryKey) {
      if (primaryKey === "version") {
        return {
          oldvalue: `${itemWithPrimaryKey[secondaryKey]}, ${itemWithPrimaryKey?.oldvalue}`,
          newvalue: `${itemWithPrimaryKey[secondaryKey]}, ${itemWithPrimaryKey?.newvalue}`
        };
      } else {
        return {
          oldvalue: `${itemWithPrimaryKey?.oldvalue}, ${itemWithPrimaryKey[secondaryKey]}`,
          newvalue: `${itemWithPrimaryKey?.newvalue}, ${itemWithPrimaryKey[secondaryKey]}`
        };
      }
    }
    if (primaryKey === "version") {
      return {
        oldvalue: concatChanges(item?.oldvalue, secondaryKey, primaryKey),
        newvalue: concatChanges(item?.newvalue, secondaryKey, primaryKey)
      };
    } else {
      return {
        oldvalue: concatChanges(item?.oldvalue, primaryKey, secondaryKey),
        newvalue: concatChanges(item?.newvalue, primaryKey, secondaryKey)
      };
    }
  });
};

const TagLogComponent = (item = []) => {
  const newhtml = item.map(
    (x) => x?.type === "Created" && <span>{x?.newvalue}</span>
  );

  const oldHtml = item.map(
    (x) =>
      x?.oldvalue &&
      x?.oldvalue !== "null" && (
        <>
          <span style={{ textDecoration: "line-through" }} id={x?.oldvalue}>
            {x?.oldvalue}
          </span>
        </>
      )
  );
  return {
    newValue: <>{newhtml.map((x, index) => (index === 0 ? x : <>,{x}</>))}</>,
    oldValue: <>{oldHtml.map((x, index) => (index === 0 ? x : <>,{x}</>))}</>
  };
};

const AuditItemSingleFrame = (
  isAuditTrial,
  auditAction,
  history,
  CellsInfoArray,
  relevantPositionState
) => {
  switch (auditAction) {
    case AUDIT_ACTIONS.CLUSTER_CREATE:
      return (
        <div>
          <ClusterDetailsCells
            messageText="Cluster commissioned with sub-component configuration"
            items={history?.addedSubEquipments}
            type={AUDIT_CLUSTER_ACTION?.cluster}
            relevantPositionState={relevantPositionState}
          />
        </div>
      );
    case AUDIT_ACTIONS.CLUSTER_DELETE:
      return (
        <div>
          <ClusterDetailsCells
            messageText="Cluster decommissioned with sub-component configuration"
            items={history?.removedSubEquipments}
            type={AUDIT_CLUSTER_ACTION?.cluster}
            relevantPositionState={relevantPositionState}
          />
        </div>
      );
    case AUDIT_ACTIONS.ADD_TO_CLUSTER:
      return (
        <div>
          <ClusterDetailsCells
            items={
              history?.clusterInfo
                ? history?.clusterInfo?.newvalue
                : history?.cluster?.newvalue
            }
            type={AUDIT_CLUSTER_ACTION?.subEquipmentAdd}
          />
        </div>
      );
    case AUDIT_ACTIONS.REMOVE_FROM_CLUSTER:
      return (
        <div>
          <ClusterDetailsCells
            items={
              history?.clusterInfo
                ? history?.clusterInfo?.newvalue
                : history?.cluster?.newvalue
            }
            type={AUDIT_CLUSTER_ACTION?.subEquipmentRemove}
          />
        </div>
      );
    default:
      const DetailsCellAllFrame = DetailsCells(
        isAuditTrial,
        history,
        { fields: CellsInfoArray },
        relevantPositionState,
        auditAction
      );
      return DetailsCellAllFrame;
  }
};

export const changeLogs = (item) => {
  const newData = JSON.parse(item?.newData);
  const relevantPositionState = newData?.trackPositionInCluster;
  const isAuditTrial = true;
  const auditAction = item?.action;
  const history = item?.changes && JSON.parse(item?.changes);
  const AuditItemSingle = AuditItemSingleFrame(
    isAuditTrial,
    auditAction,
    history,
    AuditItemContentCellsInfoArray,
    relevantPositionState
  );
  return AuditItemSingle;
};

export const allLogHierarchyTable = (allLogHierarchyData) => {
  const coverSheetDetail = recursiveCluster(allLogHierarchyData);
  return coverSheetDetail;
};

export const allLogAttributesTable = (logBookEquipment) => {
  const val = logBookEquipment;
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: "50%", paddingRight: "40px" }}>
        <table id="alllogAttributes">
          <tr>
            <td>Model:</td>
            <td>{replaceEmptyWithHyphen(val?.equipmentModel)}</td>
          </tr>
          <tr>
            <td>Variant:</td>
            <td>{replaceEmptyWithHyphen(val?.variant)}</td>
          </tr>
          <tr>
            <td>Location:</td>
            <td>{replaceEmptyWithHyphen(val?.location)}</td>
          </tr>
          <tr>
            <td>Manufacturer:</td>
            <td>{replaceEmptyWithHyphen(val?.manufacturer)}</td>
          </tr>
          <tr>
            <td>Software version:</td>
            <td>{replaceEmptyWithHyphen(val?.softwareVersion)}</td>
          </tr>
        </table>
      </div>
      <div style={{ flex: "50%", paddingLeft: "40px" }}>
        <table id="alllogAttributes">
          <tr>
            <td>Equipment ID:</td>
            <td>{replaceEmptyWithHyphen(val?.equipmentId)}</td>
          </tr>
          <tr>
            <td>Serial number:</td>
            <td>{replaceEmptyWithHyphen(val?.serialNumber)}</td>
          </tr>
          <tr>
            <td>Qualification status:</td>
            <td>
              {replaceEmptyWithHyphen(
                validateSelection(
                  GXPemptyInstrumentsOptions.qualificationStatus,
                  {
                    key: val?.qualificationStatus
                  }
                )
              )}
            </td>
          </tr>
          <tr>
            <td>System status:</td>
            <td>{replaceEmptyWithHyphen(val?.systemStatus)}</td>
          </tr>
          <tr>
            <td>Intended use GxP relevant?:</td>
            <td>
              {val?.gxpRelevant === "NOTAVAILABLE"
                ? "-"
                : replaceEmptyWithHyphen(val?.gxpRelevant)}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};
