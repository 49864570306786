import { OwcIcon, OwcIconButton, OwcInput, OwcTooltip } from "@one/react";
import { uniq } from "lodash";
import styled from "styled-components";
import moment from "moment";
import React, { useContext, useEffect, useCallback, useState } from "react";
import ButtonActionBox from "../log-book-item-fillter/ButtonActionBox";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { CustomFilterContext } from ".././custom-filter/context";
import { useSelector } from "react-redux";
import ClearAll from ".././log-book-item-fillter/ClearAll";
import { fetchLogSheets } from ".././log-sheet/fetch-log-sheets";
import { fetchRunLogs } from ".././run-logs/fetch-run-logs";
import { PaginationContext } from "../../../components/shared/pagination/PaginationContext";
import ChangeButtonActionBox from ".././log-book-item-fillter/ChangeButtonActionBox";
import { fetchChangeLogSheets } from "../log-sheet/fetch-change-log-sheets";
import {
  logSheetItemsToSheet,
  runSheetItemsToPDF
} from ".././log-sheet/log-sheet-export/log-sheet-items-to-sheet";
import { runSheetItemsToPDF as changeLogRunSheetItemsToPdf } from ".././log-sheet/log-sheet-export/change-log-items-to-sheet";
import {
  runLogsItemsToPDF,
  runLogsItemsToSheet
} from ".././run-logs/run-logs-export/run-logs-items-to-sheet";
import { SearchTextContext } from ".././log-book-item-fillter/suggester/search-context/context";

import {
  OwcCheckbox,
  OwcDatepicker,
  OwcListItem,
  OwcSelectDropdown
} from "@one/react";
import { formTypes, SHOW_ADVANCE_FILTER_TOOLTIP } from "../../../constants";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";
import { sortBy } from "underscore";
import { fetchAllLogs } from "../run-logs/fetch-all-logs";
import {
  allLogsItemsToPDF,
  allLogsItemsToSheet
} from "../run-logs/run-logs-export/all-logs-items-to-sheet";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const FilterBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
`;
const RunLogsFilterStyled = styled.div`
  align-self: flex-start;
  display: flex;
  align-items: center;
  position: relative;

  .search-field,
  .equipment-model-select,
  .date-from-picker,
  .date-to-picker {
    flex: 0 0 auto;
    margin-right: 0.75rem;
  }
  .date-from-picker,
  .date-to-picker {
    & .MuiInput-input {
      width: 6em;
    }
  }

  .date-to-picker:last-child {
    margin-right: 0;
  }
  .search-field {
    flex: 1 1 auto;
  }
`;

const SearchFilterBar = ({ value, equipmentDetail }) => {
  const { inventoryId } = useParams();
  const {
    inputsState: { dateFrom, dateTo, signee, signeeChangeLog },
    dispatchAction
  } = useContext(CustomFilterContext);
  const {
    searchText,
    onSearchTextChange,
    onAppliedSearchTextChange,
    onLastSearchedValue
  } = useContext(SearchTextContext);
  const { logType } = useContext(CoversheetMainPageContext);
  const { dispatchAction: paginationDispatch, fetching } =
    useContext(PaginationContext);
  const [showAdvanceFilter, setShowAdvanceFilter] = useState(false);
  const Signee = uniq(useSelector((state) => state.equipmentModels.signee));
  let SigneeList = Signee.map((item) => ({
    ...item,
    key: item?.email.toLowerCase(),
    value: item?.name.toLowerCase()
  }));
  SigneeList = sortBy(SigneeList, "value");

  const setDateCallback = useCallback(
    (key) => (value) => {
      if (Array.isArray(value?.detail) && (value?.detail).length > 0) {
        if (value?.detail[0] instanceof Date) {
          let dateFromTemp = moment(value?.detail[0]).format("YYYY-MM-DD");
          let dateToTemp = null;

          if ((value?.detail).length === 2) {
            dateToTemp = moment(value?.detail[1]).format("YYYY-MM-DD");
            dispatchAction({
              type: "setDateRange",
              payload: { dateFrom: dateFromTemp, dateTo: dateToTemp }
            });
          }
        }
      }
      if (Array.isArray(value?.detail) && (value?.detail).length === 2) {
        paginationDispatch({ type: "clearAll" });
        dispatchAction({ type: "applyInputsState" });
      }
      if (value?.detail === null) {
        dispatchAction({
          type: "setDateRange",
          payload: { dateFrom: null, dateTo: null }
        });
        paginationDispatch({ type: "clearAll" });
        dispatchAction({ type: "applyInputsState" });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    paginationDispatch({ type: "clearAll" });
    dispatchAction({
      type: "clearAll"
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, logType]);

  useEffect(() => {
    paginationDispatch({ type: "clearAll" });
    dispatchAction({ type: "applyInputsState" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signee, signeeChangeLog]);

  const timeString = "T00:00:00";
  let TimeFormat =
    dateFrom === null && dateTo === null
      ? null
      : dateFrom !== null && dateTo === null
      ? [new Date(dateFrom + timeString)]
      : dateFrom !== null && dateTo !== null
      ? [new Date(dateFrom + timeString), new Date(dateTo + timeString)]
      : [dateFrom, dateTo];
  return (
    <FilterBox>
      <RunLogsFilterStyled>
        <>
          <OwcInput
            autoFocus
            placeholder={"Search all columns"}
            disabled={fetching}
            onInputChange={(ev) => {
              onSearchTextChange(ev.detail);
              onLastSearchedValue(ev.detail);
            }}
            style={{ marginRight: 8 }}
            value={searchText}
          >
            <div slot="prefix">
              <OwcIcon name="search" />
            </div>
            <div
              slot="suffix"
              style={{
                color: "var(--one-color-interaction-default-neutral-3)"
              }}
            >
              {searchText && (
                <OwcIcon
                  type="filled"
                  name="circle_clear"
                  onClick={() => {
                    onSearchTextChange("");
                    onAppliedSearchTextChange("");
                    // paginationDispatch({ type: "clearAll" });
                    // dispatchAction({
                    //   type: "clearAll"
                    // });
                  }}
                  style={{
                    cursor: "pointer"
                  }}
                />
              )}
            </div>
          </OwcInput>
        </>
        <>
          <OwcTooltip anchor={"advance-filter"} placement="top">
            {SHOW_ADVANCE_FILTER_TOOLTIP}
          </OwcTooltip>
          <OwcIconButton
            id="advance-filter"
            data-testid="advance-filter-button"
            style={{ marginRight: 8 }}
            icon=" parameter_set"
            type="filled"
            onClick={() => {
              setShowAdvanceFilter(!showAdvanceFilter);
            }}
            flat
          />
        </>
        {showAdvanceFilter && (
          <>
            <>
              <OwcDatepicker
                autoClose
                hasClearIcon={dateFrom === null ? false : true}
                type={"range"}
                label={LOGBOOK_LABEL.SEARCH_FILTER.date_range}
                placeholder={"DD-MM-YYYY"}
                format="dd-MMM-yyyy"
                value={TimeFormat}
                onValueChange={setDateCallback("setDateRange")}
                style={{ marginRight: 10 }}
                invalidDateMessage={"Invalid date format"}
                disabled={fetching}
              />
            </>
            <>
              {value !== formTypes?.CHANGE_LOG && (
                <>
                  <OwcSelectDropdown
                    multiple
                    label="Signee"
                    placeholder="Signee"
                    notFoundText={"No options"}
                    style={{ marginRight: 10 }}
                    disabled={fetching}
                    onSelectChange={(e) => {
                      let selected = e.detail;
                      selected = selected.map((v) => SigneeList[v].value);
                      dispatchAction({
                        type: "setSignee",
                        payload: selected
                      });
                    }}
                  >
                    {SigneeList?.map((signeeItem, index) => {
                      return (
                        <>
                          <OwcListItem
                            selected={
                              signee.includes(signeeItem?.value) ? true : false
                            }
                          >
                            <OwcCheckbox>{signeeItem.value}</OwcCheckbox>
                          </OwcListItem>
                        </>
                      );
                    })}
                  </OwcSelectDropdown>
                </>
              )}
              {value === formTypes?.CHANGE_LOG && (
                <>
                  <OwcSelectDropdown
                    multiple
                    label="Signee"
                    placeholder="Signee"
                    notFoundText={"No options"}
                    style={{ marginRight: 10 }}
                    disabled={fetching}
                    onSelectChange={(e) => {
                      let selected = e.detail;
                      selected = selected.map((v) => Signee[v].value);
                      dispatchAction({
                        type: "setSigneeChangeLog",
                        payload: selected
                      });
                    }}
                  >
                    {Signee?.map((signeeItem, index) => {
                      return (
                        <>
                          <OwcListItem
                            selected={
                              signeeChangeLog.includes(signeeItem?.value)
                                ? true
                                : false
                            }
                          >
                            <OwcCheckbox>{signeeItem.value}</OwcCheckbox>
                          </OwcListItem>
                        </>
                      );
                    })}
                  </OwcSelectDropdown>
                </>
              )}
            </>
          </>
        )}
        <ClearAll className="clear-all" />
      </RunLogsFilterStyled>
      <>
        {value !== formTypes?.CHANGE_LOG && (
          <ButtonActionBox
            fetchingFn={
              value === formTypes?.ACTION_LOG
                ? fetchLogSheets
                : value === formTypes?.ALL_LOG
                ? fetchAllLogs
                : fetchRunLogs
            }
            dataTestId={
              value === formTypes?.ACTION_LOG
                ? "log-sheet"
                : value === formTypes?.ALL_LOG
                ? "all-log"
                : "run-log"
            }
            fileNamePrefix={
              value === formTypes?.ACTION_LOG
                ? "action-logs-report"
                : value === formTypes?.ALL_LOG
                ? "all-logs-export"
                : "run-logs-report"
            }
            mapFn={
              value === formTypes?.ACTION_LOG
                ? logSheetItemsToSheet
                : value === formTypes?.ALL_LOG
                ? allLogsItemsToSheet
                : runLogsItemsToSheet
            }
            raportTitle={
              value === formTypes?.ACTION_LOG && inventoryId
                ? `Action log - ${equipmentDetail?.shortIdentifier}`
                : value === formTypes?.ACTION_LOG
                ? "Action log list"
                : value === formTypes?.ALL_LOG
                ? `All logs list - ${equipmentDetail?.shortIdentifier}`
                : inventoryId
                ? `Run log - ${equipmentDetail?.shortIdentifier}`
                : "Run log list"
            }
            dataTestIdPDF={
              value === formTypes?.ACTION_LOG
                ? "log-sheet"
                : value === formTypes?.ALL_LOG
                ? "all-log"
                : "run-log"
            }
            fetchingFnPDF={
              value === formTypes?.ACTION_LOG
                ? fetchLogSheets
                : value === formTypes?.ALL_LOG
                ? fetchAllLogs
                : fetchRunLogs
            }
            fileNamePrefixPDF={
              value === formTypes?.ACTION_LOG
                ? "action-logs-report"
                : value === formTypes?.ALL_LOG
                ? "all-logs-report"
                : "run-logs-report"
            }
            mapFnPDF={
              value === formTypes?.ACTION_LOG
                ? runSheetItemsToPDF
                : value === formTypes?.ALL_LOG
                ? allLogsItemsToPDF
                : runLogsItemsToPDF
            }
          />
        )}
        {value === formTypes?.CHANGE_LOG && (
          <ChangeButtonActionBox
            dataTestId="change-logs"
            fetchingFn={fetchChangeLogSheets}
            mapSecondFn={changeLogRunSheetItemsToPdf}
            fileNamePrefix="change-logs-report"
            raportTitle={`Change log - ${equipmentDetail?.shortIdentifier}`}
          />
        )}
      </>
    </FilterBox>
  );
};

export default SearchFilterBar;
