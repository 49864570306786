import React, { useContext } from "react";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import { generateID } from "@digitallab/grid-common-components";
import { Module, entryType, formStates } from "../../../constants";
import { OwcButton } from "@one/react";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";

export const CloseAddItemButtonSection = ({
  dataTestId,
  handleClose,
  addButtonLabel,
  disableStatus = false,
  selectedItem
}) => {
  const { inventoryId } = useParams();
  const {
    logBookEquipment,
    handleOpenWorkFlowModel,
    loadLogBookEquipment,
    loadFormState
  } = useContext(CoversheetMainPageContext);
  const canEdit = useSelector((store) => store.user.canEdit);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
      }}
    >
      <OwcButton
        data-testid={`${dataTestId}-close-button`}
        variant="secondary"
        onClick={handleClose}
        id={
          dataTestId === "history-run-log"
            ? generateID.buttonID(Module.BUTTON.run_audit.audit_close, "button")
            : generateID.buttonID(
                Module.BUTTON.action_audit.audit_close,
                "button"
              )
        }
        style={{ marginRight: 32 }}
      >
        {LOGBOOK_LABEL.BUTTON.close}
      </OwcButton>
      {!selectedItem?.equipmentDetails?.clusterId && (
        <OwcButton
          onClick={() => {
            handleClose();
            if (!inventoryId) {
              loadLogBookEquipment(null);
            }
            loadFormState(null);
            handleOpenWorkFlowModel({
              isCluster:
                inventoryId &&
                logBookEquipment?.entryType === entryType?.cluster,
              form: inventoryId
                ? formStates?.PARTIAL_NEW_FORM
                : formStates?.NEW_FORM
            });
          }}
          variant="primary"
          data-testid={`${dataTestId}-add-new-run-log-button`}
          id={
            dataTestId === "history-run-log"
              ? generateID.buttonID(
                  Module.BUTTON.run_audit.audit_new_run,
                  "button"
                )
              : generateID.buttonID(
                  Module.BUTTON.action_audit.audit_new_log,
                  "button"
                )
          }
          disabled={disableStatus || !canEdit}
        >
          {addButtonLabel}
        </OwcButton>
      )}
    </div>
  );
};
