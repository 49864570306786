import { RUN_STATUS_FIELD, entryType } from "../../../../constants";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import RUN_LOGS_DATA_MODEL from "../../../../utils/constants/runLogsDataModel";
import {
  allLogAttributesTable,
  allLogHierarchyTable
} from "../../../../utils/helpers/changeIndex";
import {
  parseTime,
  valueOrEmpty,
  stringOrEmpty,
  replaceEmptyWithHyphen,
  validateSelection
} from "../../../../utils/helpers/text";
import { RunStatusLabels } from "../../enums";
import { findItemByKey } from "../../helpers/find-item-by-key";
import ReactDOMServer from "react-dom/server";

export const runLogsItemsToSheet = (items) =>
  items.map((item) => ({
    "Equipment ID": valueOrEmpty(item?.equipmentId),
    "Start date and time": `${valueOrEmpty(
      item.runStartDate,
      true
    )} (${valueOrEmpty(parseTime(item.runStartTime))})`,
    "End date and time": `${valueOrEmpty(
      item.runEndDate,
      true
    )} (${valueOrEmpty(parseTime(item.runEndTime))})`,
    "Serial number": valueOrEmpty(item?.serialNumber),
    "Material number": valueOrEmpty(item?.materialNumber),
    Model: valueOrEmpty(item?.equipmentModel),
    "Run status": valueOrEmpty(findItemByKey(RunStatusLabels, item?.runStatus)),
    Description: valueOrEmpty(item.description),
    Signee: valueOrEmpty(item.approverUserName),
    Nickname: valueOrEmpty(item?.instrumentDetails?.equipmentNickName),
    "Number of runs": valueOrEmpty(item.numberOfRuns),
    "Number of samples": valueOrEmpty(item.samplesProcessed),
    "Defect ID": valueOrEmpty(item.defectId),
    Operator: valueOrEmpty(item.operatorUserName),
    "Run ID": valueOrEmpty(item.runIdentification),
    "eLN ID": valueOrEmpty(item.eLNid),
    Assays: item.assay?.join(", "),
    "Media type": stringOrEmpty(item.mediaType, {}),
    Tips: valueOrEmpty(item.tipsUsed?.value),
    "Qualification status": valueOrEmpty(
      item?.instrumentDetails?.instrumentGxPStatus
    ),
    "System status": valueOrEmpty(item.systemStatus),
    Manufacturer: valueOrEmpty(item?.instrumentDetails?.manufacturer),
    Site: valueOrEmpty(item.site),
    Location: valueOrEmpty(item?.instrumentDetails?.location),
    "Software version": valueOrEmpty(item?.instrumentDetails?.softwareVersion),
    Configuration: valueOrEmpty(item?.instrumentDetails?.configurationBaseline),
    "Responsible proxy": valueOrEmpty(
      item?.instrumentDetails?.responsibleProxy
    ),
    "Responsible person": valueOrEmpty(
      item?.instrumentDetails?.responsiblePerson
    )
  }));

export const RUN_LOGS_TABLE_HEADERS = [
  { header: "Run start", value: 21 },
  { header: "Run end", value: 21 },
  { header: RUN_LOGS_DATA_MODEL.runStatus.value, value: 20 },
  { header: COMMON_LOGS_DATA_MODEL.description.value, value: 35 },
  { header: COMMON_LOGS_DATA_MODEL.equipmentModel.value, value: 20 },
  { header: "Variant", value: 20 },
  { header: COMMON_LOGS_DATA_MODEL.equipmentId.value, value: 25 },
  { header: COMMON_LOGS_DATA_MODEL.serialNumber.value, value: 25 },
  { header: RUN_LOGS_DATA_MODEL.runIdentification.value, value: 20 },
  { header: ACTION_LOGS_DATA_MODEL.gxpReady.value, value: 25 },
  { header: ACTION_LOGS_DATA_MODEL.systemStatus.value, value: 20 },
  { header: COMMON_LOGS_DATA_MODEL.approverUserName.value, value: 26 }
];

export const RUN_LOGS_CENTRIC_TABLE_HEADERS = [
  { header: "Run start", value: "auto" },
  { header: "Run end", value: "auto" },
  { header: RUN_LOGS_DATA_MODEL.eLNid.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.description.value, value: 40 },
  { header: RUN_LOGS_DATA_MODEL.runStatus.value, value: "auto" },
  { header: RUN_LOGS_DATA_MODEL.runIdentification.value, value: 20 },
  { header: ACTION_LOGS_DATA_MODEL.gxpReady.value, value: 30 },
  { header: ACTION_LOGS_DATA_MODEL.systemStatus.value, value: 20 },
  { header: COMMON_LOGS_DATA_MODEL.approverUserName.value, value: 30 }
];

export const RUN_HIERARCHY_TABLE_HEADERS = [
  { header: "", value: 1 },
  { header: COMMON_LOGS_DATA_MODEL.equipmentModel.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.location.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.manufacturer.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.equipmentId.value, value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.serialNumber.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.gxpReady.value, value: "auto" },
  { header: ACTION_LOGS_DATA_MODEL.systemStatus.value, value: "auto" },
  { header: DATA_MODEL_TABLE.gxpRelevant.value + "?", value: "auto" },
  { header: COMMON_LOGS_DATA_MODEL.softwareVersion.value, value: "auto" }
];
export const runLogsItemsToPDF = (
  items,
  allLogHierarchyData,
  logBookEquipment,
  inventoryId,
  storeGxpReadys
) => {
  let records = null;
  records = items.map((item) => {
    if (inventoryId) {
      return [
        valueOrEmpty(item?.runStartDate, true, "-"),
        valueOrEmpty(item?.runEndDate, true, "-"),
        valueOrEmpty(item?.eLNid, false, "-"),
        valueOrEmpty(item?.description, false, "-"),
        valueOrEmpty(RUN_STATUS_FIELD[item?.runStatus], false, "-"),
        valueOrEmpty(item.runIdentification, false, "-"),
        item?.equipmentDetails?.entryType === entryType.cluster
          ? valueOrEmpty(item.gxpReadyCluster?.value, false, "-")
          : valueOrEmpty(item.gxpReady?.value, false, "-"),
        item?.equipmentDetails?.entryType === entryType.cluster
          ? valueOrEmpty(item.systemStatusCluster?.value, false, "-")
          : valueOrEmpty(item.systemStatus?.value, false, "-"),
        valueOrEmpty(item?.approverUserName, false, "-"),
        "-"
      ];
    } else {
      return [
        valueOrEmpty(item?.runStartDate, true, "-"),
        valueOrEmpty(item?.runEndDate, true, "-"),
        valueOrEmpty(RUN_STATUS_FIELD[item?.runStatus], false, "-"),
        valueOrEmpty(item?.description, false, "-"),
        valueOrEmpty(item?.equipmentModel, false, "-"),
        valueOrEmpty(item?.equipmentDetails?.variant, false, "-"),
        valueOrEmpty(item?.equipmentId, false, "-"),
        valueOrEmpty(item?.serialNumber, false, "-"),
        valueOrEmpty(item.runIdentification, false, "-"),
        valueOrEmpty(item?.gxpReady.value, false, "-"),
        valueOrEmpty(item?.systemStatus.value, false, "-"),
        valueOrEmpty(item?.approverUserName, false, "-"),
        "-"
      ];
    }
  });
  let hierarchyRecords = null;
  if (
    logBookEquipment?.entryType === entryType.cluster ||
    logBookEquipment?.entryType === entryType?.clusterSubequipment
  ) {
    const allLogItem = allLogHierarchyTable(allLogHierarchyData);
    hierarchyRecords = allLogItem.map((item) => {
      return [
        replaceEmptyWithHyphen(item?.clusterParent?.length),
        replaceEmptyWithHyphen(item?.equipmentModel),
        replaceEmptyWithHyphen(item?.location),
        replaceEmptyWithHyphen(item?.manufacturer),
        replaceEmptyWithHyphen(item?.equipmentId),
        replaceEmptyWithHyphen(item?.serialNumber),
        replaceEmptyWithHyphen(
          validateSelection(storeGxpReadys, {
            key: item?.qualificationStatus
          })
        ),
        replaceEmptyWithHyphen(item?.systemStatus),
        item?.gxpRelevant === "NOTAVAILABLE"
          ? "-"
          : replaceEmptyWithHyphen(item?.gxpRelevant),
        replaceEmptyWithHyphen(item?.softwareVersion)
      ];
    });
  }

  const allLogAttributesItem = allLogAttributesTable(logBookEquipment);
  const attributes = ReactDOMServer.renderToStaticMarkup(allLogAttributesItem);
  const equipmentEntryType =
    logBookEquipment?.entryType === entryType.cluster ||
    logBookEquipment?.entryType === entryType?.clusterSubequipment;

  return {
    headers: inventoryId
      ? RUN_LOGS_CENTRIC_TABLE_HEADERS
      : RUN_LOGS_TABLE_HEADERS,
    // hierarchy: hierarchy,
    entryType: equipmentEntryType,
    hierarchyHeaders: RUN_HIERARCHY_TABLE_HEADERS,
    hierarchyRecords: hierarchyRecords,
    attributes: attributes,
    records: records
  };
};
