import { Tooltip } from "@mui/material";
import { SHOW_CLUSTER_KEY } from "../../../constants";
import COMMON_LOGS_DATA_MODEL from "../../../utils/constants/commonLogsDataModel";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";

export const DescriptionCell = {
  label: COMMON_LOGS_DATA_MODEL.description.value,
  field: ["description"],
  props: {
    fullRow: true
  }
};

export const ApproverCell = {
  label: COMMON_LOGS_DATA_MODEL.approverUserName.value,
  field: ["approverUserName"],
  props: {
    fullRow: false
  }
};
export const OperatorCell = {
  label: COMMON_LOGS_DATA_MODEL.operatorUserName.value,
  field: ["operatorUserName"],
  props: {
    fullRow: false
  }
};

export const SubComponent = {
  label: COMMON_LOGS_DATA_MODEL.subEquipment.value,
  field: ["subEquipment"],
  component: ({ item }) => (
    <div id="sub-component-list">
      {item?.subEquipment
        ? item?.subEquipment.map((i, index) => {
            let addDivCollector = "";
            let equipmentCollector = "";
            addDivCollector += `<strong>Component ${++index}: </strong>`;

            SHOW_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
              addDivCollector += `${clusterItem?.label}:${
                i?.[clusterItem?.key] !== null &&
                i?.[clusterItem?.key] !== undefined &&
                i?.[clusterItem?.key] !== "null"
                  ? i?.[clusterItem?.key]
                  : " -"
              }${indexItem < SHOW_CLUSTER_KEY.length - 1 ? ", " : ""}`;

              equipmentCollector += `${clusterItem?.label}:${
                i?.[clusterItem?.key] !== null &&
                i?.[clusterItem?.key] !== undefined &&
                i?.[clusterItem?.key] !== "null"
                  ? i?.[clusterItem?.key]
                  : " -"
              }${indexItem < SHOW_CLUSTER_KEY.length - 1 ? ", " : ""}`;
            });

            return (
              <>
                <Tooltip id="sub-component-list" title={equipmentCollector}>
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap"
                    }}
                    dangerouslySetInnerHTML={{ __html: addDivCollector }}
                  ></div>
                </Tooltip>
              </>
            );
          })
        : "-"}
    </div>
  ),
  props: {
    fullRow: true
  }
};

export const ExternalDocument = {
  label: "Link list (Description and URL)",
  field: ["externalDocument"],
  props: {
    style: { width: "100%" },
    fullRow: true
  },
  component: ({ item }) => (
    <div id="sub-component-list">
      {item?.externalDocument && item?.externalDocument.length > 0
        ? item?.externalDocument?.map((i, index) => {
            return (
              <div
                style={{
                  borderBottom:
                    "1px solid var(--one-color-interaction-default-neutral-2)"
                }}
              >
                <p style={{ textWrap: "wrap" }}>{i?.name}</p>
                <p style={{ textWrap: "wrap" }}>{i?.link}</p>
              </div>
            );
          })
        : "-"}
    </div>
  )
};
export const runHeadingDetailCell = {
  label: LOGBOOK_LABEL.run_detail_heading,
  props: {
    fullRow: true
  }
};
export const actionHeadingDetailCell = {
  label: LOGBOOK_LABEL.action_detail_heading,
  props: {
    fullRow: true
  }
};
