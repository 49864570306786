import { Formik } from "formik";
import React, { useContext, useState } from "react";

import LoadItemForm from "./LoadItemForm";
import { ItemDialogFormContext } from "./item-context/context";
import ClusterItemForm from "./ClusterItemForm";
import ClusterItemFormDialogBody from "./ClusterItemFormDialogBody";
import FormSteps from "./FormSteps";
import {
  CLUSTER_STEPS,
  CLUSTER_STEPS_ACTIONS,
  formStates,
  formTypes
} from "../../../constants";
import { useEffect } from "react";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";
import { OwcWorkflowOverlay } from "@one/react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { LogBookSelectContext } from "../log-book-table/logBooksSelectAll/context";

const ClusterItemFormDialog = ({
  cleanItemFn,
  createItemChangeFn,
  validationSchemaFactory,
  emptyItem,
  dataTestId,
  type,
  addNewDialogButtonLabel,
  equipmentDetail
}) => {
  const { inventoryId } = useParams();
  const { activeStep, setActiveStep, handleClose } = useContext(
    ItemDialogFormContext
  );

  const {
    openWorkFlowModel,
    handleCloseWorkFlowModel,
    loadLogBookEquipment,
    formState,
    logType,
    loadFormState
  } = useContext(CoversheetMainPageContext);
  const { clearSelection } = useContext(LogBookSelectContext);
  const [cleanItem, setCleanItem] = useState(null);

  const [stepList, setStepList] = useState(
    type === formTypes?.RUN_LOG ? CLUSTER_STEPS : CLUSTER_STEPS_ACTIONS
  );
  const user = useSelector((state) => state.user);

  useEffect(() => {
    setCleanItem(() => {
      return cleanItemFn({
        item: equipmentDetail,
        isNew: formState !== formStates?.EDITABLE_FORM,
        user,
        formState
      });
    });

    // eslint-disable-next-line
  }, [logType, formState]);
  useEffect(() => {
    setStepList(
      type === formTypes?.RUN_LOG ? CLUSTER_STEPS : CLUSTER_STEPS_ACTIONS
    );
  }, [type]);

  const handleStepClick = (item) => {
    if (formState === formStates?.EDITABLE_FORM) {
      setActiveStep(item);
    }
  };

  const nextButtonHandle = () => {
    const newRowId = activeStep.id + 1;
    const activeRow = stepList.find((x) => x.id === newRowId);
    setActiveStep(activeRow);
  };
  const prevButtonHandle = () => {
    const newRowId = activeStep.id - 1;
    const activeRow = stepList.find((x) => x.id === newRowId);
    setActiveStep(activeRow);
  };

  const visibleChangeHandler = (event) => {
    /* Cancel popup implementation */
    if (event?.target?.["data-testid"] !== "confirm-dialog") {
      const activeRow = stepList.find((x) => x.id === 0);
      setActiveStep(activeRow);
      clearSelection();
      handleClose();
      if (!inventoryId) {
        loadLogBookEquipment(null);
      }
      loadFormState(null);
      handleCloseWorkFlowModel();
    }
  };
  return (
    <LoadItemForm>
      <Formik
        validationSchema={validationSchemaFactory}
        initialValues={
          cleanItem ? { ...emptyItem, ...cleanItem } : { ...emptyItem }
        }
        enableReinitialize={true}
        onSubmit={async () => {}}
      >
        {openWorkFlowModel && (
          <>
            <OwcWorkflowOverlay
              className="owcoverlayZIndex"
              visible={openWorkFlowModel}
              onVisibleChange={visibleChangeHandler}
              disableBackdropClick
            >
              <ClusterItemFormDialogBody
                dataTestId={dataTestId}
                type={type}
                addNewDialogButtonLabel={addNewDialogButtonLabel}
                stepList={stepList}
                setStepList={setStepList}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                prevButtonHandle={prevButtonHandle}
                nextButtonHandle={nextButtonHandle}
                createItemChangeFn={createItemChangeFn}
                equipmentDetail={equipmentDetail}
              >
                <FormSteps
                  stepsArray={stepList}
                  activeStep={activeStep}
                  handleClick={handleStepClick}
                  isEditMode={formState === formStates?.EDITABLE_FORM}
                  type={type}
                />
                <ClusterItemForm
                  type={type}
                  activeStep={activeStep}
                  dataTestId={dataTestId}
                  equipmentDetail={equipmentDetail}
                />
              </ClusterItemFormDialogBody>
            </OwcWorkflowOverlay>
          </>
        )}
      </Formik>
    </LoadItemForm>
  );
};

export default ClusterItemFormDialog;
