import { useEffect } from "react";
import { OwcHyperlink, OwcInput, OwcTypography } from "@one/react";
import styled from "styled-components";
import { useState } from "react";
import { validURL } from "../../utils/helpers/text";
const ActionLogLinkFormDivider = styled.div`
  margin-bottom: 20px;
`;
const ActionLogLink = ({
  actionLogLinkName,
  setActionLogLinkName,
  actionLogLinkUrl,
  setActionLogLinkUrl,
  actionLogLinkCurrentFlag,
  setAddLinkFlag
}) => {
  const [actionLogLinkIsEdited, setActionLogLinkIsEdited] = useState(false);
  const [actionLogLinkNameErrorFlag, setActionLogLinkNameErrorFlag] =
    useState(false);
  const [actionLogLinkUrlErrorFlag, setActionLogLinkUrlErrorFlag] =
    useState(false);
  const updateOnChangeDescription = (e) => {
    setActionLogLinkName(e.target.value);
    setActionLogLinkNameErrorFlag(
      !e.target.value || e.target.value.trim() === "" ? true : false
    );
    setActionLogLinkIsEdited(true);
  };

  const updateOnChangeUrl = (e) => {
    setActionLogLinkUrl(e.target.value);
    const errorFlag = validURL(e.target.value);
    setActionLogLinkUrlErrorFlag(!errorFlag ? true : false);
    setActionLogLinkIsEdited(true);
  };
  useEffect(() => {
    if (actionLogLinkName && validURL(actionLogLinkUrl)) {
      if (actionLogLinkCurrentFlag === "Add") {
        setAddLinkFlag(true);
      } else if (actionLogLinkIsEdited) {
        setAddLinkFlag(true);
      }
    } else if (actionLogLinkCurrentFlag === "Delete") {
      setAddLinkFlag(true);
    } else {
      setAddLinkFlag(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionLogLinkName, actionLogLinkUrl]);

  return actionLogLinkCurrentFlag === "Delete" ? (
    <div>
      <OwcTypography variant="subtitle2" style={{ marginTop: "20px" }}>
        Are you sure do you want to delete this link?
      </OwcTypography>
    </div>
  ) : (
    <div>
      <ActionLogLinkFormDivider style={{ mergin: "10px" }}>
        <OwcInput
          onBlur={updateOnChangeDescription}
          data-testid="action-log-link-name-input"
          variant="filled"
          id="ActionLogLinkName"
          name="ActionLogLinkName"
          label="Description *"
          required
          error={actionLogLinkNameErrorFlag}
          value={actionLogLinkName}
          onInputChange={updateOnChangeDescription}
          onInputKeyDown={updateOnChangeDescription}
          style={{ width: "100%" }}
        >
          {actionLogLinkName && (
            <div slot="suffix">
              <owc-icon
                name="circle_clear"
                variant="default"
                type="filled"
                onClick={() => {
                  setActionLogLinkName(null);
                }}
              ></owc-icon>
            </div>
          )}
          {actionLogLinkNameErrorFlag && (
            <span slot="error-text">Description empty</span>
          )}
        </OwcInput>
      </ActionLogLinkFormDivider>
      <OwcInput
        onBlur={updateOnChangeUrl}
        data-testid="action-log-link-url-input"
        variant="filled"
        id="ActionLogLinkUrl"
        name="ActionLogLinkUrl"
        label="URL *"
        required
        error={actionLogLinkUrlErrorFlag}
        type="url"
        value={actionLogLinkUrl}
        onInputChange={updateOnChangeUrl}
        onInputKeyDown={updateOnChangeUrl}
        style={{ width: "100%" }}
      >
        {actionLogLinkUrl && (
          <div slot="suffix">
            <owc-icon
              name="circle_clear"
              variant="default"
              type="filled"
              onClick={() => {
                setActionLogLinkUrl(null);
              }}
            ></owc-icon>
          </div>
        )}
        {actionLogLinkUrlErrorFlag && (
          <span slot="error-text">
            Invalid link, should be a valid URL, e.g.{" "}
            <OwcHyperlink
              href="#"
              prose
              rel="rel"
              target="target"
              style={{
                textDecoration: "none",
                color: "var(--one-color-interaction-default-brand-1)"
              }}
            >
              {"https://roche.com"}
            </OwcHyperlink>
          </span>
        )}
      </OwcInput>
    </div>
  );
};

export default ActionLogLink;
