import * as yup from "yup";
import { InstrumentDetailsValidationSechma } from "../../common-for-log-sheet-and-run-logs/ValidationCommonSchema";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { changeDateFormat } from "../../helpers";
const getCustomNullable = (schema, changeTo = "") =>
  schema.nullable().transform((value) => value ?? changeTo);
export const logSheetValidationSchemaFactory = () => {
  return yup.object().shape(
    {
      systemStatus: yup
        .object({
          key: yup
            .string("Enter System status")
            .typeError("Enter System status")
            .required("Enter System status"),
          value: yup
            .string("Enter System status")
            .typeError("Enter System status")
            .required("Enter System status")
        })
        .typeError("Enter System status")
        .required("Enter System status")
        .nullable(),
      actionDate: yup
        .date()
        .typeError("Enter action date")
        .required("Enter correct action date")
        .nullable(),
      actionTime: yup
        .string()
        .typeError("Enter action Time")
        .required("Enter correct action Time")
        .test(
          "maxtimeValidation",
          "Time should not be after maximal time",
          (value, obj) => {
            let actionDateObj = obj?.parent?.actionDate;
            let currentDateTime = new Date();
            if (typeof actionDateObj === "string") {
              actionDateObj = new Date(obj?.parent?.actionDate);
            }
            if (
              changeDateFormat(actionDateObj) ===
              changeDateFormat(currentDateTime)
            ) {
              let currentTime = `${currentDateTime?.getHours()}:${currentDateTime?.getMinutes()}:${currentDateTime?.getSeconds()}`;
              return value <= currentTime;
            }

            return true;
          }
        ),
      gxpReady: yup
        .object({
          key: yup
            .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`),
          value: yup
            .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        })
        .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        .nullable(),
      action: yup
        .object({
          key: yup
            .string("Enter action type")
            .typeError("Enter action type")
            .required("Enter action type"),
          value: yup
            .string("Enter action type")
            .typeError("Enter action type")
            .required("Enter action type")
        })
        .typeError("Enter action type")
        .required("Enter action type")
        .nullable(),
      externalDocument: yup
        .array(
          yup.object({
            link: yup.string("Enter valid URL").typeError("Enter valid URL"),
            name: yup.string("Enter Description").typeError("Enter Description")
          })
        )
        .nullable(),
      ecrNumber: yup
        .string("Enter ECR number")
        .typeError("Enter ECR number")
        .nullable(),
      logStatus: yup.string().typeError().nullable(),
      modelVersion: yup.string().typeError().nullable(),
      site: yup.string(),
      approverId: yup.string(),
      approverUserName: yup.string(),
      id: yup.string(),
      logSheetEntryId: yup.string(),
      materialNumber: yup.string().nullable(),
      description: getCustomNullable(
        yup
          .string("Enter description")
          .typeError("Enter description")
          .required("Enter description")
      ),
      operatorId: yup.string().nullable(),
      operatorUserName: yup
        .string("Enter operator user name")
        .typeError("Enter operator User Name")
        .nullable(),
      serialNumber: yup.string().when("equipmentId", {
        is: (equipmentId) => !equipmentId,
        then: yup
          .string("Enter serial number")
          .typeError("Enter serial number")
          .nullable()
          .required(""),
        otherwise: yup
          .string("Enter serial number")
          .typeError("Enter serial number")
          .nullable()
      }),
      equipmentId: yup.string().when("serialNumber", {
        is: (serialNumber) => !serialNumber,
        then: yup
          .string("Enter Equipment ID")
          .typeError("Enter Equipment ID")
          .nullable()
          .required(""),
        otherwise: yup
          .string("Enter Equipment ID")
          .typeError("Enter Equipment ID")
          .nullable()
      }),
      equipmentModel: yup.string(),
      equipmentNickName: yup.string(),
      inventoryId: yup.string().required(),
      updatedSoftwareVersion: yup.object({
        newValue: yup
          .string("Enter New software version")
          .typeError("EnterNew software version")
          .nullable(),
        shouldBePublished: yup
          .boolean("Enter publish to equipment repository")
          .typeError("Enter publish to equipment repository")
          .nullable()
      }),
      ...InstrumentDetailsValidationSechma
    },
    ["serialNumber", "equipmentId"]
  );
};

export const logSheetClusterValidationSchemaFactory = () => {
  return yup.object().shape(
    {
      systemStatusCluster: yup
        .object({
          key: yup
            .string("Enter System status")
            .typeError("Enter System status")
            .required("Enter System status"),
          value: yup
            .string("Enter System status")
            .typeError("Enter System status")
            .required("Enter System status")
        })
        .typeError("Enter System status")
        .required("Enter System status"),
      actionDate: yup
        .date()
        .typeError("Enter action date")
        .required("Enter correct action date"),
      actionTime: yup
        .string()
        .typeError("Enter action time")
        .required("Enter correct action time")
        .test(
          "maxtimeValidation",
          "Time should not be after maximal time",
          (value, obj) => {
            let actionDateObj = obj?.parent?.actionDate;
            let currentDateTime = new Date();
            if (typeof actionDateObj === "string") {
              actionDateObj = new Date(obj?.parent?.actionDate);
            }
            if (
              changeDateFormat(actionDateObj) ===
              changeDateFormat(currentDateTime)
            ) {
              let currentTime = `${currentDateTime?.getHours()}:${currentDateTime?.getMinutes()}:${currentDateTime?.getSeconds()}`;
              return value <= currentTime;
            }

            return true;
          }
        ),
      gxpReadyCluster: yup
        .object({
          key: yup
            .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`),
          value: yup
            .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        })
        .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        .required(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`),
      externalDocument: yup
        .array(
          yup.object({
            link: yup.string("Enter valid URL").typeError("Enter valid URL"),
            name: yup.string("Enter Description").typeError("Enter Description")
          })
        )
        .nullable(),
      action: yup
        .object({
          key: yup
            .string("Enter action type")
            .typeError("Enter action type")
            .required("Enter action type"),
          value: yup
            .string("Enter action type")
            .typeError("Enter action type")
            .required("Enter action type")
        })
        .typeError("Enter action type")
        .required("Enter action type"),
      ecrNumber: yup
        .string("Enter ECR number")
        .typeError("Enter ECR number")
        .nullable(),
      modelVersion: yup.string().typeError().nullable(),
      site: yup.string(),
      approverId: yup.string(),
      approverUserName: yup.string(),
      id: yup.string(),
      logSheetEntryId: yup.string(),
      materialNumber: yup.string().nullable(),
      description: yup
        .string("Enter description")
        .typeError("Enter description")
        .required("Enter description"),
      operatorId: yup.string().nullable(),
      operatorUserName: yup
        .string("Enter operator user name")
        .typeError("Enter operator User Name")
        .nullable(),
      serialNumber: yup.string().when("equipmentId", {
        is: (equipmentId) => !equipmentId,
        then: yup
          .string("Enter serial number")
          .typeError("Enter serial number")
          .nullable(),
        otherwise: yup
          .string("Enter serial number")
          .typeError("Enter serial number")
          .nullable()
      }),
      equipmentId: yup.string().when("serialNumber", {
        is: (serialNumber) => !serialNumber,
        then: yup
          .string("Enter Equipment ID")
          .typeError("Enter Equipment ID")
          .nullable(),
        otherwise: yup
          .string("Enter Equipment ID")
          .typeError("Enter Equipment ID")
          .nullable()
      }),
      equipmentModel: yup.string(),
      inventoryId: yup.string().required(),
      isClusterQualificationChanged: yup
        .boolean("Enter publish to cluster qualification change")
        .typeError("Enter publish to cluster qualification change")
        .nullable(),
      updatedSoftwareVersion: yup.object({
        newValue: yup
          .string("Enter New software version")
          .typeError("EnterNew software version")
          .nullable(),
        shouldBePublished: yup
          .boolean("Enter publish to equipment repository")
          .typeError("Enter publish to equipment repository")
          .nullable()
      }),
      subEquipment: yup.array(),
      ...InstrumentDetailsValidationSechma
    },
    ["serialNumber", "equipmentId"]
  );
};
