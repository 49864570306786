import EquipmentAction from "./EquipmentAction";
import ItemWrapper from "../log-book/log-book-item-form-dialog/item-context/ItemWrapper";
import PaginationWrapper from "../../components/shared/pagination/PaginationWrapper";
import ItemCoversheetWrapper from "../log-book/log-book-item-cover-sheet-dialog/coversheet-context/ItemCoversheetWrapper";
import LogbookSelectedWrapper from "../log-book/log-book-table/logBooksSelectAll/LogBookSelectedWrapper";

const EquipmentActionPage = ({
  setOpenDialog,
  openDialog,
  logType,
  equipmentDetail
}) => {
  return (
    <>
      <PaginationWrapper>
        <ItemCoversheetWrapper>
          <LogbookSelectedWrapper equipmentDetail={equipmentDetail}>
            <ItemWrapper
              entryDataType={equipmentDetail?.entryType}
              setOpenDialog={setOpenDialog}
              openDialog={openDialog}
            >
              <EquipmentAction
                type={logType}
                equipmentDetail={equipmentDetail}
              />
            </ItemWrapper>
          </LogbookSelectedWrapper>
        </ItemCoversheetWrapper>
      </PaginationWrapper>
    </>
  );
};

export default EquipmentActionPage;
