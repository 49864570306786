import React from "react";
import { COVER_SHEET_LIST, entryType } from "../../constants";
import {
  replaceEmptyWithHyphen,
  validateSelection
} from "../../utils/helpers/text";
import { CoverSheetNoDataConatiner } from "./CoverSheetMainPageStyle";
import { FullScreenCentered } from "../log-book/log-book-item-form-dialog/LoadItemForm";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { utcStringToLocaleString } from "../log-book/helpers";
import { OwcTypography } from "@one/react";
import { CircularProgress, Grid } from "@mui/material";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import EquipmentStatusRender from "../log-book/log-book-table/EquipmentStatusRender";

const DescriptionDetailView = ({
  equipmentDetail,
  loading,
  snapShotView,
  initialCoverSheetEquipment
}) => {
  const signees = useSelector((state) => state.equipmentModels.signee);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  const userObj = find(signees, {
    user: initialCoverSheetEquipment?.createdBy
  });
  if (loading)
    return (
      <FullScreenCentered data-testid="loading">
        <CircularProgress size={40} />
      </FullScreenCentered>
    );

  if (!equipmentDetail) {
    const equipmentTypeName =
      initialCoverSheetEquipment?.entryType === entryType.cluster
        ? "Cluster"
        : "Equipment";

    return (
      <CoverSheetNoDataConatiner data-testid="no-coversheet-data">
        <OwcTypography variant="body1">
          No data for this time stamp. {equipmentTypeName} was created in the
          repository on{" "}
          {utcStringToLocaleString(initialCoverSheetEquipment?.createdAt, 24)}{" "}
          by user{" "}
          {userObj
            ? userObj?.name
            : initialCoverSheetEquipment?.createdBy || "-"}
        </OwcTypography>
      </CoverSheetNoDataConatiner>
    );
  }
  return (
    <div
      style={{
        background: snapShotView
          ? "var(--one-color-background-neutral-2)"
          : "var(--one-color-foreground-neutral-2)",
        padding: "0px 10px 10px 30px"
      }}
    >
      <Grid container spacing={1} data-testid="coversheet-data">
        {equipmentDetail &&
          Object.keys(COVER_SHEET_LIST).map((x) => (
            <Grid item xs={2}>
              {x === COVER_SHEET_LIST.currentGxPStatusFromLogs.key ||
              x === COVER_SHEET_LIST.currentSystemStatusFromLogs.key ? (
                <>
                  <OwcTypography variant="body2">
                    {COVER_SHEET_LIST[x].value}
                  </OwcTypography>
                  <OwcTypography variant="subtitle2">
                    {x === COVER_SHEET_LIST.currentGxPStatusFromLogs.key
                      ? replaceEmptyWithHyphen(
                          snapShotView
                            ? equipmentDetail?.addInfo[x]
                            : validateSelection(gxpReadys, {
                                key: equipmentDetail[
                                  DATA_MODEL_TABLE?.qualificationStatus?.key
                                ]
                              })
                        )
                      : replaceEmptyWithHyphen(
                          snapShotView
                            ? equipmentDetail?.addInfo[x]
                            : equipmentDetail[
                                DATA_MODEL_TABLE?.systemStatus?.key
                              ]
                        )}
                  </OwcTypography>
                </>
              ) : (
                <>
                  <OwcTypography variant="body2">
                    {COVER_SHEET_LIST[x].value}
                  </OwcTypography>
                  <OwcTypography variant="subtitle2">
                    {x === COVER_SHEET_LIST.status.key ? (
                      <EquipmentStatusRender value={equipmentDetail[x]} />
                    ) : (
                      replaceEmptyWithHyphen(equipmentDetail[x])
                    )}
                  </OwcTypography>
                </>
              )}
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default DescriptionDetailView;
