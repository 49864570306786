import { DEFAULT_SITE } from "../../../../constants";

export const runLogsClearSuggestion = ({
  loadSuggestion = null,
  formik,
  user,
  equipmentDetail
}) => {
  if (loadSuggestion) {
    loadSuggestion(null);
  }
  formik.setValues({
    ...formik.values,
    site: user.site ?? DEFAULT_SITE,
    approverId: user.user,
    approverUserName: user.name,
    serialNumber:
      formik.values.serialNumber ||
      (equipmentDetail ? equipmentDetail?.serialNumber : null),
    equipmentId:
      formik.values.equipmentId ||
      (equipmentDetail ? equipmentDetail?.equipmentId : null),
    equipmentModel: equipmentDetail ? equipmentDetail?.equipmentModel : "",
    equipmentNickName: equipmentDetail
      ? equipmentDetail?.equipmentNickName ??
        equipmentDetail?.equipmentDetails?.equipmentNickName ??
        ""
      : "",
    inventoryId: equipmentDetail ? equipmentDetail?.inventoryId : "",
    instrumentDetails: {
      configurationBaseline: equipmentDetail
        ? equipmentDetail?.configurationBaseline || null
        : null,
      instrumentGxPStatus: equipmentDetail
        ? equipmentDetail?.instrumentGxPStatus || null
        : null,
      equipmentNickName: equipmentDetail
        ? equipmentDetail?.equipmentNickName || null
        : null,
      responsiblePerson: equipmentDetail
        ? equipmentDetail?.responsiblePerson || null
        : null,
      manufacturer: equipmentDetail
        ? equipmentDetail?.manufacturer || null
        : null,
      softwareVersion: equipmentDetail
        ? equipmentDetail?.softwareVersion || null
        : null,
      location: equipmentDetail ? equipmentDetail?.location || null : null,
      responsibleProxy: equipmentDetail
        ? equipmentDetail?.responsibleProxy || null
        : null
    }
  });
};
