import * as yup from "yup";
import { InstrumentDetailsValidationSechma } from "../../common-for-log-sheet-and-run-logs/ValidationCommonSchema";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { changeTimeFormat } from "../../helpers";
const getCustomNullable = (schema, changeTo = "") =>
  schema.nullable().transform((value) => value ?? changeTo);

export const runLogValidationSchemaFactory = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return yup.object().shape(
    {
      equipmentId: yup.string().when("serialNumber", {
        is: (serialNumber) => !serialNumber,
        then: yup
          .string("Enter Equipment ID")
          .typeError("Enter Equipment ID")
          .nullable()
          .required(""),
        otherwise: yup
          .string("Enter Equipment ID")
          .typeError("Enter Equipment ID")
          .nullable()
      }),
      serialNumber: yup.string().when("equipmentId", {
        is: (equipmentId) => !equipmentId,
        then: yup
          .string("Enter serial number")
          .typeError("Enter serial number")
          .nullable()
          .required(""),
        otherwise: yup
          .string("Enter serial number")
          .typeError("Enter serial number")
          .nullable()
      }),
      runStartDate: yup
        .date()
        .typeError("Enter run start date")
        .required("Enter correct run start date")
        .nullable(),
      runStartTime: yup
        .string("Enter run start time (with am/pm)")
        .typeError("Enter run start time (with am/pm)")
        .nullable()
        .test(
          "startTimeValidation",
          "start time should not be empty when selecting",
          (value, obj) => {
            if (value === null && obj.from[0]?.value?.runEndTime !== null) {
              return obj.createError({
                message: "Run start time is required"
              });
            } else {
              return true;
            }
          }
        ),
      runEndDate: yup
        .date()
        .test(
          "dateValidation",
          "End date cannot be before start date",
          (value, obj) => {
            const validStartDateObj = !obj.from[0]?.value?.runStartDate
              ? new Date()
              : obj.from[0]?.value?.runStartDate;
            const startDateObj =
              typeof validStartDateObj?.getMonth === "function"
                ? validStartDateObj
                : new Date(validStartDateObj);
            const endDateObj = !value ? new Date() : new Date(value);
            const startDateStr = `${startDateObj.getDate()}-${startDateObj.getMonth()}-${startDateObj.getFullYear()}`;
            const endDateStr = `${endDateObj.getDate()}-${endDateObj.getMonth()}-${endDateObj.getFullYear()}`;
            if (startDateStr === endDateStr) {
              return true;
            } else if (
              !validStartDateObj ||
              (validStartDateObj instanceof Date && isNaN(validStartDateObj))
            ) {
              return true;
            } else if (+startDateObj === +endDateObj) {
              return true;
            } else if (startDateObj > endDateObj) {
              return obj.createError({
                message: "End date cannot be before start date"
              });
            } else {
              return true;
            }
          }
        )
        .typeError("Enter run end date")
        .required("Enter correct run end date")
        .nullable(),
      runEndTime: yup
        .string("Enter run end time (with am/pm)")
        .typeError("Enter run end time (with am/pm)")
        .nullable()
        .test(
          "mintimeValidation",
          "End time is less than Start time",
          (value, obj) => {
            if (obj.from[0]?.value?.runStartTime !== null && value !== null) {
              let runStartDateNow = "";
              let runEndDateNow = "";
              if (typeof obj.from[0]?.value?.runStartDate === "string") {
                runStartDateNow = new Date(obj.from[0]?.value?.runStartDate);
              } else {
                runStartDateNow =
                  obj.from[0]?.value?.runStartDate || new Date();
              }
              if (typeof obj.from[0]?.value?.runEndDate === "string") {
                runEndDateNow = new Date(obj.from[0]?.value?.runEndDate);
              } else {
                runEndDateNow = obj.from[0]?.value?.runEndDate || new Date();
              }
              runStartDateNow.setHours(0, 0, 0, 0);
              runEndDateNow.setHours(0, 0, 0, 0);
              const runStartTimehms =
                obj.from[0]?.value?.runStartTime ||
                changeTimeFormat(new Date());
              const runStartTimesplitTime = runStartTimehms.split(":");
              const runStartTimeseconds =
                +runStartTimesplitTime[0] * 60 * 60 +
                +runStartTimesplitTime[1] * 60 +
                +runStartTimesplitTime[2];
              runStartDateNow.setSeconds(runStartTimeseconds);
              const runEndTimehms = value || changeTimeFormat(new Date());
              const runEndTimesplitTime = runEndTimehms.split(":");
              const runEndTimeseconds =
                +runEndTimesplitTime[0] * 60 * 60 +
                +runEndTimesplitTime[1] * 60 +
                +runEndTimesplitTime[2];
              runEndDateNow.setSeconds(runEndTimeseconds);
              if (runStartDateNow > runEndDateNow) {
                return false;
              } else {
                return true;
              }
            } else if (
              obj.from[0]?.value?.runStartTime !== null &&
              value === null
            ) {
              return obj.createError({
                message: "Run end time is required"
              });
            } else {
              return true;
            }
          }
        ),
      runStatus: getCustomNullable(
        yup
          .string("Enter run status")
          .typeError("Enter run status")
          .required("Enter run status")
      ),
      runIdentification: yup
        .string("Enter run id")
        .typeError("Enter run Id")
        .nullable(),
      samplesProcessed: yup
        .number("Enter number of samples")
        .typeError("Enter number of samples")
        .nullable(),
      numberOfRuns: yup
        .number("Enter number of runs")
        .typeError("Enter number of runs")
        .nullable(),
      description: getCustomNullable(
        yup
          .string("Enter description")
          .typeError("Enter description")
          .required("Enter description")
      ),
      defectId: yup
        .string("Enter defect Id")
        .typeError("Enter defect Id")
        .nullable(),
      eLNid: yup.string("Enter Eln ID").typeError("Enter Eln ID").nullable(),
      eLNGUID: yup
        .string("Enter eLNGUID ID")
        .typeError("Enter eLNGUID ID")
        .nullable(),
      mediaType: yup
        .array(
          yup.object({
            key: yup.string("Enter media Type").typeError("Enter media Type"),
            value: yup.string("Enter media Type").typeError("Enter media Type")
          })
        )
        .max(3, "You can add/select max of 3")
        .nullable(),
      tipsUsed: yup
        .object({
          key: yup.string("Enter tips Used").typeError("Enter tips Used"),
          value: yup.string("Enter tips Used").typeError("Enter tips Used")
        })
        .nullable(),

      operatorId: yup.string().nullable(),
      operatorUserName: yup
        .string("Enter operator user name")
        .typeError("Enter operator User Name")
        .nullable(),
      assay: yup
        .array(yup.string("Enter assay").typeError("Enter assay"))
        .nullable(),
      site: yup.string(),
      approverId: yup.string(),
      approverUserName: yup.string(),
      id: yup.string(),
      runLogEntryId: yup.string(),
      systemStatus: yup
        .object({
          key: yup
            .string("Enter system Status")
            .typeError("Enter system Status"),
          value: yup
            .string("Enter system Status")
            .typeError("Enter system Status")
        })
        .typeError("Enter system Status")
        .nullable(),
      gxpReady: yup
        .object({
          key: yup
            .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`),
          value: yup
            .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        })
        .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        .nullable(),
      equipmentModel: yup.string(),
      equipmentNickName: yup.string(),
      inventoryId: yup.string().required(),
      materialNumber: yup.string().nullable(),
      ...InstrumentDetailsValidationSechma
    },
    ["serialNumber", "equipmentId"]
  );
};

export const runLogClusterValidationSchemaFactory = () => {
  const now = new Date();
  now.setHours(0, 0, 0, 0);
  return yup.object().shape(
    {
      equipmentId: yup.string().when("serialNumber", {
        is: (serialNumber) => !serialNumber,
        then: yup
          .string("Enter Equipment ID")
          .typeError("Enter Equipment ID")
          .nullable(),
        otherwise: yup
          .string("Enter Equipment ID")
          .typeError("Enter Equipment ID")
          .nullable()
      }),
      serialNumber: yup.string().when("equipmentId", {
        is: (equipmentId) => !equipmentId,
        then: yup
          .string("Enter serial number")
          .typeError("Enter serial number")
          .nullable(),
        otherwise: yup
          .string("Enter serial number")
          .typeError("Enter serial number")
          .nullable()
      }),
      runStartDate: yup
        .date()
        .typeError("Enter run start date")
        .required("Enter correct run start date")
        .nullable(),
      runStartTime: yup
        .string("Enter run start time (with am/pm)")
        .typeError("Enter run start time (with am/pm)")
        .nullable()
        .test(
          "startTimeValidation",
          "start time should not be empty when selecting",
          (value, obj) => {
            if (value === null && obj.from[0]?.value?.runEndTime !== null) {
              return obj.createError({
                message: "Run start time is required"
              });
            } else {
              return true;
            }
          }
        ),
      runEndDate: yup
        .date()
        .test(
          "dateValidation",
          "End date cannot be before start date",
          (value, obj) => {
            const validStartDateObj = !obj.from[0]?.value?.runStartDate
              ? new Date()
              : obj.from[0]?.value?.runStartDate;
            const startDateObj =
              typeof validStartDateObj?.getMonth === "function"
                ? validStartDateObj
                : new Date(validStartDateObj);
            const endDateObj = !value ? new Date() : new Date(value);
            const startDateStr = `${startDateObj.getDate()}-${startDateObj.getMonth()}-${startDateObj.getFullYear()}`;
            const endDateStr = `${endDateObj.getDate()}-${endDateObj.getMonth()}-${endDateObj.getFullYear()}`;
            if (startDateStr === endDateStr) {
              return true;
            } else if (
              !validStartDateObj ||
              (validStartDateObj instanceof Date && isNaN(validStartDateObj))
            ) {
              return true;
            } else if (+startDateObj === +endDateObj) {
              return true;
            } else if (startDateObj > endDateObj) {
              return obj.createError({
                message: "End date cannot be before start date"
              });
            } else {
              return true;
            }
          }
        )
        .typeError("Enter run end date")
        .required("Enter correct run end date")
        .nullable(),
      runEndTime: yup
        .string("Enter run end time (with am/pm)")
        .typeError("Enter run end time (with am/pm)")
        .nullable()
        .test(
          "mintimeValidation",
          "End time is less than Start time",
          (value, obj) => {
            if (obj.from[0]?.value?.runStartTime !== null && value !== null) {
              let runStartDateNow = "";
              let runEndDateNow = "";
              if (typeof obj.from[0]?.value?.runStartDate === "string") {
                runStartDateNow = new Date(obj.from[0]?.value?.runStartDate);
              } else {
                runStartDateNow =
                  obj.from[0]?.value?.runStartDate || new Date();
              }
              if (typeof obj.from[0]?.value?.runEndDate === "string") {
                runEndDateNow = new Date(obj.from[0]?.value?.runEndDate);
              } else {
                runEndDateNow = obj.from[0]?.value?.runEndDate || new Date();
              }
              runStartDateNow.setHours(0, 0, 0, 0);
              runEndDateNow.setHours(0, 0, 0, 0);
              const runStartTimehms =
                obj.from[0]?.value?.runStartTime ||
                changeTimeFormat(new Date());
              const runStartTimesplitTime = runStartTimehms.split(":");
              const runStartTimeseconds =
                +runStartTimesplitTime[0] * 60 * 60 +
                +runStartTimesplitTime[1] * 60 +
                +runStartTimesplitTime[2];
              runStartDateNow.setSeconds(runStartTimeseconds);
              const runEndTimehms = value || changeTimeFormat(new Date());
              const runEndTimesplitTime = runEndTimehms.split(":");
              const runEndTimeseconds =
                +runEndTimesplitTime[0] * 60 * 60 +
                +runEndTimesplitTime[1] * 60 +
                +runEndTimesplitTime[2];
              runEndDateNow.setSeconds(runEndTimeseconds);
              if (runStartDateNow > runEndDateNow) {
                return false;
              } else {
                return true;
              }
            } else if (
              obj.from[0]?.value?.runStartTime !== null &&
              value === null
            ) {
              return obj.createError({
                message: "Run end time is required"
              });
            } else {
              return true;
            }
          }
        ),
      runStatus: getCustomNullable(
        yup
          .string("Enter run status")
          .typeError("Enter run status")
          .required("Enter run status")
      ),
      runIdentification: yup
        .string("Enter run id")
        .typeError("Enter run Id")
        .nullable(),
      samplesProcessed: yup
        .number("Enter number of samples")
        .typeError("Enter number of samples")
        .nullable(),
      numberOfRuns: yup
        .number("Enter number of runs")
        .typeError("Enter number of runs")
        .nullable(),
      description: getCustomNullable(
        yup
          .string("Enter description")
          .typeError("Enter description")
          .required("Enter description")
      ),
      defectId: yup
        .string("Enter defect Id")
        .typeError("Enter defect Id")
        .nullable(),
      eLNid: yup.string("Enter Eln ID").typeError("Enter Eln ID").nullable(),
      eLNGUID: yup
        .string("Enter eLNGUID ID")
        .typeError("Enter eLNGUID ID")
        .nullable(),
      mediaType: yup
        .array(
          yup.object({
            key: yup.string("Enter media Type").typeError("Enter media Type"),
            value: yup.string("Enter media Type").typeError("Enter media Type")
          })
        )
        .max(3, "You can add/select max of 3")
        .nullable(),
      tipsUsed: yup
        .object({
          key: yup.string("Enter tips Used").typeError("Enter tips Used"),
          value: yup.string("Enter tips Used").typeError("Enter tips Used")
        })
        .nullable(),

      operatorId: yup.string().nullable(),
      operatorUserName: yup
        .string("Enter operator user name")
        .typeError("Enter operator User Name")
        .nullable(),
      assay: yup
        .array(yup.string("Enter assay").typeError("Enter assay"))
        .nullable(),
      site: yup.string(),
      approverId: yup.string(),
      approverUserName: yup.string(),
      id: yup.string(),
      runLogEntryId: yup.string(),
      systemStatusCluster: yup
        .object({
          key: yup
            .string("Enter system Status")
            .typeError("Enter system Status"),
          value: yup
            .string("Enter system Status")
            .typeError("Enter system Status")
        })
        .typeError("Enter system Status")
        .nullable(),
      gxpReadyCluster: yup
        .object({
          key: yup
            .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`),
          value: yup
            .string(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
            .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        })
        .typeError(`Enter ${DATA_MODEL_TABLE?.gxpReady?.value}`)
        .nullable(),
      equipmentModel: yup.string(),
      equipmentNickName: yup.string(),
      inventoryId: yup.string().required(),
      materialNumber: yup.string().nullable(),
      ...InstrumentDetailsValidationSechma
    },
    ["serialNumber", "equipmentId"]
  );
};
