import styled from "styled-components";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { OwcIcon, OwcTypography } from "@one/react";

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columnCount}, auto);
`;

const BaseCell = styled.div`
  font-size: 12px;
  line-height: 16px;
  padding: 12px;
  border-bottom: 1px solid #d3d3d3;
  display: flex;
  align-items: center;
`;

const Heading = styled(BaseCell)`
  color: #737373;
`;

const Cell = styled(BaseCell)`
  color: #333333;
  font-weight: 500;
  cursor: pointer;
  justify-content: space-between;
`;

const DefaultMeta = [
  {
    title: "Nickname",
    prop: "equipmentNickName",
    dataTestId: "equipment-nickname"
  },
  {
    title: "Model",
    prop: "equipmentModel",
    dataTestId: "equipment-model"
  },
  {
    title: "Material number",
    prop: "materialNumber",
    dataTestId: "material-number"
  }
];

const ItemFormSuggestionTable = ({
  onSelect,
  instrumentSuggestions,
  meta = DefaultMeta
}) => (
  <>
    <Table columnCount={meta.length} data-testid="suggestion-run-log-table">
      {meta.map((info) => (
        <Heading
          data-testid={`suggestion-run-log-table-heading-${info.dataTestId}`}
          key={`suggestion-run-log-heading-${info.prop}`}
        >
          {info.title}
        </Heading>
      ))}
      {instrumentSuggestions.map((instrument) => (
        <>
          {meta.map((info, index) => (
            <Cell
              data-testid={`suggestion-run-log-table-cell-${info.dataTestId}`}
              onClick={() => onSelect(instrument)}
              key={`suggestion-run-log-${instrument[info.prop]}`}
            >
              {instrument[info.prop] && instrument[info.prop] !== "null"
                ? instrument[info.prop]
                : "-"}
              {index + 1 === meta.length && (
                <KeyboardArrowRightIcon color="primary" />
              )}
            </Cell>
          ))}
        </>
      ))}
    </Table>
    {instrumentSuggestions.length >= 20 && (
      <div
        style={{
          display: "flex",
          boxSizing: "border-box",
          height: "40px",
          padding: "5px 10px",
          width: "100%",
          alignItems: "center"
        }}
      >
        <OwcIcon name="circle_info" type="legacy" />
        <OwcTypography variant="body1" style={{ paddingLeft: "5px" }}>
          Only the first 20 results are displayed. Please refine your search.
        </OwcTypography>
      </div>
    )}
  </>
);

export default ItemFormSuggestionTable;
