import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PageTabs from "./PageTabs";
import SearchFilterBar from "./SearchFilterBar";
import { useSelector } from "react-redux";
import moment from "moment";
import { OwcButton } from "@one/react";
import CustomFilterWrapper, {
  initialState
} from "../custom-filter/CustomFilterWrapper";
import SearchTextWrapper from ".././log-book-item-fillter/suggester/search-context/SearchTextWrapper";
import PaginationWrapper from "../../../components/shared/pagination/PaginationWrapper";
import RunLogsPage from ".././log-book-pages/RunLogsPage";
import LogSheetPage from ".././log-book-pages/LogSheetPage";
import ChangeLogSheetPage from ".././log-book-pages/ChangeLogSheetPage";
import { CoversheetMainPageContext } from "../../cover-sheet/coversheet-main-page-context/context";
import {
  entryType,
  equipmentStatus,
  formStates,
  formTypes
} from "../../../constants";
import LogbookSelectedWrapper from "../log-book-table/logBooksSelectAll/LogBookSelectedWrapper";
import AllLogsPage from ".././log-book-pages/AllLogsPage";
const MainContainer = styled.div``;

const LogBooksMainPage = () => {
  const { inventoryId } = useParams();
  const storeUser = useSelector((store) => store.user);
  const {
    tabValue,
    handleOpenWorkFlowModel,
    logBookEquipment,
    loadLogBookEquipment,
    logType,
    loadFormState
  } = useContext(CoversheetMainPageContext);
  const canEdit = useSelector((store) => store.user.canEdit);
  const initialRunLog = {
    site: storeUser?.site,
    dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD"),
    inputsState: {
      ...initialState.inputsState,
      site: storeUser?.site,
      dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
      dateTo: moment(new Date()).format("YYYY-MM-DD")
    }
  };
  const initialActionLog = {
    site: storeUser?.site,
    dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
    dateTo: moment(new Date()).format("YYYY-MM-DD"),
    inputsState: {
      ...initialState.inputsState,
      site: storeUser?.site,
      dateFrom: moment(new Date()).subtract(7, "days").format("YYYY-MM-DD"),
      dateTo: moment(new Date()).format("YYYY-MM-DD")
    }
  };
  return (
    <>
      <MainContainer
        data-testid="log-book-main-page"
        style={{
          width: inventoryId === null ? "100%" : ""
        }}
      >
        <CustomFilterWrapper
          initialValues={
            logType !== formTypes?.ACTION_LOG ? initialRunLog : initialActionLog
          }
        >
          <PaginationWrapper>
            <SearchTextWrapper>
              <SearchFilterBar
                value={logType}
                equipmentDetail={logBookEquipment}
              />
              <PageTabs value={logType} />
              {logType === formTypes?.RUN_LOG && (
                <LogbookSelectedWrapper equipmentDetail={logBookEquipment}>
                  <RunLogsPage equipmentDetail={logBookEquipment} />
                </LogbookSelectedWrapper>
              )}
              {logType === formTypes?.ACTION_LOG && (
                <LogbookSelectedWrapper equipmentDetail={logBookEquipment}>
                  <LogSheetPage equipmentDetail={logBookEquipment} />
                </LogbookSelectedWrapper>
              )}
              {logType === formTypes?.CHANGE_LOG && (
                <ChangeLogSheetPage equipmentDetail={logBookEquipment} />
              )}
              {logType === formTypes?.ALL_LOG && (
                <LogbookSelectedWrapper equipmentDetail={logBookEquipment}>
                  <AllLogsPage equipmentDetail={logBookEquipment} />
                </LogbookSelectedWrapper>
              )}
            </SearchTextWrapper>
          </PaginationWrapper>
        </CustomFilterWrapper>
      </MainContainer>
      {tabValue !== "change" &&
        tabValue !== "All logs" &&
        logBookEquipment?.status !== equipmentStatus?.deleted?.key &&
        canEdit && (
          <div
            style={{
              marginTop: "15px",
              marginRight: "30px",
              float: "right"
            }}
          >
            <OwcButton
              variant="secondary"
              onClick={() => {
                if (!inventoryId) {
                  loadLogBookEquipment(null);
                }
                loadFormState(null);
                handleOpenWorkFlowModel({
                  isCluster:
                    inventoryId &&
                    logBookEquipment?.entryType === entryType?.cluster,
                  form: inventoryId
                    ? formStates?.PARTIAL_NEW_FORM
                    : formStates?.NEW_FORM
                });
              }}
              disabled={logBookEquipment?.clusterId}
            >
              {tabValue}
            </OwcButton>
          </div>
        )}
    </>
  );
};

export default LogBooksMainPage;
