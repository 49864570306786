import React, { useContext, useEffect, useState } from "react";
import { CLUSTER_COVER_SHEET_LIST, COVER_SHEET_LIST } from "../../../constants";
import {
  replaceEmptyWithHyphen,
  getLocationDetails,
  subEquipmentSorting,
  subEquipmentsortedList,
  validateSelection
} from "../../../utils/helpers/text";
import { CoverSheetNoDataConatiner } from "../CoverSheetMainPageStyle";
import { generateID } from "@digitallab/grid-common-components";
import LOGBOOK_LABEL from "../../../utils/constants/logbookLabel";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { Module } from "../../../constants";
import ClusterGridTable from "../../../components/shared/ClusterAgGridTable";
import { entryType, SHOW_HIERARCHY } from "../../../constants";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import ListAction from "./ListAction.jsx";
import SnapshotAction from "../SnapshotAction";
import { FullScreenCentered } from "../../log-book/log-book-item-form-dialog/LoadItemForm";
import moment from "moment";
import { OwcIcon, OwcTab, OwcTabs, OwcTypography } from "@one/react";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { getEquipDetailInfo } from "../../log-book/LoadLogBooksInfo";
import { CoversheetMainPageContext } from "../coversheet-main-page-context/context";
import EquipmentStatusRender from "../../log-book/log-book-table/EquipmentStatusRender.jsx";

export const DescriptionForm = styled.div`
  & > .button-box-equipment {
    padding: 6px 22px;
    font-size: 16px;
  }
`;

export const CountHighlight = styled.div`
  font-size: 12px;
  font-style: normal;
  line-height: 23px;
  letter-spacing: 0px;
  background-color: ${(props) =>
    props.theme.notification.success.backgroundIconColor};
  color: ${(props) => props.theme.notification.success.backgroundColor};
  border-radius: 50%;
  width: 22px;
  display: flex;
  justify-content: center;
  margin-left: 10px;
`;

export const EquipAddedStyle = styled.div`
  display: flex;
  align-items: center;
`;
export const ActionsCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
`;

export const LocationCell = ({ item }) => {
  return <span data-testid="location-cell">{getLocationDetails(item)}</span>;
};

export const ClusterActions = ({
  client,
  item,
  triggerClusterSetCollapse = null,
  viewExpander = true,
  clusterEquipmentInfoAction
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div style={{ display: "flex" }}>
      {viewExpander && (
        <Tooltip
          data-testid="cluster-expand-button-tooltip"
          title={SHOW_HIERARCHY}
          placement="left"
          arrow
        >
          <IconButton
            aria-label="expand row"
            onClick={() => {
              setOpen(!open);
              if (triggerClusterSetCollapse) {
                triggerClusterSetCollapse(!open);
              }
            }}
          >
            {open ? (
              <OwcIcon name="caret_up" type="legacy" />
            ) : (
              <OwcIcon name="caret_down" type="legacy" />
            )}
          </IconButton>
        </Tooltip>
      )}
      <OwcIcon
        id={`cluster-identification-icon-${item?.inventoryId}`}
        className="one-icons"
        style={{
          marginTop: 10
        }}
        name="rack"
        type="legacy"
      />
    </div>
  );
};

const ClusterDescriptionDetailView = ({
  inventoryId,
  equipmentDetail,
  getSubEquipmentsDetailInfo,
  getRepoClusterDetailsById,
  showSnapShot,
  clearSnapShot,
  exportSnapShotPDF,
  loading,
  snapShotView,
  snapShotDate,
  setSnapShotDate,
  snapShotTime,
  setSnapShotTime,
  showSnapShotClk,
  setShowSnapShotClk,
  getSnapShotDetails,
  client
}) => {
  const [value, setValue] = useState("attribute");
  const [newEquipmentDetails, setNewEquipmentDetails] = useState(null);
  const { loadAllLogHierarchyData } = useContext(CoversheetMainPageContext);
  const gxpReadys = useSelector((store) => store.runLogsForm.gxpReadys);
  useEffect(() => {
    const getClusterEquipment = async () => {
      let clusterEquipmentDetail = await getEquipDetailInfo(
        equipmentDetail?.entryType === entryType?.clusterSubequipment
          ? equipmentDetail?.clusterId
          : equipmentDetail?.inventoryId,
        client
      );

      if (clusterEquipmentDetail?.clusterId) {
        clusterEquipmentDetail = await getEquipDetailInfo(
          clusterEquipmentDetail?.clusterId,
          client
        );
      }

      const clusterSubEquipmentDetail = await getSubEquipmentsDetailInfo(
        clusterEquipmentDetail?.inventoryId
      );
      let subEquipmentMapping = await getRepoClusterDetailsById(
        clusterEquipmentDetail?.inventoryId
      );
      subEquipmentMapping = subEquipmentSorting(subEquipmentMapping);
      subEquipmentMapping = subEquipmentsortedList(
        subEquipmentMapping,
        clusterSubEquipmentDetail
      );
      const subClusterDetails = await subClusterMapping(subEquipmentMapping);
      const obj = {
        ...clusterEquipmentDetail,
        subEquipment: subClusterDetails
      };
      setNewEquipmentDetails([obj]);
      loadAllLogHierarchyData([obj]);
    };

    const subClusterMapping = async (subEquipmentMapping) => {
      const subClusters = [];
      for (const details of subEquipmentMapping) {
        if (details?.entryType === entryType?.cluster) {
          const clusterEquipmentDetail = await getEquipDetailInfo(
            details?.entryType === entryType?.clusterSubequipment
              ? details?.clusterId
              : details?.id || details?.inventoryId,
            client
          );
          const clusterSubEquipmentDetail = await getSubEquipmentsDetailInfo(
            clusterEquipmentDetail?.inventoryId
          );
          let subEqpmtMapping = await getRepoClusterDetailsById(
            clusterEquipmentDetail?.inventoryId
          );
          subEqpmtMapping = subEquipmentSorting(subEqpmtMapping);
          subEqpmtMapping = subEquipmentsortedList(
            subEqpmtMapping,
            clusterSubEquipmentDetail
          );
          const obj = {
            ...clusterEquipmentDetail,
            subEquipment: subEqpmtMapping
          };
          subClusters.push(obj);
        } else {
          subClusters.push(details);
        }
      }
      return subClusters;
    };

    const getSnapShotClusterEquipment = async () => {
      const invntryId =
        equipmentDetail?.entryType === entryType?.clusterSubequipment
          ? equipmentDetail?.clusterId
          : equipmentDetail?.inventoryId;
      let clusterSnapShotEquipmentDetail = await getSnapShotDetails({
        snapShotDate,
        snapShotTime,
        id: invntryId
      });
      if (clusterSnapShotEquipmentDetail?.clusterId) {
        clusterSnapShotEquipmentDetail = await getSnapShotDetails({
          snapShotDate,
          snapShotTime,
          id: clusterSnapShotEquipmentDetail?.clusterId
        });
      }
      if (clusterSnapShotEquipmentDetail.subEquipment) {
        clusterSnapShotEquipmentDetail.subEquipment = subEquipmentSorting(
          clusterSnapShotEquipmentDetail.subEquipment
        );
      }

      const clusterSnapShotSubEquipmentDetail =
        await getSubEquipmentsDetailInfo(
          clusterSnapShotEquipmentDetail?.inventoryId
        );
      let subEquipmentMapping = await getRepoClusterDetailsById(
        clusterSnapShotEquipmentDetail?.inventoryId
      );
      subEquipmentMapping = subEquipmentsortedList(
        clusterSnapShotEquipmentDetail.subEquipment,
        clusterSnapShotSubEquipmentDetail
      );
      const subClusterDetails = await subClusterMapping(subEquipmentMapping);
      const clusterSnapShotAllEquipmentDetail = {
        ...clusterSnapShotEquipmentDetail,
        subEquipment: subClusterDetails
      };
      setNewEquipmentDetails([clusterSnapShotAllEquipmentDetail]);
    };

    if (snapShotDate && snapShotTime) {
      getSnapShotClusterEquipment();
    } else {
      getClusterEquipment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [equipmentDetail]);

  let EquipmentMetaDisplay = {
    fields: {
      clusterIdentifier: {
        headProps: { style: { width: 100 } },
        cellProps: { style: { width: 100 } },
        text: "",
        sortable: false,
        component: ({
          item,
          triggerClusterSetCollapse,
          client,
          clusterEquipmentInfoAction
        }) => {
          switch (item.entryType) {
            case entryType?.cluster:
              return (
                <ClusterActions
                  client={client}
                  item={item}
                  triggerClusterSetCollapse={triggerClusterSetCollapse}
                  clusterEquipmentInfoAction={clusterEquipmentInfoAction}
                  viewExpander={true}
                />
              );
            default:
              return "";
          }
        }
      },
      equipmentModel: {
        text: DATA_MODEL_TABLE.equipmentModel.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        }
      },
      belongingToGroup: {
        text: DATA_MODEL_TABLE.belongingToGroup.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      manufacturer: {
        text: DATA_MODEL_TABLE.manufacturer.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      serialNumber: {
        text: DATA_MODEL_TABLE.serialNumber.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      equipmentId: {
        text: DATA_MODEL_TABLE.equipmentId.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      softwareVersion: {
        text: DATA_MODEL_TABLE.softwareVersion.value,
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      currentGxPStatusFromLogs: {
        text: DATA_MODEL_TABLE.currentGxPStatusFromLogs.value,
        component: ({ item }) => (
          <div>
            {replaceEmptyWithHyphen(
              validateSelection(gxpReadys, {
                key: item?.qualificationStatus
              })
            )}
          </div>
        ),
        sortable: false,
        headProps: {
          style: {
            display: "flex",
            alignItems: "center"
          }
        },
        cellProps: {
          style: {
            display: "flex",
            alignItems: "center",
            paddingRight: "10px"
          }
        }
      },
      actions: {
        text: "Actions",
        HeadCellComponent: ActionsCell,
        component: ({ item, triggerSetCollapse }) => (
          <ListAction item={item} triggerSetCollapse={triggerSetCollapse} />
        )
      }
    },
    rowKeyId: "id"
  };

  const handleChange = (e) => {
    setValue(e.detail);
  };

  if (!equipmentDetail) {
    return (
      <CoverSheetNoDataConatiner data-testid="coversheet-no-data">
        <OwcTypography variant="body1">No data</OwcTypography>
      </CoverSheetNoDataConatiner>
    );
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
          borderBottom: "1px solid rgb(211, 211, 211)",
          background: snapShotView
            ? "var(--one-color-background-neutral-2)"
            : "var(--one-color-foreground-neutral-2)"
        }}
      >
        <OwcTabs
          data-testid="page-tabs"
          value={value}
          onTabChange={handleChange}
          inventoryId={inventoryId}
        >
          <OwcTab
            data-testid="page-tab-run-logs"
            label={LOGBOOK_LABEL.CLUSTER_TABS.attribute}
            value="attribute"
            id={generateID.UUID(Module.CLUSTER_TAB.attribute, 1, "tab")}
          >
            {LOGBOOK_LABEL.CLUSTER_TABS.attribute}
          </OwcTab>

          <OwcTab
            data-testid="page-tab-log-sheet"
            label={LOGBOOK_LABEL.CLUSTER_TABS.hierarchy}
            value="hierarchy"
            id={generateID.UUID(Module.CLUSTER_TAB.hierarchy, 1, "tab")}
          >
            {LOGBOOK_LABEL.CLUSTER_TABS.hierarchy}
          </OwcTab>
        </OwcTabs>
        <SnapshotAction
          inventoryId={inventoryId}
          showSnapShot={showSnapShot}
          clearSnapShot={clearSnapShot}
          exportSnapShotPDF={exportSnapShotPDF}
          bgColor={
            snapShotView
              ? "var(--one-color-background-neutral-2)"
              : "var(--one-color-foreground-neutral-2)"
          }
          borderBottom={true}
          snapShotDate={snapShotDate}
          setSnapShotDate={setSnapShotDate}
          snapShotTime={snapShotTime}
          setSnapShotTime={setSnapShotTime}
          showSnapShotClk={showSnapShotClk}
          setShowSnapShotClk={setShowSnapShotClk}
          equipmentDetail={equipmentDetail}
        />
      </div>
      <div
        style={{
          background: snapShotView
            ? "var(--one-color-background-neutral-2)"
            : "var(--one-color-foreground-neutral-2)",
          padding: "0px 10px 10px 30px"
        }}
      >
        {loading && (
          <FullScreenCentered data-testid="loader">
            <CircularProgress size={40} />
          </FullScreenCentered>
        )}
        {!loading && value === "attribute" && (
          <>
            {snapShotDate && showSnapShotClk && (
              <OwcTypography>
                <b>Snapshot ({moment(snapShotDate).format("DD-MMM-yyyy")})</b>
              </OwcTypography>
            )}
            <Grid container spacing={1}>
              {equipmentDetail &&
                Object.keys(CLUSTER_COVER_SHEET_LIST).map((x) => (
                  <Grid item xs={2}>
                    {x ===
                      CLUSTER_COVER_SHEET_LIST.currentGxPStatusFromLogs.key ||
                    x ===
                      CLUSTER_COVER_SHEET_LIST.currentSystemStatusFromLogs
                        .key ? (
                      <>
                        <OwcTypography>
                          {CLUSTER_COVER_SHEET_LIST[x].value}
                        </OwcTypography>
                        <OwcTypography>
                          <strong>
                            {x ===
                            CLUSTER_COVER_SHEET_LIST.currentGxPStatusFromLogs
                              .key
                              ? replaceEmptyWithHyphen(
                                  snapShotView
                                    ? equipmentDetail?.addInfo[x]
                                    : validateSelection(gxpReadys, {
                                        key: equipmentDetail[
                                          DATA_MODEL_TABLE?.qualificationStatus
                                            ?.key
                                        ]
                                      })
                                )
                              : replaceEmptyWithHyphen(
                                  snapShotView
                                    ? equipmentDetail?.addInfo[x]
                                    : equipmentDetail[
                                        DATA_MODEL_TABLE?.systemStatus?.key
                                      ]
                                )}
                          </strong>
                        </OwcTypography>
                      </>
                    ) : (
                      <>
                        <OwcTypography>
                          {CLUSTER_COVER_SHEET_LIST[x].value}
                        </OwcTypography>
                        <OwcTypography>
                          <strong>
                            {x === COVER_SHEET_LIST.status.key ? (
                              <EquipmentStatusRender
                                value={equipmentDetail[x]}
                              />
                            ) : (
                              replaceEmptyWithHyphen(equipmentDetail[x])
                            )}
                          </strong>
                        </OwcTypography>
                      </>
                    )}
                  </Grid>
                ))}
            </Grid>
          </>
        )}
      </div>
      {value === "hierarchy" ? (
        <>
          {snapShotDate && showSnapShotClk && (
            <OwcTypography>
              <b
                style={{
                  padding: "28px"
                }}
              >
                Snapshot ({moment(snapShotDate).format("DD-MMM-yyyy")})
              </b>
            </OwcTypography>
          )}
          <ClusterGridTable
            data={newEquipmentDetails}
            meta={EquipmentMetaDisplay}
            snapShotDate={snapShotDate}
            snapShotTime={snapShotTime}
            gxpReadys={gxpReadys}
          />
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default compose(
  connect(null, {}),
  withApollo
)(ClusterDescriptionDetailView);
