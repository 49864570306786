import COMMON_LOGS_DATA_MODEL from "../src/utils/constants/commonLogsDataModel";
import ACTION_LOGS_DATA_MODEL from "./utils/constants/actionLogsDataModel";
import DATA_MODEL_TABLE from "./utils/constants/dataModelTable";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
export const LOGBOOK_USER = "LogbookUsers";
export const LOGBOOK_VIEWER = "LogbookViewer";
export const LOGBOOK_WRITER = "LogbookWriter";
export const LOGBOOK_ADMIN = "LogbookAdmin";
export const SHOW_HIERARCHY = "Show hierarchy";
export const MORE_DETAILS = "More details";
export const DEFAULT_PAGE_SIZE = 10;
export const REQUIRED_GROUPS = [LOGBOOK_VIEWER, LOGBOOK_ADMIN];

export const EDITORS_GROUP = [LOGBOOK_WRITER, LOGBOOK_ADMIN];

export const GROUP_LITERAL = "belonging_to_group_literal";

export const literalFields = [
  {
    key: DATA_MODEL_TABLE?.belongingToGroup?.key,
    value: GROUP_LITERAL
  }
];

export const GROUPS_TOKEN_PATH =
  "signInUserSession.accessToken.payload.cognito:groups";

export const DEFAULT_SITE = "Rotkreuz";

export const ACCESS_TOKEN_PATH = "signInUserSession.idToken.payload";
export const SYNC_PROFILE_FIELDS = {
  givenName: "custom:firstName",
  familyName: "custom:lastName",
  name: "preferred_username",
  phone: "custom:phoneNumber",
  site: "custom:site"
};
export const SOFTWARE_CHANGE_OBJ = {
  key: "softwareChange",
  value: "Software Change"
};
export const SOFTWARE_CHANGE = "Software Change";
export const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
export const defaultTableOptions = {
  showTitleNav: true,
  showTopBar: true,
  showAddClusterEquipBtn: false,
  showPreDefinedFilter: true,
  showFilterSettings: true,
  showTopBarActions: true,
  settings: {
    showSelectColumn: true,
    showFilter: true,
    showGroup: true,
    showReason: true,
    showMasterData: true
  },
  actions: {
    showDetailedView: true,
    showEdit: true,
    showAudit: true,
    showDelete: true
  }
};

export const defaultTableOptionsCluster = {
  showTitleNav: true,
  showTopBar: true,
  showAddClusterEquipBtn: false,
  showPreDefinedFilter: true,
  showFilterSettings: true,
  showTopBarActions: false,
  settings: {
    showSelectColumn: true,
    showFilter: true,
    showGroup: true,
    showReason: true,
    showMasterData: true
  }
};
export const DEFAULT_DESCRIPTION_CONSTANT = {
  moduleVersion: "MODULE VERSION",
  moduleName: "APP NAME",
  ciJobId: "CI ID JOB",
  commitHash: "COMMIT HASH",
  updatedAt: "UPDATED AT"
};

export const ALL_EQUIPMENT_KEY = [
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentCategory.value,
    field: ["equipmentDetails", "equipmentCategory"],
    key: "equipmentCategory"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.manufacturer.value,
    field: ["equipmentDetails", "manufacturer"],
    key: "manufacturer"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentModel.value,
    field: ["equipmentDetails", "equipmentModel"],
    key: "equipmentModel"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.variant.value,
    field: ["equipmentDetails", "variant"],
    key: "variant"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentNickName.value,
    field: ["equipmentDetails", "equipmentNickName"],
    key: "equipmentNickName"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.location.value,
    field: ["equipmentDetails", "location"],
    key: "location"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.serialNumber.value,
    field: ["equipmentDetails", "serialNumber"],
    key: "serialNumber"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentId.value,
    field: ["equipmentDetails", "equipmentId"],
    key: "equipmentId"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.materialNumber.value,
    field: ["equipmentDetails", "materialNumber"],
    key: "materialNumber"
  }
];

export const SHOW_COMMON_KEY = [
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentCategory.value,
    field: ["equipmentDetails", "equipmentCategory"],
    key: "equipmentCategory"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.manufacturer.value,
    field: ["equipmentDetails", "manufacturer"],
    key: "manufacturer"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentModel.value,
    field: ["equipmentDetails", "equipmentModel"],
    key: "equipmentModel"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.variant.value,
    field: ["equipmentDetails", "variant"],
    key: "variant"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.equipmentNickName.value,
    field: ["equipmentDetails", "equipmentNickName"],
    key: "equipmentNickName"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.location.value,
    field: ["equipmentDetails", "location"],
    key: "location"
  },
  {
    label: COMMON_LOGS_DATA_MODEL.serialNumber.value,
    field: ["equipmentDetails", "serialNumber"],
    key: "serialNumber"
  }
];

export const COVER_SHEET_LIST = {
  equipmentId: {
    key: "equipmentId",
    value: "Equipment ID"
  },
  serialNumber: {
    key: "serialNumber",
    value: "Serial number"
  },
  materialNumber: {
    key: "materialNumber",
    value: "Material number"
  },
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname"
  },
  equipmentModel: {
    key: "modelName",
    value: "Model"
  },
  manufacturer: {
    key: "manufacturer",
    value: "Manufacturer"
  },
  variant: {
    key: "variant",
    value: "Variant"
  },
  location: {
    key: "location",
    value: "Location"
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person"
  },
  responsibleProxy: {
    key: "responsibleProxy",
    value: "Responsible proxy"
  },
  softwareVersion: {
    key: " softwareVersion",
    value: "Software version"
  },
  configurationBaseline: {
    key: "configurationBaseline",
    value: "Configuration"
  },
  currentGxPStatusFromLogs: {
    key: "currentGxPStatusFromLogs",
    value: DATA_MODEL_TABLE?.gxpReady?.value
  },
  currentSystemStatusFromLogs: {
    key: "currentSystemStatusFromLogs",
    value: "System status"
  },
  status: {
    key: "status",
    value: "Equipment status"
  }
};

export const Module = {
  TAB: {
    run_log: "runLog",
    action_log: "actionLog",
    change_log: "changeLog",
    all_log: "allLog"
  },
  CLUSTER_TAB: {
    attribute: "Attribute",
    hierarchy: "Hierarchy"
  },
  BUTTON: {
    sign_in: "signIn",
    info: "Info",
    log_out: "logOut",
    add_new_run: "addNewRun",
    add_new_log: "addNewLog",
    export_file: {
      run_log_export: "runLogExport",
      action_log_export: "actionLogExport"
    },
    run_log_export: {
      run_arrow_dropdown_icon: "runArrowDropDownIcon",
      run_export_CSV: "runExportCSV",
      run_download_PDF: "runDownloadPDF"
    },
    action_log_export: {
      action_arrow_dropdown_icon: "actionArrowDropDownIcon",
      action_export_CSV: "actionExportCSV",
      action_download_PDF: "actionDownloadPDF"
    },
    change_log_export: {
      change_download_PDF: "actionDownloadPDF"
    },
    search: "search",
    clear_all: "clearAll",
    run_log_popup: {
      open_coversheet: "runOpenCoversheet",
      cancel: "runCancel",
      add_run_close: "runAddRunClose",
      add_new_run: "runAddNewRun",
      edit_run: "editRun",
      run_sub_component: "runSubComponent"
    },
    action_log_popup: {
      open_coversheet: "actionOpenCoversheet",
      cancel: "actionCancel",
      add_log_close: "addLogClose",
      add_additional_log: "addAdditionalLog",
      edit_log: "editLog",
      log_sub_component: "logSubComponent"
    },
    run_coversheet: {
      close: "runCloseCoversheet",
      cancel: "runCancelCoversheet"
    },
    action_coversheet: {
      close: "actionCloseCoversheet",
      cancel: "actionCancelCoversheet"
    },
    run_audit: {
      audit_close: "runauditClose",
      audit_new_run: "runauditNewRun"
    },
    action_audit: {
      audit_close: "actionauditClose",
      audit_new_log: "actionauditNewLog"
    }
  },
  SEARCH_FILTER: {
    search_box: "searchBox",
    run_log: {
      all_model: "runLogAllModel",
      signee: "runLogSignee",
      date_from: "runLogDateFrom",
      date_to: "runLogDateTo"
    },
    action_log: {
      all_model: "actionLogAllModel",
      signee: "actionLogSignee",
      date_from: "actionLogDateFrom",
      date_to: "actionLogDateTo"
    },
    change_log: {
      signee: "changeLogSignee",
      signeeChangeLog: "signeeChangeLogid",
      reason: "changeLogReason"
    }
  },
  COVERSHEET: {
    picker: {
      snapShot: "coverSheetSnapShot"
    },
    button: {
      showSnapshotBtn: "showSnapshotBtn",
      clearSnapshotBtn: "clearSnapshotBtn"
    }
  },
  POPUP_FIELDS: {
    equipmentId: "equipmentId",
    serialNumber: "serialNumber",
    runStartDate: "runStartDate",
    runStartTime: "runStartTime",
    runEndDate: "runEndDate",
    runEndTime: "runEndTime",
    runStatus: "runStatus",
    runIdentification: "runIdentification",
    samplesProcessed: "samplesProcessed",
    numberOfRuns: "numberOfRuns",
    description: "description",
    defectId: "defectId",
    eLNid: "eLNid",
    mediaType: "mediaType",
    tipsUsed: "tipsUsed",
    operatorUserName: "operatorUserName",
    assay: "assay",
    actionDate: "actionDate",
    actionType: "actionType",
    gxpReady: "gxpReady",
    ecrNumber: "ecrNumber",
    actionDescription: "actionDescription",
    systemStatus: "systemStatus",
    actionOperatorUserName: "actionOperatorUserName"
  }
};
export const AUDIT_TRAIL_LIMIT = 100;

export const AUDIT_ACTIONS = {
  CREATE: "CREATE",
  MODIFY: "MODIFY",
  DELETE: "DELETE",
  REACTIVATE: "REACTIVATE",
  CLUSTER_CREATE: "CLUSTER_CREATE",
  CLUSTER_DELETE: "CLUSTER_DELETE",
  ADD_TO_CLUSTER: "ADD_TO_CLUSTER",
  REMOVE_FROM_CLUSTER: "REMOVE_FROM_CLUSTER",
  CLUSTER_UPDATE: "CLUSTER_UPDATE"
};

export const AUDIT_ACTION_BY = {
  CREATE: "Created by",
  MODIFY: "Edited by",
  DELETE: "Deleted by",
  REACTIVATE: "Restored by",
  CLUSTER_CREATE: "Commissioned by",
  CLUSTER_DELETE: "Decommissioned by",
  ADD_TO_CLUSTER: "Edited by",
  REMOVE_FROM_CLUSTER: "Edited by",
  CLUSTER_UPDATE: "Edited by"
};

export const AUDIT_CLUSTER_ACTION = {
  cluster: "cluster",
  subEquipmentRemove: "subEquipmentRemove",
  subEquipmentAdd: "subEquipmentAdd"
};

export const SHOW_CLUSTER_KEY = [
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    label: DATA_MODEL_TABLE.manufacturer.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    label: DATA_MODEL_TABLE.serialNumber.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentId.key,
    label: DATA_MODEL_TABLE.equipmentId.value
  },
  { key: DATA_MODEL_TABLE.location.key, label: DATA_MODEL_TABLE.location.value }
];

export const SHOW_SUBEQUIPMENT_KEY = [
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  { key: DATA_MODEL_TABLE.variant.key, label: DATA_MODEL_TABLE.variant.value },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  { key: DATA_MODEL_TABLE.location.key, label: DATA_MODEL_TABLE.location.value }
];

export const AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE = {
  Created: "Created",
  Updated: "Updated",
  Deleted: "Deleted"
};

export const entryType = {
  cluster: "CLUSTER",
  standaloneEquipment: "STANDALONE-EQUIPMENT",
  clusterSubequipment: "CLUSTER-SUBEQUIPMENT"
};
export const MyLabMenuArray = [
  {
    key: "viewLogbook",
    value: "View Log book",
    availableFor: [entryType.standaloneEquipment, entryType.cluster]
  },
  {
    key: "addRunLogs",
    value: "Add Run log(s)",
    availableFor: [entryType.standaloneEquipment, entryType.cluster]
  },
  {
    key: "addActionlogs",
    value: "Add Action log(s)",
    availableFor: [entryType.standaloneEquipment, entryType.cluster]
  }
];
export const removeField = [
  "editReason",
  "updatedBy",
  "editComment",
  "location",
  "removeField",
  "reviewId",
  "reviewAction",
  "reviewers",
  "manufacturer#equipmentModel#serialNumber"
];
export const emptyClusterInformation = {
  siteName: null,
  siteTimezone: null,
  siteId: null,
  buildingLocation: null,
  location: "",
  floor: null,
  room: null,
  equipmentAdministrator: null,
  responsiblePerson: null,
  responsibleProxy: null,
  belongingToGroup: null,
  equipmentCategory: null,
  equipmentModel: null,
  manufacturer: null,
  systemStatus: null,
  variant: null,
  equipmentNickName: null,
  cluster: null,
  isBookable: false,
  isVisualized: false,
  qualificationDocuments: null,
  qualificationStatus: null,
  gxpRelevant: null,
  positionInCluster: null,
  dateOfNextMaintanance: null,
  secondaryID: entryType.cluster,
  secondaryIDMapping: entryType.cluster,
  linkedInstance: entryType.cluster,
  entryType: entryType.cluster,
  tags: null,
  action: "CREATE",
  subEquipments: [],
  trackPositionInCluster: false,
  softwareVersion: null
};

export const linkedInstanceList = [
  {
    linkedInstance: "Corelab SAP",
    linkedInstanceDisplay: "CLP",
    secondaryIdMapping: "equipmentId"
  },
  {
    linkedInstance: "RMD SAP",
    linkedInstanceDisplay: "Molecular",
    secondaryIdMapping: "serialNumber; materialNumber"
  },

  {
    linkedInstance: "None",
    linkedInstanceDisplay: "None",
    secondaryIdMapping: "manufacturer; equipmentModel; serialNumber"
  }
];
export const CLUSTER_STEPS = [
  {
    id: 0,
    description: "Select sub components",
    error: false,
    valid: false,
    completed: false,
    active: true,
    cursor: "default",
    tooltip: "Select impacted sub components"
  },
  {
    id: 1,
    description: "Define run",
    error: false,
    valid: false,
    completed: false,
    active: false,
    cursor: "default",
    tooltip: "Define the details of the run log"
  }
];
export const CLUSTER_STEPS_ACTIONS = [
  {
    id: 0,
    description: "Select sub components",
    error: false,
    valid: false,
    completed: false,
    active: true,
    tooltip: "Select impacted sub components",
    cursor: "default"
  },
  {
    id: 1,
    description: "Define action",
    error: false,
    valid: false,
    completed: false,
    active: false,
    tooltip: "Define the details of the action log",
    cursor: "default"
  },
  {
    id: 2,
    description: "Impact on clusters",
    error: false,
    valid: false,
    completed: false,
    active: false,
    tooltip: "Define the action impact on the cluster and its sub components",
    cursor: "default"
  }
];
export const CLUSTER_COVER_SHEET_LIST = {
  siteName: {
    key: "siteName",
    value: "Site"
  },
  location: {
    key: "location",
    value: "Location"
  },
  belongingToGroup: {
    key: "belongingToGroup",
    value: "Group"
  },
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname"
  },
  equipmentCategory: {
    key: "equipmentCategory",
    value: "Category"
  },
  equipmentModel: {
    key: "modelName",
    value: "Model"
  },
  variant: {
    key: "variant",
    value: "Variant"
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person"
  },
  responsibleProxy: {
    key: "responsibleProxy",
    value: "Responsible proxy"
  },
  gxpRelevant: {
    key: "gxpRelevant",
    value: "Intended use GxP relevant?"
  },
  dateOfNextMaintanance: {
    key: "dateOfNextMaintanance",
    value: "Next maintenance or calibration"
  },
  softwareVersion: {
    key: "softwareVersion",
    value: "Software version"
  },
  currentGxPStatusFromLogs: {
    key: "currentGxPStatusFromLogs",
    value: DATA_MODEL_TABLE?.gxpReady?.value
  },
  currentSystemStatusFromLogs: {
    key: "currentSystemStatusFromLogs",
    value: "System status"
  },

  //Attribute and hierarchy
  status: {
    key: "status",
    value: "Equipment status"
  }
};
export const SHOW_ADVANCE_FILTER_TOOLTIP = "Show search and filters options";
export const RESETALL_TOOLTIP =
  "This will clear all filters and columns and reset to default";
export const CLUSTER_COVER_SUB_EQUIPMENT_OVERVIEW = {
  siteName: {
    key: "siteName",
    value: "Site"
  },
  location: {
    key: "location",
    value: "Location"
  },
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname"
  },
  equipmentCategory: {
    key: "equipmentCategory",
    value: "Category"
  },
  variant: {
    key: "variant",
    value: "Variant"
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person"
  },
  responsibleProxy: {
    key: "responsibleProxy",
    value: "Responsible proxy"
  },
  gxpRelevant: {
    key: "gxpRelevant",
    value: "Intended use GxP relevant?"
  },
  dateOfNextMaintanance: {
    key: "dateOfNextMaintanance",
    value: "Next maintenance or calibration"
  },
  systemStatus: {
    key: "systemStatus",
    value: "System status"
  },
  status: {
    key: "status",
    value: "Equipment status"
  }
};
export const SECOND_VALUE = "59";

export const OLD_SCHEMA_FIELDS = {
  instrumentName: {
    key: "instrumentName",
    value: "Instrument name"
  },
  instrumentType: {
    key: "instrumentType",
    value: "Type"
  },
  secondResponsiblePerson: {
    key: "secondResponsiblePerson",
    value: "Second responsible person"
  },
  instrumentGxPStatus: {
    key: "instrumentGxPStatus",
    value: "GxP status"
  },
  remark: {
    key: "remark",
    value: "Remark"
  }
};
export const RUN_STATUS_FIELD = {
  RUN_ABORT: "Run abort ",
  USER_ABORT: "User abort",
  PROCESSING: "Processing",
  COMPLETED: "Completed",
  SUCCESS: "Success"
};
export const ChangeLogMetaDisplay = {
  fields: {
    detailExpander: {
      text: ""
    },
    createdAt: {
      text: "Date"
    },
    modifiedByUserId: {
      text: "Signee"
    },
    editReason: {
      text: "Reason"
    },
    editComment: {
      text: "Comment"
    },
    changes: {
      text: "Changed"
    }
  }
};

export const REPO_CONFIG = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_REPO_AWS_GRAPHQL_ENDPOINT
};
export const LP_CONFIG = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_LP_AWS_GRAPHQL_ENDPOINT
};
export const LOOGBOOK_CONFIG = {
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_GRAPHQL_ENDPOINT
};
export const HOME_SUB_MENU = [
  {
    icon: <EventAvailableOutlinedIcon />,
    text: "Log list",
    id: "Loglist",
    url: "/",
    isActive: true,
    isDisabled: false,
    children: []
  },
  {
    icon: <ListAltOutlinedIcon />,
    text: "Equipment list",
    id: "Equipmentlist",
    url: "/equipment-list",
    isActive: false,
    isDisabled: false,
    children: []
  }
];
export const listOfFieldsIgnoredInTable = [
  DATA_MODEL_TABLE?.trackPositionInCluster?.key
];
export const defaultFilterObject = () => {
  let obj = {};
  Object.keys(DATA_MODEL_TABLE)?.forEach((dataModel) => {
    if (!listOfFieldsIgnoredInTable.includes(dataModel)) {
      if (dataModel === DATA_MODEL_TABLE?.belongingToGroup?.key) {
        obj["group"] = null;
      }
      if (dataModel === DATA_MODEL_TABLE?.siteName?.key) {
        obj["site"] = null;
      }
      if (dataModel === DATA_MODEL_TABLE?.equipmentCategory?.key) {
        obj["category"] = null;
      }
      obj[dataModel] = null;
    }
  });

  return obj;
};
export const DEFAULT_FILTER = {
  filterName: "",
  filterDefinition: {
    ...defaultFilterObject(),
    dateFrom: null,
    dateTo: null,
    inventoryIds: null
  }
};
export const DEFAULT_COLUMNS = {
  displayName: "",
  displayDefinition: {
    showColumns: [
      {
        key: DATA_MODEL_TABLE.equipmentModel.key,
        val: DATA_MODEL_TABLE.equipmentModel.value,
        order: 1,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.serialNumber.key,
        val: DATA_MODEL_TABLE.serialNumber.value,
        order: 2,
        show: true,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.materialNumber.key,
        val: DATA_MODEL_TABLE.materialNumber.value,
        order: 3,
        show: true,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.siteName.key,
        val: DATA_MODEL_TABLE.siteName.value,
        order: 4,
        show: true,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.manufacturer.key,
        val: DATA_MODEL_TABLE.manufacturer.value,
        order: 5,
        show: true,
        sortStatus: false
      }
    ],
    hideColumns: [
      {
        key: DATA_MODEL_TABLE.instrumentGTIN.key,
        val: "GTIN number",
        order: 6,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.equipmentId.key,
        val: DATA_MODEL_TABLE.equipmentId.value,
        order: 7,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.location.key,
        val: DATA_MODEL_TABLE.location.value,
        order: 8,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.responsiblePerson.key,
        val: DATA_MODEL_TABLE.responsiblePerson.value,
        order: 9,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.isBookable.key,
        val: DATA_MODEL_TABLE.isBookable.value,
        order: 10,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.instrumentRUDI.key,
        val: "RUDI number",
        order: 11,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.equipmentCategory.key,
        val: DATA_MODEL_TABLE.equipmentCategory.value,
        order: 12,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.comment.key,
        val: DATA_MODEL_TABLE.comment.value,
        order: 13,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.belongingToGroup.key,
        val: DATA_MODEL_TABLE.belongingToGroup.value,
        order: 14,
        show: false,
        sortStatus: true
      },
      {
        key: DATA_MODEL_TABLE.room.key,
        val: DATA_MODEL_TABLE.room.value,
        order: 15,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.systemOwner.key,
        val: DATA_MODEL_TABLE.systemOwner.value,
        order: 16,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.responsibleProxy.key,
        val: DATA_MODEL_TABLE.responsibleProxy.value,
        order: 17,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.sop.key,
        val: DATA_MODEL_TABLE.sop.value,
        order: 18,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.csv.key,
        val: DATA_MODEL_TABLE.csv.value,
        order: 19,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.electronicRecord.key,
        val: DATA_MODEL_TABLE.electronicRecord.value,
        order: 20,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.electronicSignatures.key,
        val: DATA_MODEL_TABLE.electronicSignatures.value,
        order: 21,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
        val: DATA_MODEL_TABLE.dateOfNextPeriodicReview.value,
        order: 22,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.maintenancePlan.key,
        val: DATA_MODEL_TABLE.maintenancePlan.value,
        order: 23,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.gxpRelevant.key,
        val: DATA_MODEL_TABLE.gxpRelevant.value,
        order: 24,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.qualificationStatus.key,
        val: DATA_MODEL_TABLE.qualificationStatus.value,
        order: 25,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.dateOfNextMaintanance.key,
        val: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
        order: 26,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.softwareVersion.key,
        val: "Version",
        order: 27,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.configurationBaseline.key,
        val: DATA_MODEL_TABLE.configurationBaseline.value,
        order: 28,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.installedTests.key,
        val: DATA_MODEL_TABLE.installedTests.value,
        order: 29,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.qualificationDocuments.key,
        val: DATA_MODEL_TABLE.qualificationDocuments.value,
        order: 30,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.isVisualized.key,
        val: DATA_MODEL_TABLE.isVisualized.value,
        order: 31,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.dateOfLastMaintanance.key,
        val: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
        order: 32,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.buildingLocation.key,
        val: DATA_MODEL_TABLE.buildingLocation.value,
        order: 33,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.floor.key,
        val: DATA_MODEL_TABLE.floor.value,
        order: 34,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.cluster.key,
        val: DATA_MODEL_TABLE.cluster.value,
        order: 35,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.equipmentAdministrator.key,
        val: DATA_MODEL_TABLE.equipmentAdministrator.value,
        order: 36,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.equipmentNickName.key,
        val: DATA_MODEL_TABLE.equipmentNickName.value,
        order: 37,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.functionalLocation.key,
        val: DATA_MODEL_TABLE.functionalLocation.value,
        order: 38,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.costCenter.key,
        val: DATA_MODEL_TABLE.costCenter.value,
        order: 39,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.inventoryNumber.key,
        val: DATA_MODEL_TABLE.inventoryNumber.value,
        order: 40,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.variant.key,
        val: DATA_MODEL_TABLE.variant.value,
        order: 41,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.documentedMaintenanceDate.key,
        val: DATA_MODEL_TABLE.documentedMaintenanceDate.value,
        order: 42,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
        val: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
        order: 43,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
        val: DATA_MODEL_TABLE.maintenanceIntervalInDays.value,
        order: 44,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.weightInkg.key,
        val: DATA_MODEL_TABLE.weightInkg.value,
        order: 45,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.heightInmm.key,
        val: DATA_MODEL_TABLE.heightInmm.value,
        order: 46,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.lengthInmm.key,
        val: DATA_MODEL_TABLE.lengthInmm.value,
        order: 47,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.depthInmm.key,
        val: DATA_MODEL_TABLE.depthInmm.value,
        order: 48,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
        val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
        order: 49,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceRightInmm.key,
        val: DATA_MODEL_TABLE.clearanceRightInmm.value,
        order: 50,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
        val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
        order: 51,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
        val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
        order: 52,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
        val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
        order: 53,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.tags.key,
        val: DATA_MODEL_TABLE.tags.value,
        order: 54,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.linkedInstance.key,
        val: DATA_MODEL_TABLE.linkedInstance.value,
        order: 55,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.systemStatus.key,
        val: DATA_MODEL_TABLE.systemStatus.value,
        order: 56,
        show: false,
        sortStatus: false
      },
      {
        key: DATA_MODEL_TABLE.status.key,
        val: DATA_MODEL_TABLE.status.value,
        order: 57,
        show: false,
        sortStatus: false
      }
    ]
  }
};
export const MY_LAB = "My lab";
export const SOP_RT = "SOP RT0020";
export const DEFAULT_MYLAB_FILTER = {
  filterName: MY_LAB,
  filterDefinition: {
    ...defaultFilterObject(),
    dateFrom: null,
    dateTo: null,
    inventoryIds: null
  }
};

export const GXPemptyInstrumentsOptions = {
  csv: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  electronicRecord: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  electronicSignatures: [
    { key: "NA", value: "NA" },
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  gxpRelevant: [
    { key: "No", value: "No" },
    { key: "Yes", value: "Yes" }
  ],
  qualificationStatus: [
    { key: "NA", value: "NA" },
    { key: "NOT_QUALIFIED", value: "Not qualified" },
    { key: "QUALIFIED", value: "Qualified" },
    { key: "CHANGED_COMPONENT_ONLY", value: "Changed component only" }
  ],
  controlledEquipmentStatus: [
    { key: "CALIBRATED", value: "Calibrated" },
    { key: "NA", value: "NA" },
    { key: "NOT_CALIBRATED", value: "Not calibrated" }
  ],
  dateOfNextMaintanance: null,
  dateOfLastMaintanance: null
};
export const systemStatusOptions = ["In service", "Out of service"];
export const listOfCheckBoxFilters = [
  DATA_MODEL_TABLE?.belongingToGroup?.key,
  DATA_MODEL_TABLE?.manufacturer?.key,
  DATA_MODEL_TABLE?.equipmentCategory?.key,
  DATA_MODEL_TABLE?.responsiblePerson?.key,
  DATA_MODEL_TABLE?.equipmentAdministrator?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.tags?.key,
  DATA_MODEL_TABLE?.linkedInstance?.key,
  DATA_MODEL_TABLE?.equipmentModel?.key,
  DATA_MODEL_TABLE?.variant?.key,
  DATA_MODEL_TABLE?.csv?.key,
  DATA_MODEL_TABLE?.electronicRecord?.key,
  DATA_MODEL_TABLE?.electronicSignatures?.key,
  DATA_MODEL_TABLE?.qualificationStatus?.key,
  DATA_MODEL_TABLE?.controlledEquipmentStatus?.key,
  DATA_MODEL_TABLE?.sop?.key,
  DATA_MODEL_TABLE?.gxpRelevant?.key,
  DATA_MODEL_TABLE?.systemStatus?.key,
  DATA_MODEL_TABLE?.status?.key
];

export const listOfBooleanRequired = [
  DATA_MODEL_TABLE?.isBookable?.key,
  DATA_MODEL_TABLE?.isVisualized?.key
];

export const listOfDateRequired = [
  DATA_MODEL_TABLE?.dateOfLastMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key
];

export const listOfNumberFilter = [
  DATA_MODEL_TABLE?.inventoryNumber?.key,
  DATA_MODEL_TABLE?.installedTests?.key,
  DATA_MODEL_TABLE?.qualificationDocuments?.key,
  DATA_MODEL_TABLE?.maintenanceIntervalInDays?.key,
  DATA_MODEL_TABLE?.heatOutputInW?.key,
  DATA_MODEL_TABLE?.noiseIndBA?.key,
  DATA_MODEL_TABLE?.heightInmm?.key,
  DATA_MODEL_TABLE?.lengthInmm?.key,
  DATA_MODEL_TABLE?.depthInmm?.key,
  DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key,
  DATA_MODEL_TABLE?.clearanceHeightInmm?.key,
  DATA_MODEL_TABLE?.clearanceLeftInmm?.key,
  DATA_MODEL_TABLE?.clearanceRightInmm?.key,
  DATA_MODEL_TABLE?.clearanceToFrontInmm?.key,
  DATA_MODEL_TABLE?.weightInkg?.key // this is double
];

export const listOfFieldAcceptOnlyInteger = [
  DATA_MODEL_TABLE?.inventoryNumber?.key,
  DATA_MODEL_TABLE?.installedTests?.key,
  DATA_MODEL_TABLE?.qualificationDocuments?.key,
  DATA_MODEL_TABLE?.maintenanceIntervalInDays?.key,
  DATA_MODEL_TABLE?.heatOutputInW?.key,
  DATA_MODEL_TABLE?.noiseIndBA?.key,
  DATA_MODEL_TABLE?.heightInmm?.key,
  DATA_MODEL_TABLE?.lengthInmm?.key,
  DATA_MODEL_TABLE?.depthInmm?.key,
  DATA_MODEL_TABLE?.clearanceBackwardsInmm?.key,
  DATA_MODEL_TABLE?.clearanceHeightInmm?.key,
  DATA_MODEL_TABLE?.clearanceLeftInmm?.key,
  DATA_MODEL_TABLE?.clearanceRightInmm?.key,
  DATA_MODEL_TABLE?.clearanceToFrontInmm?.key
];

export const listOfFilterBlankRequired = [
  DATA_MODEL_TABLE?.serialNumber?.key,
  DATA_MODEL_TABLE?.materialNumber?.key,
  DATA_MODEL_TABLE?.equipmentId?.key
];
export const listOfFieldsNotSortable = [DATA_MODEL_TABLE?.tags?.key];

export const listOfFieldsSortable = [
  DATA_MODEL_TABLE?.responsiblePerson?.key,
  DATA_MODEL_TABLE?.location?.key,
  DATA_MODEL_TABLE?.belongingToGroup?.key,
  DATA_MODEL_TABLE?.serialNumber?.key,
  DATA_MODEL_TABLE?.equipmentModel?.key
];

export const listOfFieldsIgnoredForSearch = [
  DATA_MODEL_TABLE?.dateOfLastMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextMaintanance?.key,
  DATA_MODEL_TABLE?.dateOfNextPeriodicReview?.key
];
export const listOfFieldsNoFilter = [DATA_MODEL_TABLE?.siteName?.key];
export const allTableColumn = () => [
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    val: DATA_MODEL_TABLE.equipmentModel.value,
    order: 1,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    val: DATA_MODEL_TABLE.serialNumber.value,
    order: 2,
    show: true,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.materialNumber.key,
    val: DATA_MODEL_TABLE.materialNumber.value,
    order: 3,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.siteName.key,
    val: DATA_MODEL_TABLE.siteName.value,
    order: 4,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    val: DATA_MODEL_TABLE.manufacturer.value,
    order: 5,
    show: true,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.instrumentGTIN.key,
    val: "GTIN number",
    order: 6,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentId.key,
    val: DATA_MODEL_TABLE.equipmentId.value,
    order: 7,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.location.key,
    val: DATA_MODEL_TABLE.location.value,
    order: 8,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.responsiblePerson.key,
    val: DATA_MODEL_TABLE.responsiblePerson.value,
    order: 9,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.isBookable.key,
    val: DATA_MODEL_TABLE.isBookable.value,
    order: 10,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.instrumentRUDI.key,
    val: "RUDI number",
    order: 11,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentCategory.key,
    val: DATA_MODEL_TABLE.equipmentCategory.value,
    order: 12,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.comment.key,
    val: DATA_MODEL_TABLE.comment.value,
    order: 13,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.belongingToGroup.key,
    val: DATA_MODEL_TABLE.belongingToGroup.value,
    order: 14,
    show: false,
    sortStatus: true
  },
  {
    key: DATA_MODEL_TABLE.room.key,
    val: DATA_MODEL_TABLE.room.value,
    order: 15,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.systemOwner.key,
    val: DATA_MODEL_TABLE.systemOwner.value,
    order: 16,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.responsibleProxy.key,
    val: DATA_MODEL_TABLE.responsibleProxy.value,
    order: 17,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.sop.key,
    val: DATA_MODEL_TABLE.sop.value,
    order: 18,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.csv.key,
    val: DATA_MODEL_TABLE.csv.value,
    order: 19,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.electronicRecord.key,
    val: DATA_MODEL_TABLE.electronicRecord.value,
    order: 20,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.electronicSignatures.key,
    val: DATA_MODEL_TABLE.electronicSignatures.value,
    order: 21,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfNextPeriodicReview.key,
    val: DATA_MODEL_TABLE.dateOfNextPeriodicReview.value,
    order: 22,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.maintenancePlan.key,
    val: DATA_MODEL_TABLE.maintenancePlan.value,
    order: 23,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.gxpRelevant.key,
    val: DATA_MODEL_TABLE.gxpRelevant.value,
    order: 24,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.qualificationStatus.key,
    val: DATA_MODEL_TABLE.qualificationStatus.value,
    order: 25,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfNextMaintanance.key,
    val: DATA_MODEL_TABLE.dateOfNextMaintanance.value,
    order: 26,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.softwareVersion.key,
    val: "Version",
    order: 27,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.configurationBaseline.key,
    val: DATA_MODEL_TABLE.configurationBaseline.value,
    order: 28,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.installedTests.key,
    val: DATA_MODEL_TABLE.installedTests.value,
    order: 29,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.qualificationDocuments.key,
    val: DATA_MODEL_TABLE.qualificationDocuments.value,
    order: 30,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.isVisualized.key,
    val: DATA_MODEL_TABLE.isVisualized.value,
    order: 31,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.dateOfLastMaintanance.key,
    val: DATA_MODEL_TABLE.dateOfLastMaintanance.value,
    order: 32,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.buildingLocation.key,
    val: DATA_MODEL_TABLE.buildingLocation.value,
    order: 33,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.floor.key,
    val: DATA_MODEL_TABLE.floor.value,
    order: 34,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.cluster.key,
    val: DATA_MODEL_TABLE.cluster.value,
    order: 35,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentAdministrator.key,
    val: DATA_MODEL_TABLE.equipmentAdministrator.value,
    order: 36,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    val: DATA_MODEL_TABLE.equipmentNickName.value,
    order: 37,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.functionalLocation.key,
    val: DATA_MODEL_TABLE.functionalLocation.value,
    order: 38,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.costCenter.key,
    val: DATA_MODEL_TABLE.costCenter.value,
    order: 39,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.inventoryNumber.key,
    val: DATA_MODEL_TABLE.inventoryNumber.value,
    order: 40,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.variant.key,
    val: DATA_MODEL_TABLE.variant.value,
    order: 41,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.documentedMaintenanceDate.key,
    val: DATA_MODEL_TABLE.documentedMaintenanceDate.value,
    order: 42,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.controlledEquipmentStatus.key,
    val: DATA_MODEL_TABLE.controlledEquipmentStatus.value,
    order: 43,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.maintenanceIntervalInDays.key,
    val: DATA_MODEL_TABLE.maintenanceIntervalInDays.value,
    order: 44,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.weightInkg.key,
    val: DATA_MODEL_TABLE.weightInkg.value,
    order: 45,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.heightInmm.key,
    val: DATA_MODEL_TABLE.heightInmm.value,
    order: 46,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.lengthInmm.key,
    val: DATA_MODEL_TABLE.lengthInmm.value,
    order: 47,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.depthInmm.key,
    val: DATA_MODEL_TABLE.depthInmm.value,
    order: 48,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceLeftInmm.key,
    val: DATA_MODEL_TABLE.clearanceLeftInmm.value,
    order: 49,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceRightInmm.key,
    val: DATA_MODEL_TABLE.clearanceRightInmm.value,
    order: 50,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceHeightInmm.key,
    val: DATA_MODEL_TABLE.clearanceHeightInmm.value,
    order: 51,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceToFrontInmm.key,
    val: DATA_MODEL_TABLE.clearanceToFrontInmm.value,
    order: 52,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.clearanceBackwardsInmm.key,
    val: DATA_MODEL_TABLE.clearanceBackwardsInmm.value,
    order: 53,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.tags.key,
    val: DATA_MODEL_TABLE.tags.value,
    order: 54,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.linkedInstance.key,
    val: DATA_MODEL_TABLE.linkedInstance.value,
    order: 55,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.systemStatus.key,
    val: DATA_MODEL_TABLE.systemStatus.value,
    order: 56,
    show: false,
    sortStatus: false
  },
  {
    key: DATA_MODEL_TABLE.status.key,
    val: DATA_MODEL_TABLE.status.value,
    order: 76,
    show: false,
    sortStatus: false
  }
];

export const MORE_DETAILS_LIST = {
  siteName: {
    key: "siteName",
    value: "Site"
  },
  location: {
    key: "location",
    value: "Location"
  },
  equipmentNickName: {
    key: "equipmentNickName",
    value: "Nickname"
  },
  equipmentCategory: {
    key: "equipmentCategory",
    value: "Category"
  },
  variant: {
    key: "variant",
    value: "Variant"
  },
  responsiblePerson: {
    key: "responsiblePerson",
    value: "Responsible person"
  },
  responsibleProxy: {
    key: "responsibleProxy",
    value: "Responsible proxy"
  },
  gxpRelevant: {
    key: "gxpRelevant",
    value: "Intended use GxP relevant?"
  },
  dateOfNextMaintanance: {
    key: "dateOfNextMaintanance",
    value: "Next maintenance or calibration"
  },
  systemStatus: {
    key: "systemStatus",
    value: "System status"
  }
};

export const DUMMY_ROW = "dummyRow";
export const agGridAutoGroupfieldName = "ag-Grid-AutoColumn";

export const formStates = {
  NEW_FORM: "NEW",
  PARTIAL_NEW_FORM: "PARTIAL_NEW_FORM",
  EDITABLE_FORM: "EDITABLE_FORM"
};

export const formTypes = {
  RUN_LOG: "runLog",
  ACTION_LOG: "actionLog",
  CHANGE_LOG: "changeLog",
  ALL_LOG: "allLog"
};

export const logViews = {
  NORMAL_VIEW: "normalView",
  CENTRIC_VIEW: "centricView"
};

export const displayColumns = {
  EQUIPMENT_LIST: "equipmentList",
  RUN_LOG: "runLog",
  ACTION_LOG: "actionLog"
};

export const tableReloadTime = 3000;

export const actionLogManditoryFields = [
  ACTION_LOGS_DATA_MODEL?.actionDate?.key,
  ACTION_LOGS_DATA_MODEL?.actionTime?.key,
  ACTION_LOGS_DATA_MODEL?.actionType?.key,
  COMMON_LOGS_DATA_MODEL?.description?.key
];

export const SHOW_SUB_CLUSTER_KEY = [
  {
    key: DATA_MODEL_TABLE.manufacturer.key,
    label: DATA_MODEL_TABLE.manufacturer.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentModel.key,
    label: DATA_MODEL_TABLE.equipmentModel.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentNickName.key,
    label: DATA_MODEL_TABLE.equipmentNickName.value
  },
  {
    key: DATA_MODEL_TABLE.serialNumber.key,
    label: DATA_MODEL_TABLE.serialNumber.value
  },
  {
    key: DATA_MODEL_TABLE.equipmentId.key,
    label: DATA_MODEL_TABLE.equipmentId.value
  },
  { key: DATA_MODEL_TABLE.location.key, label: DATA_MODEL_TABLE.location.value }
];

export const equipmentStatus = {
  active: { key: "ACTIVE", value: "Active" },
  pending: { key: "PENDING", value: "Pending" },
  deleted: { key: "DELETED", value: "Deleted" },
  delete: { key: "DELETE", value: "Deleted" }
};
export const equipmentStatusOptions = [
  equipmentStatus?.active?.value,
  equipmentStatus?.deleted?.value,
  equipmentStatus?.pending?.value
];

export const statusChip = {
  ACTIVE: equipmentStatus?.active?.value,
  PENDING: equipmentStatus?.pending?.value,
  DELETED: equipmentStatus?.deleted?.value,
  DELETE: equipmentStatus?.delete?.value
};
