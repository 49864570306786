import {
  listOfBooleanRequired,
  listOfCheckBoxFilters,
  listOfDateRequired,
  listOfFieldsNoFilter,
  listOfFieldsNotSortable,
  listOfFilterBlankRequired,
  listOfNumberFilter
} from "../../constants";
import DATA_MODEL_TABLE from "../../utils/constants/dataModelTable";
import BooleanRender from "./BooleanRender";
import ChipRender from "./ChipRender";
import DateRender from "./DateRender";
import LinkedInstanceRender from "./LinkedInstanceRender";
import {
  MY_LAB,
  SOP_RT,
  TOOL_PANELS
} from "../../components/DLabGrid/dLabGridConstant";
import Notify from "../notifications/Notify";
import { find } from "lodash";
import EquipmentStatusRender from "../log-book/log-book-table/EquipmentStatusRender";

export const getColumnObj = (
  value,
  masterDataFilters,
  autoGroupField = null
) => {
  let colObj = value;

  colObj = {
    ...colObj,
    sortable: !listOfFieldsNotSortable?.includes(value.key)
  };

  if (value.key === DATA_MODEL_TABLE?.linkedInstance?.key) {
    colObj = {
      ...colObj,
      cellRenderer: LinkedInstanceRender
    };
  }

  if (value.key === DATA_MODEL_TABLE?.tags?.key) {
    colObj = {
      ...colObj,
      cellRenderer: ChipRender
    };
  }

  if (value.key === DATA_MODEL_TABLE?.status?.key) {
    colObj = {
      ...colObj,
      cellRenderer: EquipmentStatusRender
    };
  }

  if (listOfDateRequired?.includes(value.key)) {
    colObj = {
      ...colObj,
      cellClass: "action-render",
      cellRenderer: DateRender,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        inRangeFloatingFilterDateFormat: "DD-MMM-YYYY",
        suppressAndOrCondition: true,
        defaultJoinOperator: "OR",
        buttons: ["reset", "apply"]
      }
    };
  } else if (listOfBooleanRequired?.includes(value.key)) {
    colObj = {
      ...colObj,
      cellClass: "action-render",
      cellRenderer: BooleanRender,
      filter: "agSetColumnFilter",
      filterParams: {
        defaultToNothingSelected: true,
        showTooltips: true,
        buttons: ["reset"],
        values: (params) => {
          const values = ["true", "false"];
          params.success(values);
        }
      }
    };
  } else if (listOfCheckBoxFilters?.includes(value.key)) {
    colObj = {
      ...colObj,
      filter: "agSetColumnFilter",
      filterParams: {
        buttons: ["reset"],
        defaultToNothingSelected: true,
        values: (params) => {
          const values = masterDataFilters[value.key] || [];
          params.success(values);
        }
      }
    };
  } else if (listOfNumberFilter?.includes(value.key)) {
    colObj = {
      ...colObj,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: ["equals", "lessThan", "greaterThan", "inRange"],
        defaultJoinOperator: "OR",
        buttons: ["reset", "apply"]
      }
    };
  } else if (!listOfFieldsNoFilter.includes(value.key)) {
    colObj = {
      ...colObj,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: listOfFilterBlankRequired?.includes(value.key)
          ? ["contains", "blank"]
          : ["contains"],
        buttons: ["reset", "apply"],
        defaultToNothingSelected: true,
        defaultJoinOperator: "OR",
        values: (params) => {
          const values = masterDataFilters[value.key] || [];
          params.success(values);
        }
      }
    };
  }

  if (autoGroupField && value.key === autoGroupField) {
    colObj = {
      ...colObj,
      hide: true,
      lockVisible: true
    };
  }

  return colObj;
};

export const getColumnObjClient = (value) => {
  let colObj = value;

  colObj = {
    ...colObj,
    sortable: !listOfFieldsNotSortable?.includes(value.key)
  };
  if (listOfBooleanRequired?.includes(value.key)) {
    colObj = {
      ...colObj,
      cellClass: "action-render",
      cellRenderer: BooleanRender
    };
  }

  if (value.key === DATA_MODEL_TABLE?.linkedInstance?.key) {
    colObj = {
      ...colObj,
      cellRenderer: LinkedInstanceRender
    };
  }

  if (value.key === DATA_MODEL_TABLE?.tags?.key) {
    colObj = {
      ...colObj,
      cellRenderer: ChipRender
    };
  }

  if (listOfDateRequired?.includes(value.key)) {
    colObj = {
      ...colObj,
      cellClass: "action-render",
      cellRenderer: DateRender
    };
  }

  return colObj;
};
//======================FILTER FUNCTIONC===================
export const onUpdateFilter = async ({
  lastFavFilter,
  isSaveConfigUpdated = false,
  updateRedux = true,
  id,
  email,
  client,
  query,
  userProfileQuery,
  updateLastFavFilter = null
}) => {
  let lastFilterData = {};
  lastFilterData.lastFavFilter = lastFavFilter;
  lastFilterData = JSON.stringify(lastFilterData);
  let result = null;
  try {
    result = await client.mutate({
      mutation: userProfileQuery,
      variables: { id, email, lastFilter: lastFilterData }
    });

    if (isSaveConfigUpdated) {
      const filterDefinition =
        typeof lastFavFilter?.filterDefinition === "string"
          ? lastFavFilter?.filterDefinition
          : JSON.stringify(lastFavFilter?.filterDefinition);
      await client.mutate({
        mutation: query,
        variables: {
          userID: id,
          filterName: lastFavFilter?.filterName,
          filterDefinition
        },
        fetchPolicy: "no-cache"
      });
    }

    if (isSaveConfigUpdated) {
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Updated filter successfully `
      });
    }
  } catch (error) {
    if (isSaveConfigUpdated) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: "Failed to update filter"
      });
    }
    result = null;
  }
  if (updateRedux && updateLastFavFilter) {
    updateLastFavFilter(lastFavFilter);
  }
  return result;
};

export const onCreatefilter = async ({
  id,
  email,
  client,
  userFilters,
  lastFavFilter,
  query,
  userProfileQuery,
  updateUserFilters = null,
  updateLastFavFilter = null
}) => {
  let result = null;
  try {
    const FilterNameMatch = userFilters.filter(
      (item) => item.filterName === lastFavFilter?.filterName?.trim()
    );
    if (FilterNameMatch.length === 0 && lastFavFilter?.filterName !== MY_LAB) {
      result = await client.mutate({
        mutation: query,
        variables: {
          userID: id,
          filterDefinition: JSON.stringify(lastFavFilter?.filterDefinition),
          filterName: lastFavFilter.filterName?.trim()
        }
      });
      if (result) {
        await onUpdateFilter({
          lastFavFilter,
          updateLastFavFilter,
          userProfileQuery,
          id,
          email,
          client
        });
        if (updateUserFilters) {
          updateUserFilters([...userFilters, lastFavFilter]);
        }
      }

      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Filters successfully saved `
      });
    } else {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Duplicate filter name `
      });
    }
  } catch (err) {
    console.log(err);
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: `Filter creation failure `
    });
  }

  return result;
};

//===============COLUMN FUNCTIONS=================
export const onUpdateSelectColumn = async ({
  data,
  isSaveConfigUpdated = false,
  updateRedux = true,
  id,
  email,
  client,
  userProfileQuery,
  query,
  userDisplayList,
  updateUserDisplayColumns = null,
  updateLastDisplayColumn = null
}) => {
  let result = null;
  try {
    result = await client.mutate({
      mutation: userProfileQuery,
      variables: { id, email, lastDisplayColumns: data },
      fetchPolicy: "no-cache"
    });

    if (isSaveConfigUpdated) {
      const updateData = typeof data === "string" ? JSON.parse(data) : data;
      const displayDefinition =
        typeof updateData?.displayDefinition === "string"
          ? updateData?.displayDefinition
          : JSON.stringify(updateData?.displayDefinition);
      await client.mutate({
        mutation: query,
        variables: {
          userID: id,
          displayName: updateData?.displayName,
          displayDefinition
        },
        fetchPolicy: "no-cache"
      });
      const displayObj = typeof data === "string" ? JSON.parse(data) : data;
      userDisplayList = userDisplayList?.map((userDisplay) => {
        if (displayObj?.displayName === userDisplay?.displayName) {
          return displayObj;
        }
        return userDisplay;
      });

      if (updateUserDisplayColumns) {
        updateUserDisplayColumns([...userDisplayList]);
      }
    }

    if (isSaveConfigUpdated) {
      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Updated columns set successfully `
      });
    }
  } catch (error) {
    if (isSaveConfigUpdated) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Failed to update columns set`
      });
    }
    return null;
  }

  if (updateRedux && updateLastDisplayColumn) {
    updateLastDisplayColumn(data);
  }

  return result;
};

export const onCreateSelectColumn = async ({
  id,
  email,
  userDisplayList,
  unParsedData,
  client,
  query,
  userProfileQuery,
  updateUserDisplayColumns = null,
  updateLastDisplayColumn = null
}) => {
  let result = null;

  const columnMatches = userDisplayList.filter(
    (item) => item.displayName === unParsedData?.displayName?.trim()
  );
  if (
    columnMatches?.length === 0 &&
    unParsedData?.displayName !== "SOP RT0020"
  ) {
    let columnObj = {
      displayName: unParsedData?.displayName,
      userID: id,
      displayDefinition: JSON.stringify(unParsedData?.displayDefinition)
    };

    try {
      result = await client.mutate({
        mutation: query,
        variables: columnObj,
        fetchPolicy: "no-cache"
      });

      if (result) {
        await onUpdateSelectColumn({
          data: JSON.stringify(unParsedData),
          updateLastDisplayColumn,
          userProfileQuery,
          id,
          email,
          client
        });

        userDisplayList = [...userDisplayList, result?.data?.createUserDisplay];
        if (updateUserDisplayColumns) {
          updateUserDisplayColumns([...userDisplayList]);
        }
      }

      Notify({
        type: "success",
        icon: "circle_confirm",
        appName: "",
        text: `Columns Set successfully saved`
      });
    } catch (error) {
      Notify({
        type: "warning",
        icon: "caution",
        appName: "",
        text: `Failed to save columns set`
      });
    }
  } else {
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text: "Duplicate column name"
    });
  }
  return result;
};

//==============onChip delete======================

export const onDeleteSaveConfigurations = async ({
  userID,
  email,
  client,
  chipToDelete,
  panel,
  lastSavedChip,
  userProfileQuery,
  deleteFilterQuery,
  deleteColumnQuery,
  userFilters,
  userDisplayList,
  updateUserFilters = null,
  updateUserDisplayColumns = null,
  updateLastFavFilter = null,
  updateLastDisplayColumn = null
}) => {
  let result = null;
  let variables = { userID: chipToDelete?.userID || userID };
  if (panel === TOOL_PANELS?.filters) {
    variables = {
      ...variables,
      filterName: chipToDelete?.filterName
    };
  } else if (panel === TOOL_PANELS?.columns) {
    variables = {
      ...variables,
      displayName: chipToDelete?.displayName
    };
  }
  try {
    result = await client.mutate({
      mutation:
        panel === TOOL_PANELS?.filters ? deleteFilterQuery : deleteColumnQuery,
      variables
    });
    if (panel === TOOL_PANELS?.filters) {
      const removeIndex = userFilters.findIndex(
        (item) => item.filterName === chipToDelete?.filterName
      );
      userFilters.splice(removeIndex, 1);
      if (lastSavedChip?.filterName === chipToDelete?.filterName) {
        await onUpdateFilter({
          lastFavFilter: {
            filterDefinition: chipToDelete?.filterDefinition,
            filterName: ""
          },
          userProfileQuery,
          updateLastFavFilter,
          id: userID,
          email,
          client
        });
      }
      const updateFilter = [...userFilters];
      if (find(updateFilter, { filterName: MY_LAB })) {
        updateFilter.shift();
      }
      if (updateUserFilters) {
        updateUserFilters([...updateFilter]);
      }
    } else {
      const removeIndex = userFilters.findIndex(
        (item) => item.displayName === chipToDelete?.displayName
      );
      userFilters.splice(removeIndex, 1);
      const savedColumn =
        typeof lastSavedChip === "string"
          ? JSON.parse(lastSavedChip)
          : lastSavedChip;
      if (savedColumn?.displayName === chipToDelete?.displayName) {
        await onUpdateSelectColumn({
          data: JSON.stringify({
            displayDefinition:
              typeof chipToDelete?.displayDefinition === "string"
                ? JSON.parse(chipToDelete?.displayDefinition)
                : chipToDelete?.displayDefinition,
            displayName: ""
          }),
          updateLastDisplayColumn,
          userProfileQuery,
          id: userID,
          email,
          client
        });
      }
      const updateColumns = [...userFilters];
      if (find(updateColumns, { displayName: SOP_RT })) {
        updateColumns.shift();
      }
      userDisplayList = [...updateColumns];
      if (updateUserDisplayColumns) {
        updateUserDisplayColumns([...updateColumns]);
      }
    }
    Notify({
      type: "success",
      icon: "circle_confirm",
      appName: "",
      text:
        panel === TOOL_PANELS?.filters
          ? "Filter successfully deleted"
          : "Columns set successfully deleted"
    });
  } catch (err) {
    Notify({
      type: "warning",
      icon: "caution",
      appName: "",
      text:
        panel === TOOL_PANELS?.filters
          ? "Filter deletion failure"
          : "Columns set deletion failure"
    });
  }
  return { result, userFilters, panel };
};

export const saveLastPageSize = async ({
  lastPageSize,
  query,
  updateLastPageSize,
  id,
  email,
  client
}) => {
  try {
    await client.mutate({
      mutation: query,
      variables: { id, email, lastPageSize }
    });
    updateLastPageSize(lastPageSize);
  } catch (err) {
    console.log(" last page size fetch!", { err });
  }
};
