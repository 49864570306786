import { DateTimeDetailValue } from "../../../../components/shared/details-cells/DateTimeDetailValue";
import {
  ApproverCell,
  DescriptionCell,
  OperatorCell
} from "../../common-for-log-sheet-and-run-logs/HistoryCommonCellsInfo";
import ValueOrFallbackText from "../../common-for-log-sheet-and-run-logs/ValueOrFallbackText";
import RunStatusLabel from "../../common-for-log-sheet-and-run-logs/RunStatusLabel";
import { MediaTypeCell } from "../../common-for-log-sheet-and-run-logs/table-common-meta-info";
import { changeDateFormat } from "../../helpers";
import RUN_LOGS_DATA_MODEL from "../../../../utils/constants/runLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "./../../../../utils/constants/commonLogsDataModel";

export const CellsInfoArray = [
  {
    label: COMMON_LOGS_DATA_MODEL.runStartDate.value,
    component: ({ item }) => (
      <DateTimeDetailValue
        date={changeDateFormat(item?.runStartDate)}
        time={item.runStartTime}
      />
    )
  },
  {
    label: COMMON_LOGS_DATA_MODEL.runEndDate.value,
    component: ({ item }) => (
      <DateTimeDetailValue
        date={changeDateFormat(item?.runEndDate)}
        time={item.runEndTime}
      />
    )
  },
  {
    label: RUN_LOGS_DATA_MODEL.runStatus.value,
    component: RunStatusLabel,
    props: {
      fullRow: false
    }
  },
  DescriptionCell,
  ApproverCell,
  OperatorCell,
  {
    label: RUN_LOGS_DATA_MODEL.runIdentification.value,
    field: ["runIdentification"],
    props: {
      fullRow: false
    }
  },
  {
    label: RUN_LOGS_DATA_MODEL.samplesProcessed.value,
    field: ["samplesProcessed"],
    props: {
      fullRow: false
    }
  },
  {
    label: RUN_LOGS_DATA_MODEL.numberOfRuns.value,
    field: ["numberOfRuns"],
    props: {
      fullRow: false
    }
  },
  {
    label: RUN_LOGS_DATA_MODEL.defectId.value,
    field: ["defectId"],
    props: {
      fullRow: false
    }
  },
  {
    label: RUN_LOGS_DATA_MODEL.mediaType.value,
    component: MediaTypeCell,
    props: {
      fullRow: false
    }
  },
  {
    label: RUN_LOGS_DATA_MODEL.tipsUsed.value,
    component: ({ item }) => (
      <ValueOrFallbackText
        reduxPath={["runLogsForm", "tips"]}
        item={item.tipsUsed}
        defaultValue="-"
      />
    ),
    props: {
      fullRow: false
    }
  },
  {
    label: RUN_LOGS_DATA_MODEL.assay.value,
    field: ["assay"],
    component: ({ item }) => (
      <span>
        {item.assay && item.assay.length > 0 ? item.assay.join(", ") : "-"}
      </span>
    ),
    props: {
      fullRow: true
    }
  }
];
