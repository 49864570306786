import "jspdf-autotable";
import { jsPDF } from "jspdf";
import moment from "moment";

export const A4_PAGE_HEIGHT = 297;
export const A4_PAGE_MARGIN = 14;

const addFooters = (doc, params) => {
  const pageCount = doc.internal.getNumberOfPages();
  const footerPositionY = doc.internal.pageSize.height - A4_PAGE_MARGIN / 2;
  doc.setFontSize(11);
  doc.setFont("Helvetica");
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.text(params?.text, A4_PAGE_MARGIN, footerPositionY, {
      align: "left"
    });
    doc.text(
      "Page " + String(i) + "/" + String(pageCount),
      doc.internal.pageSize.width - A4_PAGE_MARGIN,
      footerPositionY,
      {
        align: "right"
      }
    );
  }
};

const addChangeLogFooters = (doc, meta) => {
  const pageCount = doc.internal.getNumberOfPages();
  const footerPositionY = doc.internal.pageSize.height - A4_PAGE_MARGIN / 2;
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(11);
    doc.setFont("Helvetica");
    doc.text(
      String(i) + "/" + String(pageCount),
      doc.internal.pageSize.width - A4_PAGE_MARGIN,
      footerPositionY,
      {
        align: "right"
      }
    );
  }
};

const addAllLogFooters = (doc, meta) => {
  const pageCount = doc.internal.getNumberOfPages();
  const footerPositionY = doc.internal.pageSize.height - A4_PAGE_MARGIN / 2;
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(17);
    doc.setFont("Helvetica");
    const titleText = doc.splitTextToSize(
      `${meta?.title}`,
      doc.internal.pageSize.width - A4_PAGE_MARGIN - 60
    );
    doc.text(titleText, A4_PAGE_MARGIN, 15);
    if (meta?.image) {
      doc.addImage(
        meta?.image?.src,
        "PNG",
        doc.internal.pageSize.width - A4_PAGE_MARGIN - 30,
        10,
        25,
        10,
        {}
      );
    }
    doc.setFontSize(11);
    doc.setFont("Helvetica");
    doc.text(
      String(i) + "/" + String(pageCount),
      doc.internal.pageSize.width - A4_PAGE_MARGIN,
      footerPositionY,
      {
        align: "right"
      }
    );
  }
};

const addAllLogTable = (doc, finalY, meta, results) => {
  doc.setFontSize(12);
  doc.setFont("Helvetica");
  // let footerPositionY = doc.internal.pageSize.height - A4_PAGE_MARGIN - 15;
  let footerPositionY = meta?.entryType ? finalY : finalY + 60;
  doc.text(`All log list`, A4_PAGE_MARGIN, footerPositionY);
  footerPositionY += 4;
  doc.autoTable({
    startY: footerPositionY,
    head: [meta?.tableHeaders?.map((item) => item?.header)],
    body: results,
    headStyles: {
      fillColor: [245, 245, 242],
      textColor: [33, 31, 31],
      fontStyle: "normal",
      fontSize: 9
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255]
    },
    styles: { overflow: "linebreak", fontSize: 9 },
    columns: meta?.tableHeaders?.map((item) => ({
      header: item?.header,
      dataKey: item?.header
    })),
    columnStyles: getCellStyles(meta?.tableHeaders),
    willDrawCell: function (data) {
      // add borders around the head cells
      if (data.section === "body") {
        doc.setDrawColor(211, 211, 211); // set the border color
        doc.setLineWidth(0.5); // set the border with
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        );
      }
    },
    didDrawPage: (data) => {
      data.settings.margin.top = 35;
    }
  });
};

const addRunLogTable = (doc, finalY, meta, results) => {
  doc.setFontSize(12);
  doc.setFont("Helvetica");
  let footerPositionY = meta?.entryType ? finalY : finalY + 60;
  doc.text(`Run log list`, A4_PAGE_MARGIN, footerPositionY);
  footerPositionY += 4;
  doc.autoTable({
    startY: footerPositionY,
    head: [meta?.tableHeaders?.map((item) => item?.header)],
    body: results,
    headStyles: {
      fillColor: [245, 245, 242],
      textColor: [33, 31, 31],
      fontStyle: "normal",
      fontSize: 9
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255]
    },
    styles: { overflow: "linebreak", fontSize: 9 },
    columns: meta?.tableHeaders?.map((item) => ({
      header: item?.header,
      dataKey: item?.header
    })),
    columnStyles: getCellStyles(meta?.tableHeaders),
    willDrawCell: function (data) {
      // add borders around the head cells
      if (data.section === "body") {
        doc.setDrawColor(211, 211, 211); // set the border color
        doc.setLineWidth(0.5); // set the border with
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        );
      }
    },
    didDrawPage: (data) => {
      data.settings.margin.top = 35;
    }
  });
};

const addRunLogFooters = (doc, meta) => {
  const pageCount = doc.internal.getNumberOfPages();
  const footerPositionY = doc.internal.pageSize.height - A4_PAGE_MARGIN / 2;
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(17);
    doc.setFont("Helvetica");
    const titleText = doc.splitTextToSize(
      `${meta?.title}`,
      doc.internal.pageSize.width - A4_PAGE_MARGIN - 60
    );
    doc.text(titleText, A4_PAGE_MARGIN, 15);
    if (meta?.image) {
      doc.addImage(
        meta?.image?.src,
        "PNG",
        doc.internal.pageSize.width - A4_PAGE_MARGIN - 30,
        10,
        25,
        10,
        {}
      );
    }
    doc.setFontSize(11);
    doc.setFont("Helvetica");
    doc.text(
      String(i) + "/" + String(pageCount),
      doc.internal.pageSize.width - A4_PAGE_MARGIN,
      footerPositionY,
      {
        align: "right"
      }
    );
  }
};

const addActionLogTable = (doc, finalY, meta, results) => {
  doc.setFontSize(12);
  doc.setFont("Helvetica");
  let footerPositionY = meta?.entryType ? finalY : finalY + 60;
  doc.text(`Action log list`, A4_PAGE_MARGIN, footerPositionY);
  footerPositionY += 4;
  doc.autoTable({
    startY: footerPositionY,
    head: [meta?.tableHeaders?.map((item) => item?.header)],
    body: results,
    headStyles: {
      fillColor: [245, 245, 242],
      textColor: [33, 31, 31],
      fontStyle: "normal",
      fontSize: 9
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255]
    },
    styles: { overflow: "linebreak", fontSize: 9 },
    columns: meta?.tableHeaders?.map((item) => ({
      header: item?.header,
      dataKey: item?.header
    })),
    columnStyles: getCellStyles(meta?.tableHeaders),
    willDrawCell: function (data) {
      // add borders around the head cells
      if (data.section === "body") {
        doc.setDrawColor(211, 211, 211); // set the border color
        doc.setLineWidth(0.5); // set the border with
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        );
      }
    },
    didDrawPage: (data) => {
      data.settings.margin.top = 35;
    }
  });
};

const addActionLogFooters = (doc, meta) => {
  const pageCount = doc.internal.getNumberOfPages();
  const footerPositionY = doc.internal.pageSize.height - A4_PAGE_MARGIN / 2;
  for (let i = 1; i <= pageCount; i++) {
    doc.setPage(i);
    doc.setFontSize(17);
    doc.setFont("Helvetica");
    const titleText = doc.splitTextToSize(
      `${meta?.title}`,
      doc.internal.pageSize.width - A4_PAGE_MARGIN - 60
    );
    doc.text(titleText, A4_PAGE_MARGIN, 15);
    if (meta?.image) {
      doc.addImage(
        meta?.image?.src,
        "PNG",
        doc.internal.pageSize.width - A4_PAGE_MARGIN - 30,
        10,
        25,
        10,
        {}
      );
    }
    doc.setFontSize(11);
    doc.setFont("Helvetica");
    doc.text(
      String(i) + "/" + String(pageCount),
      doc.internal.pageSize.width - A4_PAGE_MARGIN,
      footerPositionY,
      {
        align: "right"
      }
    );
  }
};

const addHierarchyTable = (doc, finalY, meta) => {
  doc.setFontSize(12);
  doc.setFont("Helvetica");
  let footerPositionY = finalY;
  doc.autoTable({
    startY: footerPositionY,
    head: [meta?.hierarchyHeaders?.map((item) => item?.header)],
    body: meta?.hierarchyRecords,
    headStyles: {
      fillColor: [245, 245, 242],
      textColor: [33, 31, 31],
      fontStyle: "normal",
      fontSize: 9
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255]
    },
    styles: { overflow: "linebreak", fontSize: 9 },
    columns: meta?.hierarchyHeaders?.map((item) => ({
      header: item?.header,
      dataKey: item?.header
    })),
    columnStyles: getCellStyles(meta?.hierarchyHeaders),
    didParseCell: function (data) {
      if (data.column.dataKey === "Model") {
        if (data.section === "body") {
          data.cell.styles.cellPadding =
            data.row.raw[0] === 3
              ? {
                  top: data.cell.styles.cellPadding,
                  right: data.cell.styles.cellPadding - 0.3,
                  bottom: data.cell.styles.cellPadding - 0.3,
                  left: 14
                }
              : data.row.raw[0] === 2
              ? {
                  top: data.cell.styles.cellPadding,
                  right: data.cell.styles.cellPadding - 0.3,
                  bottom: data.cell.styles.cellPadding - 0.3,
                  left: 7
                }
              : data.cell.styles.cellPadding;
        }
      }
      if (data.column.dataKey === "") {
        if (data.section === "body") {
          data.cell.styles.textColor = [0, 0, 0, 0];
        }
      }
    },
    willDrawCell: function (data) {
      // add borders around the head cells
      if (data.section === "body") {
        doc.setDrawColor(211, 211, 211); // set the border color
        doc.setLineWidth(0.5); // set the border with
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        );
      }
    },
    didDrawPage: (data) => {
      data.settings.margin.top = 35;
    }
  });
};

export const getImageData = async (url) => {
  if (!url) {
    return null;
  }
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    if (blob.type !== "image/png") {
      return null;
    }
    return URL.createObjectURL(blob);
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const getCellStyles = (headers) =>
  headers?.reduce(
    (acc, curr) =>
      curr?.value !== "auto"
        ? {
            ...acc,
            [curr?.header]: {
              cellWidth: curr?.value
            }
          }
        : acc,
    {}
  );

export const createPDF = async (results, meta) => {
  const doc = new jsPDF("landscape", "mm");
  let finalY = 15;
  finalY += 10;
  doc.setFontSize(12);
  const footerPositionY = doc.internal.pageSize.width - A4_PAGE_MARGIN;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  doc.text(`Creation date:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${moment().format("DD-MMM-yyyy h:mm a")}`, 70, finalY);
  finalY += 6;
  doc.text(`Author:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${meta?.createdBy}`, 70, finalY);
  finalY += 6;
  doc.text(`Applied filters:`, A4_PAGE_MARGIN, finalY);
  const filtersText = doc.splitTextToSize(`${meta?.filters}`, 160);
  doc.text(filtersText, 70, finalY);
  finalY += 6 * filtersText.length;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 10;
  doc.autoTable({
    startY: finalY,
    head: [meta?.tableHeaders?.map((item) => item?.header)],
    body: results,
    headStyles: {
      fillColor: [245, 245, 242],
      textColor: [33, 31, 31],
      fontStyle: "normal",
      fontSize: 9
    },
    alternateRowStyles: {
      fillColor: [255, 255, 255]
    },
    styles: { overflow: "linebreak", fontSize: 9 },
    columns: meta?.tableHeaders?.map((item) => ({
      header: item?.header,
      dataKey: item?.header
    })),
    columnStyles: getCellStyles(meta?.tableHeaders),
    willDrawCell: function (data) {
      // add borders around the head cells
      if (data.section === "body") {
        doc.setDrawColor(211, 211, 211); // set the border color
        doc.setLineWidth(0.5); // set the border with
        doc.line(
          data.cell.x + data.cell.width,
          data.cell.y,
          data.cell.x,
          data.cell.y
        );
      }
    },
    didDrawPage: (data) => {
      data.settings.margin.top = 35;
    }
  });

  addRunLogFooters(doc, meta);
  return doc;
};

export const createRunLogPDF = async (results, meta) => {
  const doc = new jsPDF("landscape", "mm");
  let finalY = 15;
  finalY += 28;
  doc.setFontSize(12);
  const footerPositionY = doc.internal.pageSize.width - A4_PAGE_MARGIN;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  doc.text(`Creation date:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${moment().format("DD-MMM-yyyy h:mm a")}`, 70, finalY);
  finalY += 6;
  doc.text(`Author:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${meta?.createdBy}`, 70, finalY);
  finalY += 6;
  doc.text(`Applied filters:`, A4_PAGE_MARGIN, finalY);
  const filtersText = doc.splitTextToSize(`${meta?.filters}`, 160);
  doc.text(filtersText, 70, finalY);
  finalY += 6 * filtersText.length;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  doc.text(
    meta?.entryType ? `Hierarchy` : `Attributes`,
    A4_PAGE_MARGIN,
    finalY
  );

  finalY += 3;
  if (meta?.entryType) {
    addHierarchyTable(doc, finalY, meta);
    let finalAHY = doc.lastAutoTable.finalY + 10;
    addRunLogTable(doc, finalAHY, meta, results);
    addRunLogFooters(doc, meta);
    return doc;
  } else {
    let finalAHY = finalY;
    var temp = document.createElement("div");
    temp.innerHTML = meta?.attributes;
    var htmlObject = temp.firstChild;

    doc.html(htmlObject, {
      callback: function (doc) {
        addRunLogTable(doc, finalAHY, meta, results);
        addRunLogFooters(doc, meta);
        // Save the PDF
        doc.save(meta?.file?.fileName);
      },
      x: A4_PAGE_MARGIN,
      y: finalAHY,
      width: doc.internal.pageSize.width - 40,
      windowWidth: 750
    });
    return doc;
  }
};

export const createActionLogPDF = async (results, meta) => {
  const doc = new jsPDF("landscape", "mm");
  let finalY = 15;
  finalY += 28;
  doc.setFontSize(12);
  const footerPositionY = doc.internal.pageSize.width - A4_PAGE_MARGIN;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  doc.text(`Creation date:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${moment().format("DD-MMM-yyyy h:mm a")}`, 70, finalY);
  finalY += 6;
  doc.text(`Author:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${meta?.createdBy}`, 70, finalY);
  finalY += 6;
  doc.text(`Applied filters:`, A4_PAGE_MARGIN, finalY);
  const filtersText = doc.splitTextToSize(`${meta?.filters}`, 160);
  doc.text(filtersText, 70, finalY);
  finalY += 6 * filtersText.length;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  doc.text(
    meta?.entryType ? `Hierarchy` : `Attributes`,
    A4_PAGE_MARGIN,
    finalY
  );

  finalY += 3;
  if (meta?.entryType) {
    addHierarchyTable(doc, finalY, meta);
    let finalAHY = doc.lastAutoTable.finalY + 10;
    addActionLogTable(doc, finalAHY, meta, results);
    addActionLogFooters(doc, meta);
    return doc;
  } else {
    let finalAHY = finalY;
    var temp = document.createElement("div");
    temp.innerHTML = meta?.attributes;
    var htmlObject = temp.firstChild;

    doc.html(htmlObject, {
      callback: function (doc) {
        addActionLogTable(doc, finalAHY, meta, results);
        addActionLogFooters(doc, meta);
        // Save the PDF
        doc.save(meta?.file?.fileName);
      },
      x: A4_PAGE_MARGIN,
      y: finalAHY,
      width: doc.internal.pageSize.width - 40,
      windowWidth: 750
    });
    return doc;
  }
};

export const createAllLogPDF = async (results, meta) => {
  const doc = new jsPDF("landscape", "mm");
  let finalY = 15;
  finalY += 28;
  doc.setFontSize(12);
  const footerPositionY = doc.internal.pageSize.width - A4_PAGE_MARGIN;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  doc.text(`Creation date:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${moment().format("DD-MMM-yyyy h:mm a")}`, 70, finalY);
  finalY += 6;
  doc.text(`Author:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${meta?.createdBy}`, 70, finalY);
  finalY += 6;
  doc.text(`Applied filters:`, A4_PAGE_MARGIN, finalY);
  const filtersText = doc.splitTextToSize(`${meta?.filters}`, 160);
  doc.text(filtersText, 70, finalY);
  finalY += 6 * filtersText.length;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  doc.text(
    meta?.entryType ? `Hierarchy` : `Attributes`,
    A4_PAGE_MARGIN,
    finalY
  );

  finalY += 3;
  if (meta?.entryType) {
    addHierarchyTable(doc, finalY, meta);
    let finalAHY = doc.lastAutoTable.finalY + 10;
    addAllLogTable(doc, finalAHY, meta, results);
    addAllLogFooters(doc, meta);
    return doc;
  } else {
    let finalAHY = finalY;
    var temp = document.createElement("div");
    temp.innerHTML = meta?.attributes;
    var htmlObject = temp.firstChild;

    doc.html(htmlObject, {
      callback: function (doc) {
        addAllLogTable(doc, finalAHY, meta, results);
        addAllLogFooters(doc, meta);
        // Save the PDF
        doc.save(meta?.file?.fileName);
      },
      x: A4_PAGE_MARGIN,
      y: finalAHY,
      width: doc.internal.pageSize.width - 40,
      windowWidth: 750
    });
    return doc;
  }

  /* Future purpose */
  // let finalAHY = finalY + 5;
  // finalY += 7;
  // doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);

  // var temp = document.createElement("div");
  // temp.innerHTML = meta?.hierarchy;
  // var htmlObject = temp.firstChild;

  // doc.html(htmlObject, {
  //   callback: function (doc) {
  //     addAllLogTable(doc, finalAHY, meta, results);
  //     addAllLogFooters(doc);
  //     // Save the PDF
  //     // window.open(doc.output("bloburl"), "_blank");
  //     doc.save(meta?.file?.fileName);
  //   },
  //   x: A4_PAGE_MARGIN,
  //   y: finalAHY,
  //   width: 280,
  //   windowWidth: 750
  // });
  // return doc;
};

export const createSnapShotPDF = async (results, meta) => {
  const doc = new jsPDF("landscape", "mm");
  let finalY = 15;
  doc.setFontSize(20);
  doc.setFont("Helvetica");
  doc.text(`${meta?.title}`, A4_PAGE_MARGIN, finalY);
  finalY += 10;
  doc.setFontSize(12);
  doc.text(`Creation Date:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${moment().format("DD-MMM-yyyy h:mm a")}`, 70, finalY);
  finalY += 6;
  doc.text(`Created by:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${meta?.createdBy}`, 70, finalY);
  finalY += 6;
  doc.text(`Applied Filters:`, A4_PAGE_MARGIN, finalY);
  const filtersText = doc.splitTextToSize(`${meta?.filters}`, 160);
  doc.text(filtersText, 70, finalY);
  finalY += 6 * filtersText.length + 4;

  if (meta?.image) {
    doc.addImage(
      meta?.image?.src,
      "PNG",
      doc.internal.pageSize.width - A4_PAGE_MARGIN - 45,
      15,
      45,
      20,
      {}
    );
  }
  doc.autoTable({
    startY: finalY,
    head: [meta?.tableHeaders?.map((item) => item?.header)],
    body: results,
    headStyles: { fillColor: [219, 219, 219], textColor: [31, 31, 31] },
    styles: { overflow: "linebreak", fontSize: 9 },
    columns: meta?.tableHeaders?.map((item) => ({
      header: item?.header,
      dataKey: item?.header
    })),
    columnStyles: getCellStyles(meta?.tableHeaders)
  });

  addFooters(doc, {
    text: `${meta?.title} Creation Date: ${moment().format(
      "DD-MMM-yyyy hh:mm a"
    )}`
  });
  return doc;
};

export const createHTMLPDF = async (results, meta) => {
  const doc = new jsPDF("portrait", "mm");
  let finalY = 15;
  doc.setFontSize(17);
  doc.setFont("Helvetica");
  const titleText = doc.splitTextToSize(`${meta?.title}`, 148);
  doc.text(titleText, A4_PAGE_MARGIN, finalY);
  finalY += 28;
  doc.setFontSize(12);
  const footerPositionY = doc.internal.pageSize.width - A4_PAGE_MARGIN;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  doc.text(`Creation Date:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${moment().format("DD-MMM-yyyy h:mm a")}`, 70, finalY);
  finalY += 6;
  doc.text(`Author:`, A4_PAGE_MARGIN, finalY);
  doc.text(`${meta?.createdBy}`, 70, finalY);
  finalY += 7;
  doc.text(`Applied filters:`, A4_PAGE_MARGIN, finalY);
  const filtersText = doc.splitTextToSize(`${meta?.filters}`, 160);
  doc.text(filtersText, 70, finalY);
  finalY += 6 * filtersText.length + 4;
  doc.line(A4_PAGE_MARGIN, finalY, footerPositionY, finalY);
  finalY += 7;
  if (meta?.image) {
    doc.addImage(
      meta?.image?.src,
      "PNG",
      doc.internal.pageSize.width - A4_PAGE_MARGIN - 30,
      10,
      25,
      10,
      {}
    );
  }
  var temp = document.createElement("div");
  temp.innerHTML = results;
  var htmlObject = temp.firstChild;
  finalY -= 13;

  doc.html(htmlObject, {
    callback: function (doc) {
      addChangeLogFooters(doc, meta);
      // Save the PDF
      doc.save(meta?.file?.fileName);
    },
    margin: [15, 0, 30, 0],
    autoPaging: "slice",
    x: A4_PAGE_MARGIN,
    y: finalY,
    width: doc.internal.pageSize.width - 28,
    windowWidth: 750
  });
  return doc;
};
