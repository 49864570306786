import { generateID } from "@digitallab/grid-common-components";
import React, { useRef, useState } from "react";
import CustomTimePicker from "../../components/shared/TimePicker";
import { entryType, Module } from "../../constants";
import LOGBOOK_LABEL from "../../utils/constants/logbookLabel";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { useEffect } from "react";
import moment from "moment";
import {
  OwcButton,
  OwcDatepicker,
  OwcIconButton,
  OwcTypography
} from "@one/react";

const SnapshotAction = ({
  inventoryId,
  showSnapShot,
  clearSnapShot,
  exportSnapShotPDF,
  bgColor,
  borderBottom,
  snapShotDate,
  setSnapShotDate,
  snapShotTime,
  setSnapShotTime,
  showSnapShotClk,
  setShowSnapShotClk,
  equipmentDetail
}) => {
  const datepickerElement = useRef(null);
  const [disableExportIcon, setDisableExportIcon] = useState(true);
  const dateValue = (value) => {
    setSnapShotDate(() => value);
    if (showSnapShotClk && value && snapShotTime) {
      showSnapShot({
        snapShotDate: value,
        snapShotTime,
        id: inventoryId
      });
    }
    if (showSnapShotClk && !value && !snapShotTime) {
      setShowSnapShotClk(false);
      clearSnapShot(inventoryId);
    }
  };

  const timeValue = (value) => {
    setSnapShotTime(() => value);
    if (showSnapShotClk && value && snapShotDate) {
      showSnapShot({
        snapShotDate,
        snapShotTime: value,
        id: inventoryId
      });
    }
    if (showSnapShotClk && !value && !snapShotDate) {
      setShowSnapShotClk(false);
      clearSnapShot(inventoryId);
    }
  };

  useEffect(() => {
    if (!equipmentDetail) {
      setDisableExportIcon(true);
    } else if (equipmentDetail?.entryType !== entryType?.standaloneEquipment) {
      setDisableExportIcon(true);
    } else {
      setDisableExportIcon(false);
    }
  }, [equipmentDetail, snapShotDate, snapShotTime, showSnapShotClk]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent:
          snapShotDate &&
          showSnapShotClk &&
          equipmentDetail?.entryType === entryType?.standaloneEquipment
            ? "space-between"
            : "flex-end",
        borderBottom: borderBottom ? "" : "",
        background: bgColor,
        marginBottom: 5
      }}
    >
      {snapShotDate &&
        showSnapShotClk &&
        equipmentDetail?.entryType === entryType?.standaloneEquipment && (
          <div style={{ paddingLeft: "30px" }}>
            <OwcTypography
              style={{
                margin: "auto"
              }}
            >
              <b>Snapshot ({moment(snapShotDate).format("DD-MMM-yyyy")})</b>
            </OwcTypography>
          </div>
        )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <OwcDatepicker
          ref={datepickerElement}
          autoClose
          hasClearIcon
          className="date-from-picker"
          data-testid={`coverSheetSnapShot-datepicker`}
          id={generateID.customID(
            Module.COVERSHEET.picker.coverSheetSnapShot,
            "CustomDatePicker",
            "datepicker"
          )}
          label={"Date  "}
          placeholder={"DD-MM-YYYY"}
          variant="filled"
          style={{ width: "180px", marginRight: 10 }}
          format="dd-MMM-yyyy"
          value={snapShotDate}
          onValueChange={(e) => dateValue(e?.detail)}
        />
        <CustomTimePicker
          time={snapShotTime}
          data-testid={`coverSheetSnapShot-timepicker`}
          id={generateID.customID(
            Module.COVERSHEET.picker.coverSheetSnapShot,
            "CustomTimePicker",
            "timepicker"
          )}
          setTime={timeValue}
          label={"Time  "}
          placeholder={"HH:MM"}
          style={{ width: 155, marginRight: 10 }}
        />
        {showSnapShotClk && (
          <OwcButton
            variant="primary"
            data-testid="coversheet-clear-snapshot-btn"
            onClick={() => {
              setSnapShotDate(() => null);
              setSnapShotTime(() => null);
              setShowSnapShotClk(false);
              clearSnapShot(inventoryId);
            }}
            id={generateID.buttonID(
              Module.COVERSHEET.button.clearSnapshotBtn,
              "button"
            )}
          >
            {LOGBOOK_LABEL.BUTTON.clear_snapshot}
          </OwcButton>
        )}
        {!showSnapShotClk && (
          <OwcButton
            variant="primary"
            data-testid="coversheet-snapshot-btn"
            onClick={() => {
              setShowSnapShotClk(true);
              showSnapShot({
                snapShotDate,
                snapShotTime,
                id: inventoryId
              });
            }}
            disabled={!(snapShotDate && snapShotTime)}
            id={generateID.buttonID(
              Module.COVERSHEET.button.showSnapshotBtn,
              "button"
            )}
          >
            {LOGBOOK_LABEL.BUTTON.show_snapshot}
          </OwcButton>
        )}
        <OwcIconButton
          data-testid="clear-search-button"
          disabled={disableExportIcon}
          onClick={(evt) => {
            evt.stopPropagation();
            exportSnapShotPDF(snapShotDate ?? null, snapShotTime ?? null);
          }}
          icon="export"
          type="outlined"
          flat
        />
        <div style={{ paddingRight: "10px" }}>
          <OwcTypography
            style={{
              margin: "auto"
            }}
          >
            Export
          </OwcTypography>
        </div>
      </div>
    </div>
  );
};
export default compose(connect(null, {}), withApollo)(SnapshotAction);
