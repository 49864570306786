import { useFormikContext } from "formik";
import styled from "styled-components";
import {
  commonPropsForInputsWithoutValue,
  changeDateFormat
} from "../../helpers";
import {
  SOFTWARE_CHANGE,
  formTypes,
  formStates,
  equipmentStatus
} from "../../../../constants";
import {
  DescriptionWrapper,
  EmptyLinkContainer,
  EmptyLinkContainerStyled,
  FlexView,
  LinkContainerDiv,
  LinkContainerDivInnerLeft,
  LinkContainerDivInnerRight,
  LinkContainerStyled,
  Mainbox,
  Title,
  TwoCell
} from "../../log-book-item-form-dialog/FormsStyled";
import { useSelector } from "react-redux";
import { useContext, useState } from "react";
import LOGBOOK_LABEL from "../../../../utils/constants/logbookLabel";
import ACTION_LOGS_DATA_MODEL from "../../../../utils/constants/actionLogsDataModel";
import COMMON_LOGS_DATA_MODEL from "../../../../utils/constants/commonLogsDataModel";
import { generateID } from "@digitallab/grid-common-components";
import { Module } from "../../../../constants";
import { useEffect } from "react";
import {
  OwcCard,
  OwcCheckbox,
  OwcDatepicker,
  OwcIcon,
  OwcInput,
  OwcTextarea,
  OwcTooltip,
  OwcTypography
} from "@one/react";
import styles from "./ActionDataInputsBoxCluster.module.scss";
import RunTimeInput from "../../run-logs/run-logs-form/run-data-inputs/RunTimeInput";
import { CoversheetMainPageContext } from "../../../cover-sheet/coversheet-main-page-context/context";
import { logSheetClusterClear } from "./log-sheet-clear-suggestion";
import CommonOwcEditableDropDown from "../../../../components/shared/CommonOwcEditableDropDown";
import { find } from "lodash";
import IconEmptyBox from "../../../../icons/IconEmptyBox";
import { ItemDialogFormContext } from "../../log-book-item-form-dialog/item-context/context";
import { LogBookSelectContext } from "../../log-book-table/logBooksSelectAll/context";
const Container = styled.div`
  & > div > div > .MuiInputBase-root {
    padding-top: 12px;
  }
`;
const UpdateVersionShouldBePublishedStyled = styled.div`
  background-color: #fafafa;
  padding: 3px 12px;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const ActionDataInputsBoxCluster = ({ equipmentDetail }) => {
  const formik = useFormikContext();
  const classes = {};
  const actionLog = useSelector((store) => store.runLogsForm.actionLog);
  const { logType, formState } = useContext(CoversheetMainPageContext);
  const user = useSelector((state) => state.user);
  const [actionTime, setActionTime] = useState(null);
  const [selectedActionType, setSelectedActionType] = useState(null);
  const { selected } = useContext(LogBookSelectContext);
  const {
    setActionLogLinkName,
    setActionLogLinkUrl,
    setActionLogLinkCurrentIndex,
    setActionLogLinkCurrentFlag,
    setAddLinkFlag,
    subClusterPendingFlag,
    setSubClusterPendingFlag
  } = useContext(ItemDialogFormContext);
  useEffect(() => {
    if (formik?.values?.action) {
      setSelectedActionType(() => {
        if (formik?.values?.action?.key) {
          const obj = find(actionLog, { value: formik?.values?.action?.value });
          if (!obj) {
            formik.setFieldError("action", "Enter action type");
          }
          return obj ? obj.value : null;
        }
        return null;
      });

      if (
        formik.values.action?.value?.toLowerCase() ===
          SOFTWARE_CHANGE.toLowerCase() &&
        formState !== formStates?.EDITABLE_FORM
      ) {
        formik.setFieldValue(
          "updatedSoftwareVersion.newValue",
          equipmentDetail?.softwareVersion
            ? equipmentDetail?.softwareVersion
            : null
        );
      }
    } else {
      setSelectedActionType(() => null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik?.values?.action]);

  useEffect(() => {
    if (logType === formTypes?.ACTION_LOG) {
      if (formState !== formStates?.EDITABLE_FORM) {
        logSheetClusterClear({ formik, user, equipmentDetail });
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const pendingSub = find(selected, {
      status: equipmentStatus?.pending?.key
    });
    setSubClusterPendingFlag(pendingSub ? true : false);
    // eslint-disable-next-line
  }, [selected]);

  useEffect(() => {
    if (formState !== formStates?.EDITABLE_FORM) {
      let time = formik?.values?.actionTime
        ? new Date(
            changeDateFormat(new Date()) + " " + formik?.values?.actionTime
          )
        : null;

      setActionTime(time);
    } else {
      const tempActionTime = formik?.values?.actionTime
        ? new Date(
            changeDateFormat(new Date()) + " " + formik?.values?.actionTime
          )
        : null;
      setActionTime(tempActionTime);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.actionTime]);

  const openLinkPopup = (str) => {
    setActionLogLinkCurrentFlag(str);
  };
  const dateValue = (value) => {
    if (
      (value.detail instanceof Date && !isNaN(value.detail)) ||
      value.detail === null
    ) {
      formik.setFieldValue("actionDate", value.detail, true);
    }
  };

  const onSelectedActionType = (actionType) => {
    if (formik?.values?.action?.key !== actionType?.key) {
      if (actionType?.value === SOFTWARE_CHANGE) {
        formik.setFieldValue(
          "updatedSoftwareVersion.shouldBePublished",
          Boolean(formState !== formStates?.EDITABLE_FORM)
        );
      } else {
        formik.setFieldValue("updatedSoftwareVersion.shouldBePublished", null);
        formik.setFieldValue("updatedSoftwareVersion.newValue", null);
      }
      formik.setValues({
        ...formik.values,
        action: actionType
      });
      setSelectedActionType(() => actionType?.value ?? null);
    }
  };

  return (
    <>
      <Mainbox>
        <Title data-testid="action-data-title">
          {LOGBOOK_LABEL.action_data}
        </Title>
        <OwcDatepicker
          style={{ width: "100%" }}
          autoClose
          inputVariant="filled"
          onBlur={formik.handleBlur}
          format="dd-MMM-yyyy"
          data-testid="action-data-inputs-action-date-input"
          id={generateID.UUID(Module.POPUP_FIELDS.actionDate, 1, "datePicker")}
          name="actionDate"
          variant="filled"
          maxDate={new Date().getTime()}
          label={`${ACTION_LOGS_DATA_MODEL.actionDate.value} *`}
          value={
            typeof formik.values.actionDate === "string"
              ? new Date(formik.values.actionDate)
              : formik.values.actionDate
          }
          onValueChange={(e) => dateValue(e)}
          invalidDateMessage={"Invalid date format"}
          required={true}
          requiredDateMessage={formik.errors.actionDate}
        >
          {formik.errors.actionDate && (
            <span slot="error-text">{formik.errors.actionDate}</span>
          )}
        </OwcDatepicker>
        <RunTimeInput
          property={ACTION_LOGS_DATA_MODEL.actionTime.key}
          label={ACTION_LOGS_DATA_MODEL.actionTime.value}
          testid="run-start-time"
          timeObj={actionTime}
          setTimeObj={setActionTime}
          required={true}
          maxTime={new Date().getTime()}
          logType={formTypes?.ACTION_LOG}
        />
        <Container>
          <CommonOwcEditableDropDown
            labelValue={false}
            label={ACTION_LOGS_DATA_MODEL.actionType.value + ` *`}
            defaultLabel={ACTION_LOGS_DATA_MODEL.actionType.value + ` *`}
            propValue="value"
            list={actionLog}
            selected={selectedActionType}
            onChange={(selectedValue) => {
              const selVal = actionLog?.find(
                (value, index) => index === selectedValue
              );
              onSelectedActionType(selVal ?? null);
            }}
            required={true}
            helperText={
              formik.errors[ACTION_LOGS_DATA_MODEL.actionType.key]?.key
            }
          />
        </Container>
        <OwcInput
          onBlur={formik.handleBlur}
          style={{ width: "100%" }}
          data-testid="action-data-inputs-operator-user-name-input"
          variant="filled"
          id={generateID.textBoxID(
            Module.POPUP_FIELDS.actionOperatorUserName,
            "textbox"
          )}
          name="operatorUserName"
          label={COMMON_LOGS_DATA_MODEL.operatorUserName.value}
          value={formik.values.operatorUserName}
          onInputChange={formik.handleChange}
          {...commonPropsForInputsWithoutValue(
            classes,
            formik,
            "operatorUserName",
            "operator-user-name",
            "action-data-inputs"
          )}
        />
      </Mainbox>
      <FlexView>
        <OwcInput
          style={{ width: "100%", marginTop: "20px" }}
          onBlur={formik.handleBlur}
          data-testid="action-data-inputs-ecrNumber-input"
          variant="filled"
          id={generateID.textBoxID(Module.POPUP_FIELDS.ecrNumber, "textbox")}
          name="ecrNumber"
          label={ACTION_LOGS_DATA_MODEL.ecrNumber.value}
          value={formik.values.ecrNumber}
          onInputChange={formik.handleChange}
          {...commonPropsForInputsWithoutValue(
            classes,
            formik,
            "ecrNumber",
            "ecrNumber",
            "action-data-inputs"
          )}
          fullWidth
        />
        <DescriptionWrapper>
          <OwcTextarea
            onInputKeyDown={(e) => {
              formik.setFieldValue("description", e.target.value, true);
            }}
            onInputReset={() => formik.setFieldValue("description", "", true)}
            cols={20}
            data-testid="action-data-inputs-description-input"
            required
            id={generateID.textBoxID(
              Module.POPUP_FIELDS.actionDescription,
              "textbox"
            )}
            label={COMMON_LOGS_DATA_MODEL.description.value + ` *`}
            onInputChange={(e) => {
              formik.setFieldValue("description", e.target.value, true);
            }}
            maxLength={200}
            minLength={0}
            resizable
            reset
            rows={2}
            value={formik.values.description}
            className={styles.actionDataInputsBoxClusterWidth}
          />
        </DescriptionWrapper>
      </FlexView>

      {formik.values.action &&
        formik.values.action?.value?.toLowerCase() ===
          SOFTWARE_CHANGE.toLowerCase() && (
          <>
            <TwoCell>
              <OwcInput
                onBlur={formik.handleBlur}
                data-testid="action-data-inputs-new-software-version-input"
                variant="filled"
                id="updatedSoftwareVersion.newValue"
                name="updatedSoftwareVersion.newValue"
                label="New software version"
                value={formik.values.updatedSoftwareVersion.newValue}
                onInputChange={formik.handleChange}
                onInputKeyDown={formik.handleChange}
                className={styles.actionDataInputsBoxClusterWidth}
                disabled={
                  formState !== formStates?.EDITABLE_FORM &&
                  subClusterPendingFlag
                    ? true
                    : false
                }
              />
            </TwoCell>
            <TwoCell>
              <UpdateVersionShouldBePublishedStyled>
                <OwcCheckbox
                  data-testid="action-data-inputs-new-software-version-checkbox"
                  checked={
                    formik.values.updatedSoftwareVersion.shouldBePublished
                  }
                  name="updatedSoftwareVersion.shouldBePublished"
                  onInputChange={(e) =>
                    formik.setFieldValue(
                      "updatedSoftwareVersion.shouldBePublished",
                      e.detail
                    )
                  }
                  id="updatedSoftwareVersion.shouldBePublished"
                  disabled={
                    subClusterPendingFlag ||
                    formState === formStates?.EDITABLE_FORM
                  }
                />
                {LOGBOOK_LABEL.publish_to_ER}
              </UpdateVersionShouldBePublishedStyled>
            </TwoCell>
          </>
        )}
      <OwcTypography
        variant="subtitle1"
        id={generateID.UUID("AddLinkHeading", `addLinkHeading`, "type_title")}
        style={{ padding: "10px 0", fontWeight: "600" }}
      >
        <strong>External document and file links</strong>
      </OwcTypography>
      <OwcCard flat style={{ padding: "0px", width: "50%" }}>
        <div
          slot="header"
          style={{
            display: "flex",
            background: "var(--one-color-background-neutral-2)",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "-3px",
            padding: "10px"
          }}
        >
          <OwcTypography
            variant="subtitle2"
            id={generateID.UUID(
              "AddLinkHeading",
              `addLinkHeading`,
              "type_title"
            )}
            style={{ fontWeight: "600" }}
          >
            Link list
          </OwcTypography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer"
            }}
          >
            <OwcIcon
              type="legacy"
              variant="primary"
              name="circle_add"
              onClick={() => {
                openLinkPopup("Add");
              }}
            ></OwcIcon>
            <OwcTypography
              variant="subtitle2"
              style={{
                marginLeft: "5px",
                color: "var(--one-color-interaction-default-brand-1)"
              }}
              onClick={() => {
                openLinkPopup("Add");
              }}
            >
              <strong> Add link</strong>
            </OwcTypography>
          </div>
        </div>

        {!formik?.values?.externalDocument ||
        formik?.values?.externalDocument?.length <= 0 ? (
          <EmptyLinkContainerStyled
            data-testid="not-found-apps"
            id={generateID.UUID("EmptyApplication", `application_empty`, "div")}
          >
            <EmptyLinkContainer
              onClick={() => {
                openLinkPopup("Add");
              }}
            >
              <OwcIcon className="emptyIconMyLab">
                <IconEmptyBox />
              </OwcIcon>
              <OwcTypography
                variant="title6"
                id={generateID.UUID("PleaseAddLink", `addLink`, "type_title")}
              >
                <strong> Please add link</strong>
              </OwcTypography>
              <OwcTypography
                variant="body2"
                id={generateID.UUID(
                  "NoLinkAvailable",
                  `NoLinkAvailable`,
                  "type_Description"
                )}
              >
                No links available
              </OwcTypography>
            </EmptyLinkContainer>
          </EmptyLinkContainerStyled>
        ) : (
          <LinkContainerStyled
            data-testid="link-block"
            id={generateID.UUID("LinkBlock", `link_block`, "div")}
          >
            {formik?.values?.externalDocument?.map((item, index) => {
              return (
                <LinkContainerDiv>
                  <LinkContainerDivInnerLeft>
                    <OwcIcon type="outlined" name="link"></OwcIcon>
                    <OwcTypography
                      variant="subtitle2"
                      style={{
                        marginLeft: "5px",
                        color: "var(--one-color-interaction-default-brand-1)"
                      }}
                    >
                      {item.name}
                    </OwcTypography>
                  </LinkContainerDivInnerLeft>
                  <LinkContainerDivInnerRight>
                    <div
                      style={{ cursor: "pointer", marginRight: "5px" }}
                      onClick={() => {
                        setActionLogLinkName(
                          formik?.values?.externalDocument[index]?.name
                        );
                        setActionLogLinkUrl(
                          formik?.values?.externalDocument[index]?.link
                        );
                        setActionLogLinkCurrentIndex(index);
                        setAddLinkFlag(false);
                        openLinkPopup("Edit");
                      }}
                    >
                      <OwcIcon
                        name="write"
                        type="filled"
                        id={generateID.UUID(
                          "LinkBlockEdit",
                          `link_block`,
                          `icon-${index}`
                        )}
                      ></OwcIcon>
                      <OwcTooltip
                        anchor={generateID.UUID(
                          "LinkBlockEdit",
                          `link_block`,
                          `icon-${index}`
                        )}
                      >
                        {"Edit"}
                      </OwcTooltip>
                    </div>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        openLinkPopup("Delete");
                        setAddLinkFlag(true);
                        setActionLogLinkCurrentIndex(index);
                      }}
                    >
                      <OwcIcon
                        type="outlined"
                        name="delete"
                        id={generateID.UUID(
                          "LinkBlockDelete",
                          `link_block`,
                          `icon-${index}`
                        )}
                      ></OwcIcon>
                      <OwcTooltip
                        anchor={generateID.UUID(
                          "LinkBlockDelete",
                          `link_block`,
                          `icon-${index}`
                        )}
                      >
                        {"Delete"}
                      </OwcTooltip>
                    </div>
                  </LinkContainerDivInnerRight>
                </LinkContainerDiv>
              );
            })}
          </LinkContainerStyled>
        )}
      </OwcCard>
    </>
  );
};

export default ActionDataInputsBoxCluster;
