import styled, { css } from "styled-components";
import DetailsCells from "../../../components/shared/details-cells/DetailsCells";
import { DetailsCellStyled } from "../../../components/shared/details-cells/DetailsCellStyled";

export const Frame = styled.div`
  color: #000000;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px 40px;
`;
const FrameWithBackground = styled(Frame)`
  background-color: var(--one-color-gray-50);
  border: 1px solid var(--one-color-gray-300);
  border-radius: 4px;
  padding: 20px 16px;
`;
const DetailsCellWithFullRowStyled = styled(DetailsCellStyled)`
  overflow: hidden;
  ${(props) =>
    props.fullRow &&
    css`
      grid-column: 1 / -1;
    `}
`;

const HistoryItemSingleFrame = ({
  history,
  CellsInfoArray,
  withBackground = true,
  ownWrapper
}) => {
  return (
    <DetailsCells
      item={history}
      Wrapper={
        ownWrapper ? ownWrapper : withBackground ? FrameWithBackground : Frame
      }
      CellWrapper={DetailsCellWithFullRowStyled}
      infoMeta={{ fields: CellsInfoArray }}
    />
  );
};

export default HistoryItemSingleFrame;
