import React from "react";
import { OwcButton, OwcModalDialog } from "@one/react";

export const ConfirmDialog = ({
  testid = "confirm-dialog",
  title = "",
  content = "",
  cancelText,
  approveText,
  cancelVariant,
  cancelColor,
  approveColor,
  approveVariant,
  onCancel,
  onApprove,
  close,
  open,
  children,
  renderActions,
  isDivider = false,
  selectedReason,
  size = "sm",
  disableBackdropClick = false
}) => {
  const handleCancel = () => {
    close();
    if (onCancel) {
      onCancel();
    }
  };
  const handleApprove = () => {
    close();
    if (onApprove) {
      onApprove();
    }
  };
  return (
    <OwcModalDialog
      visible={open}
      data-testid={testid}
      disableScrollLock
      size={size}
      className="owcmodalZIndex"
      disableBackdropClick={disableBackdropClick}
      onVisibleChange={(event) => {
        if (!event?.detail) {
          handleCancel();
        }
      }}
    >
      <div slot="header" data-testid="confirm-dialog-title">
        {title}
      </div>
      {/* {isDivider && <Divider data-testid="divider" />} */}
      <div data-testid="confirm-dialog-content">
        {children ? (
          children
        ) : (
          <div data-testid="confirm-dialog-content-text">
            {open ? content : ""}
          </div>
        )}
      </div>
      <div
        slot="actions"
        data-testid="confirm-dialog-actions"
        style={{
          justifyContent: "flex-end",
          display: "flex"
        }}
      >
        {typeof renderActions === "function" ? (
          renderActions({
            cancelColor,
            cancelVariant,
            approveColor,
            approveVariant,
            cancelText,
            approveText,
            close
          })
        ) : (
          <div style={{ display: "flex" }}>
            <OwcButton
              data-testid="confirm-dialog-actions-button-cancel"
              onClick={handleCancel}
              variant="secondary"
              style={{ marginRight: 32 }}
            >
              {cancelText || null}
            </OwcButton>
            <OwcButton
              data-testid="confirm-dialog-actions-button-approve"
              onClick={handleApprove}
              color={approveColor && "primary"}
              variant="primary"
              disabled={!selectedReason}
            >
              {approveText || null}
            </OwcButton>
          </div>
        )}
      </div>
    </OwcModalDialog>
  );
};
export default ConfirmDialog;
